import { Banner, Swiper } from 'common'
import { Item as ItemComponenet } from 'components'
import { Photos } from 'assets'
// import { createDocs } from 'config/firebase/utils'
// import { updateDocFire } from 'config/firebase/utils'

export default function Item() {
  // updateDocFire()
  // createDocs()
  return (
    <div>
      <Swiper.Carousel
        height="md"
        data={[Photos.CarouselNuocep]}
      />
      <ItemComponenet />
      <Banner.Free />
    </div>
  )
}
