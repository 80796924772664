import { Photos } from 'assets'
import { SizeGlass } from 'constants/enum'

export const FEATURE_GIFTS: IVoucher[] = [
  {
    title: 'Áo Hoodies Trắng',
    point: 800,
    photo: Photos.Gift1,
    group: 'Nước Ép Signature',
  },
  {
    title: 'Fresh Energy 350ml',
    point: 500,
    group: 'Nước Ép Signature',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/%231.4-fresh-energy.jpg.png?alt=media&token=b9224f10-3c67-402f-917b-7850a8e8543f',
  },
  {
    title: 'Ly Sứ Trắng',
    point: 700,
    group: 'Nước Ép Signature',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/ly%20su%20trang.png?alt=media&token=c6e383ef-01bd-44e9-aea4-3bc108975e95',
  },
]
export const DRINKS_MONTH: IVoucher[] = [
  {
    title: 'Nước Ép Ổi',
    point: 800,
    group: 'Nước Ép Đóng Chai',
    ml: SizeGlass['350ml'],
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
  },
  {
    title: 'Nước Ép Cà Rốt',
    point: 800,
    group: 'Nước Ép Đóng Chai',
    ml: SizeGlass['250ml'],
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
  },
  {
    title: 'Nước Ép Cà Rốt',
    point: 800,
    group: 'Nước Ép Đóng Chai',
    ml: SizeGlass['250ml'],
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
  },
  {
    title: 'Nước Ép Ổi',
    point: 800,
    group: 'Nước Ép Đóng Chai',
    ml: SizeGlass['350ml'],
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
  },
  {
    title: 'Nước Ép Cà Rốt',
    point: 800,
    group: 'Nước Ép Đóng Chai',
    ml: SizeGlass['250ml'],
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
  },
  {
    title: 'Nước Ép Cà Rốt',
    point: 800,
    group: 'Nước Ép Đóng Chai',
    ml: SizeGlass['250ml'],
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
  },
]
export const FOOD_MONTH: IVoucher[] = [
  {
    title: 'Vibrant Beet-Raspberry',
    point: 800,
    group: 'Sinh Tố Ly',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/vibrant.png?alt=media&token=e038d0fb-dfba-4b17-9da2-8b33af137dad',
  },
  {
    title: 'Strawberry Coconut Milk',
    point: 500,
    group: 'Sinh Tố Ly',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/strawberry.png?alt=media&token=cbd07541-6969-4a8a-bc88-403eab595531',
  },
  {
    title: 'Antioxidant Berry',
    point: 700,
    group: 'Sinh Tố Ly',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/antioxidant.png?alt=media&token=1870ac2e-5305-4074-92e6-5730c646f130',
  },
]
export const ITEMS: IVoucher[] = [
  {
    title: 'Ly Sứ Mùa Hè',
    point: 700,
    group: 'Item',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/ly%20su%20mau%20he.png?alt=media&token=f271c7e3-74b5-4aae-99dc-f021f161fefb',
  },
  {
    title: 'Móc Khoá Yêu Thương',
    point: 700,
    group: 'Item',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/moc%20khoa%20yeu%20thuong.png?alt=media&token=74ad5ec8-104a-435b-b363-cfc72f760f56',
  },
]
