import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  termsOfUseWrapper: {
    padding: '40px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 10%)',
    borderRadius: 10,
    maxHeight: '739px',
    overflowY: 'auto',
    ul: {
      marginLeft: 20,
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: '20px',
    },
  },
}))
