import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  contactFeedbackWrapper: {
    padding: '0 40px',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 10%)',
    borderRadius: 10,
    '.box': {
      fontSize: 12,
      paddingBottom: 20,
      paddingTop: 20,
      borderBottom: '1px solid #F5F5F5',
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
  },
}))
