import { Box, Stack, TextInput } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useStyles } from './styles'

const Address = ({
  formMoreAdress,
  idx,
}: {
  formMoreAdress: UseFormReturnType<IMoreAdress>
  idx: number
}) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.addressHomeWrapper}>
      <Stack spacing={15}>
        <TextInput
          label="Tên người nhận"
          variant="filled"
          placeholder="Nhập tên của bạn"
          radius={10}
          {...formMoreAdress.getInputProps(`listAddress.${idx}.recipientName`)}
        />
        <TextInput
          label="Số điện thoại"
          variant="filled"
          placeholder="037.7871.681"
          radius={10}
          {...formMoreAdress.getInputProps(`listAddress.${idx}.phone`)}
        />
        <TextInput
          label="Tên địa chỉ"
          variant="filled"
          placeholder="nơi ngoại tình, bạn gái cũ,..."
          radius={10}
          {...formMoreAdress.getInputProps(`listAddress.${idx}.addressName`)}
        />
        <TextInput
          label="Địa chỉ"
          variant="filled"
          placeholder="123 Le Thanh Ton, Quan 1, HCM "
          radius={10}
          {...formMoreAdress.getInputProps(`listAddress.${idx}.address`)}
        />
        <TextInput
          label="Toà nhà, số tầng (nếu có)"
          variant="filled"
          placeholder="landmark 81"
          radius={10}
          {...formMoreAdress.getInputProps(
            `listAddress.${idx}.wholeHouseNumberFloors`
          )}
        />
        <TextInput
          label="Ghi chú khác"
          variant="filled"
          placeholder="gần, đối diện, cạnh,..."
          radius={10}
          {...formMoreAdress.getInputProps(`listAddress.${idx}.note`)}
        />
      </Stack>
    </Box>
  )
}

export default Address
