export const VOUCHER: IVoucher[] = [
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Tặng 60.000đ cho bạn mới lần đầu đặt hàng!',
    code: 'CHAOBANMOI',
    date: '15/10/2022',
    description: `Hôm nay trời mây mát mẻ, trời xanh trong lành. Hãy để đội ngũ
                  vận chuyển ONE gửi đến bạn nhiều năng lượng tích cực & tình
                  yêu thương! Tặng các thành viên thân yêu voucher giảm giá
                  shipping 50%!`,
    type: 'save',
    checkList: ['Số lượng có hạn', 'Chỉ áp dụng khu vực thành phố Hồ Chí Minh'],
  },
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Giảm 20% đơn hàng 150.000đ',
    code: 'CHAOBANMOI',
    date: '15/10/2022',
    type: 'expired',
  },
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Tặng 60.000đ cho bạn mới lần đầu đặt hàng!',
    code: 'CHAOBANMOI',
    date: '15/10/2022',
    type: 'saved',
  },
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Giảm 50% đơn hàng 250.000đ',
    code: 'CHAOBANMOI',
    date: '15/10/2022',
    type: 'save',
  },
]
export const GIFT_EXCHANGED: IVoucher[] = [
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Giảm 20% đơn hàng 150.000đ',
    date: '15/10/2022 15:25',
    type: 'use',
    ml: '350ml',
    point: -150,
    group: 'Nước Ép Ly',
  },
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Giảm 20% đơn hàng 150.000đ',
    date: '15/10/2022 15:25',
    type: 'use',
    ml: '350ml',
    point: -150,
    group: 'Nước Ép Ly',
  },
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Giảm 20% đơn hàng 150.000đ',
    date: '15/10/2022 15:25',
    type: 'use',
    ml: '350ml',
    point: -150,
    group: 'Nước Ép Ly',
  },
  {
    photo:
      'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
    title: 'Giảm 20% đơn hàng 150.000đ',
    date: '15/10/2022 15:25',
    type: 'use',
    ml: '350ml',
    point: -150,
    group: 'Nước Ép Ly',
  },
]
