import { useSearchParams } from 'react-router-dom'

export const useDecodeSearchParams = <T,>() => {
  const [queryParameters] = useSearchParams()
  const params: T = {} as T
  queryParameters.forEach((value, key) => {
    params[key] = value
  })
  return params
}
