import { Accordion as AccordionM, Box } from '@mantine/core'
import { Svgs } from 'assets'
import { useStyles } from './styles'

type IProps = {
  accordionData: {
    panel: JSX.Element
    control: string
    icon: JSX.Element
  }[]
  addNewAddress?: () => void
}
export default function Accordion({ accordionData, addNewAddress }: IProps) {
  const { classes } = useStyles()
  return (
    <Box className={classes.accordionWrapper}>
      <AccordionM mx="auto" variant="filled" defaultValue="customization">
        {accordionData.map((val, idx) => (
          <AccordionM.Item key={idx} value={val.control}>
            <AccordionM.Control icon={val.icon}>
              {val.control}
            </AccordionM.Control>
            <AccordionM.Panel>{val.panel}</AccordionM.Panel>
          </AccordionM.Item>
        ))}
        {addNewAddress && (
          <AccordionM.Item value="Thêm địa chỉ mới">
            <AccordionM.Control
              chevron={<div></div>}
              icon={<Svgs.Plusaddress />}
              onClick={addNewAddress}
            >
              Thêm địa chỉ mới
            </AccordionM.Control>
          </AccordionM.Item>
        )}
      </AccordionM>
    </Box>
  )
}
