import './styles.scss'
import { Center, Stack, Text, clsx } from '@mantine/core'
import { useAppSelector } from 'app/hooks'

export const SlashScreen = () => {
  const { loadingOverlay } = useAppSelector(state => state)

  return (
    <div className="animation-body">
      <div className="animation-container">
        <div className="lightning-container">
          <div className="lightning white" />
          <div className="lightning red" />
        </div>
        <div className="boom-container">
          <div className="shape circle big white" />
          <div className="shape circle white" />
          <div className="shape triangle big yellow" />
          <div className="shape disc white" />
          <div className="shape triangle blue" />
        </div>
        <div className="boom-container second">
          <div className="shape circle big white" />
          <div className="shape circle white" />
          <div className="shape disc white" />
          <div className="shape triangle blue" />
        </div>
      </div>
      {/* <div className="footer">Loading...</div> */}
      <div className="classic-4" title={loadingOverlay.contentLoading}></div>
    </div>
  )
}

export const LoadingProgress = ({ active }: { active?: boolean }) => {
  return (
    <Stack spacing={5}>
      <Center>
        <div id="wrapperloader">
          <div className={clsx('loader', [{ active }])} />
          <div className={clsx('check_icon', [{ active }])} />
        </div>
      </Center>
      {active && (
        <Text size={20} weight={700}>
          Đăng ký thành công
        </Text>
      )}
    </Stack>
  )
}
