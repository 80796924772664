import { Buffer } from 'buffer'
import { DD_MM_YYY } from 'constants/index'
import { SizeGlass } from 'constants/enum'
import { format } from 'date-fns'

export function formatNumber(
  value?: number,
  digit?: number,
  offsetRate?: number,
  toFixed?: boolean,
  failoverValue: string = '0'
) {
  if (value == null || isNaN(value)) {
    return failoverValue
  }

  let data = value

  if (offsetRate != null) {
    data = value / offsetRate
  }

  let tempValueString = data.toString()
  let prefix = ''

  if (tempValueString[0] === '-') {
    prefix = '-'
    tempValueString = tempValueString.substring(1, tempValueString.length)
  }

  try {
    const tempValue = Number(tempValueString)
    let fractionDigit = 0
    if (digit != null) {
      fractionDigit = digit
    }
    if (fractionDigit > 0) {
      const temp = +`${Math.round(
        Number(`${Number(tempValue.toString())}e+${fractionDigit}`)
      )}e-${fractionDigit}`
      let fractionString = ''
      let i = ''
      if (toFixed === true) {
        i = temp.toFixed(fractionDigit)
        fractionString = i.substring(i.indexOf('.'), i.length)
        i = i.substring(0, i.indexOf('.'))
      } else {
        i = temp.toString()
        if (temp.toString().indexOf('.') > 1) {
          fractionString = temp
            .toString()
            .substring(temp.toString().indexOf('.'), temp.toString().length)
          i = temp.toString().substring(0, temp.toString().indexOf('.'))
        }
      }
      return prefix + i.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + fractionString
    } else {
      const temp = +`${Math.round(
        Number(`${Number(tempValue.toString())}e+${fractionDigit}`)
      )}e-${fractionDigit}`
      const i = temp.toString()
      return prefix + i.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  } catch {
    return ''
  }
}
export function formatDateV2(date: string, formatString: string = DD_MM_YYY) {
  return format(new Date(date), formatString)
}
export function phoneRegex(phone: string) {
  return /(84|[3|5|7|8|9])+([0-9]{8})\b/g.test(String(phone))
}
export const encodeBase64 = (data: string) => {
  return Buffer.from(data).toString('base64')
}
export const decodeBase64 = (data?: string) => {
  return Buffer.from(data ?? '', 'base64').toString()
}

export const extracMlAndPrice = (
  mlAndPrice: IProduct['mlAndPrice'],
  ml?: SizeGlass
) => {
  return mlAndPrice[String(ml)]
}

export function getKeyByValue<K, T>(num: K, value: string) {
  const indexOfS = Object.values(num).indexOf(value as unknown as T)

  const key = Object.keys(num)[indexOfS]

  return key
}

export const splitName = (name?: string | null) => {
  if (name) {
    const [firstName, ...lastName] = name.split(' ').filter(Boolean)
    return {
      firstName: firstName,
      lastName: lastName.join(' '),
    }
  } else {
    return {
      firstName: '',
      lastName: '',
    }
  }
}
