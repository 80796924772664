import {
  COFFEE_HOT,
  TEST2,
} from 'constants/coffee'
import {
  JUICE,
  JUICE_GLASS,
} from 'constants/juice'
import { TEA } from 'constants/tea'
import {
  VITAMIN_BOTTLE,
  VITAMIN_CUP,
} from 'constants/vitamin'
import {
  YOGURT_GRINDIN,
  YOGURT_ICE,
} from 'constants/yogurt'
import { FirebaseError } from 'firebase/app'
import {
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from 'firebase/auth'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  Query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { auth, database } from './index'

// export const getDocsData2 = <T>(
//   querySnapshot: QuerySnapshot<T>,
//   type: 'object' | 'array'
// ) => {
//   let result
//   if (type === 'array') {
//     querySnapshot.forEach(doc => {
//       // doc.data()x is never undefined for query doc snapshots
//       result = [...result, { ...doc.data(), id: doc.id }]
//     })
//   } else {
//     const result = querySnapshot.docs.map(doc => {
//       return { ...doc.data(), id: doc.id }
//     })
//   }

//   return result
// }

export const getDocsData = async <T>(
  q: Query<T>
) => {
  // Create a query against the collection.
  // const q = query(citiesRef, where('txtPhone', '==', '0902248599'))
  const querySnapshot = await getDocs(q)
  // let result: IUsers
  // querySnapshot.forEach(doc => {
  //   // doc.data() is never undefined for query doc snapshots
  //   result = { id: doc.id, ...doc.data() }
  // })
  // console.log('result', result)
  const data = querySnapshot.docs.map(doc => {
    return { ...doc.data(), id: doc.id }
  })
  return data
}
const colref = collection(
  database,
  'products/vitamin/1'
)

getDocs(colref)
  .then(snapshot => {
    console.log('snapshot', snapshot.docs)
    let vitamin: { id: string }[] = []
    snapshot.docs.forEach(doc => {
      vitamin.push({ ...doc.data(), id: doc.id })
    })
    console.log('vita', vitamin)
  })
  .catch(err => console.log('err', err))

export async function readData() {
  const docRef = doc(
    database,
    'orders',
    '3ThzFXiDzSb4fLJtf1o1'
  )

  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    console.log('Document data:', docSnap.data())
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!')
  }
}

// nTbVdJ2FgI1DxaJehnD0
// const washingtonRef = doc(database, pathCollection.coffee, 'test')

// // Set the "capital" field of the city 'DC'
// export async function setCapital() {
//   const x = await updateDoc(washingtonRef, {
//     aboutProduct: DEMO_DATA,
//   })
//   console.log('x', x)
// }

export const queryFirestore = async (
  txtPhone: string
) => {
  // const citiesRef = collection(database, 'users') as CollectionReference<IUsers>
  const q = query(
    collection(database, 'users'),
    where('txtPhone', '==', txtPhone)
  )
  // Create a query against the collection.
  // const q = query(citiesRef, where('txtPhone', '==', '0902248599'))
  const querySnapshot = await getDocs(q)
  let result
  querySnapshot.forEach(doc => {
    // doc.data() is never undefined for query doc snapshots
    console.log('doc.data() ', doc.data())
    result = { id: doc.id, ...doc.data() }
  })
  return result
}
export const FacebookProvider = async () => {
  const provider = new FacebookAuthProvider()
  try {
    const result = await signInWithPopup(
      auth,
      provider
    )
    const credantial =
      FacebookAuthProvider.credentialFromResult(
        result
      )
    // The signed-in user info.
    const user = result.user

    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    const credential =
      FacebookAuthProvider.credentialFromResult(
        result
      )
    const accessToken = credential?.accessToken

    // IdP data available using getAdditionalUserInfo(result)
    // ...
    console.log(
      'user',
      user,
      credential,
      accessToken,
      credantial
    )
  } catch (err) {
    // Handle Errors here.
    const error = err as FirebaseError
    const errorCode = error.code
    const errorMessage = error.message
    // The email of the user's account used.
    const email = error.customData?.email
    // The AuthCredential type that was used.
    const credential =
      FacebookAuthProvider.credentialFromError(
        error
      )
    console.log('errorCode', error)

    // ...
  }
}

export const createDocs = async () => {
  // const cityRef = doc(database, pathCollection.coffee)
  // setDoc(cityRef, { data: [...COFFEE_ICE, ...COFFEE_HOT, ...COFFEE_BOTTLE] })
  const array = TEST2
  for (let i = 0; i < array.length; i++) {
    const element = array[i]
    await addDoc(
      collection(database, 'products'),
      element
    )
    console.log('1')
  }
  console.log('cityRef done')
}

export const deletdde = async () => {
  await deleteDoc(
    doc(
      database,
      'orders',
      '230406_049130c8-964e-4ff7-a533-c9f1863bb229'
    )
  )
}
export const updateDocFire = async () => {
  const frankDocRef = doc(
    database,
    'products',
    'MWhQJMhYzBHvsNfdZW7r'
  )
  await updateDoc(frankDocRef, {
    'property.isHidden': true,
  })
}
