import { createStyles } from '@mantine/core'

export default createStyles(_theme => ({
  deliveryTimeWrapper: {
    display: 'flex',
    gap: 20,
    '>div': {
      width: '100%',
      '.mantine-InputWrapper-root': {
        cursor: 'pointer',
        '	.mantine-Select-item': {
          // applies styles to selected item
          fontSize: 14,
          paddingTop: 12,
          paddingBottom: 12,
          borderRadius: 0,
          '&[data-selected]': {
            '&, &:hover': {
              backgroundColor: '#E5E5E5',
              color: '#000',
              borderRadius: 0,
            },
          },

          // applies styles to hovered item (with mouse or keyboard)
          '&[data-hovered]': {},
        },
        '.mantine-Select-dropdown': {
          border: 'none',
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
          borderRadius: 10,
          overflow: 'hidden',
        },
        '.mantine-Select-wrapper': {
          // boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
        },
        '.mantine-Select-itemsWrapper': {
          padding: 0,
        },
        '.mantine-Select-input': {
          height: 40,
        },
      },
    },
    [`@media (max-width: ${_theme.breakpoints.xs}px)`]: {
      flexDirection: 'column',
    },
  },
}))
