import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  settingWrapper: {},
  langageWrapper: {
    '.mantine-Checkbox-root': {
      cursor: 'pointer',
      padding: '12px 0',
      '&:nth-of-type(2)': {
        borderTop: '1px solid #F5F5F5',
      },
      '.mantine-Checkbox-body': {
        justifyContent: 'space-between',
        width: '100%',
        '.mantine-Checkbox-labelWrapper': {
          width: '100%',
          cursor: 'pointer',
          '.mantine-Checkbox-label': {
            cursor: 'pointer',
          },
        },
        '.mantine-Checkbox-inner': {
          cursor: 'pointer',
          '.mantine-Checkbox-input': {
            background: 'unset',
            border: 'unset',
          },
        },
        '.mantine-Checkbox-icon': {
          color: '#000!important',
          width: 15,
        },
      },
    },
  },
  screenModeWrapper: {
    fontSize: 14,
    '.mantine-Stack-root': {
      '>div': {
        padding: '13px 0',
        '&:nth-of-type(even)': {
          borderTop: ' 1px solid #F5F5F5',
        },
      },
    },
    '.mantine-Switch-root': {
      display: 'flex',
      '.mantine-Switch-track': {
        height: 30,
        '.mantine-Switch-trackLabel': {
          paddingInline: 2,
        },
      },
    },
    '.mantine-Checkbox-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      '.mantine-Checkbox-input': {
        '&:checked': {
          background: 'unset',
          border: 'unset',
        },
      },
      '.mantine-Checkbox-icon': {
        width: 20,
      },
    },
  },
  linkAccountWrapper: {
    fontSize: 14,
    '.mantine-Stack-root': {
      '>div': {
        cursor: 'pointer',
        padding: '13px 0',
        '&:hover': {
          // background: '#F5F5F5',
        },
        '&:nth-of-type(even)': {
          borderTop: ' 1px solid #F5F5F5',
        },
      },
    },
  },
  modalBody: {
    '.btn-Custom': {
      width: '100%',
      height: 30,
      padding: '0!important',
      '.mantine-Button-icon': {
        transform: 'scale(0.75)',
      },
      '.mantine-Button-label': {
        fontSize: 14,
      },
    },
    '.mantine-Indicator-common': {
      background: 'unset',
      display: 'block',
      minWidth: 'unset',
      height: 'unset',
      padding: 0,
      bottom: 13,
      right: 15,
      svg: {
        width: 30,
        height: 30,
      },
    },
  },
}))
