import { Banner, Swiper } from 'common'
import { Coffee as CoffeeComponenet } from 'components'
import { Photos } from 'assets'
export default function Coffee() {
  return (
    <div>
      <Swiper.Carousel
        height="md"
        data={[
          Photos.Cafe1,
          Photos.Cafe2,
          Photos.Cafe3,
          Photos.Cafe4,
          Photos.Cafe5,
        ]}
      />
      <CoffeeComponenet />
      <Banner.Free />
    </div>
  )
}
