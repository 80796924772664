import { createSlice } from '@reduxjs/toolkit'
import { doVitamin } from './action'

type IInitialState = IQueryState<IProduct[] | null>

const initialState: IInitialState = { data: null, status: {} }

export const vitamin = createSlice({
  name: 'VITAMIN',
  initialState,
  reducers: {
    // doVitamin: (state: IInitialState, action: PayloadAction<string>) => {
    //   state.data = null
    //   state.status.isLoading = true
    // },
  },
  extraReducers: builder => {
    builder.addCase(doVitamin.pending, state => {
      state.status.isLoading = true
    }),
      builder.addCase(doVitamin.rejected, (state, action) => {
        state.status.errorMessage = action.error
        state.status.isLoading = false
      }),
      builder.addCase(doVitamin.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.data = action.payload
      })
  },
})
export default vitamin
