import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  cardPhotoWrapper: {
    '.title': {
      marginTop: 60,
      textAlign: 'center',
      marginBottom: 30,
    },
    '.photo': {
      overflow: 'hidden',
      borderRadius: 10,
      position: 'relative',
      '.imgPhoto': {
        borderRadius: 10,
        objectFit: 'contain',
        margin: 'auto',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
      },
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      '.title': {
        marginTop: 30,
      },
    },
  },
}))
