import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  userWrapper: {
    margin: '30px 0',
  },
  tabsWrapper: {
    // '.mantine-Grid-root': {
    // flex: 1,
    // '.mantine-Tabs-tabsList': {
    //   border: 'unset',
    //   overflow: 'hidden',
    //   boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
    //   borderRadius: '10px',
    //   '.mantine-Tabs-tab': {
    //     border: 'unset',
    //     padding: '11px',
    //     '&[data-active]': {
    //       boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    //     },
    //     '.mantine-Tabs-tabLabel': {
    //       width: '100%',
    //       fontSize: 12,
    //     },
    //   },
    // },
    // },
    '.mantine-SimpleGrid-root': {
      width: '100%',
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        marginTop: 20,
      },
      '.mantine-Tabs-tabsList': {
        border: 'unset',
        height: 'max-content',
        overflow: 'hidden',
        boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        '.mantine-Tabs-tab': {
          border: 'unset',
          padding: '11px',
          '&[data-active]': {
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          },
          '.mantine-Tabs-tabLabel': {
            width: '100%',
            fontSize: 12,
          },
        },
      },
    },
    '.mantine-ActionIcon-root': {
      svg: {
        width: 20,
        height: 20,
        // path: {
        //   fill: '#C4C4C4',
        // },
      },
    },
  },
}))
