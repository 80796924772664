import { Box } from '@mantine/core'
import {
  GoogleMap,
  GoogleMapProps,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { useStyles } from './styles'
import MapAutocomplete from 'components/CartComponent/Delivery/MapAutocomplete'
import React, { useCallback, useEffect, useState } from 'react'

function GgMap(props: GoogleMapProps) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'YOUR_API_KEY',
  })
  const [myPlaces, setMyPlaces] = useState<{
    id: string
    pos: google.maps.LatLngLiteral
  }>({
    id: 'place1',
    pos: { lat: 10.73442024256404, lng: 106.65329617219255 },
  })
  const onLoad = useCallback(function callback(_map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
  }, [])
  const onUnmount = useCallback(function callback() {
    // do your stuff before map is unmounted
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: 600,
        width: '100%',
        borderRadius: 10,
      }}
      center={myPlaces.pos}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={ev => {
        if (ev.latLng) {
          setMyPlaces({
            id: 'new place',
            pos: { lat: ev.latLng.lat(), lng: ev.latLng.lng() },
          })
        }
      }}
      {...props}
    >
      <Marker
        title="ONE Drink & Food"
        label="ONE Drink & Food"
        key={myPlaces.id}
        position={myPlaces.pos}
        // onLoad={marker => markerLoadHandler(marker, place)}
        // onClick={event => markerClickHandler(event, place)}
        // Not required, but if you want a custom icon:
      />
    </GoogleMap>
  ) : (
    <>Loading</>
  )
}

export default React.memo(GgMap)

export const PickLpcation = ({
  valueAddress,
  onChange,
}: {
  onChange: (e: string | null) => void
  valueAddress: string
}) => {
  const { classes } = useStyles()
  const [myPlaces, setMyPlaces] = useState<{
    id: string
    pos: google.maps.LatLngLiteral
  }>({
    id: 'place1',
    pos: { lat: 10.73442024256404, lng: 106.65329617219255 },
  })
  const onLoad = useCallback(function callback(_map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
  }, [])
  const onUnmount = useCallback(function callback() {
    // do your stuff before map is unmounted
  }, [])

  useEffect(() => {
    console.log('co')
    ;(async () => {
      const results = await getGeocode({
        address: valueAddress,
      })
      const { lat, lng } = getLatLng(results[0])
      console.log('value', results[0], valueAddress)
      setMyPlaces({
        id: 'new place',
        pos: { lat, lng },
      })
    })()
  }, [valueAddress])
  return (
    <Box className={classes.pickLocationWrapper}>
      <Box mb={20}>
        <MapAutocomplete
          error=""
          valueAddress={valueAddress}
          onChange={onChange}
        />
      </Box>
      <GoogleMap
        mapContainerStyle={{
          height: 332,
          borderRadius: 10,
        }}
        options={{
          zoomControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        }}
        center={myPlaces.pos}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={ev => {
          if (ev.latLng) {
            setMyPlaces({
              id: 'new place',
              pos: { lat: ev.latLng.lat(), lng: ev.latLng.lng() },
            })
          }
        }}
      >
        <Marker
          key={myPlaces.id}
          position={myPlaces.pos}
          // onLoad={marker => markerLoadHandler(marker, place)}
          // onClick={event => markerClickHandler(event, place)}
          // Not required, but if you want a custom icon:
        />
      </GoogleMap>
    </Box>
  )
}
