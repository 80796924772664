import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  accordionWrapper: {
    boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    overflow: 'hidden',
    '.mantine-Accordion-item': {
      border: '1px solid transparent',
      position: 'relative',
      zIndex: 0,
      transition: 'transform 150ms ease',

      '&[data-active]': {
        // transform: 'scale(1.03)',
        // boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        backgroundColor: theme.white,
      },
      '.mantine-Accordion-control': {
        padding: '15px 20px',
        fontSize: 14,
        '&:hover': {
          background: '#f8f9fa',
        },
        '.mantine-Accordion-chevron': {
          // '&[data-rotate]': {
          //   transform: 'rotate(-90deg)',
          // },
        },
      },
    },
  },
}))
