import { ActionIcon, Box, Button, Flex, Stack, Text } from '@mantine/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { MouseEvent } from 'react'
import { Svgs } from 'assets'
import useStyles from './styles'

const Voucher = ({
  photo,
  title,
  date,
  code,
  ml,
  point,
  type,
  group,
  from,
  description,
  checkList,
}: IVoucher & {
  from?: 'bag' | 'modal'
}) => {
  const { classes } = useStyles()
  const handleCode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
  }
  return (
    <Box className={classes.voucherWrapper}>
      <Flex className="voucher" justify="flex-start" align="center">
        <Box className="imgWrapper">
          <LazyLoadImage
            className="imgPhoto"
            src={photo}
            alt="With default placeholder"
          />
        </Box>
        {from === 'bag' ? (
          <Stack spacing={0} mx={20} justify="space-between">
            <Box>
              <Text size={14} weight="bold">
                {title}
              </Text>
              <Text size={14} weight="bold">
                {ml}
              </Text>
              <Text size={12}>{group}</Text>
              <Text size={12} weight="bold">
                {point} ONE
              </Text>
            </Box>
            <Text size={12}>{date} 15:25</Text>
          </Stack>
        ) : (
          <Box mx={22}>
            <Text className="title" size={14} weight="bold">
              {title}
            </Text>
            {description && from === 'modal' && (
              <Box mb={20} mt={10}>
                <Text size={14} mb={20}>
                  {description}
                </Text>
                {checkList &&
                  checkList.map((val, idx) => (
                    <Flex key={idx} mb={5} gap={10} align="center">
                      <ActionIcon size={16}>
                        <Svgs.Check />
                      </ActionIcon>
                      <Text
                        size={13}
                        maw="376px"
                        dangerouslySetInnerHTML={{ __html: val }}
                      />
                    </Flex>
                  ))}
              </Box>
            )}
            <Text size={14}>Mã: {code}</Text>
            <Text size={14}>Đến {date}</Text>
            {from === 'modal' && (
              <Button onClick={handleCode} fullWidth className="btnSaveCode">
                Lưu mã khuyến mãi
              </Button>
            )}
          </Box>
        )}
        {type !== 'exchanged' && from !== 'modal' && (
          <Button
            disabled={type === 'saved' || type === 'expired'}
            className="btnApply"
            fullWidth
            onClick={handleCode}
          >
            {type === 'use'
              ? 'Sử dụng'
              : type === 'expired'
              ? 'Hết hạn'
              : from === 'bag'
              ? 'Xóa'
              : type === 'save'
              ? 'Lưu mã'
              : 'Đã lưu'}
          </Button>
        )}
      </Flex>
    </Box>
  )
}
export default Voucher
