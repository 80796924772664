import {
  Box,
  Center,
  Container,
  Divider,
  Group,
  SimpleGrid,
  Text,
} from '@mantine/core'
import { IPath, SizeGlass } from 'constants/enum'
import { Link } from 'react-router-dom'
import { Svgs } from 'assets'
import { Voucher } from 'common'
import { useStyles } from './styles'

type IProps = {
  type?: 'modal'
}
const History = ({ type }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <Box
      className={cx(classes.giftHisotyWrapper, [
        {
          gifthistoryWrapper__modal: type === 'modal',
        },
      ])}
    >
      <Container fluid>
        <Text size={24} className="title" weight="bold">
          Lịch Sử Đổi Thưởng
        </Text>
        {type === 'modal' && <Divider my={20} color="#F5F5F5" />}
        <Box className="gift_exchanged">
          <HistoryBox
            title="Quà Đã Đổi"
            description="Những phần quà bạn đã đổi gần đây"
            type={type}
            data={[
              {
                type: 'exchanged',
                title: 'Fresh Energy',
                code: '123456789',
                group: 'Nước Ép Ly',
                photo:
                  'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
                date: '12/12/2021',
                ml: SizeGlass['350ml'],
                point: -600,
              },
              {
                type: 'exchanged',
                title: 'Fresh Energy',
                group: 'Cà Phê Đá',
                code: '123456789',
                photo:
                  'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
                date: '12/12/2021',
                ml: SizeGlass['350ml'],
                point: -600,
              },
              {
                type: 'exchanged',
                title: 'Fresh Energy',
                code: '123456789',
                photo:
                  'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
                date: '12/12/2021',
                ml: SizeGlass['350ml'],
                group: 'Cà Phê Đá',
                point: -600,
              },
              {
                type: 'exchanged',
                title: 'Fresh Energy',
                code: '123456789',
                photo:
                  'https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg',
                date: '12/12/2021',
                ml: SizeGlass['350ml'],
                group: 'Cà Phê Đá',
                point: -600,
              },
            ]}
          />
        </Box>
        {type !== 'modal' && (
          <HistoryBox
            title="Mã đã Sử Dụng"
            description="Những mã khuyến mãi đã sử dụng"
            data={[
              {
                code: '123456789',
                type: 'exchanged',
                photo:
                  'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
                title: 'Giảm 50% phí ship trong khu vực TP.Hồ Chí Minh',
                date: '12/12/2021',
              },
              {
                code: '123456789',
                type: 'exchanged',
                photo:
                  'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
                title: 'Giảm 50% phí ship trong khu vực TP.Hồ Chí Minh',
                date: '12/12/2021',
              },
              {
                code: '123456789',
                type: 'exchanged',
                photo:
                  'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
                title: 'Giảm 50% phí ship trong khu vực TP.Hồ Chí Minh',
                date: '12/12/2021',
              },
              {
                code: '123456789',
                type: 'exchanged',
                photo:
                  'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi.png?alt=media&token=01ff9aea-c652-4ace-a6b8-f568359086f2',
                title: 'Giảm 50% phí ship trong khu vực TP.Hồ Chí Minh',
                date: '12/12/2021',
              },
            ]}
          />
        )}
      </Container>
    </Box>
  )
}
export default History

const HistoryBox = ({
  type,
  title,
  description,
  data,
}: {
  type?: 'modal'
  title: string
  description: string
  data: IVoucher[]
}) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.historyBox}>
      <Box className="giftHisoty">
        <Box className="content_box">
          <Box className="content_box-GiftWrapper">
            <Box mx={20}>
              <Group position="apart">
                <Text size={18} weight="bold">
                  {title}
                </Text>
                {type === 'modal' && (
                  <Link to={IPath.Gift_History}>
                    <Center className="watchMore">
                      <Text size={12}>Xem tất cả</Text>
                      <Svgs.ArrowLeft />
                    </Center>
                  </Link>
                )}
              </Group>
              <Text mb={20}>{description}</Text>
            </Box>
            <SimpleGrid
              cols={3}
              breakpoints={[
                { maxWidth: 'md', cols: 1 },
                { maxWidth: 1330, cols: 2 },
              ]}
              spacing={20}
              verticalSpacing={20}
            >
              {[...data, ...data].map((val, idx) => (
                <Box key={idx} className="cardGift">
                  <Voucher {...val} />
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
