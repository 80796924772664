import { ActionIcon, Box, Group, Select, Text } from '@mantine/core'
import { MdLocationOn } from 'react-icons/md'
import { ReactNode, forwardRef } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'
import useStyles from './styles'

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  description: string
}

const ItemSearch = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <ActionIcon variant="transparent">
            <MdLocationOn color="black" size={20} />
          </ActionIcon>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs">{description}</Text>
          </div>
        </Group>
      </div>
    )
  }
)

type IProps = {
  onChange: (e: string | null) => void
  valueAddress: string
  error: ReactNode
  disabled?: boolean
}
const MapAutocomplete = ({
  onChange,
  valueAddress,
  error,
  disabled,
}: IProps) => {
  const { classes } = useStyles()

  const {
    value,
    suggestions: { data },
    setValue,
    // clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      language: 'vi',
    },
    debounce: 300,
  })
  return (
    <Box className={classes.mapAutocompleteWrapper}>
      <Select
        mt={10}
        placeholder="Điền thông tin địa chỉ giao hàng của bạn"
        itemComponent={ItemSearch}
        data={data.map(sug => {
          return {
            label: sug.description,
            value: sug.description,
          }
        })}
        searchable
        maxDropdownHeight={400}
        nothingFound="Nobody here"
        onSearchChange={setValue}
        searchValue={value === '' ? valueAddress : value}
        disabled={disabled}
        value={valueAddress}
        selectOnBlur={false}
        clearable
        filter={(value, item) =>
          item.label
            ? item.label
            : ''.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.description
                .toLowerCase()
                .includes(value.toLowerCase().trim())
        }
        onChange={e => {
          setValue(e ?? valueAddress)
          onChange(e)
        }}
        error={error}
      />
    </Box>
  )
}

export default MapAutocomplete
