import { DocumentReference, addDoc, collection } from 'firebase/firestore'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'
import { pathCollection } from 'config/firebase/collection'
import { showNotification } from 'common'

export const doRegisterInfor = createAsyncThunk(
  pathCollection.regiserInfor,
  async (params: IFormFooter, thunkAPI) => {
    try {
      const res: DocumentReference = await addDoc(
        collection(database, pathCollection.regiserInfor),
        params
      )
      showNotification({
        type: 'success',
        title: 'Gửi thông tin',
        message: 'Thành công',
      })
      return res.id
    } catch (error) {
      console.log('error send infor', error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)
