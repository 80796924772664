import { ActionIcon, Box, Center, Group, Stack, Text } from '@mantine/core'
import { IInitialStateAddToCart, onRemoveItem } from 'reducer/Carts'
import { Modal } from 'common'
import { Svgs } from 'assets'
import { extracMlAndPrice, formatNumber } from 'utils'
import { useAppDispatch } from 'app/hooks'
import { useDisclosure, useHover } from '@mantine/hooks'
import { useState } from 'react'
import PickOption from 'components/InforProduct/PickOption'
import Rate from './Rate'
import Vouchers from './Vouchers'
import useStyles from './styles'

type IProps = {
  productList?: IInitialStateAddToCart
  type?: 'typeInfor' | 'orderLookup'
  isHiddenEditDelete?: boolean
  isSendRating?: boolean
}
export default function YourCart({
  productList,
  type,
  isSendRating,
}: // isHiddenEditDelete,
IProps) {
  const dispatch = useAppDispatch()
  const { classes } = useStyles()
  const { hovered, ref } = useHover()
  const [opened, { close, open }] = useDisclosure(false)
  const [isOpenVoucher, { close: closeVoucher, open: openVoucher }] =
    useDisclosure(false)
  const [prodModalUpdate, setProductModalUpdate] =
    useState<Pick<IProduct, 'id' | 'optionPicked' | 'amount'>>()
  const [isOverlay, setIsOverlay] = useState(false)
  return (
    <Box className={classes.yourCartWrapper}>
      {isOverlay && (
        <Box>
          <Rate onClose={() => setIsOverlay(false)} />
        </Box>
      )}
      <Group mb={20} position="apart">
        <Text className="your_cart-title" size={24} weight="bold">
          Giỏ hàng của bạn
        </Text>
        {isSendRating && (
          <Text
            onClick={() => setIsOverlay(true)}
            color="#FFBB36"
            size={12}
            italic
            weight="bold"
            className="cursor"
          >
            Gửi đánh giá {'>>'}
          </Text>
        )}
      </Group>
      {productList &&
        productList.productList.map((val, idx) => {
          const sizePrice = extracMlAndPrice(
            val.mlAndPrice,
            val?.optionPicked?.size
          )
          return (
            <Center
              key={idx}
              className="cartProduct"
              sx={{
                alignItems: 'stretch',
                marginBottom: 20,
              }}
            >
              <Box className="optionPicked">
                <Group position="apart">
                  <Text weight={700}>
                    {val.amount} x {val.name}
                  </Text>
                  <Text weight={700}>{formatNumber(sizePrice.price)}</Text>
                </Group>
                {val.optionPicked &&
                  Object.values(val.optionPicked).map((item, index) => (
                    <Group
                      key={index}
                      position="apart"
                      className="txt__description"
                    >
                      <Text>{item}</Text>
                      {/* <Text>{formatNumber(val.price)}</Text> */}
                    </Group>
                  ))}
              </Box>
              <Stack spacing={2} hidden={type === 'orderLookup'}>
                <ActionIcon
                  className="icon penIcon"
                  onClick={() => {
                    open()
                    setProductModalUpdate({
                      id: val.id,
                      optionPicked: val.optionPicked,
                      amount: val.amount,
                    })
                  }}
                >
                  <Svgs.PenIcon />
                </ActionIcon>
                <ActionIcon
                  className="icon closebtn"
                  onClick={() =>
                    dispatch(
                      onRemoveItem({
                        id: val.id,
                      })
                    )
                  }
                >
                  <Svgs.Closebtn />
                </ActionIcon>
              </Stack>
            </Center>
          )
        })}
      <Text className="your_cart-title" size={24} weight="bold" mt={30} mb={20}>
        Tổng cộng
      </Text>
      <Group className="group__apart" position="apart">
        <Text>Tổng tiền sản phẩm</Text>
        <Text>{formatNumber(productList?.totalOrderValue)}đ</Text>
      </Group>
      <Group className="group__apart" position="apart">
        <Text>Phí vận chuyển</Text>
        <Text>10.000đ</Text>
      </Group>
      <Group className="group__apart hiddenBorder" position="apart">
        <Center onClick={openVoucher} className="cursor">
          <Box h={24} ref={ref}>
            {hovered ? <Svgs.TagHover /> : <Svgs.Tag />}
          </Box>
          <Text pl={10} pr={10} weight="bold">
            Thẻ khuyến mãi
          </Text>
        </Center>
      </Group>
      {[
        { title: 'Giảm 20% gái trị đơn hàng', discount: -24000 },
        { title: 'Giảm 50% phí ship', discount: -44000 },
      ].map((item, idx) => (
        <Group
          key={idx}
          className="group__apart discount hiddenBorder"
          position="apart"
        >
          <Text>{item.title}</Text>
          <Text>{formatNumber(item.discount)}</Text>
        </Group>
      ))}
      <Group position="apart" mt={22}>
        <Text weight="bold" size={16}>
          Thành tiền
        </Text>
        <Text weight="bold" size={16}>
          {formatNumber(Number(productList?.totalOrderValue) - 10000)}
        </Text>
      </Group>
      <Modal
        title="Thẻ khuyến mãi"
        onClose={closeVoucher}
        opened={isOpenVoucher}
      >
        <Vouchers />
      </Modal>
      {prodModalUpdate && (
        <Modal onClose={close} opened={opened}>
          <PickOption status="UPDATE_CART" {...prodModalUpdate} />
        </Modal>
      )}
    </Box>
  )
}
