import { UserCredential } from 'firebase/auth'
import { createSlice } from '@reduxjs/toolkit'
import { onConfirmationResult, signGoogle, signInFacebook } from './actions'

const initialState: IQueryState<UserCredential | null> = {
  data: null,
  status: {},
}

export const confirmationResultUser = createSlice({
  name: 'Confirmation Result',
  initialState,
  reducers: {
    onClearConfirmationResultUser: () => {
      return initialState
    },
  },
  extraReducers: builder => {
    builder.addCase(onConfirmationResult.pending, state => {
      state.status.errorMessage = null
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
    }),
      builder.addCase(onConfirmationResult.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(onConfirmationResult.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      }),
      builder.addCase(signInFacebook.pending, state => {
        state.status.errorMessage = null
        state.status.isLoading = true
        state.status.isFailed = false
        state.status.isSucceeded = false
      }),
      builder.addCase(signInFacebook.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(signInFacebook.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      }),
      builder.addCase(signGoogle.pending, state => {
        state.status.errorMessage = null
        state.status.isLoading = true
        state.status.isFailed = false
        state.status.isSucceeded = false
      }),
      builder.addCase(signGoogle.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(signGoogle.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})

export const { onClearConfirmationResultUser } = confirmationResultUser.actions
