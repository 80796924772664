import { Box, Select as SelectM, SelectProps } from '@mantine/core'
import { Svgs } from 'assets'
import useStyles from './styles'

type IProps = {} & SelectProps

export default function Select(props: IProps) {
  const { classes } = useStyles()

  return (
    <Box className={classes.selectWrapper}>
      <SelectM
        radius={10}
        searchable
        nothingFound="No options"
        maxDropdownHeight={250}
        rightSection={<Svgs.ArrowDown />}
        selectOnBlur={false}
        clearButtonTabIndex={-1}
        // initiallyOpened={true}
        {...props}
      />
    </Box>
  )
}
