import { Container } from '@mantine/core'
import { Product2, Tabs } from 'common'
import { TABS } from 'constants/enum'
import { doQueryProducts } from 'reducer/Products/actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'

export default function Vitamin() {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector(state => state)
  useEffect(() => {
    dispatch(doQueryProducts(['Sinh Tố Ly', 'Sinh Tố Đóng Chai']))
  }, [])
  return (
    <Container fluid>
      <Tabs.Tabs
        title="SINH TỐ"
        data={[
          {
            tabLabel: TABS.VITAMIN_CUP,
            component: (
              <Product2
                data={products.data?.filter(
                  item => item.group === 'Sinh Tố Ly'
                )}
                status={products.status}
              />
            ),
          },
          {
            tabLabel: TABS.VITAMIN_BOTTLE,
            component: (
              <Product2
                data={products.data?.filter(
                  item => item.group === 'Sinh Tố Đóng Chai'
                )}
                status={products.status}
              />
            ),
          },
        ]}
      />
    </Container>
  )
}
