import {
  Avatar,
  Box,
  Button,
  Center,
  CopyButton,
  Flex,
  Group,
  Input,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Svgs } from 'assets'
import { doCreateUser, doQueryUser, doUpdateUser } from 'reducer/User/action'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'
import { useForm } from '@mantine/form'
import { usePrevious } from '@mantine/hooks'
import { useStyles } from './styles'
import { validateFormUser } from 'constants/validate'
import Select from 'customs/Select'
export default function PersonalInfo() {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { queryUserResult, updateUser, confirmationResultUser, createdUser } =
    useAppSelector(state => state)
  const previousValue = usePrevious({
    updateUser,
    createdUser,
    queryUserResult,
  })
  const phoneForm = useForm<IUsers>({
    initialValues: {
      ...(queryUserResult.data?.[0] as IUsers),
      dob: queryUserResult.data?.[0]?.dob
        ? new Date(queryUserResult.data?.[0]?.dob)
        : '',
    },
    transformValues: values => {
      return {
        ...values,
        dob: values.dob?.toString(),
      }
    },
    validate: validateFormUser(),
  })

  useEffect(() => {
    if (
      (updateUser.data !== previousValue?.updateUser.data &&
        updateUser.status.isSucceeded) ||
      (createdUser.data !== previousValue?.createdUser.data &&
        createdUser.status.isSucceeded)
    ) {
      dispatch(
        doQueryUser({
          txtPhone: phoneForm.values.txtPhone,
          email: phoneForm.values.email,
        })
      )
    }
    if (
      queryUserResult.data &&
      queryUserResult.data !== previousValue?.queryUserResult.data &&
      queryUserResult.status.isSucceeded
    ) {
      phoneForm.setValues(queryUserResult.data[0])
    }
  }, [updateUser, createdUser, queryUserResult])

  useEffect(() => {
    dispatch(
      doQueryUser({
        txtPhone: confirmationResultUser.data?.user.phoneNumber ?? '',
        email: confirmationResultUser.data?.user.email ?? '',
      })
    )
  }, [])
  const handleUpdateuser = (user: IUsers) => {
    if (user.id) {
      dispatch(doUpdateUser({ user }))
    } else {
      dispatch(doCreateUser(user))
    }
  }
  return (
    <Box className={classes.personalInfoWrapper}>
      <Box className="content_box">
        <Flex align="center" gap={0}>
          <Avatar
            radius={100}
            size={100}
            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
          />
          <Stack ml={13}>
            <Box>
              <Text size={20} weight="bold">
                Chào{' '}
                {phoneForm.values.firstName && (
                  <span>
                    {phoneForm.values.firstName.charAt(0).toUpperCase() +
                      phoneForm.values.firstName.slice(1)}
                  </span>
                )}
                !
              </Text>
              <Text size={14}>Chúc bạn một ngày tốt lành!</Text>
            </Box>
          </Stack>
        </Flex>
        <Box className="code">
          <Button className="txt_intro space-center btnsignin">
            Mã giới thiệu
          </Button>
          <Group className="code_intro" spacing={2}>
            <Center className="code_intro-title">MÃ GIỚI THIỆU</Center>
            <CopyButton value="123456" timeout={1000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? 'Copied' : 'Copy'}
                  withArrow
                  position="right"
                  color="#FE5F51"
                  transition="slide-right"
                  transitionDuration={300}
                >
                  <Center onClick={copy} className="copy_btn">
                    <Svgs.Copy />
                    <Text mx={12}>ONE123456</Text>
                  </Center>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Box>
      </Box>
      <Box className="content_box" mt={20}>
        <form onSubmit={phoneForm.onSubmit(handleUpdateuser)}>
          <Text my={30} size={20} weight={700} align="center">
            Thông tin cá nhân
          </Text>
          <Stack spacing={15}>
            <Input
              variant="filled"
              placeholder="Nhập tên của bạn *"
              radius={10}
              {...phoneForm.getInputProps('firstName')}
            />
            <Input
              variant="filled"
              placeholder="Nhập họ của bạn *"
              radius={10}
              {...phoneForm.getInputProps('lastName')}
            />
            <Input
              variant="filled"
              placeholder="Email của bạn *"
              radius={10}
              {...phoneForm.getInputProps('email')}
            />
            <Input
              placeholder="Nhập số điện thoại của bạn *"
              variant="filled"
              radius={10}
              type="number"
              disabled
              value={
                phoneForm.values.txtPhone &&
                phoneForm.values.txtPhone.replace('+84', '0')
              }
            />
            <Select
              placeholder="Giới tính"
              data={[
                { value: 'Male', label: 'Nam' },
                { value: 'Female', label: 'Nữ' },
                { value: '', label: 'Khác' },
              ]}
              {...phoneForm.getInputProps('gender')}
            />
            <DatePicker
              inputFormat="DD/MM/YYYY"
              placeholder="Sinh nhật của bạn?"
              rightSection={<Svgs.Date />}
              {...phoneForm.getInputProps('dob')}
              // value={phoneForm.values.dob}
            />
            <Button
              type="submit"
              className="space-center btnsignin"
              color="#D9D9D9"
            >
              Cập nhập
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  )
}
