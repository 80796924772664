import { BUSINESS_DATA } from 'constants/index'
import { Box, Card, Center, Image, SimpleGrid, Text } from '@mantine/core'

export default function Distribution() {
  return (
    <Box>
      <Text size={24} my={58} weight={700} align="center">
        Các Lĩnh Vực Phân Phối Sản Phẩm
      </Text>
      <SimpleGrid
        mb={60}
        cols={2}
        spacing={40}
        breakpoints={[
          {
            maxWidth: 'xs',
            spacing: 20,
            cols: 1,
          },
          {
            maxWidth: 'md',
            spacing: 20,
            cols: 2,
          },
          {
            maxWidth: 'lg',
            cols: 2,
          },
        ]}
      >
        {BUSINESS_DATA.map((item, idx) => (
          <Card
            key={idx}
            p={0}
            sx={() => ({
              cursor: 'pointer',
              transition: '.2s ease-in-out',
              '&:hover': {
                boxShadow: ' rgba(0, 0, 0, 0.1) 0px 4px 12px',
                transform: 'scale(1.05)',
              },
              margin: 'auto',
            })}
            radius="md"
          >
            <Card.Section p={1}>
              <Image
                src={item.photo}
                sx={{
                  height: '100%',
                  width: '100%',
                }}
                alt="Norway"
                radius="md"
              />
            </Card.Section>
            <Center
              sx={{
                minHeight: '148px',
              }}
            >
              <Box>
                <Text
                  mt={20}
                  size={36}
                  mb={20}
                  weight="bold"
                  align="center"
                  sx={{
                    lineHeight: '29px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                />
                <Text
                  pb={20}
                  sx={{
                    maxWidth: 554,
                  }}
                  lineClamp={3}
                  align="center"
                >
                  {item.description}
                </Text>
              </Box>
            </Center>
          </Card>
        ))}
      </SimpleGrid>
    </Box>
  )
}
