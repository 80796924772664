import { ActionIcon, Box, Container, Grid, Group, Text } from '@mantine/core'
import { Svgs } from 'assets'
import { Tabs } from 'common'
import { doSignOut } from './actions'
import { motion } from 'framer-motion'
import { useAppDispatch } from 'app/hooks'
import { useStyles } from './styles'
import ContactFeedback from './ContactFeedback'
import InforAddress from './InforAddress'
import PersonalInfo from './PersonalInfo'
import Policy from './Policy'
import Setting from './Setting'
import TermsOfUse from './TermsOfUse'

const enum TABS {
  INFOR_ADDRESS = 'Thông tin địa chỉ',
  SETTING_UI = 'Cài đặt giao diện',
  CONTACT_FEEDBACK = 'Liên hệ & feedback',
  PRIVACY_POLICY = 'Chính sách bảo mật',
  TERMS_OF_USE = 'Điều khoản sử dụng',
}

const tabsData: ITabs[] = [
  {
    tabLabel: TABS.INFOR_ADDRESS,
    component: <InforAddress />,
    icon: <Svgs.Location />,
  },
  {
    tabLabel: TABS.SETTING_UI,
    component: <Setting />,
    icon: <Svgs.Setting />,
  },
  {
    tabLabel: TABS.CONTACT_FEEDBACK,
    component: <ContactFeedback />,
    icon: <Svgs.Contact />,
  },
  {
    tabLabel: TABS.PRIVACY_POLICY,
    component: <Policy />,
    icon: <Svgs.Shield />,
  },
  {
    tabLabel: TABS.TERMS_OF_USE,
    component: <TermsOfUse />,
    icon: <Svgs.Note />,
  },
]
export default function Account() {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const onSignOut = () => {
    dispatch(doSignOut())
  }

  return (
    <Container fluid>
      <Box className={classes.userWrapper}>
        <Grid gutter={0} grow>
          <Grid.Col md={4}>
            <PersonalInfo />
          </Grid.Col>
          <Grid.Col md={7.8} offsetMd={0.2}>
            <Tabs.User type="with icon" tabsData={tabsData}>
              <Box className="signout" onClick={onSignOut}>
                <Group position="apart">
                  <Svgs.Signout />
                  <Text> Đăng xuất</Text>
                  <ActionIcon
                    variant="transparent"
                    component={motion.div}
                    initial={{
                      rotate: -90,
                    }}
                    whileTap={{
                      scale: 1.3,
                    }}
                  >
                    <Svgs.ArrowDown />
                  </ActionIcon>
                </Group>
              </Box>
            </Tabs.User>
          </Grid.Col>
        </Grid>
      </Box>
    </Container>
  )
}
