import { ActionIcon, Modal as ModalM, ModalProps, Text } from '@mantine/core'
import { Svgs } from 'assets'
import useStyles from './styles'

export default function Modal({
  children,
  onClose,
  opened,
  size = 1070,
  title,
  ...otherProps
}: ModalProps) {
  const { classes } = useStyles()

  return (
    <ModalM
      radius={10}
      className={classes.modalWrapper}
      centered
      zIndex={1000}
      opened={opened}
      size={size}
      withCloseButton={false}
      onClose={() => onClose()}
      transitionDuration={400}
      transitionTimingFunction="ease"
      overlayColor={'#000'}
      overlayOpacity={0.55}
      overlayBlur={3}
      exitTransitionDuration={400}
      {...otherProps}
    >
      {title && (
        <Text size={24} weight="bold" mb={20}>
          {title}
        </Text>
      )}
      {children}
      <ActionIcon
        radius={50}
        color="dark"
        className="closeIcon"
        onClick={onClose}
      >
        <Svgs.X />
      </ActionIcon>
    </ModalM>
  )
}
