import {
  Box,
  Card,
  Container,
  Group,
  Image,
  SimpleGrid,
  Text,
} from '@mantine/core'
import { Btn } from 'customs'
import { IPath } from 'constants/enum'
import { Link } from 'react-router-dom'
import { NEW_FEED_DATA } from 'constants/index'
import { Photos } from 'assets'
import Feed from './Feed'

export default function Feeds() {
  return (
    <Container fluid>
      <Feed
        btn={{
          label: 'Đến trang nước ép',
          to: IPath.Feeds,
        }}
        title="Bảng Tin <br/> ONE Drink & Food"
        description="Chào mừng bạn đến với bảng tin của ONE. Tại đây bạn sẽ được cập
              nhập những thông báo mới nhất đến từ chúng tôi như các chương
              trình quà tặng, khuyến mãi, gói combo sản phấm. Bảng tin ra đời
              với mong muốn chia sẽ những kinh nghiệm về dinh dưỡng, biết cách
              sử dụng những sản phẩm nước ép & sinh tố hằng ngày đúng cách. Khi
              bạn trải nghiệm các sản phẩm từ ONE, bạn sẽ cảm thấy sự thay đổi
              hiệu quả bất ngờ về sức khoẻ cũng như giá trị mang lại."
        txtPhotoLeft={{
          title: 'ONE Người Bạn Đồng Hành Sức Khoẻ',
          description:
            'Hướng giới trẻ đến sự văn minh, hiện đại trong từng sản phẩm',
          photo: Photos.GreenBottle,
        }}
        txtPhotoRight={{
          title: 'Thời Điểm Dùng Nước Ép',
          description: 'Dùng nước nước ép khi nào là tối ưu hiệu quả?',
          photo: Photos.Ice,
        }}
      />
      <Feed
        btn={{
          label: 'Đến trang lộ trình',
          to: IPath.Juice,
        }}
        flexDirection="row-reverse"
        title="Mùa Hè Sảng Khoái <br/>Nạp Tung Năng Lượng"
        description="Nạp năng lượng tươi mới & sảng khoái khi uống gói combo “Mùa Hè Sảng Khoái - Nạp Tung Năng Lượng”. Ưu đãi giảm giá gói liệu trình tuần 20%, nhấp xem thêm để biết thông tin chi tiết."
        txtPhotoRight={{
          title: 'Sức Sống Trẻ Văn Minh Hiện Đại',
          description:
            'Giới trẻ Việt Nam hướng đến việc chăm sóc sức khoẻ, sử dụng các sản phẩm healthy',
          photo: Photos.TwoBottle,
        }}
        txtPhotoLeft={{
          title: 'Một Ly Mỗi Ngày - Trẻ Đẹp Làn Da',
          description: 'Những tác dụng thần kì mà sinh tố mang lại khi sử dụng',
          photo: Photos.TwoLemon,
        }}
      />
      <Text
        weight="bold"
        size={54}
        sx={{
          lineHeight: '78px',
        }}
        dangerouslySetInnerHTML={{
          __html: 'Bảng Tin Mới Nhất',
        }}
      />
      <Text pb={30} pt={30}>
        Các tin tức mới nhất đến từ ONE Drink & Food
      </Text>
      <SimpleGrid
        mb={30}
        cols={4}
        spacing={30}
        breakpoints={[
          { cols: 1, maxWidth: 'sm' },
          { cols: 2, maxWidth: 'lg' },
          { cols: 3, maxWidth: 1441 },
        ]}
      >
        {NEW_FEED_DATA.map((val, idx) => (
          <Link
            to={val.to}
            key={idx}
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="liner"
            data-aos-once="true"
          >
            <Card
              shadow="sm"
              radius="md"
              sx={{
                cursor: 'pointer',
                margin: 'auto',
                minHeight: '570px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Card.Section p={1}>
                <Image
                  src={val.photo}
                  height={280}
                  alt={val.title}
                  radius={10}
                />
              </Card.Section>
              <Box pl={24} pr={24}>
                <Text
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  size={14}
                  weight={700}
                  pt={28}
                  pb={13}
                >
                  {val.tag}
                </Text>
                <Text
                  lineClamp={2}
                  mb={13}
                  pb={2}
                  size={24}
                  weight={700}
                  sx={{ lineHeight: '24px' }}
                >
                  {val.title}
                </Text>
                <Text pb={36} size={14} sx={{ lineHeight: '24px' }}>
                  {val.description}
                </Text>
                <Group position="apart">
                  <Text>{val.date}</Text>
                  <Btn
                    label="Xem thêm"
                    width={160}
                    height={30}
                    ActionIconProps={{ size: 12 }}
                    TextProps={{ size: 14, align: 'center' }}
                  />
                </Group>
              </Box>
            </Card>
          </Link>
        ))}
      </SimpleGrid>
    </Container>
  )
}
