import { createSlice } from '@reduxjs/toolkit'
import { doQueryProducts } from './actions'

type IInitialState = IQueryState<IProduct[] | null>

const initialState: IInitialState = { data: null, status: {} }

export const products = createSlice({
  name: 'QUERY_PRODUCTS',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doQueryProducts.pending, state => {
      state.status.errorMessage = null
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
    }),
      builder.addCase(doQueryProducts.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(doQueryProducts.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})
