import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  addressHomeWrapper: {
    '.mantine-InputWrapper-label': {
      fontWeight: 700,
    },
    '.mantine-TextInput-input': {
      height: 40,
      '&:focus': {
        border: '2px solid #000',
      },
    },
  },
}))
