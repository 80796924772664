import { createStyles } from '@mantine/core'

export default createStyles(_theme => ({
  voucherWrapper: {
    '.voucher': {
      position: 'relative',
      boxShadow: ' 0px 0px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      padding: '10px 0',
      '.imgWrapper': {
        padding: '0 10px',
        borderRight: '3px dashed #E6E7E8',
        '.imgPhoto': {
          borderRadius: 10,
          height: 100,
          width: 100,
        },
      },
      '.btnSaveCode': {
        background: '#000',
        borderRadius: 50,
        height: 50,
        marginTop: 20,
        '&:disabled': {
          color: '#c4c4c4',
          background: '#f5f5f5',
        },
      },
      '.btnApply': {
        position: 'absolute',
        bottom: 0,
        right: 0,
        maxWidth: '120px',
        background: 'linear-gradient(270deg, #000000 0%, #434343 100%)',
        borderRadius: '10px 0px',
        '&:disabled': {
          color: '#c4c4c4',
          background: '#f5f5f5',
        },
      },
    },
    '.mantine-TextInput-rightSection': {
      padding: '0 5px ',
      width: 'max-content',
    },
  },
}))
