import { FirebaseError } from 'firebase/app'
import { Query, collection, query, where } from 'firebase/firestore'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'
import { getDocsData } from './../../../config/firebase/utils'
import { pathCollection } from 'config/firebase/collection'
import { showNotification } from 'common'

export const doQueryStatusOrders = createAsyncThunk(
  'Query status orders',
  async (
    {
      status,
    }: // idUser,
    { status: IFormRecipient['status']; idUser: IFormRecipient['idUser'] },
    thunkAPI
  ) => {
    try {
      let data: IFormRecipient[] = []
      const q = query(
        collection(database, pathCollection.orders),
        where('status', '==', status)
        // where('idUser', '==', idUser)
      ) as Query<IFormRecipient>
      data = await getDocsData<IFormRecipient>(q)
      console.log('data', data)
      return data
    } catch (error) {
      const err = error as FirebaseError
      console.log('Query status orders', error, err)
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)
