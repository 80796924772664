import { BEAUTIFUL_SKIN, TIME_USE_JUICE, YOUNG_VITALITY } from 'constants/index'
import { Page404 } from 'common'
import { Photos } from 'assets'
import { decodeBase64 } from 'utils'
import { useParams } from 'react-router-dom'
import DetailFeed from './DetailFeed'

const DetailFeeds = () => {
  let { id } = useParams()
  switch (decodeBase64(id)) {
    case 'Thời Điểm Dùng Nước Ép':
      return (
        <DetailFeed
          photoTitle={Photos.Scroll0}
          data={YOUNG_VITALITY}
          photo={[
            Photos.Scroll1,
            Photos.Scroll2,
            Photos.Scroll3,
            Photos.Scroll4,
            Photos.Scroll5,
            Photos.Scroll6,
            Photos.Scroll7,
            Photos.Scroll8,
          ]}
        />
      )
    case 'ONE Người Bạn Đồng Hành Sức Khoẻ':
      return (
        <DetailFeed
          photoTitle={Photos.Modern}
          data={TIME_USE_JUICE}
          photo={[
            Photos.Scroll111,
            Photos.Scroll222,
            Photos.Scroll333,
            Photos.Scroll444,
            Photos.Scroll555,
            Photos.Scroll666,
            Photos.Scroll777,
            Photos.Scroll888,
          ]}
        />
      )
    case 'Một Ly Mỗi Ngày - Trẻ Đẹp Làn Da':
      return (
        <DetailFeed
          photoTitle={Photos.Scroll00}
          data={BEAUTIFUL_SKIN}
          photo={[
            Photos.Scroll11,
            Photos.Scroll22,
            Photos.Scroll33,
            Photos.Scroll44,
            Photos.Scroll55,
            Photos.Scroll66,
            Photos.Scroll77,
            Photos.Scroll88,
          ]}
        />
      )
    // case 'Sức Sống Trẻ Văn Minh Hiện Đại':
    //   return <DetailFeed />
    default:
      return <Page404 />
  }
}

export default DetailFeeds
