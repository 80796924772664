import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { reducers } from './reducers'
import rootSaga from './rootSaga'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['queryUserResult', 'confirmationResultUser'],
}

const reducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware()
const configStore = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }).concat(sagaMiddleware),
})
sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof configStore.dispatch
export type RootState = ReturnType<typeof configStore.getState>
export const persistor = persistStore(configStore)

export default configStore
