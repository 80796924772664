import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  bagWrapper: {
    '.content_box': {
      boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
      padding: '0px 40px 40px 40px',
      borderRadius: 10,
      margin: '30px 0',
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      '.content_box': {
        padding: '0px',
      },
    },
  },
  modalBag: {},
  tabBagWrapper: {
    '.mantine-Tabs-root ': {
      '.mantine-Tabs-tabsList': {
        borderBottom: '1px solid #F5F5F5',
        '.mantine-Tabs-tab': {
          fontSize: 14,
          height: 65,
          padding: '0 24px',
          overflow: 'hidden',
          '&[data-active]': {
            fontWeight: 700,
            border: 'unset',
            '&:after': {
              borderTop: '5px solid #000',
              content: '""',
              position: 'absolute',
              width: 'calc(100% - 48px)',
              height: 15,
              bottom: -8,
              borderRadius: 5,
            },
          },
        },
      },
      '.mantine-Tabs-panel': {
        paddingTop: 30,
        position: 'relative',
      },
    },
  },
  contentWrapper: {
    '.mantine-SimpleGrid-root': {
      maxHeight: 540,
      overflow: 'auto',
      padding: '20px 20px 100px 10px',
      '&:after': {
        zIndex: 1,
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 'calc(100% - 5px)',
        height: '100px',
        background:
          'linear-gradient(0deg, rgb(255 255 255) 50%, rgb(255 255 255 / 20%) 100%)',
      },
    },
  },
}))
