import { Box, Image, Text, Title } from '@mantine/core'
import { CardPhoto } from 'common'
import { doQueryProductId } from '../PickOption/actions'
import { isArray } from 'lodash'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'
import { useStyles } from './styles'

type IProps = {
  id: IProduct['id']
}
export default function MoreInfor({ id }: IProps) {
  const dispatch = useAppDispatch()
  const { data } = useAppSelector(state => state.productId)
  useEffect(() => {
    dispatch(doQueryProductId(id as string))
  }, [])
  const { classes, cx } = useStyles({
    background: data?.signature?.backGround,
  })

  return (
    <Box className={classes.moreInforWrapper}>
      {data?.group === 'Nước Ép Signature' && (
        <Box className="signature">
          <Text size={24} weight={700} align="center" mt={60}>
            GIỚI THIỆU VỀ <span>{data.name}</span>
          </Text>
          <Image
            data-aos="fade-left"
            data-aos-duration="1000"
            src={data.signature?.photo}
            alt="Logo white"
          />
          <Text
            mb={60}
            sx={{ lineHeight: '30px', maxWidth: 874 }}
            align="center"
            size={18}
            dangerouslySetInnerHTML={{
              __html: data.signature?.description ?? '',
            }}
          />
        </Box>
      )}
      {data?.aboutProduct &&
        data.aboutProduct.map((val, idx) => {
          const lastElement =
            isArray(data?.aboutProduct) && data?.aboutProduct.length - 1
          return (
            <Box
              key={idx}
              sx={{
                borderBottom:
                  idx === lastElement
                    ? 'unset'
                    : data?.group === 'Nước Ép Signature'
                    ? '1px solid #000'
                    : '',
              }}
              className={cx('cardPhoto_moreInfor', {
                'cardPhoto_moreInfor-left': val.photoPosition === 'right',
                'cardPhoto_moreInfor-right': val.photoPosition !== 'right',
                'cardPhoto_moreInfor-Signleft':
                  data.group === 'Nước Ép Signature',
                'cardPhoto_moreInfor-Signright':
                  val.photoPosition === 'right' &&
                  data.group === 'Nước Ép Signature',
              })}
            >
              <CardPhoto
                sxText={{
                  padding:
                    val.photoPosition === 'right'
                      ? '30px 30px 30px 0'
                      : '30px 0 30px 30px',
                }}
                {...val}
              />
            </Box>
          )
        })}
      {data?.rickText?.userManual && (
        <Box>
          <Title className="UserManual" order={1}>
            <Text size={24}>HƯỚNG DẪN SỬ DỤNG</Text>
          </Title>
          <Box
            className="rickText"
            dangerouslySetInnerHTML={{
              __html: data?.rickText?.userManual,
            }}
          />
        </Box>
      )}
    </Box>
  )
}
