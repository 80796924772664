import {
  Box,
  Button,
  Card as CardM,
  Group,
  Image,
  Stack,
  Text,
} from '@mantine/core'
import { MouseEvent, memo } from 'react'
import { Swiper } from 'common'
import { SwiperSlide } from 'swiper/react'
import { formatNumber } from 'utils'
import { onAddToCart } from 'reducer/Carts'
import { useAppDispatch } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles'

type IProps = {
  onClick?: (val: IProduct) => void
  isSmall?: boolean
} & IProduct

function Card({ onClick, isSmall, ...props }: IProps) {
  const navigate = useNavigate()
  const { classes, cx } = useStyles({ backGround: props.signature?.backGround })
  const dispatch = useAppDispatch()
  const mlAndPrice = Object.values(props.mlAndPrice).map(val => {
    return {
      price: formatNumber(val.price),
    }
  })
  return (
    <Box className={classes.cardWrapper} hidden={props.property?.isHidden}>
      <CardM
        onClick={() => {
          onClick?.(props)
          if (props.types !== 'designing') {
            navigate(props.id)
          }
        }}
        shadow="sm"
        p="lg"
        radius="md"
        className={cx('card', {
          cardSmall: isSmall,
          cursorNotAllow: props.types === 'designing',
        })}
      >
        <CardM.Section p={25}>
          <Swiper.Animation>
            {Array(props.photo.length == 1 ? 1 : 2)
              .fill(0)
              .map((_, idx) => (
                <SwiperSlide key={idx}>
                  <Image
                    src={props.photo[idx]}
                    withPlaceholder
                    height="100%"
                    alt="With default placeholder"
                    styles={{
                      image: {
                        borderRadius: '10px',
                      },
                    }}
                  />
                </SwiperSlide>
              ))}
          </Swiper.Animation>
        </CardM.Section>
        {!isSmall && (
          <Stack
            justify="space-between"
            spacing={0}
            className={cx('mantineStack', {
              mantineStackRoot: !!props.signature?.backGround,
            })}
          >
            <Box>
              <Text
                size={20}
                transform="uppercase"
                weight={700}
                sx={{ lineHeight: '24px' }}
              >
                {props.name}
              </Text>
              {props.hasTag && <Text size={10}>{props.hasTag}</Text>}
            </Box>
            <Group position="apart" spacing={10}>
              <Box w={100}>
                <Swiper.Animation>
                  <SwiperSlide>{`${mlAndPrice[0].price}đ`}</SwiperSlide>
                  <SwiperSlide>{`${mlAndPrice[1].price}đ`}</SwiperSlide>
                </Swiper.Animation>
              </Box>
              <Button
                className="btn-click"
                color="dark"
                variant="outline"
                radius="xl"
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  dispatch(onAddToCart(props))
                }}
              >
                Chọn
              </Button>
            </Group>
          </Stack>
        )}
        {isSmall && (
          <Box>
            <Box
              className={cx('mantineStack', {
                mantineStackRoot: !!props.signature?.backGround,
              })}
            >
              <Text
                size={14}
                transform="uppercase"
                mb={5}
                lineClamp={1}
                weight={700}
                sx={{ lineHeight: '17px' }}
              >
                {props.name}
              </Text>
              <Swiper.Animation>
                <SwiperSlide>
                  <Text weight={400} size={12} className="txtPrice" h={19}>
                    {`${mlAndPrice[1].price}đ`}
                  </Text>
                </SwiperSlide>
                <SwiperSlide>
                  <Text weight={400} size={12} className="txtPrice" h={19}>
                    {`${mlAndPrice[1].price}đ`}
                  </Text>
                </SwiperSlide>
              </Swiper.Animation>
            </Box>
            <Button
              className="btn-click"
              mt={5}
              color="dark"
              radius="xl"
              variant="outline"
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation()
                dispatch(onAddToCart(props))
              }}
              fullWidth
            >
              Chọn
            </Button>
          </Box>
        )}
      </CardM>
    </Box>
  )
}

export default memo(Card)
