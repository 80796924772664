import 'swiper/scss'
import { Autoplay } from 'swiper'
import { Box } from '@mantine/core'
import { BrandMean } from 'components'
import { Swiper, SwiperSlide } from 'swiper/react'

export default function Carousel() {
  return (
    <Box
      mt={40}
      autoHeight={true}
      component={Swiper}
      autoplay={{
        delay: 1000 * 10,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      loop={true}
      modules={[Autoplay]}
      grabCursor={true}
    >
      <SwiperSlide>
        <BrandMean type="introduction" />
      </SwiperSlide>
      <SwiperSlide>
        <BrandMean type="mean" />
      </SwiperSlide>
    </Box>
  )
}
