import {
  ActionIcon,
  Box,
  Center,
  Group,
  Image,
  SimpleGrid,
  Tabs as TabsM,
  TabsValue,
  Text,
  Title,
} from '@mantine/core'
import { Photos, Svgs } from 'assets'
import { motion } from 'framer-motion'
import { useState } from 'react'
import useStyles from './styles'

type IProps = {
  data: ITabs[]
  title?: string
}
export const Tabs = ({ data, title }: IProps) => {
  const { classes, cx } = useStyles()
  const [tabActive, setTabActive] = useState<TabsValue>(data[0].tabLabel)
  return (
    <Box className={classes.tabsWrapper}>
      {title && (
        <Title className="title" order={1}>
          <Text size={24}>{title}</Text>
        </Title>
      )}
      <TabsM
        className={cx([
          {
            isEqueals1: data.length === 1,
          },
        ])}
        variant="pills"
        defaultValue={tabActive}
        onTabChange={value => setTabActive(value)}
      >
        <TabsM.List position="center">
          {data.map((item, idx) => (
            <TabsM.Tab
              key={idx}
              value={item.tabLabel}
              icon={
                <Box w={14} mr={13}>
                  {tabActive === item.tabLabel && <Svgs.WingLiftIcon />}
                </Box>
              }
              rightSection={
                <Box w={14} ml={13}>
                  {tabActive === item.tabLabel && <Svgs.WingRightIcon />}
                </Box>
              }
            >
              {item.tabLabel}
            </TabsM.Tab>
          ))}
        </TabsM.List>
        {data.map((item, idx) => (
          <TabsM.Panel key={idx} value={item.tabLabel} pt={60}>
            {item.component}
          </TabsM.Panel>
        ))}
      </TabsM>
    </Box>
  )
}

export const User = ({
  tabsData,
  children,
  type,
  onTabChange,
}: {
  tabsData: ITabs[]
  children?: React.ReactNode | string
  type: 'with icon' | 'with text'
  onTabChange?(value: TabsValue): void
}) => {
  const { classes } = useStyles()
  const [activeTab, setActiveTab] = useState<string | null>(
    tabsData[0].tabLabel
  )
  return (
    <TabsM
      className={classes.tabsUserWrapper}
      value={activeTab}
      onTabChange={(value: TabsValue) => {
        setActiveTab(value)
        onTabChange?.(value)
      }}
      orientation="vertical"
    >
      <SimpleGrid
        cols={2}
        spacing={20}
        breakpoints={[
          {
            cols: 1,
            maxWidth: 'md',
          },
        ]}
      >
        <Box>
          <TabsM.List>
            {type === 'with icon' &&
              tabsData.map((val, idx) => (
                <TabsM.Tab key={idx} value={val.tabLabel}>
                  <Group position="apart">
                    <Center>
                      {val.icon && (
                        <ActionIcon mr={10} component="span">
                          {val.icon}
                        </ActionIcon>
                      )}
                      {val.tabLabel}
                    </Center>
                    <ActionIcon
                      component={motion.div}
                      initial={{
                        rotate: -90,
                      }}
                      whileTap={{
                        scale: 1.3,
                      }}
                    >
                      <Svgs.ArrowDown />
                    </ActionIcon>
                  </Group>
                </TabsM.Tab>
              ))}
            {type === 'with text' && (
              <Box className="withTextWrapper">
                <Box pl={40} pb={20} pr={40}>
                  <Text size={18} weight={700}>
                    Thành Viên One
                  </Text>
                  <Center>
                    <Center w={32} h={36}>
                      <Image src={Photos.Gift} />
                    </Center>
                    <Text size={10} ml={5}>
                      Bạn nhận được nhiều quyền lợi & ưu đãi khi là thành viên
                      của ONE! Nhận quà tặng dễ dàng!
                    </Text>
                  </Center>
                </Box>
                {tabsData.map((val, idx) => (
                  <TabsM.Tab key={idx} value={val.tabLabel}>
                    <Box className="withText">
                      <Text size={14} weight={700} mb={5}>
                        {val.tabLabel}
                      </Text>
                      <Text>{val.icon}</Text>
                    </Box>
                  </TabsM.Tab>
                ))}
                <Box pl={20} w={145} h={125}>
                  <Image src={Photos.Tree} />
                </Box>
              </Box>
            )}
          </TabsM.List>
          {children}
        </Box>
        {tabsData.map((val, idx) => (
          <TabsM.Panel key={idx} value={val.tabLabel}>
            {val.component}
          </TabsM.Panel>
        ))}
      </SimpleGrid>
    </TabsM>
  )
}
