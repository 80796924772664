import { CollectionReference, collection, getDocs } from 'firebase/firestore'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'

const colref = collection(database, 'test8') as CollectionReference<IProduct>

export const doTeaHeat = createAsyncThunk(
  'test8',
  async (_params, thunkAPI) => {
    try {
      const res = await getDocs(colref)
      const data = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
      })
      return data
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)
