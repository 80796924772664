import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  productPortfolioWrapper: {
    '.imgPhoto': {
      width: '100%',
      objectFit: 'cover',
      borderRadius: 10,
    },
  },
}))
