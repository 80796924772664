import {
  Box,
  Center,
  Divider,
  Group,
  SimpleGrid,
  Slider,
  Text,
} from '@mantine/core'
import { CardMember, Modal } from 'common'
import { DRINKS_MONTH } from 'components/Users/Member/BarcodePoint/GiftSection/SpecialGifts/constant'
import { Gifts } from 'components'
import { Svgs } from 'assets'
import { useDisclosure } from '@mantine/hooks'
import { useState } from 'react'
import { useStyles } from './styles'
import CardGift from './GiftSection/SpecialGifts/CardGift'
import Vouchers from 'components/CartComponent/YourCart/Vouchers'

const GIFT = {
  SPECIAL_BENEFITS: 'Quyền Lợi Đặc Biệt',
  SPECIAL_GIFTS: 'Phần Quà Ưu Đãi',
  GIFT_HISTORY: 'Lịch Sử Đổi Thưởng',
  PROMO_CARD_CODE: 'Mã Thẻ Khuyến Mãi',
}
const GIFT_DATA = [
  {
    lable: GIFT.SPECIAL_BENEFITS,
    icon: <Svgs.Person />,
  },
  {
    lable: GIFT.SPECIAL_GIFTS,
    icon: <Svgs.Gift />,
  },
  {
    lable: GIFT.GIFT_HISTORY,
    icon: <Svgs.Time />,
  },
  {
    lable: GIFT.PROMO_CARD_CODE,
    icon: <Svgs.Couple />,
  },
]
export default function BarcodePoint() {
  const { classes } = useStyles()
  const [opened, { close, open }] = useDisclosure(false)
  const [lableShouldOpen, setlableShouldOpen] = useState('')
  const [valPoint] = useState(100)
  return (
    <Box className={classes.barcodePointWrapper}>
      <SimpleGrid
        cols={1}
        breakpoints={[
          {
            maxWidth: 1400,
            cols: 2,
          },
          {
            maxWidth: 900,
            cols: 1,
          },
        ]}
      >
        <Box className="content_box">
          <CardMember point={1000} name="Dang" />
          <Box className="sliderWrapper">
            <Slider
              thumbChildren={<Svgs.Wing />}
              value={valPoint}
              max={1000}
              thumbSize={25}
              label={null}
              showLabelOnHover={false}
            />
            <Group position="apart">
              <Text size={12} weight={600}>
                Tổng điểm đã đạt: {valPoint}
              </Text>
              <Text size={12} weight={600}>
                {valPoint}/1000
              </Text>
            </Group>
            <Text size={14} weight="bold" align="center" mt={18} mb={8}>
              Tích điểm One Để Thăng Hạng
            </Text>
            <Text size={10} align="center">
              Dùng ONE để quy đổi các phần quà & ưu đãi hấp dẫn. Giờ đã khoẻ nay
              càng thêm vui!
            </Text>
          </Box>
        </Box>
        <Box className="content_box">
          <SimpleGrid cols={2} spacing={20}>
            {GIFT_DATA.map((val, idx) => (
              <Center
                key={idx}
                className="blockBox cursor"
                onClick={() => {
                  setlableShouldOpen(val.lable)
                  open()
                }}
              >
                <Box>
                  {val.icon}
                  <Text maw={120} weight={700}>
                    {val.lable}
                  </Text>
                </Box>
              </Center>
            ))}
          </SimpleGrid>
        </Box>
      </SimpleGrid>
      <Modal onClose={close} opened={opened} size="90%">
        <GiftComponent lable={lableShouldOpen} />
      </Modal>
    </Box>
  )
}

const GiftComponent = ({ lable }: { lable: string }) => {
  switch (lable) {
    case GIFT.SPECIAL_BENEFITS:
      return <Gifts.SpecialBenefits />
    case GIFT.SPECIAL_GIFTS:
      return (
        <Box>
          <Text size={24} weight="bold">
            Phần Quà Ưu Đãi
          </Text>
          <Divider my={20} color="#F5F5F5" />
          <CardGift
            type="modal"
            title="Món Nước Tháng Này"
            description="Các sản phẩm quà tặng thay đổi định kỳ, hãy luôn theo dõi & nhanh
              tay đổi quà!"
            data={DRINKS_MONTH.slice(0, 4)}
          />
        </Box>
      )
    case GIFT.GIFT_HISTORY:
      return <Gifts.History type="modal" />
    default:
      return <Vouchers />
  }
}
