import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  cardProductWrapper: {
    '.watchMore': {
      cursor: 'pointer',
      svg: {
        height: 20,
        width: 20,
        transform: 'rotateY(180deg)',
        rect: {
          fill: '#c4c4c4',
        },
      },
    },
  },
  cardProduct: {
    display: 'flex',
    flexDirection: 'column',
    '.content_box-cardProduct': {
      boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
      padding: '13px',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '.description': {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '.title': {
          flex: 1,
        },
      },
      '.imgPhoto': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
}))
