import { createStyles, keyframes } from '@mantine/core'
const rotateDown = keyframes({
  '0%': { transform: 'rotateX(90deg) translateY(-50%)' },
  '10%': { transform: 'rotateX(0deg) translateY(0%)' },
  '48%': { transform: 'rotateX(0deg) translateY(0%)' },
  '50%': { transform: 'rotateX(0deg) translateY(0%)' },
  '60%': { transform: 'rotateX(90deg) translateY(-50%)' },
  '100%': { transform: 'rotateX(90deg) translateY(-50%)' },
})

const rotateUp = keyframes({
  '0%': { transform: 'rotateX(0deg) translateY(0%)' },
  '10%': { transform: 'rotateX(90deg) translateY(50%)' },
  '48%': { transform: 'rotateX(90deg) translateY(50%)' },
  '50%': { transform: 'rotateX(90deg) translateY(50%)' },
  '60%': { transform: 'rotateX(0deg) translateY(0%)' },
  '100%': { transform: 'rotateX(0deg) translateY(0%)' },
})

type IParams = {
  backGround?: string
}
export const useStyles = createStyles((theme, { backGround }: IParams) => ({
  cardWrapper: {
    '.card': {
      margin: 'auto',
      // maxWidth: '300px',
      width: '100%',
      // minHeight: '300px',
      cursor: 'pointer',
      height: '100%',
      boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
      transition: 'all 0.3s linear',
      background: backGround,
      '&:hover': {
        transform: 'scale(1.05)!important',
      },
      '.mantine-Card-cardSection': {
        minHeight: '200px',
        '.imgPhoto': {
          borderRadius: 10,
          // maxHeight: '250px',
          objectFit: 'cover',
          width: '100%',
        },
        '.mantine-Image-root': {
          // height: '250px',
        },
      },
      '&.cursorNotAllow': {
        cursor: 'not-allowed',
      },
      '.mantineStack': {
        '&.mantineStackRoot': {
          color: theme.white,
          button: {
            border: '1px solid #fff',
            color: theme.white,
            '&:hover': {
              backgroundColor: theme.white,
              color: 'black',
              // mixBlendMode:'multiply'
            },
          },
        },
        '.txtPrice': {
          position: 'relative',
          '&:before': {
            content: 'attr(data-before)',
            position: 'absolute',
            top: 0,
            left: 0,
            fontFamily: 'Montserrat',
            transformOrigin: 'top',
            // transform: 'rotateX(90deg) translateY(-50%)',
            animation: `${rotateDown} 6s ease-in-out infinite forwards`,
          },
          '&:after': {
            content: 'attr(data-after)',
            position: 'absolute',
            top: 0,
            fontFamily: 'Montserrat',
            left: 0,
            transformOrigin: 'bottom',
            // transform: 'rotateX(0deg) translateY(0%)',
            animation: `${rotateUp} 6s ease-in-out infinite forwards`,
          },
          // '&:hover:before': {
          //   transform: 'rotateX(0deg) translateY(0%)',
          // },
          // '&:hover:after': {
          //   transform: 'rotateX(90deg) translateY(50%)',
          // },
        },
      },
    },
    '.btn-click': {
      fontSize: 14,
      fontWeight: 700,
      height: 30,
      '&:hover': {
        backgroundColor: '#000',
        color: theme.white,
      },
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      '.btn-click': {
        width: '100%',
      },
    },
    '.cardSmall': {
      padding: '13px!important',
      paddingBottom: '9px!important',
      maxWidth: '160px',
      minHeight: '203px',
      background: backGround,
      '.mantine-Card-cardSection .mantine-Image-root': {
        height: '134px',
      },
      button: {
        border: '1px solid #fff',
        color: theme.white,
        '&:hover': {
          backgroundColor: theme.white,
          color: 'black',
          // mixBlendMode:'multiply'
        },
      },
    },
  },
}))
