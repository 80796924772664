import { createStyles } from '@mantine/core'

export default createStyles(_theme => ({
  mapAutocompleteWrapper: {
    '.mantine-Select-input': {
      border: 'unset',
      padding: '0px 20px',
    },
    '.mantine-Select-rightSection': {
      display: 'none',
    },
  },
}))
