import {
  ActionIcon,
  ActionIconProps,
  Box,
  Button,
  Radio,
  Text,
  TextProps,
} from '@mantine/core'
import { GetInputProps } from '@mantine/form/lib/types'
import { Link } from 'react-router-dom'
import { Svgs } from 'assets'
import { formatNumber } from 'utils'
import { usePrevious } from '@mantine/hooks'
import { useStyles } from './styles'

export const Btn = ({
  label,
  border,
  to = '/',
  width = 285,
  height = 49,
  ActionIconProps,
  TextProps,
}: {
  label: string
  border?: 'unset'
  to?: string
  width?: number
  height?: number
  ActionIconProps?: ActionIconProps
  TextProps?: TextProps
}) => (
  <Button
    color="dark"
    className="btn-Custom"
    component={Link}
    to={to}
    pl={5}
    pr={5}
    styles={{
      root: {
        width: width,
        height: height,
        transition: '.4s',
        background: '#fff',
        border: border,
        '&:hover': {
          background: '#000',
          color: '#fff',
          path: {
            fill: '#fff',
          },
        },
      },
      label: {
        fontSize: '20px',
        fontWeight: 400,
      },
      inner: {
        justifyContent: 'space-between',
      },
    }}
    variant="outline"
    radius="xl"
    rightIcon={
      <ActionIcon
        {...ActionIconProps}
        variant="transparent"
      >
        <Svgs.WingRightIcon />
      </ActionIcon>
    }
    leftIcon={
      <ActionIcon
        {...ActionIconProps}
        variant="transparent"
      >
        <Svgs.WingLiftIcon />
      </ActionIcon>
    }
  >
    <Text {...TextProps}> {label}</Text>
  </Button>
)

export const RadioButton = ({
  label,
  data,
  orientation,
  GetInputProps,
  toggleChecked,
  value,
}: {
  label: React.ReactNode
  data: IRadioGroup[]
  orientation?: 'vertical'
  GetInputProps: GetInputProps<IFormGlass>
  toggleChecked?: (value: boolean) => void
  value?: string
}) => {
  const { classes, cx } = useStyles()
  const preValue = usePrevious(value)
  return (
    <Box className={classes.radioButtonWrapper}>
      <Radio.Group
        orientation={orientation}
        label={label}
        styles={{
          root: {
            background: '#e5e5e5',
            '.mantine-Group-root, .mantine-Stack-root':
              {
                background: '#fff',
                justifyContent: 'space-between',
                padding: '11px 23px',
                minHeight: 50,
                gap: 12,
                '.mantine-Radio-root': {
                  display: 'flex',
                  alignItems: 'center',
                },
              },
          },
          label: {
            height: 40,
            padding: '9px 23px',
            fontWeight: 700,
            fontSize: 14,
          },
        }}
        {...GetInputProps}
        onClick={() =>
          toggleChecked?.(preValue === value)
        }
      >
        {data.map((item, idx) => (
          <Radio
            key={idx}
            value={item.value}
            color="dark"
            disabled={item.disabled}
            label={
              <Text>
                {item.label}
                {item.detail &&
                  item.detail !== 0 && (
                    <Text
                      className={cx({
                        subLabel: !!orientation,
                      })}
                      ml={10}
                      component="b"
                      size="sm"
                    >
                      {`(+${formatNumber(
                        item.detail
                      )}đ)`}
                    </Text>
                  )}
              </Text>
            }
          />
        ))}
      </Radio.Group>
    </Box>
  )
}
