import { Box, Center, Text, Title } from '@mantine/core'
import { IPath } from 'constants/enum'
import { Svgs } from 'assets'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Countdown from 'react-countdown'

const Step3 = () => {
  const navigate = useNavigate()
  const dateTime = useMemo(() => {
    return Date.now() + 10 * 1000
  }, [])
  return (
    <Box p={30}>
      <Center>
        <Svgs.IconLogo />
      </Center>
      <Title mt={13} weight={700} size={36} align="center">
        Bạn đã trở thành ONE Member
      </Title>
      <Countdown
        date={dateTime}
        intervalDelay={0}
        precision={3}
        onComplete={() => {
          navigate(IPath.User)
        }}
        renderer={({ seconds }) => (
          <Text mt={10} size={12} align="center" color="#FE5F51">
            Bạn sẽ được chuyển đến trang User trong ({seconds}s)
          </Text>
        )}
      />
    </Box>
  )
}

export default Step3
