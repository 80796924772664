import { Box, Button, Container, SimpleGrid, Text } from '@mantine/core'
import { DRINKS_MONTH, FEATURE_GIFTS, FOOD_MONTH, ITEMS } from './constant'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useStyles } from './styles'
import CardGift from './CardGift'

export default function SpecialGift() {
  const { classes } = useStyles()
  return (
    <Container fluid>
      <Text size={24} mt={30} weight="bold">
        Phần Quà Ưu Đãi
      </Text>
      <Box className={classes.specialGiftWrapper}>
        <Box className="content_box">
          <Box className="content_box-GiftWrapper">
            <Text size={18} weight="bold">
              Quà Nổi Bật
            </Text>
            <Text mb={20}>
              Các sản phẩm quà tặng thay đổi định kỳ, hãy luôn theo dõi & nhanh
              tay đổi quà!
            </Text>
            <SimpleGrid
              cols={3}
              breakpoints={[
                {
                  maxWidth: 430,
                  cols: 1,
                },
                {
                  maxWidth: 'sm',
                  cols: 2,
                },
              ]}
            >
              {FEATURE_GIFTS.map((val, idx) => (
                <Box key={idx} className="cardGift">
                  <Box className="img">
                    <LazyLoadImage
                      className="imgPhoto"
                      src={val.photo}
                      effect="blur"
                      alt="With default placeholder"
                    />
                  </Box>
                  <Box className="description">
                    <Text size={18} weight="bold">
                      {val.title}
                    </Text>
                    <Text mt={3} size={12}>
                      {val.group}
                    </Text>
                    <Button mt={20} fullWidth color="dark" radius="xl" h={30}>
                      {val.point} ONE
                    </Button>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
          <Box className="content_box-ProductWrapper">
            <CardGift
              data={DRINKS_MONTH}
              title="Món Nước Tháng Này"
              description="Các sản phẩm quà tặng thay đổi định kỳ, hãy luôn theo dõi & nhanh
              tay đổi quà!"
            />
          </Box>
          <Box className="content_box-ProductWrapper">
            <CardGift
              data={FOOD_MONTH}
              title="Món Ăn Tháng này"
              description="Các sản phẩm quà tặng thay đổi định kỳ, hãy luôn theo dõi & nhanh
              tay đổi quà!"
            />
          </Box>
          <Box className="content_box-ProductWrapper">
            <CardGift
              data={ITEMS}
              title="Vật phẩm"
              description="Các sản phẩm quà tặng thay đổi định kỳ, hãy luôn theo dõi & nhanh
              tay đổi quà!"
            />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
