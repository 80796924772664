import { Box, Container, Group, SimpleGrid, Text } from '@mantine/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useEffect, useRef, useState } from 'react'
import { useStyles } from './styles'

type IProps = {
  photoTitle: string
  photo: string[]
  data: ITimeUseJuice[]
}
const DetailFeed = ({ photo, data, photoTitle }: IProps) => {
  const { classes } = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  useEffect(() => {
    setHeight(ref.current ? ref.current.clientHeight : 0)
  })
  return (
    <Box className={classes.detailNewFeedWrapper}>
      <Container fluid mt={20}>
        <SimpleGrid
          spacing={60}
          cols={2}
          breakpoints={[
            {
              cols: 1,
              maxWidth: 768,
            },
            {
              cols: 2,
              spacing: 20,
              maxWidth: 992,
            },
          ]}
        >
          <Box ref={ref}>
            <LazyLoadImage
              className="imgPhoto"
              src={photoTitle}
              alt="With default placeholder"
            />
            <Box
              sx={theme => ({
                maxWidth: 560,
                paddingLeft: 40,
                [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                  paddingLeft: 'unset',
                  margin: 'auto',
                },
              })}
            >
              <Text
                className="title"
                dangerouslySetInnerHTML={{
                  __html: 'Sức Sống Trẻ <br/>Văn Minh Hiện Đại ',
                }}
              />
              {data.map((val, idx) => (
                <Text key={idx} size={idx === 0 ? 28 : 20} mt={30} weight="700">
                  {val.title}
                  {val.description.map((val, idx) => (
                    <Text
                      mt={30}
                      key={idx}
                      size={14}
                      sx={{ lineHeight: '24px' }}
                      dangerouslySetInnerHTML={{ __html: val }}
                      weight="400"
                    />
                  ))}
                </Text>
              ))}
            </Box>
          </Box>
          <Group noWrap className="contentRight" style={{ height }}>
            <Marquee data={[...photo, ...photo, ...photo]} />
          </Group>
        </SimpleGrid>
      </Container>
    </Box>
  )
}

export default DetailFeed

const Marquee = ({ data }: { data: string[] }) => {
  const { classes } = useStyles()
  const half = Math.ceil(data.length / 2)
  const firstHalf = data.slice(0, half)
  const secondHalf = data.slice(half)
  return (
    <div className={classes.marqueeWrapper}>
      <div className="marquee-content">
        <Group noWrap>
          <Box className="firstHalf">
            {firstHalf.map((val, idx) => (
              <LazyLoadImage
                key={idx}
                className="imgPhoto"
                src={val}
                alt="With default placeholder"
              />
            ))}
          </Box>
          <Box className="secondHalf">
            {secondHalf.map((val, idx) => (
              <LazyLoadImage
                key={idx}
                className="imgPhoto"
                src={val}
                alt="With default placeholder"
              />
            ))}
          </Box>
        </Group>
      </div>
    </div>
  )
}
