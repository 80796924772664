import { Banner, Swiper } from 'common'
import { Box } from '@mantine/core'
import { Home as HomeComponents } from 'components'

function Home() {
  console.log(process.env.REACT_APP_URL_PAYMENT)
  return (
    <Box>
      <Swiper.Carousel />
      <HomeComponents.ProductPortfolio />
      <HomeComponents.AboutOneFD />
      {/* <OrderMethod /> */}
      <Banner.Video />
    </Box>
  )
}

export default Home
