import { TextInputCustomProps } from '.'
import { createStyles } from '@mantine/core'

export const useStyles = createStyles(
  (theme, { input }: TextInputCustomProps<null>) => ({
    wrapper: {
      overflow: 'hidden',
      borderRadius: '10px',
      background: '#C4C4C4',
    },
    input: {
      height: 40,
      paddingLeft: `${input?.paddingLeft ?? 198}px!important`,
      border: 'unset',
      borderRadius: 10,
      overflow: 'hidden',
      background: '#F5F5F5',
      '&:focus': {
        border: '1px solid black',
      },
    },
    icon: {
      color: '#000',
      borderRight: ' 1px solid white',
      backgroundColor: '#E5E5E5',
      pointerEvents: 'unset',
      cursor: 'pointer',
      fontSize: 14,
    },
  })
)
