import { Box, Center, Image, Text } from '@mantine/core'
import { Photos } from 'assets'
import { useStyles } from './styles'

const OnePoint = () => {
  const { classes } = useStyles()
  return (
    <Box className={classes.onePointWrapper}>
      <Center mb={20}>
        <Image src={Photos.AppleHeart} height={103} width={94} />
      </Center>
      <Text
        size={12}
        weight={700}
        dangerouslySetInnerHTML={{
          __html: 'Chào Tín!<br><br>',
        }}
      />
      <Text
        size={12}
        dangerouslySetInnerHTML={{
          __html:
            'Theo chính sách được ONE quy định, mỗi 10.000đ chi tiêu sẽ tương ứng với 10 điểm ONE (các giá trị từ 9.000đ trở xuống sẽ không được quy đổi điểm và làm tròn xuống 0)<br><br>Ví dụ:<br><ul><li>Bạn mua hàng với giá trị đơn hàng là 50.000đ, điểm của bạn là 50đ</li><li>Bạn mua hàng với giá trị đơn hàng là 55.000đ, điểm của bạn là 50đ (tức 5.000đ không được quy đổi)</li></ul>',
        }}
      />
      <Text className="point">10.000đ = 10 ONE</Text>
      <Text
        size={12}
        align="center"
        dangerouslySetInnerHTML={{
          __html: 'Giá trị quy đổi',
        }}
      />
    </Box>
  )
}

export default OnePoint
