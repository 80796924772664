import { Box, Text } from '@mantine/core'
import { useStyles } from './styles'

export default function ContactFeedback() {
  const { classes } = useStyles()
  return (
    <Box className={classes.contactFeedbackWrapper}>
      <Box className="box">
        <Text weight={700}>Trụ sở đăng ký</Text>
        <Text>
          213/13 Hồng Lạc, Phường 10, Quận Tân Bình, Thành phố Hồ Chí Minh
        </Text>
      </Box>
      <Box className="box">
        <Text weight={700}>Địa chỉ kinh doanh</Text>
        <Text>
          1002 Tạ Quang Bửu, Phường 6, Quận 8, Thành phố Hồ Chí Minh (Chung Cư
          The Pegasuite 1)
        </Text>
      </Box>
      <Box className="box">
        <Text weight={700}>Hotline tư vấn & đặt hàng</Text>
        <Text>0933 22 00 78</Text>
      </Box>
      <Box className="box">
        <Text weight={700}>Email phản hồi & feedback</Text>
        <Text>one.drinkandfood@gmail.com</Text>
      </Box>
      <Box className="box">
        <Text weight={700}>Website chính thức</Text>
        <Text>www.onedrinkandfood.com</Text>
      </Box>
    </Box>
  )
}
