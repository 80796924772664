import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  onePointWrapper: {
    boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
    padding: 40,
    borderRadius: 10,
    ul: {
      marginLeft: 20,
    },
    '.point': {
      margin: '20px 0 10px 0',
      fontSize: 30,
      fontWeight: 700,
      background: 'linear-gradient(270deg, #000000 0%, #434343 100%)',
      borderRadius: 10,
      height: 100,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [`@media  (min-width:  ${theme.breakpoints.md}px) and  (max-width: ${theme.breakpoints.lg}px)`]:
      {
        '.point': {
          fontSize: 25,
        },
      },
    [`@media  (min-width:  ${theme.breakpoints.xs}px) and  (max-width: ${theme.breakpoints.sm}px)`]:
      {
        '.point': {
          fontSize: 20,
        },
      },
  },
}))
