import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  UserCredential,
  signInWithPopup,
} from 'firebase/auth'
import { FirebaseError } from 'firebase/app'
import { auth } from 'config/firebase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { showNotification } from 'common'

export const signGoogle = createAsyncThunk(
  'Sign In Google',
  async (_params, thunkAPI) => {
    const provider = new GoogleAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      const credantial = GoogleAuthProvider.credentialFromResult(result)
      // The signed-in user info.
      const user = result.user

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const accessToken = credential?.accessToken

      // IdP data available using getAdditionalUserInfo(result)
      // ...
      console.log('user', user, credential, accessToken, credantial)
      return result
    } catch (err) {
      const error = err as FirebaseError
      // Handle Errors here.
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error)
      console.log('errorCode', error, credential)
      showNotification({
        type: 'error',
        title: `${error.name}`,
        message: error.code || error.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const signInFacebook = createAsyncThunk(
  'Sign In Facebook',
  async (_params, thunkAPI) => {
    const provider = new FacebookAuthProvider()
    try {
      const result = await signInWithPopup(auth, provider)
      const credantial = FacebookAuthProvider.credentialFromResult(result)
      // The signed-in user info.
      const user = result.user

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result)
      const accessToken = credential?.accessToken

      // IdP data available using getAdditionalUserInfo(result)
      // ...
      console.log('user', user, credential, accessToken, credantial)
      return result
    } catch (err) {
      // Handle Errors here.
      const error = err as FirebaseError
      // The email of the user's account used.
      const email = error.customData?.email as string
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error)
      console.log('errorCode', error, credential, email)
      showNotification({
        type: 'error',
        title: `${error.name}`,
        message: email || error.code || error.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const onConfirmationResult = createAsyncThunk(
  'Confirmation Result',
  async (otp: string, thunkAPI) => {
    try {
      const res: UserCredential = await window.confirmationResult.confirm(otp)
      return res
    } catch (error) {
      const err = error as FirebaseError
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(err)
    }
  }
)
