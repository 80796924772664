import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  giftHisotyWrapper: {
    '.title': {
      margin: '30px 0px 0 30px',
    },
    '.gift_exchanged': {
      '.voucher': {
        '>.imgWrapper': {
          borderRight: 'unset',
          '.imgPhoto': {
            height: 124,
            width: 124,
          },
        },
      },
    },
    '&.gifthistoryWrapper__modal': {
      '.mantine-Container-root': {
        '.title': {
          margin: '0px 0px 0 0px',
        },
        padding: 'unset',
        '.giftHisoty': {
          '.content_box': {
            boxShadow: 'unset',
            padding: 'unset',
            borderRadius: 'unset',
            margin: 'unset',
          },
        },
      },
    },
  },
  historyBox: {
    '.giftHisoty': {
      '.content_box': {
        boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '40px 20px 30px 30px',
        borderRadius: 10,
        margin: '40px 0',
        '.content_box-GiftWrapper': {
          position: 'relative',
          '.watchMore': {
            cursor: 'pointer',
            svg: {
              height: 20,
              width: 20,
              transform: 'rotateY(180deg)',
              rect: {
                fill: '#c4c4c4',
              },
            },
          },
          '.mantine-SimpleGrid-root': {
            maxHeight: 500,
            overflowY: 'auto',
            padding: '0px 20px 100px 10px',
            '&:after': {
              zIndex: 1,
              content: '""',
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: 'calc(100% - 5px)',
              height: '100px',
              background:
                'linear-gradient(0deg, rgb(255 255 255) 50%, rgb(255 255 255 / 20%) 100%)',
            },
            '.cardGift': {
              overflow: 'hidden',
              boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
              borderRadius: 10,
            },
          },
        },
      },
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      '.giftHisoty .content_box': {
        paddingLeft: 0,
      },
    },
  },
}))
