import {
  Box,
  Button,
  Container,
  Flex,
  SimpleGrid,
  Tabs,
  Text,
} from '@mantine/core'
import { GIFT_EXCHANGED, VOUCHER } from 'constants/voucher'
import { Modal, Voucher } from 'common'
import { useState } from 'react'
import { useStyles } from './styles'

export default function Bag() {
  const { classes } = useStyles()

  return (
    <Container fluid className={classes.bagWrapper}>
      <Text size={24} mt={30} weight="bold">
        Túi đồ cá nhân
      </Text>
      <Box>
        <Box className="content_box">
          <TabBag
            data={[
              {
                tabLabel: 'Mã khuyến mãi',
                component: <Content data={VOUCHER} />,
              },
              {
                tabLabel: 'Quà đổi thưởng',
                component: <Content data={GIFT_EXCHANGED} />,
              },
            ]}
          />
        </Box>
      </Box>
    </Container>
  )
}

const TabBag = ({ data }: { data: ITabs[] }) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.tabBagWrapper}>
      <Tabs defaultValue={data[0].tabLabel}>
        <Tabs.List position="center">
          {data.map((item, idx) => (
            <Tabs.Tab key={idx} value={item.tabLabel}>
              {item.tabLabel}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {data.map((item, idx) => (
          <Tabs.Panel key={idx} value={item.tabLabel}>
            {item.component}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Box>
  )
}

const Content = ({ data }: { data: IVoucher[] }) => {
  const { classes } = useStyles()
  const [openIVoucher, setOpenIVoucher] = useState(false)
  const [, setVoucher] = useState<IVoucher | null>(null)
  return (
    <Box className={classes.contentWrapper}>
      <SimpleGrid
        cols={3}
        breakpoints={[
          {
            maxWidth: 'md',
            cols: 1,
          },
          {
            maxWidth: 'xl',
            cols: 2,
          },
        ]}
      >
        {data.map((val, idx) => (
          <Box
            className="cursor"
            key={idx}
            onClick={() => {
              setOpenIVoucher(true)
              setVoucher(val)
            }}
          >
            <Voucher from="bag" {...val} />
          </Box>
        ))}
      </SimpleGrid>
      <Modal
        classNames={{
          body: classes.modalBag,
        }}
        size="464px"
        opened={openIVoucher}
        onClose={() => setOpenIVoucher(false)}
      >
        <Flex align="center" gap={30} direction="column">
          <Text size={24} weight="bold">
            Xoá mã khuyến mãi?
          </Text>
          <Button color="dark" radius={50} h={50} fullWidth maw={220}>
            Xác nhận
          </Button>
        </Flex>
      </Modal>
    </Box>
  )
}
