// import { Hightland } from 'assets'
import { Box, Group, Text } from '@mantine/core'
import Myvideo from 'assets/video/one-drink-and-food-video.mov'
import Test from 'assets/video/test.mp3'
import useStyles from './styles'

export const Video = () => {
  return (
    <Box>
      <video
        autoPlay
        muted
        loop
        playsInline
        style={{
          objectFit: 'fill',
          width: '100%',
          marginTop: '50px',
        }}
      >
        <source src={Myvideo} type="video/mp4" />
      </video>
      <audio src={Test} preload="auto" autoPlay={true} controls />
    </Box>
  )
}

export const Free = () => {
  const { classes } = useStyles()
  return (
    <Box className={classes.freeWrapper}>
      <Group position="center">
        <Text size={20} weight={700}>
          TẶNG BẠN MỚI
        </Text>
        <Text size={60} weight={700}>
          VOUCHER 50%
        </Text>
        <Text size={20} weight={700}>
          KHI ĐĂNG NHẬP
        </Text>
      </Group>
      <Text px={16} size={12} mt={5} align="center">
        Không giới hạn tổng giá trị đơn hàng cho lần đặt đầu tiên!
      </Text>
    </Box>
  )
}
