import {
  Avatar,
  Box,
  Center,
  Checkbox,
  Group,
  Image,
  Indicator,
  SimpleGrid,
  Stack,
  Switch,
  Text,
} from '@mantine/core'
import { Btn } from 'customs'
import { Modal } from 'common'
import { Photos, Svgs } from 'assets'
import { useState } from 'react'
import { useStyles } from './styles'
import Accordion from '../Accordion'

const Langage = () => {
  const { classes } = useStyles()
  const [checked, setChecked] = useState(true)
  return (
    <Box className={classes.langageWrapper}>
      <Checkbox
        labelPosition="left"
        value="VI"
        label="Tiếng Việt"
        checked={checked}
        onChange={() => setChecked(pre => !pre)}
      />
      <Checkbox
        labelPosition="left"
        value="EN"
        label="Tiếng Anh"
        checked={!checked}
        onChange={() => setChecked(pre => !pre)}
      />
    </Box>
  )
}

const ScreenMode = () => {
  const { classes } = useStyles()
  const [checked, setChecked] = useState(true)

  return (
    <Box className={classes.screenModeWrapper}>
      <Stack spacing={0}>
        <Group position="apart">
          <Text>Độ sáng tự động</Text>
          <Switch
            color="dark"
            size="lg"
            labelPosition="left"
            onLabel={<Svgs.Switchleaf />}
            offLabel={<Svgs.Switchleaf />}
          />
        </Group>
        <SimpleGrid cols={2} spacing={14}>
          <Box className="cursor" onClick={() => setChecked(true)}>
            <Box
              component={Image}
              src={Photos.Dark}
              alt="With default placeholder"
              withPlaceholder
            />
            <Checkbox
              readOnly
              radius="xl"
              checked={checked}
              icon={({ className }) => (
                <Svgs.Switchleafblack className={className} />
              )}
            />
          </Box>
          <Box className="cursor" onClick={() => setChecked(false)}>
            <Box
              component={Image}
              src={Photos.Light}
              alt="With default placeholder"
              withPlaceholder
            />
            <Checkbox
              readOnly
              radius="xl"
              checked={!checked}
              icon={({ className }) => (
                <Svgs.Switchleafblack className={className} />
              )}
            />
          </Box>
        </SimpleGrid>
      </Stack>
    </Box>
  )
}
const LinkAccount = () => {
  const [isModalFb, setIsModalFb] = useState(false)
  const { classes } = useStyles()
  return (
    <Box className={classes.linkAccountWrapper}>
      <Stack spacing={0}>
        <Group position="apart" onClick={() => setIsModalFb(true)}>
          <Center>Thêm liên kết Facebook</Center>
          <Svgs.Facebookfill />
        </Group>
        <Group position="apart">
          <Center>Thêm liên kết Instagram</Center>
          <Svgs.Instagramfill />
        </Group>
      </Stack>
      <Modal
        classNames={{
          body: classes.modalBody,
        }}
        opened={isModalFb}
        onClose={() => setIsModalFb(false)}
        size={334}
        padding={30}
      >
        <Stack spacing={15}>
          <Center>
            <Indicator
              inline
              position="bottom-end"
              label={<Svgs.Facebookfill />}
            >
              <Avatar
                size={100}
                radius={100}
                src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
              />
            </Indicator>
          </Center>
          <Text weight={700} size={20} align="center">
            Liên kết facebook với tài khoản ONE?
          </Text>
          <Text size={12} align="center">
            Thông tin của bạn trên ONE sẽ được đồng bộ với thông tin trên
            Facebook.
          </Text>
          <Btn label="Liên kết ngay" />
          <Btn label="Huỷ bỏ" />
        </Stack>
      </Modal>
    </Box>
  )
}
const accordionData: {
  panel: JSX.Element
  control: string
  icon: JSX.Element
}[] = [
  {
    panel: <Langage />,
    control: 'Ngôn ngữ',
    icon: <Svgs.Global />,
  },
  {
    panel: <ScreenMode />,
    control: 'Chế độ màn hình',
    icon: <Svgs.Sun />,
  },
  {
    panel: <LinkAccount />,
    control: 'Liên kết tài khoản',
    icon: <Svgs.Link />,
  },
]
export default function Setting() {
  const { classes } = useStyles()
  return (
    <Box className={classes.settingWrapper}>
      <Accordion accordionData={accordionData} />
    </Box>
  )
}
