import 'aos/dist/aos.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Layout, Page404, Slash } from 'common'
import { LoadingOverlay } from '@mantine/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ROUTERS, ROUTERS_USERS } from 'constants/routers'
import { ScrollToTop } from 'hooks'
import { Suspense, useEffect } from 'react'
import { useAppSelector } from 'app/hooks'
import AOS from 'aos'
import ErrorFallback from '../common/ErrorFallback'
import PrivateRoute from './PrivateRoute'

const queryClient = new QueryClient()
function RouterWrapper() {
  const { isVisible } = useAppSelector(state => state.loadingOverlay)
  useEffect(() => {
    AOS.init({
      duration: 1000,
    })
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<Slash.SlashScreen />}>
            <Routes>
              <Route element={<Layout.Layout1 />}>
                {ROUTERS.map((val, idx) => (
                  <Route
                    index={val.index}
                    key={idx}
                    path={val.path}
                    element={val.element}
                  >
                    {val.children &&
                      val.children.map((item, index) => (
                        <Route
                          index={item.index}
                          key={index}
                          path={item.path}
                          element={item.element}
                        />
                      ))}
                  </Route>
                ))}
                <Route path="*" element={<Page404 />} />
              </Route>
              <Route
                element={
                  <PrivateRoute>
                    <Layout.Layout2 />
                  </PrivateRoute>
                }
              >
                {ROUTERS_USERS.map((val, idx) => (
                  <Route key={idx} path={val.path} element={val.element} />
                ))}
                <Route path="*" element={<Page404 />} />
              </Route>
            </Routes>
          </Suspense>
          <LoadingOverlay visible={isVisible} loader={<Slash.SlashScreen />} />
        </QueryClientProvider>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default RouterWrapper
