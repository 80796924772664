import { Box, Container, Grid } from '@mantine/core'
import { Tabs } from 'common'
import { useStyles } from './styles'
import BarcodePoint from './BarcodePoint'
import CheckInEveryDay from './CheckInEveryDay'
import Friends from './Friends'
import NewMember from './NewMember'
import OnePoint from './OnePoint'

const tabsData: ITabs[] = [
  {
    tabLabel: '#1 Chào Bạn Mới',
    component: <NewMember />,
    icon: 'Tặng 1 sản phẩm nước khi đăng ký tài khoản',
  },
  {
    tabLabel: '#2 Điểm danh Mỗi Ngày',
    component: <CheckInEveryDay />,
    icon: 'Điểm danh mỗi ngày nhận nước miễn phí',
  },
  {
    tabLabel: '#3 Tích Điểm ONE',
    component: <OnePoint />,
    icon: 'Mỗi 10.000VNĐ sử dụng được quy đổi thành 10 ONE',
  },
  {
    tabLabel: '#4 Giới thiệu Bạn Bè',
    component: <Friends />,
    icon: 'Giới thiệu bạn mới, nhận ngay một ly nước mới',
  },
]
const Member = () => {
  const { classes } = useStyles()
  return (
    <Container fluid>
      <Box className={classes.memberWrapper}>
        <Grid gutter={0} grow>
          <Grid.Col xl={4}>
            <BarcodePoint />
          </Grid.Col>
          <Grid.Col xl={7.8} offsetXl={0.2}>
            <Box className="tabUser">
              <Tabs.User type="with text" tabsData={tabsData} />
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    </Container>
  )
}

export default Member
