import { FirebaseError } from 'firebase/app'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from 'config/firebase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { showNotification } from 'common'
/** signInWithPhoneNumber
 * and
 * recaptchaVerifier */
export const onRecaptchaVerifier = () => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (_response: string) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // console.log('response', response)
        },
        'expired-callback': () => {
          console.log('expired-callback')
          // Response expired. Ask user to solve reCAPTCHA again.
        },
      },
      auth
    )
  }
}

export const onSignInWithPhoneNumber = createAsyncThunk(
  'Sign In With Phone Number',
  async (txtPhone: string, thunkAPI) => {
    try {
      const appVerifier = window.recaptchaVerifier
      const res = await signInWithPhoneNumber(
        auth,
        `+84${txtPhone}`,
        appVerifier
      )
      window.confirmationResult = res
      return res
    } catch (error) {
      const err = error as FirebaseError
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(err)
    }
  }
)
