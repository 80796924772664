import {
  Account,
  Bag,
  Business,
  Cart,
  Course,
  GiftHistory,
  History,
  Introduction,
  Item,
  Member,
  NewFeeds,
  OrderLookup,
  PrivacyPolicy,
  SpecialGifts,
  Tea,
  Vitamin,
  Yogurt,
} from 'pages'
import {
  DetailFeeds,
  InforProduct,
  MomoPayment,
  ZaloPayment,
} from 'components'
import { IPath } from 'constants/enum'
import { RouteObject } from 'react-router-dom'
import CartSuccess from 'components/CartComponent/CartSuccess'
import CodeDiscount from 'pages/CodeDiscount'
import React from 'react'

const Home = React.lazy(() => {
  return new Promise<typeof import('pages/Home')>(
    resolve => {
      setTimeout(() => {
        return resolve(import('pages/Home'))
      }, 0)
    }
  )
})

const Juice = React.lazy(() => {
  return new Promise<
    typeof import('pages/Juice')
  >(resolve => {
    setTimeout(() => {
      return resolve(import('pages/Juice'))
    }, 0)
  })
})
const OtherDishes = React.lazy(() => {
  return new Promise<
    typeof import('pages/Coffee')
  >(resolve => {
    setTimeout(() => {
      return resolve(import('pages/Coffee'))
    }, 0)
  })
})

export const ROUTERS: RouteObject[] = [
  {
    path: IPath.Home,
    element: <Home />,
  },
  {
    path: IPath.Juice,
    children: [
      {
        element: <Juice />,
        index: true,
      },
      {
        path: ':id',
        element: <InforProduct />,
      },
    ],
  },
  {
    path: IPath.Vitamin,
    children: [
      {
        element: <Vitamin />,
        index: true,
      },
      {
        path: ':id',
        element: <InforProduct />,
      },
    ],
  },
  {
    path: IPath.Course,
    element: <Course />,
  },
  {
    path: IPath.Tea,
    element: <Tea />,
  },
  {
    path: IPath.Coffee,
    children: [
      {
        element: <OtherDishes />,
        index: true,
      },
      {
        path: ':id',
        element: <InforProduct />,
      },
    ],
  },
  {
    path: IPath.Introduction,
    element: <Introduction />,
  },
  {
    path: IPath.Privacy_Policy,
    element: <PrivacyPolicy />,
  },
  {
    path: IPath.Feeds,
    children: [
      {
        index: true,
        element: <NewFeeds />,
      },
      {
        path: ':id',
        element: <DetailFeeds />,
      },
    ],
  },
  {
    path: IPath.Cart,
    children: [
      {
        index: true,
        element: <Cart />,
      },
      {
        path: 'zalo',
        element: <ZaloPayment />,
      },
      {
        path: 'momo',
        element: <MomoPayment />,
      },
      {
        path: ':id',
        element: <CartSuccess />,
      },
    ],
  },
  {
    path: IPath.Order_Lookup,
    element: <OrderLookup />,
  },
  {
    path: IPath.Business,
    element: <Business />,
  },
  {
    path: IPath.Yogurt,
    element: <Yogurt />,
  },
  {
    path: IPath.Item,
    element: <Item />,
  },
]

export const ROUTERS_USERS: RouteObject[] = [
  {
    path: IPath.User,
    element: <Account />,
  },
  {
    path: IPath.Member,
    element: <Member />,
  },
  {
    path: IPath.History,
    element: <History />,
  },
  {
    path: IPath.Gift,
    element: <SpecialGifts />,
  },
  {
    path: IPath.Gift_History,
    element: <GiftHistory />,
  },
  {
    path: IPath.Code_Discount,
    element: <CodeDiscount />,
  },
  {
    path: IPath.Your_bag,
    element: <Bag />,
  },
]
