import { Box, Center, Image, Text } from '@mantine/core'
import { Photos } from 'assets'
import { Voucher } from 'common'
import { useStyles } from './styles'

const NewMember = () => {
  const { classes } = useStyles()
  return (
    <Box className={classes.newMemberWrapper}>
      <Center mb={20}>
        <Image src={Photos.AppleHeart} height={103} width={94} />
      </Center>
      <Text
        size={12}
        weight={700}
        dangerouslySetInnerHTML={{
          __html: 'Chào Tín!<br><br>',
        }}
      />
      <Text
        size={12}
        dangerouslySetInnerHTML={{
          __html:
            'ONE rất vui khi bạn mới lần đầu tham gia cộng đồng của chúng tớ. Để gửi gắm tình cảm chân thành này, ONE tặng bạn một ly nước miễn phí với voucher 50.000đ (được giảm giá trực tiếp vào đơn hàng).<br/><br/>Mã: ONECHAOBANMOI<br><br/>Hãy đổi mới thói quen sử dụng các sản phẩm Healthy & Balance và Enjoy Cái Moment này nhé! <3<br/><br>ONE chúc bạn có một sức khoẻ tốt mỗi ngày!',
        }}
      />
      <Box mt={20}>
        <Voucher
          title="Tặng 60.000đ cho bạn mới lần đầu đặt hàng!"
          code="CHAOBANMOI"
          date="15/10/2022"
          photo="https://en.kepoper.com/wp-content/uploads/2020/11/blackpink-rose-profile-1-e1605241913974-997x1024.jpg"
        />
      </Box>
    </Box>
  )
}

export default NewMember
