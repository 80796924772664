import {
  Box,
  Card,
  Center,
  Container,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { PRODUCT_PORTFOLIO_DATA } from 'constants/index'
import { useStyles } from './styles'

export default function ProductPortfolio() {
  const { classes } = useStyles()

  return (
    <Box className={classes.productPortfolioWrapper}>
      <Container fluid>
        <Title mt={60} mb={60} order={1} align="center">
          <Text size={24}>DANH MỤC SẢN PHẨM</Text>
        </Title>
        <SimpleGrid
          cols={4}
          spacing={40}
          breakpoints={[
            {
              maxWidth: 'xs',
              spacing: 20,
              cols: 2,
            },
            {
              maxWidth: 'md',
              spacing: 20,
              cols: 2,
            },
            {
              maxWidth: 'lg',
              cols: 4,
            },
          ]}
        >
          {PRODUCT_PORTFOLIO_DATA.map((item, idx) => (
            <Card
              component={Link}
              to={item.to}
              mb={30}
              key={idx}
              sx={theme => ({
                cursor: 'poiter',
                transition: '.2s ease-in-out',
                '&:hover': {
                  boxShadow: ' rgba(0, 0, 0, 0.1) 0px 4px 12px',
                  transform: 'scale(1.05)',
                },
                width: '100%',
                margin: 'auto',
                [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                  // maxWidth: '300px',
                },
              })}
              radius="md"
            >
              <Card.Section p={1}>
                <LazyLoadImage
                  className="imgPhoto"
                  src={item.photo}
                  alt="With default placeholder"
                />
              </Card.Section>
              <Center
                sx={{
                  height: '120px',
                }}
              >
                <Text
                  size={24}
                  weight="bold"
                  align="center"
                  sx={{
                    lineHeight: '29px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                />
              </Center>
            </Card>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  )
}
