import axios, { AxiosError, AxiosResponse } from 'axios'

const axiosProviderZaloPay = axios.create({
  baseURL: process.env.REACT_APP_URL_PAYMENT + '/zalo',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
})

export async function zaloPay<T, P = IParams>(
  { url, method, config = {} }: IAPI,
  queryParams?: P | IParams
) {
  if ((method === 'GET' || method === 'DELETE') && queryParams) {
    config.params = queryParams
  }
  if ((method === 'POST' || method === 'PUT') && queryParams) {
    config.data = queryParams
  }
  try {
    const res: AxiosResponse<T> = await axiosProviderZaloPay({
      method,
      url,
      ...config,
    })
    console.log('res.data', res.data)
    return res.data
  } catch (error) {
    const err = <AxiosError<IError>>error
    console.log('res.err', err)

    return Promise.reject(err.response?.data ?? {})
  }
}

const axiosProviderMomo = axios.create({
  baseURL: process.env.REACT_APP_URL_PAYMENT + '/momo',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
})

export async function momoPayment<T, P = IParams>(
  { url, method, config = {} }: IAPI,
  queryParams?: P | IParams
) {
  let finalUrl: string = url ?? ''
  if ((method === 'GET' || method === 'DELETE') && queryParams) {
    config.params = queryParams
  }
  if ((method === 'POST' || method === 'PUT') && queryParams) {
    config.data = queryParams
  }
  try {
    const res: AxiosResponse<T> = await axiosProviderMomo({
      method,
      url: finalUrl,
      ...config,
    })
    return res.data
  } catch (error) {
    const err = <AxiosError<IErrorMomo>>error
    return err.response?.data
  }
}
