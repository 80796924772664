import {
  ActionIcon,
  Box,
  Container,
  Group,
  List,
  NavLink as NavLinkM,
  ThemeIcon,
} from '@mantine/core'
import { IPath } from 'constants/enum'
import { Link, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Svgs } from 'assets'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useWindowScroll } from '@mantine/hooks'
import DrawerRight from './DrawerRight'
import Search from './Search'
import useStyles from './styles'

const HEADER_HEIGHT_BREAKPOINT = 1200
type IProps = {
  headerData: ILinks[]
  isScrollDownSmall?: boolean
}
function Header({ headerData, isScrollDownSmall }: IProps) {
  const [opened, setOpened] = useState<boolean>(false)
  const [scroll] = useWindowScroll()
  const location = useLocation()
  const isScrollY = scroll.y % 450 !== 0 && !!isScrollDownSmall

  const { classes, cx } = useStyles({ isScrollY })
  return (
    <Container
      className={cx(classes.headerWrapper, {
        [classes.scrollYed]: isScrollY,
        'box-shadow': !isScrollY,
      })}
      fluid
    >
      <Group w={96}>
        <ActionIcon
          sx={{
            transition: '.4s ease',
          }}
          size={isScrollY ? 60 : 96}
          variant="transparent"
          component={Link}
          to={IPath.Home}
        >
          <Svgs.LogoIcon />
        </ActionIcon>
      </Group>
      <Group>
        <List
          sx={theme => ({
            [theme.fn.smallerThan(HEADER_HEIGHT_BREAKPOINT)]: {
              display: 'none',
            },
          })}
          styles={{
            root: {
              display: 'flex',
            },
            item: {
              position: 'relative',
            },
          }}
          listStyleType="none"
        >
          {headerData.map((link, idx) => (
            <List.Item
              key={idx}
              sx={{
                fontWeight: 600,
              }}
            >
              <NavLinkM
                disabled={link.disabled}
                to={link.to}
                icon={
                  location.pathname === link.to && (
                    <ThemeIcon color="dark" size="lg" radius="xs">
                      <Svgs.LeafIcon />
                    </ThemeIcon>
                  )
                }
                component={NavLink}
                label={link.label}
                key={idx}
                active={location.pathname === link.to}
                classNames={{
                  children: classes.childrenList,
                  root: classes.rootList,
                  icon: classes.iconList,
                }}
                styles={{
                  label: {
                    fontSize: 'inherit',
                  },
                  rightSection: {
                    display: 'none',
                  },
                }}
              >
                {link?.subLinks &&
                  link.subLinks.map((val, idx) => (
                    <NavLinkM
                      component={NavLink}
                      key={idx}
                      label={val.label}
                      to={`${link.to}/${val.to}`}
                      active={location.pathname === `${link.to}/${val.to}`}
                      styles={{
                        root: {
                          fontWeight: 'normal',
                          padding: '20px 42px',
                          '&[data-active]': {
                            color: '#000',
                            background: '#f8f9fa',
                            '&:hover': {
                              background: 'unset',
                            },
                          },
                        },
                      }}
                    />
                  ))}
              </NavLinkM>
            </List.Item>
          ))}
        </List>
      </Group>
      <Group>
        <ActionIcon
          component={motion.div}
          whileHover={{
            rotate: -45,
          }}
          whileTap={{
            scale: 1.3,
          }}
          sx={theme => ({
            [theme.fn.largerThan(HEADER_HEIGHT_BREAKPOINT)]: {
              display: 'none',
            },
          })}
          onClick={() => setOpened(!opened)}
        >
          <Svgs.StarLeave />
        </ActionIcon>
        <Box
          sx={theme => ({
            [theme.fn.smallerThan(HEADER_HEIGHT_BREAKPOINT)]: {
              display: 'none',
            },
          })}
        >
          <Search width="240px" />
        </Box>
      </Group>
      <DrawerRight
        headerData={headerData}
        opened={opened}
        setOpened={setOpened}
      />
    </Container>
  )
}

export default Header
