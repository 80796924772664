import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  codeDiscountWrapper: {
    '.content_box': {
      boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
      padding: '40px 30px',
      borderRadius: 10,
      margin: '30px 0',
      '.content_box-GiftWrapper': {
        position: 'relative',
        '.search-bar': {
          maxWidth: 764,
          '.mantine-Input-rightSection': {
            width: 176,
            borderLeft: '2px solid white',
          },
        },
      },
      '.mantine-SimpleGrid-root': {
        maxHeight: 525,
        overflowY: 'auto',
        padding: '0px 20px 100px 10px',
        '&:after': {
          zIndex: 1,
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: 'calc(100% - 5px)',
          height: '100px',
          background:
            'linear-gradient(0deg, rgb(255 255 255) 50%, rgb(255 255 255 / 20%) 100%)',
        },
      },
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      '.content_box': {
        padding: '40px 10px',
        '.content_box-GiftWrapper': {
          '.search-bar': {
            '.mantine-Input-rightSection': {
              width: 95,
            },
          },
        },
      },
    },
  },
  modalCodeDiscount: {
    '.code_discount-voucher .voucher': {
      boxShadow: 'unset',
      padding: 'unset',
      '.title': {
        fontSize: 18,
      },
      '.imgWrapper': {
        border: 'unset',
        padding: 'unset',
        '.imgPhoto': {
          height: 250,
          width: 250,
        },
      },
    },
  },
}))
