import { Banner, Swiper } from 'common'
import { Box } from '@mantine/core'
import { Outlet } from 'react-router-dom'
import { Photos } from 'assets'
import { Vitamin as VitaminComponent } from 'components'

export default function Vitamin() {
  return (
    <Box>
      <Swiper.Carousel height="md" data={[Photos.CarouselNuocep]} />
      <VitaminComponent />
      <Banner.Free />
      <Outlet />
    </Box>
  )
}
