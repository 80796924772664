import { Box, Button, Center } from '@mantine/core'
import { HmacSHA256 } from 'crypto-js'
import { IPath } from 'constants/enum'
import { getStatusByAppTransId } from '../Delivery/payment'
import { useDecodeSearchParams } from 'hooks/useDecodeSearchParams'
import { useState } from 'react'
import ErrorUI from 'common/ErrorUI'

//developer.zalopay.vn/v1/docs/gateway/api.html#redirect_code-mau-kiem-tra-redirect-hop-le

export default function ZaloPayment() {
  const queryParameters = useDecodeSearchParams<IResultPaymentUrl>()
  const [errorUi, setErrorUi] = useState({
    resultCode: queryParameters.status,
    message: 'Zalo Payment Error',
  })

  let checksumData =
    queryParameters.appid +
    '|' +
    queryParameters.apptransid +
    '|' +
    queryParameters.pmcid +
    '|' +
    queryParameters.bankcode +
    '|' +
    queryParameters.amount +
    '|' +
    queryParameters.discountamount +
    '|' +
    queryParameters.status
  let checksum = HmacSHA256(
    checksumData,
    process.env.REACT_APP_ZALO_KEY2
  ).toString()
  if (checksum != queryParameters.checksum) {
    console.log('checksum 400')
  } else {
    // kiểm tra xem đã nhận được callback hay chưa, nếu chưa thì tiến hành gọi API truy vấn trạng thái thanh toán của đơn hàng để lấy kết quả cuối cùng
    console.log('checksum 200')
  }
  if (queryParameters.status === '1') {
    window.location.href = IPath.Cart + '/' + queryParameters.apptransid
    return null
  } else {
    return (
      <Box>
        <Center>
          <Button
            variant="outline"
            color="dark"
            onClick={async () => {
              const x = await getStatusByAppTransId(queryParameters.apptransid)
              setErrorUi({
                resultCode: String(x.returncode),
                message: x.returnmessage,
              })
            }}
          >
            Kiểm tra trang thái đơn hàng ZaloPay
          </Button>
        </Center>
        <ErrorUI resultCode={errorUi.resultCode} message={errorUi.message} />
      </Box>
    )
  }
}
