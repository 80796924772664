import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  historyWrapper: {
    margin: '30px 0 90px 0',
    '.mantine-SimpleGrid-root .mantine-Tabs-tabsList .mantine-Tabs-tab ': {
      height: '60px',
      padding: '0 20px',
    },
  },
  orderWrapper: {
    position: 'relative',
    boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    cursor: 'pointer',
    maxHeight: '75vh',
    height: '100%',
    overflowY: 'auto',
    '.order_Box': {
      '&>div': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 80,
        margin: '0px 40px',
        borderBottom: '1px solid #F5F5F5',
        '.idOrder': {
          whiteSpace: 'nowrap',
          overflow: 'hidden !important',
          textOverflow: 'ellipsis',
          maxWidth: 200,
          display: 'inline-block',
        },
      },

      '&:hover, &.activeBg': {
        backgroundColor: '#f8f9fa',
      },
      '.txtStatus': {
        fontWeight: 700,
        fontSize: 12,
        '.iconArrow': {
          transform: 'rotate(-90deg)',
          marginTop: 1,
        },
        '&.paymenting': {
          color: '#FE5F51',
          '.iconArrow': {
            path: {
              fill: '#FE5F51',
            },
          },
        },
      },
    },
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      maxHeight: 300,
      minHeight: 300,
      overflowY: 'auto',
    },
  },
  yourCardWrapper: {
    boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    padding: 40,
    height: '100%',
    maxHeight: '75vh',
    overflowY: 'auto',
    '.your_Card': {
      '.your_cart-title, .cartProduct': {
        fontSize: 16,
      },
    },
    '.your_Delivery': {
      fontSize: 12,
      '.delevery-title': {
        fontSize: 16,
      },
    },
    [`@media (max-width: 1199px)`]: {
      marginTop: 20,
    },
  },
}))
