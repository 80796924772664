import { Box } from '@mantine/core'
import { GgMap } from 'common'
import Carousel from './Carousel'
import Contact from './Contact'
import CriteriaofInterest from './CriteriaofInterest'
import Social from './Social'

export default function Introduction() {
  return (
    <Box>
      <Carousel />
      <Social />
      <CriteriaofInterest />
      <Contact />
      <GgMap />
    </Box>
  )
}
