import { Container } from '@mantine/core'
import { Product2, Tabs } from 'common'
import { TABS } from 'constants/enum'
import { doQueryProducts } from 'reducer/Products/actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'
// import { Tabs } from 'common'
// import TeaHeat from './TeaHeat'
// import TeaHot from './TeaHot'

export default function Tea() {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector(state => state)
  useEffect(() => {
    dispatch(doQueryProducts(['Tea']))
  }, [])
  return (
    <Container fluid>
      <Tabs.Tabs
        title="TRÀ"
        data={[
          {
            tabLabel: TABS.TEA_COOL,
            component: (
              <Product2 data={products.data} status={products.status} />
            ),
          },
          {
            tabLabel: TABS.TEA_HOT,
            component: (
              <Product2 data={products.data} status={products.status} />
            ),
          },
        ]}
      />
    </Container>
  )
}
