import { Box, Group, Image, ScrollArea, SimpleGrid, Text } from '@mantine/core'
import { Btn } from 'customs'
import { Photos } from 'assets'

export default function Care() {
  return (
    <Box>
      <Text size={24} mb={58} weight={700} align="center">
        Tiêu Chí Quan Tâm
      </Text>
      <SimpleGrid
        spacing={60}
        cols={2}
        breakpoints={[
          {
            cols: 1,
            maxWidth: 768,
          },
        ]}
      >
        <Box>
          <Box
            sx={{
              borderRadius: '10px',
              overflow: 'hidden',
              width: '100%',
              height: 366,
              zIndex: 1,
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/mTQA9wjcjsE"
              width="100%"
              height="366"
              title="Riot Ten - Rail Breaker (feat. Rico Act)"
              frameBorder="0"
              allowFullScreen
            />
          </Box>
          <Box
            sx={theme => ({
              maxWidth: 560,
              paddingLeft: 40,
              [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
                paddingLeft: 'unset',
              },
            })}
          >
            <Text
              my={60}
              size={36}
              weight={700}
              dangerouslySetInnerHTML={{
                __html:
                  'Phân Phối Độc Quyền Sản Phẩm Sức Khoẻ Mang Thương Hiệu ONE',
              }}
            />
            <Text>
              ONE mở rộng kinh doanh, phân phối độc quyền các dòng sản phẩm nước
              ép, sinh tố, liệu trình detox, trà, ăn vặt... đến các đối tác từ
              lớn đến nhỏ như các trung tâm sức khoẻ, thể thao, spa làm đẹp,
              quán cà phê,...
            </Text>
            <Text size={20} weight={700} mt={30}>
              Các quyền lợi đảm bảo cho các đối tác
            </Text>
            <Text
              mb={30}
              size={14}
              weight={400}
              dangerouslySetInnerHTML={{
                __html:
                  '1.Sản phẩm an toàn sức khoẻ, hợp vệ sinh người sử dụng <br/>2.Xây dựng phát triển, định hình thương hiệu ONE trên thị trường Việt Nam<br/>3.Marketing quảng bá rộng rãi sản phẩm kèm thông tin địa chỉ của các đối tác, đại lý, chi nhánh nhập sản phẩm bán<br/>4.Lợi nhuận hoa hồng hấp dẫn đến từ ONE',
              }}
            />
            <Btn label="Liên hệ hợp tác" />
          </Box>
        </Box>
        <Box>
          <ScrollArea style={{ height: 967 }} type="never">
            <Group spacing={44} noWrap>
              <Box sx={{ marginBottom: 250 }}>
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll2}
                />
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll1}
                />
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll1}
                />
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll1}
                />
              </Box>
              <Box>
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll1}
                />
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll1}
                />
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll2}
                />
                <Image
                  styles={{
                    root: {
                      margin: '44px 0',
                    },
                    imageWrapper: {
                      minHeight: 497,
                      height: '100%',
                      width: '100%',
                      maxWidth: 288,
                    },
                  }}
                  radius={10}
                  src={Photos.Scroll2}
                />
              </Box>
            </Group>
          </ScrollArea>
        </Box>
      </SimpleGrid>
    </Box>
  )
}
