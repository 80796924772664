import {
  ActionIcon,
  Box,
  Center,
  Drawer,
  Group,
  NavLink as NavLinkM,
} from '@mantine/core'
import { Link, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Svgs } from 'assets'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useMediaQuery } from '@mantine/hooks'
import Search from '../Search'

type IProps = {
  opened: boolean
  setOpened: (opened: boolean) => void
  headerData: ILinks[]
}
export default function DrawerRight({ opened, setOpened, headerData }: IProps) {
  const windowSizeX = useMediaQuery('(min-width: 992px)')
  const location = useLocation()
  useEffect(() => {
    if (windowSizeX) {
      setOpened(false)
    }
  }, [windowSizeX])

  return (
    <Drawer
      onClose={() => setOpened(false)}
      opened={opened}
      overlayOpacity={0.55}
      overlayBlur={3}
      position="right"
      withCloseButton={false}
      aria-labelledby="drawer-title"
      aria-describedby="drawer-body"
      closeButtonLabel="Close drawer"
      styles={{
        drawer: {
          paddingTop: '0px!important',
          overflow: 'hidden',
        },
      }}
    >
      <Group
        position="apart"
        sx={{
          position: 'relative',
          height: 70,
          padding: '0 20px!important',
          '&:before': {
            position: 'absolute',
            top: 0,
            left: -37,
            content: "''",
            width: '150%',
            height: '100%',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <ActionIcon variant="transparent" onClick={() => setOpened(false)}>
          <Svgs.Closebtn />
        </ActionIcon>
        <Box>
          <Search width="240px" />
        </Box>
      </Group>
      {headerData.map((item, index) => (
        <NavLinkM
          disabled={item.disabled}
          component={Link}
          to={item.to}
          key={index}
          active={location.pathname === item.to}
          onClick={() => setOpened(false)}
          label={
            <Center>
              <ActionIcon
                component={motion.div}
                initial={{ rotate: -90 }}
                hidden={location.pathname !== item.to}
                variant="transparent"
                color="dark"
                size="lg"
              >
                <Svgs.LeafIcon />
              </ActionIcon>
              {item.label}
              <ActionIcon
                component={motion.div}
                initial={{ rotate: 90 }}
                hidden={location.pathname !== item.to}
                variant="transparent"
                color="dark"
                size="lg"
              >
                <Svgs.LeafIcon />
              </ActionIcon>
            </Center>
          }
          styles={{
            children: {
              paddingLeft: '0px',
            },
            root: {
              borderRadius: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              height: 70,
              fontSize: '16px',
              '&[data-active]': {
                color: '#000',
                fontWeight: 600,
                background: 'unset',
                '&:hover': {
                  background: 'unset',
                },
              },
            },
            label: {},
            body: {
              flex: 'unset',
            },
          }}
        >
          {item?.subLinks &&
            item.subLinks.map((val, idx) => (
              <NavLinkM
                component={NavLink}
                key={idx}
                label={val.label}
                to={`${item.to}/${val.to}`}
                active={location.pathname === `${item.to}/${val.to}`}
                styles={{
                  root: {
                    fontWeight: 'normal',
                    padding: '20px 42px',
                    textAlign: 'right',
                    '&[data-active]': {
                      color: '#000',
                      background: '#f8f9fa',
                      '&:hover': {
                        background: 'unset',
                      },
                    },
                  },
                }}
              />
            ))}
        </NavLinkM>
      ))}
    </Drawer>
  )
}
