import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  footerWrapper: {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    position: 'relative',
    padding: '60px 0px',
    height: '100%',
    '.about_one': { fontSize: 12 },
    '.registerInfor': {
      height: '100%',
      display: 'flex',
      padding: '0px 10px',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '300px',
      '.inputIcon': {
        display: 'flex',
        '.mantine-ActionIcon-root': {
          color: 'white',
          borderColor: 'white',
          borderRadius: '10px',
          svg: {
            path: {
              fill: 'white',
            },
          },
        },
        '.mantine-InputWrapper-root': {
          flex: 1,
          paddingLeft: 10,
          '.mantine-TextInput-wrapper': {
            height: '100%',
            '.mantine-Input-input': {
              fontSize: 14,
              height: '100%',
              padding: '0 20px',
              borderRadius: '10px',
              backgroundColor: 'transparent',
              color: 'white',
              '::placeholder': {
                color: 'white',
              },
            },
          },
        },
      },
      '.mantine-Button-root': {
        color: '#000',
        fontSize: 14,
        fontWeight: 700,
        borderRadius: '10px',
        height: 50,
      },
    },
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      '.about_one': {
        marginBottom: '35px',
        '.mantine-Group-root': {
          padding: '20px 0',
        },
      },
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      '.about_one': {
        marginBottom: 0,
        '.mantine-Group-root': {
          paddingBottom: '0px',
        },
      },
      '.link_other_page': {
        margin: '35px 0',
      },
      '.registerInfor': {
        padding: '40px 10px',
      },
    },
  },
}))
