import { FormValidateInput } from '@mantine/form/lib/types'
import { phoneRegex } from 'utils'

export const validateFormRecipient = (): FormValidateInput<IFormRecipient> => {
  return {
    //address: value => (value.length < 15 ? true : null),
    recipientName: (value: IFormRecipient['recipientName']) =>
      value?.length < 3 ? true : null,
    phone: (value: IFormRecipient['phone']) =>
      phoneRegex(value) ? null : true,
    termsOfService: (value: IFormRecipient['termsOfService']) =>
      value ? null : 'You have to check your terms of service',
  }
}

export const validateFormUser = (): FormValidateInput<IUsers> => {
  return {
    txtPhone: value => (phoneRegex(value) ? null : true),
    firstName: value => (value.length < 3 ? true : null),
    lastName: value => (value.length < 3 ? true : null),
    email: value =>
      /^[\w-.]+@([\w-]+\.)+[\w-]{3}$/.test(value) ? null : 'Invalid email',
  }
}

export const validateFormFooter = (): FormValidateInput<IFormFooter> => {
  return {
    txtPhone: value => (phoneRegex(value) ? null : true),
    firstName: value => (value.length < 3 ? true : null),
    email: value => (/^[\w-.]+@([\w-]+\.)+[\w-]{3}$/.test(value) ? null : true),
  }
}
