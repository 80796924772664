import './styles/global.scss'
import 'assets/fonts/stylesheet.css'
import { MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { Theme } from 'constants/enum'
import ReactDOM from 'react-dom/client'
import RouterWrapper from 'router'
import configStore, { persistor } from 'app/configStore'
import reportWebVitals from './reportWebVitals'

let store: typeof configStore
const injectStore = (_store: typeof configStore) => {
  store = _store
}
injectStore(configStore)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={configStore}>
    <MantineProvider theme={{ ...Theme }} withGlobalStyles withNormalizeCSS>
      <NotificationsProvider zIndex={1001}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterWrapper />
        </PersistGate>
      </NotificationsProvider>
    </MantineProvider>
  </Provider>
)
reportWebVitals()
export { store }
