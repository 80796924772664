import {
  DocumentReference,
  Query,
  addDoc,
  collection,
  doc,
  or,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'
import { getDocsData } from 'config/firebase/utils'
import { pathCollection } from 'config/firebase/collection'
import { showNotification } from 'common'

export const doUpdateUser = createAsyncThunk(
  pathCollection.users,
  async (
    {
      user,
      isShowNotification = true,
    }: { user: IUsers; isShowNotification?: boolean },
    thunkAPI
  ) => {
    console.log('user', user)
    try {
      const { id, ...rest } = user
      const userRef = doc(database, pathCollection.users, String(id))
      const res = await updateDoc(userRef, { ...rest })
      console.log('res', res)
      if (isShowNotification) {
        showNotification({
          type: 'success',
          title: `Update user`,
          message: 'You updated success',
        })
      }
      return {}
    } catch (error) {
      const err = error as FirebaseError
      console.log('err', err)
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const doQueryUser = createAsyncThunk(
  'Query user',
  async (
    { txtPhone, email }: Partial<Pick<IUsers, 'txtPhone' | 'email'>>,
    thunkAPI
  ) => {
    try {
      let data: IUsers[] = []
      const q = query(
        collection(database, pathCollection.users),
        or(where('txtPhone', '==', txtPhone), where('email', '==', email))
      ) as Query<IUsers>
      data = await getDocsData<IUsers>(q)
      console.log('data user', data)
      return data
    } catch (error) {
      const err = error as FirebaseError
      console.log('Query user', error, err)
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const doCreateUser = createAsyncThunk(
  pathCollection.users,
  async (user: IUsers, thunkAPI) => {
    try {
      const res: DocumentReference = await addDoc(
        collection(database, pathCollection.users),
        user
      )
      return res.id
    } catch (error) {
      console.log('error create user', error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)
