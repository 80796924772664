import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  specialBenefitsWrapper: {
    overflow: 'auto',
    paddingRight: 50,
    '&:before': {
      zIndex: 1,
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100px',
      height: '100%',
      borderRadius: '10px',
      background:
        'linear-gradient(270deg, rgb(255 255 255) 50%, rgb(255 255 255 / 20%) 100%)',
    },
    '>div': {
      display: 'flex',
      width: 'max-content',
      gap: 20,
      '.content_left': {
        width: '100%',
        maxWidth: 330,
        '.description': {
          ul: {
            marginLeft: 20,
          },
        },
      },
      '.content_right': {
        display: 'flex',
        gap: 20,
      },
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      paddingRight: 0,
      '&:before': {
        position: 'unset',
      },
      '>div': {
        display: 'unset',
        '.content_left': {
          maxWidth: 'unset',
        },
        '.content_right': {
          display: 'unset',
        },
      },
    },
  },
}))
