import { Box, CSSObject } from '@mantine/core'
import { FallbackProps } from 'react-error-boundary'

const ErrorBoundary: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: FallbackProps) {
  return (
    <Box sx={ErrorBoundary}>
      <h3>Something went wrong:</h3>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
      <a href="/">Home</a>
    </Box>
  )
}
