import {
  ActionIcon,
  BackgroundImage,
  Box,
  Button,
  Container,
  Footer as FooterM,
  Grid,
  Group,
  List,
  MediaQuery,
  NavLink,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { FOOTER_DATA } from 'constants/index'
import { IPath } from 'constants/enum'
import { Link, useLocation } from 'react-router-dom'
import { Photos, Svgs } from 'assets'
import { doRegisterInfor } from './actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'
import { useForm } from '@mantine/form'
import { usePrevious } from '@mantine/hooks'
import { useStyles } from './styles'
import { validateFormFooter } from 'constants/validate'

function Footer() {
  const { pathname } = useLocation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { registerInfor } = useAppSelector(state => state)
  const formFooter = useForm<IFormFooter>({
    initialValues: {
      txtPhone: '',
      firstName: '',
      email: '',
      createOn: `${new Date()}`,
    },
    validate: validateFormFooter(),
  })

  const previousValue = usePrevious({
    registerInfor,
  })
  useEffect(() => {
    if (registerInfor.status)
      if (
        previousValue?.registerInfor.data !== registerInfor?.data &&
        registerInfor.status.isSucceeded
      ) {
        formFooter.reset()
      }
  }, [registerInfor])

  return [IPath.Cart, IPath.Order_Lookup].includes(pathname as IPath) ? (
    <FooterM
      mt={60}
      height={100}
      sx={{
        border: 'none',
        background: '#000',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // position: 'absolute',
        bottom: 0,
        width: '100%',
      }}
    >
      <Text size={14} align="center">
        Copyright © 2022 One Drink & Food. All rights reserved.
      </Text>
    </FooterM>
  ) : (
    <FooterM className={classes.footerWrapper} height={'100%'}>
      <Container fluid pl={100} pr={100}>
        <Grid m={0}>
          <Grid.Col p={0} sm={12} md={12} lg={4} className="about_one">
            <Stack justify="space-between" spacing={0} h="100%">
              <Box>
                <Link to={IPath.Home}>
                  <Svgs.LogoIcon />
                </Link>
                <Text mb={20} mt={24}>
                  ONE ra mắt các dòng sản phẩm mang đến giá trị Healthy &
                  Balance cho sức khoẻ. Dù là những buổi gặp gỡ bạn bè, đi làm
                  trên công ty hay về nhà, ONE sẽ luôn phục vụ bạn trọn vẹn!
                </Text>
                <Text weight={700}>Thông tin liên hệ</Text>
                <Text
                  dangerouslySetInnerHTML={{
                    __html:
                      'Trụ sở đăng ký kinh doanh: 213/13 Hồng Lạc, Phường 10, Quận Tân Bình, Thành phố Hồ Chí Minh<br>Địa chỉ: 1002 Tạ Quang Bửu, Phường 6, Quận 8, Thành phố Hồ Chí Minh (Chung Cư The Pegasuite 1)<br>Hotline: 0933 22 00 78<br>Email: one.drinkandfood@gmail.com',
                  }}
                />
              </Box>
              <Group spacing={18}>
                <Text size={12} weight={700}>
                  Theo dõi ONE
                </Text>
                <ActionIcon size={30} variant="transparent">
                  <Svgs.FacebookIcon />
                </ActionIcon>
                <ActionIcon size={30} variant="transparent">
                  <Svgs.Youtube />
                </ActionIcon>
                <ActionIcon size={30} variant="transparent">
                  <Svgs.GoogleIcon />
                </ActionIcon>
                <ActionIcon size={30} variant="transparent">
                  <Svgs.Instagram />
                </ActionIcon>
                <ActionIcon size={30} variant="transparent">
                  <Svgs.Tiktok />
                </ActionIcon>
              </Group>
              <MediaQuery
                smallerThan="md"
                styles={{
                  position: 'absolute',
                  bottom: '0px',
                  textAlign: 'center',
                  width: '100%',
                  marginBottom: '20px',
                  left: '0px',
                }}
              >
                <Text italic>
                  Copyright © 2022 One Drink & Food. All rights reserved.
                </Text>
              </MediaQuery>
            </Stack>
          </Grid.Col>
          <Grid.Col
            p={0}
            sm={5}
            md={5}
            lg={3}
            offsetLg={0.5}
            className="link_other_page"
          >
            <Text mb={10} weight={700} size="md">
              ONE Drink & Food
            </Text>
            <List
              styles={{
                root: {
                  listStyle: 'none',
                },
                item: {
                  borderBottom: '1px solid #ebebeb',
                  cursor: 'pointer',
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                },
              }}
            >
              {FOOTER_DATA.map((item, idx) => (
                <List.Item key={idx}>
                  <NavLink
                    icon={
                      pathname === item.to ? (
                        <Svgs.Blackdot />
                      ) : (
                        <Svgs.DotIcon />
                      )
                    }
                    component={Link}
                    to={item.to}
                    label={item.label}
                    styles={{
                      root: {
                        padding: '11.21px 0',
                        fontSize: '12px',
                        '&:hover': {
                          backgroundColor: 'unset',
                        },
                      },
                    }}
                  />
                </List.Item>
              ))}
            </List>
          </Grid.Col>
          <Grid.Col
            p={0}
            sm={6}
            offsetSm={1}
            md={6}
            offsetMd={1}
            lg={4}
            offsetLg={0.5}
          >
            <BackgroundImage
              className="registerInfor"
              src={Photos.BackgroundFood}
              radius={10}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '95%',
                }}
              >
                <Text size={20} weight={700} color="white">
                  Đăng ký nhận thông tin
                </Text>
                <Text size={12} color="white" my={15}>
                  ONE sẽ gửi thông tin mới nhất về các chương trình khuyến mãi &
                  quà tặng đến cho quý khách hàng đã đăng ký!
                </Text>
                <form
                  onSubmit={formFooter.onSubmit(values =>
                    dispatch(doRegisterInfor(values))
                  )}
                >
                  <Stack spacing={10}>
                    <Box className="inputIcon">
                      <ActionIcon size={50} variant="outline">
                        <Svgs.User />
                      </ActionIcon>
                      <TextInput
                        placeholder="Họ tên của bạn"
                        {...formFooter.getInputProps('firstName')}
                      />
                    </Box>
                    <Box className="inputIcon">
                      <ActionIcon size={50} variant="outline">
                        <Svgs.Email2 />
                      </ActionIcon>
                      <TextInput
                        placeholder="Email"
                        {...formFooter.getInputProps('email')}
                      />
                    </Box>
                    <Box className="inputIcon">
                      <ActionIcon size={50} variant="outline">
                        <Svgs.Phone2 />
                      </ActionIcon>
                      <TextInput
                        type="number"
                        placeholder="Số điện thoại"
                        {...formFooter.getInputProps('txtPhone')}
                      />
                    </Box>
                    <Button
                      loading={registerInfor.status.isLoading}
                      type="submit"
                      fullWidth
                      variant="white"
                    >
                      Gửi thông tin
                    </Button>
                  </Stack>
                </form>
              </Box>
            </BackgroundImage>
          </Grid.Col>
        </Grid>
      </Container>
    </FooterM>
  )
}

export default Footer
