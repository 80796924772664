import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  freeWrapper: {
    marginTop: 100,
    padding: '33px 0',
    background: 'linear-gradient(135deg, #000000 0%, #434343 100%)',
    color: theme.white,
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      marginTop: 30,
      '.mantine-Group-root': {
        display: 'block',
        textAlign: 'center',
      },
    },
  },
}))
