import {
  BackgroundImage,
  Box,
  Center,
  Container,
  SimpleGrid,
  Text,
} from '@mantine/core'
import { CRITERIAO__DATA } from 'constants/index'

export default function CriteriaofInterest() {
  return (
    <Container fluid>
      <Text size={24} mb={58} weight={700} align="center">
        Tiêu Chí Quan Tâm
      </Text>
      <SimpleGrid
        mb={30}
        cols={3}
        spacing={45}
        breakpoints={[
          { cols: 1, maxWidth: 'sm' },
          { cols: 2, maxWidth: 'lg' },
        ]}
      >
        {CRITERIAO__DATA.map((val, idx) => (
          <Box
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-easing="liner"
            data-aos-once="true"
            data-aos-offset="500"
            sx={{
              cursor: 'pointer',
              minHeight: 410,
              margin: 'auto',
              height: '100%',
              width: '100%',
              borderRadius: '10px',
              boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
            }}
            key={idx}
          >
            <BackgroundImage
              src={val.photo}
              radius={10}
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                transition: 'all 0.4s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                  '&:before': {
                    content: 'none',
                  },
                  '.hidden-text': {
                    display: 'none',
                  },
                },
                '&:before': {
                  transition: '.4s ease',
                  inset: 0,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  borderRadius: '10px',
                  content: '""',
                  backdropFilter: 'brightness(0.8)',
                },
              }}
            >
              <Center
                className="hidden-text"
                sx={{
                  height: '100%',
                  position: 'relative',
                  transition: '.4s ease-in-out',
                }}
              >
                <Box>
                  <Text
                    mb={8}
                    align="center"
                    color="#fff"
                    size={36}
                    weight={700}
                  >
                    {val.title}
                  </Text>
                  <Text
                    sx={{ maxWidth: 346 }}
                    align="center"
                    color="#fff"
                    size={14}
                  >
                    {val.description}
                  </Text>
                </Box>
              </Center>
            </BackgroundImage>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  )
}
