import { combineReducers } from '@reduxjs/toolkit'
import {
  carts,
  loadingOverlay,
  Products,
  User,
} from 'reducer'
import vitamin from 'components/Vitamin/reducer'
import teaHeat from 'components/Tea/TeaHeat/reducer'
import teaHot from 'components/Tea/TeaHot/reducer'
import session from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import delivery, {
  ZaloPayment,
} from 'components/CartComponent/Delivery/reducer'
import orderLookup from 'components/OrderLookup/reducers'
import { signInWithPhoneNumber } from 'components/Header/Search/SignIn/reducer'
import signOut from 'components/Users/Account/reducer'
import QueryStatusOrders from 'components/Users/History/reducer'
import { registerInfor } from 'components/Footer/reducers'
import { productId } from 'components/InforProduct/PickOption/reducers'
import { confirmationResultUser } from 'reducer/SignIn/reducers'

export const reducers = combineReducers({
  loadingOverlay: loadingOverlay.reducer,
  carts: persistReducer(
    {
      key: carts.name,
      storage: session,
    },
    carts.reducer
  ),
  vitamin: vitamin.reducer,
  teaHeat: teaHeat.reducer,
  teaHot: teaHot.reducer,
  doOrder: delivery.doOrder.reducer,
  momoPayment: delivery.doMomoPayment.reducer,
  orderUpdateStatus:
    delivery.orderUpdateStatus.reducer,
  orderLookup: orderLookup.reducer,
  createdUser: User.createdUser.reducer,
  confirmationResultUser:
    confirmationResultUser.reducer,
  signInWithPhoneNumber:
    signInWithPhoneNumber.reducer,
  queryUserResult: User.queryUserResult.reducer,
  updateUser: User.updateUser.reducer,
  signOut: signOut.reducer,
  ZaloPayment: ZaloPayment.reducer,
  queryStatusOrders: QueryStatusOrders.reducer,
  registerInfor: registerInfor.reducer,
  products: Products.products.reducer,
  productId: productId.reducer,
})
