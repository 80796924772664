import {
  DefaultMantineColor,
  MantineTheme,
  MantineThemeOverride,
  Tuple,
} from '@mantine/core'

type ExtendedCustomColors = 'oneDrink' | DefaultMantineColor

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}
export const Theme: MantineThemeOverride = {
  fontFamily: 'Montserrat',
  components: {
    Container: {
      defaultProps: {
        pl: 60,
        pr: 60,
        sx: (theme: MantineTheme) => ({
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            padding: '0 16px',
          },
        }),
      },
    },
    Center: {
      defaultProps: {
        h: '100%',
      },
    },
  },
  colors: {
    oneDrink: ['#e5e5e5'],
  },
}

export enum IPath {
  Home = '/',
  Juice = '/juice',
  Vitamin = '/vitamin',
  Course = '/course',
  Tea = '/tea',
  Snacks = '/snacks',
  Coffee = '/coffee',
  Fruit_Market = '/fruit_market',
  Introduction = '/introduction',
  Feeds = '/feeds',
  Privacy_Policy = '/privacy_policy',
  Terms_of_Use = '/terms_of_use',
  Nutrition_Consult = '/nutrition_consult',
  Cart = '/cart',
  Order_Lookup = '/order_lookup',
  Business = '/business',
  User = '/user',
  Member = '/member',
  Yogurt = '/yogurt',
  Item = '/item',
  Gift = '/gift',
  Gift_History = '/gift_history',
  History = '/history',
  Code_Discount = '/code_discount',
  Your_bag = '/your_bag',
}

export enum SizeGlass {
  '350ml' = '350ml',
  '250ml' = '250ml',
  '500ml' = 'Size M',
  '700ml' = 'Size L',
}
export enum Cooldess {
  'COOL' = 'Ủ Lạnh',
  'NOT_COLL' = 'Không Lạnh',
}
export enum IcreamCheese {
  'FILL_CHEESE' = 'Phủ kem cheese',
  'NOT_FILL_CHEESE' = 'Không phủ kem cheese',
}

export enum Ices {
  '100%' = '100% Đá (Bình Thường)',
  '50%' = '50% Đá (Ít Đá)',
  '0%' = '0% Đá (Không Đá)',
}

export enum Sugars {
  '100%' = '100% Đường (Ngọt Ngây)',
  '50%' = '50% Đường (Ngọt Vừa)',
  '0%' = '0% Đường (Nguyên Chất)',
}

export enum Payment {
  CASH = 'cash',
  MOMO = 'Momo',
  ZALO_PAY = 'ZaloPay',
  BANK = 'Bank',
}

export enum TabLabel {
  'Chờ thanh toán' = 'paymenting',
  'Đang thực hiện' = 'paid',
  'Đang giao' = 'delivering',
  'Đã hoàn tất' = 'delivered',
  'Đã huỷ' = 'canceled',
}

export const enum TABS {
  ALL = 'Tất Cả',
  COFFEE_ICE = 'Cà Phê Đá',
  COFFEE_HOT = 'Cà Phê Nóng',
  COFFEE_BOTTLE = 'Cà Phê Chai',
  COFFEE_MATCHA = 'Matcha nóng',
  TEA_COOL = 'Trà Lạnh',
  TEA_HEAT = 'Trà Thanh Nhiệt',
  TEA_HOT = 'Trà Nóng',
  JUICE_GLASS = 'Nước Ép Ly',
  JUICE_SIGNATURE = 'Nước Ép Signature',
  JUICE_BOOTLE = 'Nước Ép Đóng Chai',
  VITAMIN_CUP = 'Sinh Tố Ly',
  VITAMIN_BOTTLE = 'Sinh Tố Đóng Chai',
  VITAMIN_SIGNATURE = 'Sinh Tố Signature',
  YOGURT_ICE = 'Yogurt Đá Viên',
  YOGURT_GRINDIN = 'Yogurt Đá Xay',
}
