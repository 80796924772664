import { createSlice } from '@reduxjs/toolkit'
import { doQueryStatusOrders } from './actions'

type IInitialState = IQueryState<IFormRecipient[] | null>

const initialState: IInitialState = { data: null, status: {} }

export const QueryStatusOrders = createSlice({
  name: 'QUERY_STATUS_ORDERS',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doQueryStatusOrders.pending, state => {
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
      state.status.errorMessage = null
    }),
      builder.addCase(doQueryStatusOrders.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(doQueryStatusOrders.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})
export default QueryStatusOrders
