import { Box, Text } from '@mantine/core'
import { useStyles } from './styles'
const x: ICriteria[] = [
  {
    title: 'Chào mừng Quý Khách Hàng đến với ONE Drink & Food!',
    description:
      'Nhằm đảm bảo quyền lợi của Quý Khách Hàng, việc bảo vệ thông tin dữ liệu cá nhân là vấn đề rất quan trọng với chúng tôi. ONE luôn tôn trọng và cam kết bảo mật những thông tin mang tính riêng tư của Quý Khách Hàng. Xin Quý Khách Hàng vui lòng đọc bản chính sách bảo mật dưới đây để nắm rõ điều khoản khi tham gia sử dụng dịch vụ tại trang website www.onedrinkandfood.com.<br/><br/>',
  },
  {
    title: '1. THU THẬP THÔNG TIN',
    description:
      '</p> <p>Công Ty TNHH Thương Mại Dịch Vụ ONE Drink &amp; Food tuân thủ các quy định của pháp luật về bảo mật thông tin cá nhân. Chúng tôi không chia sẻ hay trao đổi thông tin cá nhân của Quý Khách Hàng cho một bên thứ ba nào khác khi tham gia sử dụng dịch vụ trên website: www.onedrinkandfood.com và các nền tảng khác kinh doanh khác của ONE.<br> Chúng tôi thu thập thông tin cá nhân khi:</p> <ul> <li>Khách hàng tham gia đăng ký thành viên</li> <li>Khách hàng truy cập website để đăng ký tìm hiểu thông tin</li> <li>Khách hàng đặt hàng trực tuyến</li> <li>Khách hàng phản hồi hoặc khiếu nại</li> <li>Khách hàng tham gia làm khảo sát, nhận voucher khuyến mãi</li> <li>Khách hàng trao đổi với Công ty qua các phương tiện như: điện thoại, thư từ, fax, email, các ứng dụng, mạng xã hội<br> <br> Các thông tin chúng tôi sẽ thu thập bao gồm:</li> <li>Thông tin cá nhân: họ tên, số điện thoại, ngày sinh, giới tính, email, địa chỉ</li> <li>Thông tin được cung cấp thông qua các thiết bị được sử dụng để truy cập vào website</li> </ul><br/>',
  },
  {
    title: '2. MỤC ĐÍCH SỬ DỤNG THÔNG TIN',
    description:
      '<p>ONE sử dụng thông tin cá nhân của Quý Khách Hàng nhằm nâng cao giá trị dịch vụ cung cấp, phục vụ cho các mục đích:</p> <ul> <li>Xử lý đơn hàng: Thông tin được lưu khi Quý Khách Hàng đặt mua sản phẩm, tiến hành giao hàng, tích lũy điểm và trao thưởng</li> <li>Cải tiến thương hiệu: Chúng tôi gửi các bảng kháo sát hay liên hệ trực tiếp để lấy đánh giá từ khách hàng. ONE nhận được sự đóng góp ý kiến để nâng cấp chất lượng sản phẩm và dịch vụ</li> <li>Dịch vụ người tiêu dùng: Chúng tôi nắm bắt thông tin, hiểu thói quen mua sắm của Quý Khách Hàng để xây dựng chương trình khuyến mãi phù hợp<br> </li> </ul><br/>',
  },
  {
    title: '3. QUYỀN LỢI THÔNG TIN KHÁCH HÀNG',
    description:
      '<ul> <li>&nbsp;Quý Khách Hàng có quyền cung cấp thông tin cá nhân cho ONE để nhận các chương trình khuyến mãi, voucher giảm giá</li> <li>Quý Khách Hàng có quyền tự đăng nhập tài khoản, kiểm tra, cập nhật, điều chỉnh thông tin của mình</li> <li>Quý Khách Hàng có quyền yêu cầu sửa lại những thông tin sai sót trong dữ liệu tài khoản cá nhân</li> <li>Quý Khách Hàng có quyền yêu cầu ngưng sử dụng dữ liệu cá nhân của mình cho mục đích tiếp thị<br> <br> </li> </ul>',
  },
  {
    title: '4. BẢO MẬT THÔNG TIN',
    description:
      '<p>&nbsp;Chúng tôi có chuyên viên IT với biện pháp thích hợp về kỹ thuật và an ninh để ngăn chặn truy cập trái phép, trái pháp luật, làm mất mát hay gây thiệt hại cho thông tin của Quý Khách Hàng:</p> <ul> <li>Khi thu thập thông tin dữ liệu của Quý Khách Hàng, chúng tôi bảo mật trên nền tảng máy chủ an toàn, sử dụng bức tường lửa cho máy chủ.</li> <li>Khi thu thập thông tin trên các thẻ thanh toán điện tử của Quý Khách Hàng, chúng tôi mã hoá nhằm ngăn chặn hacker xâm phạm, lấy cắp thông tin.<br> <br> Lưu ý:</li> <li>Chúng tôi khuyến nghị Quý Khách Hàng không đưa thông tin đăng nhập cá nhân, hay tài liệu chi tiết về việc thanh toán cho bất kỳ ai. ONE không chịu trách nhiệm về những mất mát mà Quý Khách Hàng có thể gặp trong việc phó thác tài khoản, hay việc trao đổi thông tin cá nhân với người khác</li> <li>Quý Khách Hàng tuyệt đối không sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào hệ thống, làm thay đổi cấu trúc dữ liệu website.&nbsp;</li> <li>ONE nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống trang website. Các hành vi vi phạm sẽ bị phía công ty tước bỏ mọi quyền lợi ngay lập tức, và có thể bị truy tố trước pháp luật nếu cần thiết.</li> <li>Mọi thông tin của Quý Khách Hàng sẽ được ONE nâng cao bảo mật. Trừ trường hợp có yêu cầu từ cơ quan pháp luật có thẩm quyền, chúng tôi buộc phải cung cấp theo quy định nhà nước.<br> <br> </li> </ul>',
  },
  {
    title: '5. ĐỐI TƯỢNG TIẾP CẬN THÔNG TIN',
    description:
      '<p>&nbsp;Ngoài trường hợp pháp luật Việt Nam có những quy định mới, chỉ những đối tượng dưới đây mới được tiếp cận Thông Tin Khách Hàng:</p> <ul> <li>Cơ quan nhà nước có thẩm quyền như: Viện kiểm sát, tòa án, cơ quan công an điều tra… khi có yêu cầu cụ thể theo quy định của pháp luật Việt Nam</li> <li>Các ngân hàng hay các Tổ chức thẻ tín dụng Visa/Mastercard/JCB liên quan đến hành vi vi phạm pháp luật, gian lận thẻ tín dụng liên quan đến Khách hàng</li> <li>Tập đoàn, Công ty, công ty mẹ, công ty con, công ty liên kết của Công ty, đại lý, nhà thầu, đơn vị cung cấp dịch vụ của Công ty, hoặc bên thứ ba, có liên quan đến việc Công ty cung cấp Sản Phẩm - Dịch Vụ cho Quý khách mới được tiếp cận Thông Tin Khách Hàng để thực hiện Mục Đích như quy định bên trên<br> <br> </li> </ul>',
  },
  {
    title: '6. THỜI GIAN LỮU TRỮ THÔNG TIN',
    description:
      'Thông tin cá nhân sẽ được lưu trữ đến khi Quý Khách Hàng có yêu cầu hủy bỏ, thời gian kể từ ngày xoá tài khoản tối tiểu 30 ngày<br/><br/>',
  },
  {
    title: '7. LIÊN HỆ',
    description:
      '<p>&nbsp;Thông tin cá nhân của bạn sẽ được lưu trữ an toàn. Nếu bạn có bất kỳ câu hỏi, hoặc ý kiến gì về chính sách bảo mật của ONE Drink &amp; Food, xin vui lòng liên hệ với qua:</p> <ol> <li>Website: www.onedrinkandfood.com</li> <li>Hotline: 0933.22.00.78</li> <li>Fanpage: www.facebook.com/onedrinkandfood.official</li> <li>Email: one.drinkandfood@gmail.com<br> </li> </ol> <p>Chúng tôi giữ toàn quyền thực hiện các thay đổi trong chính sách này. Tất cả thay đổi mới trong tương lai sẽ được thông báo trên trang website chính thức của ONE.</p> <br>',
  },
  {
    title: '8. QUY TRÌNH TIẾP NHẬN & XỬ LÝ KHIẾU NẠI',
    description:
      '<p>&nbsp;Công Ty TNHH Thương Mại Dịch Vụ ONE Drink &amp; Food luôn đón nhận những thông tin đóng góp và phản ánh thực tế từ phía Quý Khách Hàng, điều này giúp chúng tôi cải thiện chất lượng và dịch vụ tốt hơn mỗi ngày. Khi có thắc mắc, Quý Khách Hàng vui lòng tuân thủ quy trình phản hồi dưới đây:</p> <ul> <li>Thời gian phản hồi từ 09:00 - 17:00, Thứ Hai đến Chủ Nhật (trừ các ngày Lễ - Tết)</li> <li>Phản hồi qua email one.drinkandfood@gmail.com<br> Tất cả ý kiến của bạn sẽ được bộ phận CSKH của chúng tôi tiếp nhận, lưu trữ và xử lý theo trình tự trong thời gian sớm nhất.</li> </ul> <p>Những phản hồi không rõ ràng, không mang tính chất xây dựng hay mang tính phỉ báng sẽ không được chấp thuận, có thể bị đưa ra pháp luật xét xử.</p>',
  },
]
export default function Policy() {
  const { classes } = useStyles()
  return (
    <Box className={classes.policyWrapper}>
      {x.map((val, idx) => (
        <Text key={idx} size={14} weight="700" sx={{ lineHeight: '24px' }}>
          {val.title}
          <Text
            size={14}
            sx={{ lineHeight: '24px' }}
            dangerouslySetInnerHTML={{ __html: val.description }}
            weight="400"
          />
        </Text>
      ))}
    </Box>
  )
}
