import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { pathCollection } from 'config/firebase/collection'

export const onOrderLookup = createAsyncThunk(
  pathCollection.orders,
  async (
    params: {
      id: string
    },
    thunkAPI
  ) => {
    try {
      const docRef = doc(database, pathCollection.orders, params.id)
      const docSnap = await getDoc(docRef)
      return docSnap.data() as IFormRecipient
    } catch (error) {
      // doc.data() will be undefined in this case
      console.log('No such document!', error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)
