import { createSlice } from '@reduxjs/toolkit'
import { doCreateUser, doQueryUser, doUpdateUser } from './action'

type IInitialState = IQueryState<{} | null>

const initialState: IInitialState = { data: null, status: {} }

export const updateUser = createSlice({
  name: 'UPDATE_USER',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doUpdateUser.pending, state => {
      state.status.errorMessage = null
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
    }),
      builder.addCase(doUpdateUser.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(doUpdateUser.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})

const initialStatereatedUser: IQueryState<string | null> = {
  data: null,
  status: {},
}

export const createdUser = createSlice({
  name: 'ORDERS',
  initialState,
  reducers: {
    onClearDoOrder: () => {
      return initialStatereatedUser
    },
  },
  extraReducers: builder => {
    builder.addCase(doCreateUser.pending, state => {
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
      state.status.errorMessage = null
    }),
      builder.addCase(doCreateUser.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(doCreateUser.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})

const initialStateQueryUserResult: IQueryState<IUsers[] | null> = {
  data: null,
  status: {},
}

export const queryUserResult = createSlice({
  name: 'Query User Result',
  initialState: initialStateQueryUserResult,
  reducers: {
    onClearQueryUserResult: () => {
      return initialStateQueryUserResult
    },
  },
  extraReducers: builder => {
    builder.addCase(doQueryUser.pending, state => {
      state.status.errorMessage = null
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
    }),
      builder.addCase(doQueryUser.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(doQueryUser.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})

export default updateUser
export const { onClearQueryUserResult } = queryUserResult.actions
