import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Input,
  MantineSize,
  Overlay,
  Rating,
  Stack,
  Text,
} from '@mantine/core'
import { Svgs } from 'assets'
import { useRef, useState } from 'react'
import useStyles from './styles'

const DATA = [
  {
    id: '1',
    image:
      'https://cdn.tgdd.vn/Products/Images/42/220533/samsung-galaxy-a52-5g-128gb-1-400x460.png',
    name: 'Dưa Hấu',
    status: 'rate yet',
  },
  {
    id: '2',
    image:
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80',
    name: 'Cam',
    status: 'rate yet',
  },
]
export default function Rate({ onClose }: { onClose: () => void }) {
  const { classes } = useStyles()
  const refInput = useRef<HTMLInputElement | null>(null)
  const [rating, setRating] = useState(0)
  const [data, setData] = useState(DATA)
  const handleSubmit = (id: string) => {
    const newData = data.map(val => {
      if (val.id === id) {
        return { ...val, status: 'rated' }
      }
      return val
    })
    setData(newData)
  }
  return (
    <Overlay opacity={1} radius={10} className={classes.rateWrapper}>
      <ActionIcon onClick={onClose} variant="transparent">
        <Svgs.Closebtn />
      </ActionIcon>
      <Text size={16} weight={700} align="center">
        Đánh giá sản phẩm
      </Text>
      {data.map(val => (
        <Box key={val.id} mt={40}>
          <Stack spacing={10} align="center">
            <Avatar radius={100} size={200} src={val.image} />
            <Text size={16} weight={700}>
              {val.name}
            </Text>
            <Text align="center" size={12}>
              Bạn cảm thấy chất lượng sản phẩm như thế nào?
            </Text>
            <Rating
              size={50 as unknown as MantineSize}
              fractions={2}
              defaultValue={rating}
              onChange={setRating}
            />
            {/* <Input
                ref={refInput}
                w="100%"
                variant="filled"
                placeholder="Ghi nhận xét của bạn"
                radius={10}
              />
              <Button className="btn" onClick={handleSubmit}>
                Gửi nhận xét
              </Button> */}
            {val.status === 'rated' ? (
              <Text align="center" size={12}>
                Gửi nội dung nhận xét thành công. Cảm ơn bạn đã đóng góp ý kiến
                cho ONE! {'<3'}
              </Text>
            ) : (
              <Box w="100%">
                <Input
                  ref={refInput}
                  w="100%"
                  variant="filled"
                  placeholder="Ghi nhận xét của bạn"
                  radius={10}
                  mb={10}
                />
                <Button
                  disabled={rating <= 0}
                  className="btn"
                  onClick={() => handleSubmit(val.id)}
                >
                  Gửi nhận xét
                </Button>
              </Box>
            )}
          </Stack>
        </Box>
      ))}
    </Overlay>
  )
}
