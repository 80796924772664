import { Container } from '@mantine/core'
import { Product2, Tabs } from 'common'
import { TABS } from 'constants/enum'
import { doQueryProducts } from 'reducer/Products/actions'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/hooks'
import { useEffect } from 'react'

export default function Juice() {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector(
    state => state
  )
  useEffect(() => {
    dispatch(
      doQueryProducts([
        'Nước Ép Ly',
        'Nước Ép Đóng Chai',
        'Nước Ép Signature',
      ])
    )
  }, [])
  console.log(
    products.data?.filter(
      item => item.group === 'Nước Ép Ly'
    )
  )
  return (
    <Container fluid>
      <Tabs.Tabs
        data={[
          {
            tabLabel: TABS.JUICE_GLASS,
            component: (
              <Product2
                data={products.data?.filter(
                  item =>
                    item.group === 'Nước Ép Ly'
                )}
                status={products.status}
              />
            ),
          },
          // {
          //   tabLabel: TABS.JUICE_BOOTLE,
          //   component: (
          //     <Product2
          //       data={products.data?.filter(
          //         item => item.group === 'Nước Ép Đóng Chai'
          //       )}
          //       status={products.status}
          //     />
          //   ),
          // },
          // {
          //   tabLabel: TABS.JUICE_SIGNATURE,
          //   component: (
          //     <Product2
          //       data={products.data?.filter(
          //         item => item.group === 'Nước Ép Signature'
          //       )}
          //       status={products.status}
          //     />
          //   ),
          // },
        ]}
        title="NƯỚC ÉP"
      />
    </Container>
  )
}
