import { Box, Center, Image, Text } from '@mantine/core'
import { Photos } from 'assets'

export const Body404 = () => {
  return (
    <Center
      sx={{
        width: '100%',
        minHeight: '90vh',
      }}
    >
      <Box>
        <Image
          m="auto"
          sx={{
            maxWidth: 472,
            maxHeight: 292,
          }}
          src={Photos.Img404}
        />
        <Text align="center" size={64} weight={700} mt={40} mb={20}>
          Trang đang được nâng cấp!
        </Text>
        <Text align="center" size={32}>
          Vui lòng quay lại sau
        </Text>
      </Box>
    </Center>
  )
}
export default function Page404() {
  return (
    <Box>
      <Body404 />
    </Box>
  )
}
