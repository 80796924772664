import {
  ActionIcon,
  Box,
  Center,
  Container,
  Image,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core'
import { Photos, Svgs } from 'assets/index'

type IProps = {
  type?: 'mean' | 'introduction'
}
export default function BrandMean({ type }: IProps) {
  const isTypeMean = type === 'mean'
  return (
    <Container fluid>
      <Center
        data-aos="flip-up"
        data-aos-mirror="true"
        data-aos-offset="300"
        data-aos-duration="1000"
      >
        {isTypeMean && (
          <ActionIcon
            variant="transparent"
            component={Svgs.LogoIcon}
            sx={{ width: 444, height: 219 }}
          />
        )}
        {!isTypeMean && (
          <Box mb={30}>
            <Image
              data-aos="fade-left"
              data-aos-duration="1000"
              styles={{
                figure: {
                  maxWidth: 455,
                  maxHeight: 493,
                  marginLeft: 'auto',
                  ['@media (max-width: 576px)']: {
                    margin: 'auto',
                  },
                },
              }}
              src={Photos.ThreeBottle}
              alt="Logo white"
            />
          </Box>
        )}
      </Center>
      {isTypeMean && (
        <SimpleGrid
          mt={60}
          mb={60}
          cols={2}
          spacing={60}
          breakpoints={[{ maxWidth: 'xs', cols: 1 }]}
        >
          <Image
            data-aos="fade-left"
            data-aos-duration="1000"
            styles={{
              figure: {
                maxWidth: 315,
                maxHeight: 377,
                marginLeft: 'auto',
                ['@media (max-width: 576px)']: {
                  margin: 'auto',
                },
              },
            }}
            src={Photos.Logowhite}
            alt="Logo white"
          />
          <Image
            data-aos="fade-right"
            data-aos-duration="1000"
            styles={{
              figure: {
                maxWidth: 315,
                maxHeight: 377,
                ['@media (max-width: 576px)']: {
                  margin: 'auto',
                },
              },
            }}
            src={Photos.LogoBlack}
            alt="Logo black"
          />
        </SimpleGrid>
      )}
      <Title mb={30} align="center">
        <Text size={36} weight={700}>
          {isTypeMean ? 'Ý NGHĨA' : 'GIỚI THIỆU'}
        </Text>
      </Title>
      {isTypeMean && (
        <Center>
          <Text
            mb={30}
            sx={{ lineHeight: '30px', maxWidth: 750 }}
            align="center"
            size={18}
          >
            ONE với ý nghĩa là duy nhất trong từ <b>“Just One&quot;</b>. Tên ONE
            ra đời thể hiện sự định vị về chất lượng, dịch vụ & sự khác biệt của
            thương hiệu trên thị trường, cung cấp cho khách hàng những trãi
            nghiệm thú vị về sản phẩm.
          </Text>
        </Center>
      )}
      {!isTypeMean && (
        <Box>
          <Center>
            <Text
              mb={40}
              sx={{ lineHeight: '30px', maxWidth: 874 }}
              align="center"
              size={16}
            >
              ONE Drink&Food là cửa hàng thức uống & ăn vặt. ONE ra mắt với
              những sản phẩm nước giải khát chất lượng đáp ứng nhu cầu &quot;ăn
              hàng&quot; của giới trẻ Việt Nam. Dòng sản phẩm chính của ONE là
              Nước Ép Trái Cây
            </Text>
          </Center>
          <Center>
            <Text
              mb={30}
              sx={{ lineHeight: '30px', maxWidth: 874 }}
              align="center"
              size={16}
            >
              ONE hướng đến một cuộc sống Healthy & Fun. Healthy trong những sản
              phẩm ăn uống hằng ngày, và Fun trong việc tạo ra môi trường vui
              nhộn đầy sự tươi mới. ONE muốn dẫn dắt giới trẻ tới gần hơn cuộc
              sống khoẻ đẹp, quan tâm đến bản thân nhiều hơn.
            </Text>
          </Center>
        </Box>
      )}
      {isTypeMean && (
        <Title order={1} align="center">
          <Text mb={30} size={18} weight="bold">
            Biểu tượng logo ONE cách điệu từ tạo hình trái cây
          </Text>
        </Title>
      )}
    </Container>
  )
}
