import { ActionIcon, Affix, Transition } from '@mantine/core'
import { TiArrowUpOutline } from 'react-icons/ti'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useWindowScroll } from '@mantine/hooks'
export default function ScrollToTop() {
  const [scroll, scrollTo] = useWindowScroll()
  const location = useLocation()
  useEffect(() => {
    scrollTo({ y: 0 })
  }, [location])

  return (
    <>
      <Affix position={{ bottom: 30, right: 30 }}>
        <Transition transition="slide-up" mounted={scroll.y > 1000}>
          {transitionStyles => (
            <ActionIcon
              color="dark"
              size={40}
              radius="xl"
              variant="filled"
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
              sx={{
                boxShadow: 'rgb(255 255 255) 0px 4px 12px',
              }}
            >
              <TiArrowUpOutline />
            </ActionIcon>
          )}
        </Transition>
      </Affix>
    </>
  )
}
