import { createStyles } from '@mantine/core'
type IParams = {
  isScrollY: boolean
}
export default createStyles((theme, params: IParams) => ({
  headerWrapper: {
    width: '100%',
    position: 'sticky',
    top: '0',
    zIndex: 101,
    minHeight: 100,
    background: theme.white,
    paddingTop: 22,
    paddingBottom: 22,
    borderRadius: '0 0 10px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: '.4s ease',
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: '0 16px',
    },
    '&.box-shadow': {
      boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 20px',
    },
  },
  scrollYed: {
    minHeight: 70,
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 20px',
  },
  childrenList: {
    position: 'absolute',
    borderRadius: '10px',
    boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
    padding: 0,
    width: '250px',
    overflow: 'hidden',
    left: 40,
    top: 52,
    backgroundColor: theme.white,
    'a::first-of-type:hover': {
      borderRadius: '10px 10px 0 0',
    },
    'a::nth-last-of-type(2):hover': {
      borderRadius: '0 0 10px 10px',
    },
  },
  rootList: {
    padding: '8px 23px',
    textAlign: 'center',
    position: 'relative',
    // width: isScroolY ? '170px' : 'max-content',
    fontSize: params.isScrollY ? 14 : 16,
    transition: '.4s ease',
    cursor: 'pointer',
    fontWeight: 'normal',
    borderRadius: '5px',
    '&[data-active]': {
      color: '#000',
      fontWeight: 600,
      background: 'unset',
      '&:hover': {
        background: 'unset',
      },
    },
  },
  iconList: {
    position: 'absolute',
    width: '100%',
    inset: 0,
    top: '-20px',
    marginRight: 20,
    textAlign: 'center',
    color: theme.white,
    height: '100%',
    div: {
      background: 'unset',
    },
  },
}))
