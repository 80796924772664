import { Box, Container } from '@mantine/core'
import { GgMap } from 'common'
import Banner from './Banner'
import Care from './Care'
import Distribution from './Distribution'

export default function Business() {
  return (
    <Box>
      <Container fluid>
        <Care />
        <Distribution />
      </Container>
      <Banner />
      <GgMap />
    </Box>
  )
}
