import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  socialWrapper: {
    position: 'relative',
    '.img-wrapper': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: '0 16px',
      '.img-wrapper': {
        display: 'none',
      },
    },
  },
}))
