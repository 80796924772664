import { Box, Button, Input, LoadingOverlay, Stack, clsx } from '@mantine/core'
import { Slash } from 'common'
import { UseFormReturnType } from '@mantine/form'
import { doCreateUser } from 'reducer/User/action'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'
import _ from 'lodash'

const Step2 = ({
  phoneForm,
  nextStep,
}: {
  phoneForm: UseFormReturnType<IUsers>
  nextStep: () => void
}) => {
  const dispatch = useAppDispatch()
  const { createdUser } = useAppSelector(state => state)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (createdUser.status.isSucceeded) {
      // next step 3
      setTimeout(() => {
        nextStep()
      }, 1500)
    }
  }, [createdUser])
  const isvalueFormEmtpy =
    phoneForm.values.email === '' ||
    phoneForm.values.firstName === '' ||
    phoneForm.values.lastName === '' ||
    phoneForm.values.txtPhone === ''
  const isErrors = _.isEmpty(phoneForm.errors)
  const isDisableCreate = !isvalueFormEmtpy && isErrors

  const onCreateUser = (values: IUsers) => {
    setIsLoading(true)
    dispatch(doCreateUser(values))
  }
  return (
    <Box>
      <LoadingOverlay
        loader={
          <Slash.LoadingProgress active={!!createdUser.status.isLoading} />
        }
        visible={isLoading}
      />
      <Stack spacing={15}>
        {/* <form
        onSubmit={phoneForm.onSubmit(values => dispatch(doCreateUser(values)))}
      > */}
        <Input
          variant="filled"
          placeholder="Nhập tên của bạn *"
          radius={10}
          invalid={phoneForm.errors.firstName}
          {...phoneForm.getInputProps('firstName')}
          error="false"
        />
        <Input
          variant="filled"
          placeholder="Nhập họ của bạn *"
          radius={10}
          invalid={phoneForm.errors.lastName}
          {...phoneForm.getInputProps('lastName')}
          error="false"
        />
        <Input
          variant="filled"
          placeholder="Email của bạn *"
          radius={10}
          invalid={phoneForm.errors.email}
          {...phoneForm.getInputProps('email')}
          error="false"
        />
        <Input
          disabled={phoneForm.values.property.providerId === 'phone'}
          variant="filled"
          radius={10}
          {...phoneForm.getInputProps('txtPhone')}
          error="false"
        />
        <Button
          disabled={!isDisableCreate}
          className={clsx('space-center btnsignin', [
            {
              active: isDisableCreate,
            },
          ])}
          type="submit"
          color="#D9D9D9"
          onClick={() => onCreateUser(phoneForm.values)}
        >
          Tạo tài khoản
        </Button>
        {/* </form> */}
      </Stack>
    </Box>
  )
}

export default Step2
