import { IPath } from 'constants/enum'
import { useDecodeSearchParams } from 'hooks/useDecodeSearchParams'
import ErrorUI from 'common/ErrorUI'

export default function MomoPayment() {
  const queryParameters = useDecodeSearchParams<IRedirectUrl>()
  console.log('MomoPayment', queryParameters)
  if (queryParameters.resultCode === '9000') {
    window.location.href = IPath.Cart + '/' + queryParameters.orderId
    return null
  } else {
    return (
      <ErrorUI
        message={queryParameters.message}
        resultCode={queryParameters.resultCode}
      />
    )
  }
}
