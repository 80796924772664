import { IPath } from './enum'
import { Photos } from 'assets'

export const DD_MM_YYY = 'dd/MM/yyyy'
export const DD_MM_YY_HH_MM = 'dd/MM/yyyy HH:mm'

export const HEADER_DATA: ILinks[] = [
  {
    label: 'Nước ép',
    to: IPath.Juice,
  },
  {
    label: 'Sinh tố',
    to: IPath.Vitamin,
  },
  {
    label: 'Yogurt',
    to: IPath.Yogurt,
  },
  {
    label: 'Trà',
    to: IPath.Tea,
  },
  {
    label: 'Cà phê',
    to: IPath.Coffee,
  },
  {
    label: 'Vật phẩm',
    to: IPath.Item,
  },
  // {
  //   label: 'Ăn vặt',
  //   to: IPath.Snacks,
  //   disabled: true,
  // },
  // {
  //   label: 'Món khác',
  //   to: IPath.Other_Dishes,
  // },
  // {
  //   label: 'Chợ Trái Cây',
  //   to: IPath.Fruit_Market,
  // },
  {
    label: 'Liệu trình Detox',
    to: IPath.Course,
    disabled: true,
  },
]

export const HEADER_DATA_USER: ILinks[] = [
  {
    label: 'Tài khoản',
    to: IPath.User,
  },
  {
    label: 'Thành viên',
    to: IPath.Member,
  },
  {
    label: 'Lịch sử',
    to: IPath.History,
  },
  {
    label: 'Túi đồ',
    to: IPath.Your_bag,
  },
]

export const FOOTER_DATA: ILinks[] = [
  {
    label: 'Giới thiệu thương hiệu',
    to: IPath.Introduction,
  },
  {
    label: 'Bảng tin mỗi ngày',
    to: IPath.Feeds,
  },
  {
    label: 'Hợp tác kinh doanh',
    to: IPath.Business,
  },
  {
    label: 'Tư Vấn Dinh Dưỡng',
    to: IPath.Nutrition_Consult,
  },
  {
    label: 'Tái chế sản phẩm',
    to: IPath.Terms_of_Use,
  },
  {
    label: 'Chính Sách & đều khoản',
    to: IPath.Privacy_Policy,
  },
  {
    label: 'Tuyển dụng nhân viên',
    to: IPath.Privacy_Policy,
  },
  {
    label: 'Nhượng quyền ONE',
    to: IPath.Privacy_Policy,
  },
]

export const PRODUCT_PORTFOLIO_DATA: IPortfolio[] = [
  {
    title: 'NƯỚC ÉP <br/>TRÁI CÂY',
    photo: Photos.Portfolio1,
    to: IPath.Juice,
  },
  {
    title: 'SINH TỐ <br/> ĐÁ XAY',
    photo: Photos.Portfolio2,
    to: IPath.Home,
  },
  {
    title: 'LIỆU TRÌNH <br/> DETOX',
    photo: Photos.Portfolio3,
    to: IPath.Home,
  },
  {
    title: 'CÀ PHÊ <br/> TRUYỀN THỐNG',
    photo: Photos.Portfolio4,
    to: IPath.Coffee,
  },
]

export const ONE_FOOD_DRINK_DATA: IOneFoodDrink[] = [
  {
    title: 'VỀ ONE - FOOD & DRINK',
    label: 'KHOẺ ĐẸP & TƯƠI VUI',
    qoue: { top: '   “HEALTHY & FUN”' },
    description1:
      'ONE Drink & Food là thương hiệu thức uống & ăn vặt mới. ONE ra mắt với những sản phẩm nước giải khát chất lượng đáp ứng nhu cầu “ăn hàng" của giới trẻ Việt Nam. Dòng sản phẩm chính của ONE là Nước Ép Trái Cây & Sinh Tố. Bên cạnh còn nhiều dòng sản phẩm khác trong danh sức thức uống nhằm tạo nên sự đa dạng cho người dùng.<br> <br>ONE hướng đến một cuộc sống Healthy, Balance & Fun. Healthy & Balance trong những sản phẩm ăn uống hằng ngày, và Fun trong việc tạo ra môi trường vui nhộn đầy sự tươi mới. ONE muốn dẫn dắt giới trẻ tới gần hơn cuộc sống khoẻ đẹp, quan tâm đến bản thân nhiều hơn. ',
    labelBtn: [
      {
        label: 'Xem thêm',
        to: IPath.Introduction,
      },
    ],
    photo: Photos.Beef,
  },
  {
    label: 'DINH DƯỠNG & GIÁ TRỊ',
    qoue: { top: '“NUTRITION & VALUATION”' },
    description1:
      'Khi cuộc sống trở nên hiện đại hoá & văn minh hơn, con người có xu hướng nhắm tới những dòng sản phẩm healthy, quan tâm đến sức khoẻ bản thân nhiều hơn. Vì thế ONE xuất hiện để đáp ứng các nhu cầu ấy.<br><br>ONE mong muốn tương lai trở thành một thương hiệu không chỉ là ăn uống vặt, những sản phẩm bên ONE còn mang lại những giá trị tinh thần lẫn vật chất cho người dùng. Giới trẻ sẽ được trải nghiệm nhiều điều mới mẻ từ ONE.',
    photo: Photos.Healthbeauty,
    labelBtn: [
      {
        label: 'Các dòng nước ép',
        to: IPath.Juice,
      },
      {
        label: 'Liệu trình Detox',
        to: IPath.Course,
      },
    ],
    photoPosition: 'right',
  },
  {
    label: 'LAI RAI & NGON MIỆNG ',
    qoue: {
      top: '“GOOD & DELICIOUS”',
    },
    description1:
      'Bên cạnh những món thức uống thơm ngom bổ dưỡng. Giới trẻ không thể bỏ qua các món ăn vặt hấp dẫn trong danh sách đồ ăn của ONE.<br><br>ONE cho ra những gói combo ăn uống dành cho buổi sáng, buổi trưa & xế chiều cho các bạn trẻ đi làm, dân văn phòng, hay các gia đình bận rộn thời gian. Không cần bất tiện trong việc đi lại hay đặt hàng nhiều nơi. Thức ăn & đồ uống được ONE cung cấp đầy đủ.',
    labelBtn: [
      {
        label: 'Đến trang ăn vặt',
        to: IPath.Order_Lookup,
      },
    ],
    photo: Photos.Bottle,
  },
  {
    label: 'SÁNG TẠO & MỚI LẠ',
    qoue: { top: '“CREATIVE & NEW”' },
    description1:
      'ONE luôn đổi mới sản phẩm, lồng ghép tính nghệ thuật vào thương hiệu nhằm tạo thêm giá trị thẫm mỹ. ONE thành lập một fanpage hướng dẫn cách thiết kế trang trí những chai lọ bình đã qua sử dụng với nhiều phương thức khác nhau. Tái sử dụng đa dạng như: trang trí nhà cửa, đựng đồ vật, bình cắm hoa,…<br><Br>Hình thành môi trường giao lưu học hỏi sáng tạo, giúp các bạn trẻ biết ứng dụng sản phẩm của mình “có ích hơn", tạo ra sự thú vị trong cuộc sống hằng ngày.',
    labelBtn: [
      {
        label: 'Khám phá',
        to: IPath.Introduction,
      },
    ],
    photo: Photos.Orange,
    photoPosition: 'right',
  },
]

export const CRITERIA_DATA: ICriteria[] = [
  {
    title: 'TƯƠI NGON',
    description:
      'Lựa chọn sản phẩm trái cây tươi mới, pha chế & sử dụng ngay, sạch sẽ, không chất bảo quản.',
  },
  {
    title: 'DINH DƯỠNG',
    description:
      'Những sản phẩm từ ONE mang lại nhiều giá trị dinh dưỡng cho người dùng.',
  },
  {
    title: 'AN TOÀN',
    description:
      'Nghiên cứu chuyên sâu về sản  phẩm, an toàn sức khoẻ, hợp vệ sinh.',
  },
  {
    title: 'TÁI CHẾ',
    description:
      'Hướng đến môi trường xanh, ONE ra nhiều ý tưởng để có thể tái sử dụng sản phẩm cũ.',
  },
]
export const ONE_FOOD_DRINK_BRANCH_DATA: IOneFoodDrink[] = [
  {
    photo: Photos.ThreeBottle,
    title: 'Thương Hiệu ONE Drink & Food',
    label: 'GIỚI THIỆU',
    description1:
      'ONE Drink&Food là cửa hàng thức uống & ăn vặt. ONE ra mắt với những sản phẩm nước giải khát chất lượng đáp ứng nhu cầu “ăn hàng" của giới trẻ Việt Nam. Dòng sản phẩm chính của ONE là Nước Ép Trái Cây<br><br>ONE hướng đến một cuộc sống Healthy & Fun. Healthy trong những sản phẩm ăn uống hằng ngày, và Fun trong việc tạo ra môi trường vui nhộn đầy sự tươi mới. ONE muốn dẫn dắt giới trẻ tới gần hơn cuộc sống khoẻ đẹp, quan tâm đến bản thân nhiều hơn.',
    isShowSmallCarousel: true,
  },
  {
    photo: Photos.OrangeJuice,
    label: 'TẦM NHÌN',
    description1:
      'Khi cuộc sống trở nên hiện đại hoá & văn minh hơn, con người có xu hướng nhắm tới những dòng sản phẩm healthy, quan tâm đến sức khoẻ bản thân nhiều hơn. Vì thế ONE xuất hiện để đáp ứng các nhu cầu ấy.<br><br>ONE mong muốn tương lai trở thành một thương hiệu không chỉ là ăn uống vặt, những sản phẩm bên ONE còn mang lại những giá trị tinh thần lẫn vật chất cho người dùng. Hướng mọi người đến sự văn minh, khoẻ đẹp. Giới trẻ Việt Nam sẽ được trải nghiệm nhiều điều mới mẻ từ ONE.',
    photoPosition: 'right',
  },
  {
    photo: Photos.VitaminChart,
    label: 'SỨ MỆNH',
    description1:
      'Sứ mệnh của ONE hướng đến một cuộc sống Healthy & Fun. Healthy trong những sản phẩm ăn uống hằng ngày, và Fun trong việc tạo ra môi trường vui nhộn đầy sự tươi mới. ONE muốn dẫn dắt giới trẻ tới gần hơn cuộc sống khoẻ đẹp, quan tâm đến bản thân nhiều hơn.<br><br>Với mô hình online hiện tại, ONE lựa chọn các nguồn thực phẩm sạch sẽ & tươi ngon để thời gian đầu ra mắt đem tới hương vị sản phẩm tốt nhất. ONE nghiên cứu những giá trị dinh dưỡng của trái cây, giúp người dùng hiểu được lợi ích việc hấp thụ dưỡng chất, vitamin từ các dòng nước ép bên ONE.',
  },
  {
    photo: Photos.AppleHeart,
    label: 'GIÁ TRỊ CỐT LÕI',
    description1:
      '<b>Với Khách Hàng:</b><br/>-Trao đến tay khách hàng những sản phẩm chất lượng nhất <br/>-Cho ra sản phẩm mang lại công dụng “khoẻ đẹp" <br/> -Tạo ra môi trường nhiệt huyết, sáng tạo với giao diện hình ảnh tươi trẻ & các phương thức tái sử dụng sản phẩm đa dạng.<br><br><b>Với Chính Doanh Nghiệp:</b><br/>-Nghiên cứu tạo ra những sản phẩm chất lượng, an toàn, vệ sinh. <br/>-Ưu tiên quan tâm về sức khoẻ, trải nghiệm người dùng & những giá trị dinh dưỡng mang lại.<br/>-Hướng đến bảo vệ môi trường & tái sử dụng, tiết kiệm nguyên liệu.',
    photoPosition: 'right',
  },
]

export const DEMO_DATA: IOneFoodDrink[] = [
  {
    title: 'THÔNG TIN VỀ TÁO',
    label: 'NGUỒN GỐC',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/tao1.png?alt=media&token=4900aa0a-ec07-4066-80c0-e718da058ab3',
    description1:
      'Táo còn gọi là bơm (tiếng pháp: pomme) , một loại quả ăn được. Táo tây được trồng rộng rãi trên khắp thế giới. Cây Táo có nguồn gốc ở trung á, nơi tổ tiên của nó là loài táo dai Tân Cương sinh sống, hiện vẫn còn tồn tại cho đến ngày nay. Chúng đã được trồng từ hàng ngàn năm qua ở châu Á và châu Âu, đã được thực dân châu Âu đưa đến Bắc Mỹ. Táo có ý nghĩa tôn giáo và thần thoại trong nhiều nền văn hóa, bao gồm Bắc Âu, Hy Lạp và Cơ đốc giáo châu Âu.',
  },
  {
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/tao2.png?alt=media&token=49f25e09-033f-4c7f-8217-940dca2a020a',
    label: 'THÀNH PHẦN <br/> DINH DƯỠNG',
    textAlign: 'right',
    photoPosition: 'right',
    description1:
      'Táo rất giàu chất xơ. Một quả táo cỡ trung bình (100 gram) chứa khoảng 4 gram chất dinh dưỡng này, chiếm 17% giá trị dinh dưỡng cần thiết mỗi ngày. Một phần chất xơ của táo đến từ các chất xơ không hòa tan và chất xơ hòa tan được gọi là pectin. Táo được biết đến là loại trái cây có nhiều vitamin và khoáng chất. Táo cung cấp nhiều vitamin C cho cơ thể giúp chống oxy hoá.',
    qoue: {
      bottom: 'Trong 100g táo bao gồm các chất dinh dưỡng:',
    },
  },
  {
    label: 'ĐẶC TÍNH',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/tao3.png?alt=media&token=4959b9a2-8508-4b49-b5fa-0c87a9f2314c',
    description1:
      'Táo có vị ngon, ngọt, có loại hơi chua, giòn, chứa nhiều thịt, nhiều nước, khi táo chín thì mềm, hơi xốp. Giữa thịt có hạt cứng, dài, thuôn nhọn ở hai đầu, trên hạt có những vết nhấp nhô lồi lõm. Trong hạt có nhân hình bầu dục dài, ăn có vị bùi , thơm.<br><br>Cây táo sẽ cho ra trái sau 24 tháng trồng, thời điểm thích hợp nhất để trồng là từ giữa hoặc cuối mùa mưa. Cây thường rụng lá vào mùa thu. Hoa thường nở chủ yếu vào mùa xuân. Quả thường chín vào mùa thu.',
  },
  {
    title: 'TÁC DỤNG DIỆU KÌ CỦA TÁO',
    label: 'HỖ TRỢ GIẢM CÂN',
    textAlign: 'right',
    photoPosition: 'right',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/le4.png?alt=media&token=390268df-d49b-4e65-a0e4-506a8c622ca6',
    description1:
      'Trong thành phần dinh dưỡng của táo chủ yếu là chất xơ và nước. Chất xơ có thể làm chậm quá trình tiêu hóa để bạn cảm thấy no hơn sau khi ăn. Điều này có thể giữ cho bạn khỏi ăn quá nhiều. Ăn thực phẩm giàu chất xơ như táo sẽ có lợi cho việc giảm cân. Trong một nghiên cứu, những người ăn táo trước bữa ăn cảm thấy no hơn so với những người không ăn táo.',
  },
  {
    label: 'VI KHUẨN CÓ LỢI <br> CHO CƠ THỂ',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/shield.png?alt=media&token=00e059fa-72b7-485a-872b-f79ba4276f6a',
    description1:
      'Táo có chứa pectin - một loại chất xơ hoạt động như một prebiotic, giúp nuôi dưỡng vi khuẩn có lợi trong ruột của bạn. Thực tế, ruột non không hấp thụ chất xơ trong quá trình tiêu hóa. Thay vào đó, chất xơ đi đến đại tràng - nơi nó có thể thúc đẩy sự phát triển của vi khuẩn có lợi, biến thành các hợp chất hữu ích khác lưu thông trở lại trong cơ thể.',
  },
  {
    label: 'GIẢM HEN SUYỄN <br> & GIẢM VIÊM',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/duahau9.png?alt=media&token=32ae8f44-6732-4125-8ab2-dd88c1ad2c31',
    description1:
      'Táo giàu chất chống oxy hóa, bảo vệ phổi khỏi bị tổn thương oxy hóa. Vỏ táo có chứa quercetin flavonoid giúp điều chỉnh hệ thống miễn dịch và giảm viêm. Đây là hai tác động giúp giảm hen suyễn và phản ứng dị ứng.',
    textAlign: 'right',
    photoPosition: 'right',
  },
  {
    label: 'DẠ DÀY <br> KHOẺ MẠNH',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/beatheart.png?alt=media&token=c966e886-861d-4ebe-990b-0e27f9dc6d42',
    description1:
      'Thuốc chống viêm không steroid (NSAID) có tác dụng làm giảm đau, nhưng nó lại làm tổn thương niêm mạc dạ dày của người uống. Táo có chứa các hợp chất axit chlorogen và catechin, giúp bảo vệ các tế bào dạ dày khỏi bị tổn thương do NSAID.',
  },
  {
    label: 'BẢO VỆ <br> NÃO BỘ',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/le8.png?alt=media&token=2cb8e181-3f26-4291-9d5b-ae75881e5e99',
    description1:
      'Nước táo có thể mang lại lợi ích cho sự suy giảm tinh thần liên quan đến tuổi. Táo làm giảm các dạng oxy phản ứng có hại (ROS) trong mô não và giảm thiểu sự suy giảm về mặt tinh thần, giúp duy trì acetylcholine. Đây là chất dẫn truyền thần kinh có thể suy giảm theo tuổi tác. Nồng độ acetylcholine thấp có liên quan đến bệnh Alzheimer.',
    textAlign: 'right',
    photoPosition: 'right',
  },
  {
    label: 'PHÒNG NGỪA <br> UNG THƯ',
    photo:
      'https://firebasestorage.googleapis.com/v0/b/one-drink-and-food-369215.appspot.com/o/oi5.png?alt=media&token=55f88619-dcd9-4439-87d6-2849b65ef475',
    description1:
      'Các hợp chất thực vật trong quả táo có thể giúp làm giảm nguy cơ ung thư. Ngoài ra, theo nghiên cứu việc ăn táo có thể làm giảm tỷ lệ tử vong do ung thư. Tác dụng của táo có đặc tính chống oxy hóa và chống viêm – khả năng chính trong việc ngăn ngừa ung thư.',
  },
]

export const PRIVACY_POLICY_DATA: ICriteria[] = [
  {
    title: ' 1. CHÍNH SÁCH BẢO MẬT ',
    description:
      'Khi khách hàng sử dụng dịch vụ tại website LUMINUSSMOOTHIES.COM, chúng tôi sẽ đảm bảo quyền riêng tư của người dùng theo những điều khoản dưới đây.<br /> <br /> ',
  },
  {
    title: ' 2. SỬ DỤNG THÔNG TIN',
    description:
      'LUMINUSSMOOTHIES.COM chỉ dùng thông tin của người dùng để phục vụ cho chính Người dùng khi bạn sử dụng dịch vụ tại LUMINUSSMOOTHIES.COM. Vì vậy chúng tôi sẽ dùng thông tin cá nhân của bạn để: <br /> <br /> Xác minh danh tính của người sử dụng dịch vụ <br /> Xác nhận thanh toán <br /> Những hình thức sử dụng để chia sẻ thông tin của bạn với các công ty khác bao gồm (nhưng không giới hạn) như công ty thẻ tín dụng hoặc tổ chức ngân hàng... nhằm mục đích phục vụ giao dịch của bạn <br /> Cung cấp các dịch vụ hỗ trợ & chăm sóc khách hàng. Nâng cấp dịch vụ của chúng tôi; <br /> Thực hiện giao dịch thanh toán & gửi các thông báo trong quá trình giao dịch; <br /> Xử lý khiếu nại, thu phí & giải quyết sự cố; <br /> Gửi bạn các thông tin về chương trình Marketing, các thông báo & chương trình khuyến mại; <br /> So sánh độ chính xác của thông tin cá nhân của bạn trong quá trình kiểm tra với bên thứ ba. <br /><br /> ',
  },
  {
    title: ' 3. HẠN CHẾ CÔNG BỐ THÔNG TIN',
    description:
      'Chúng tôi sẽ không bán, tiết lộ hoặc cho thuê cho bên thứ ba nhận dạng cá nhân người sử dụng có thông tin thu thập tại trang web của chúng tôi, thông qua các máy chủ của chúng tôi, ngoài việc cung cấp dịch vụ của chúng tôi và như trong chính sách bảo mật. <br /><br /> ',
  },
  {
    title: ' 4. MẬT KHẨU VÀ THÔNG TIN BÍ MẬT CỦA CHÚNG TÔI',
    description:
      'Chúng tôi yêu cầu bạn không tiết lộ hoặc chia sẻ mật khẩu hoặc các thông tin nhận dạng khác mà chúng tôi cung cấp cho bạn với bất cứ một ai khác, kể cả nhân viên của LUMINUSSMOOTHIES.COM. Mật khẩu và thông tin là tài sản của chúng tôi và việc sử dụng của bạn có thể bị thu hồi theo quyết định của chúng tôi. Bạn cũng bị cấm sử dụng bất kỳ mật khẩu nào khác mà nó không phải là mật khẩu tài khoản của bạn. <br /> <br /> ',
  },
  {
    title: '  5. THU THẬP THÔNG TIN ',
    description:
      'LUMINUSSMOOTHIES.COM sẽ thu thập các thông tin bao gồm (nhưng không giới hạn) sau đây để đảm bảo an toàn cho giao dịch: địa chỉ IP, loại trình duyệt web, các trang bạn truy cập trong quá trình sử dụng dịch vụ tại LUMINUSSMOOTHIES.COM, thông tin về máy tính, thiết bị mạng, ...<br/> <br /> Trong một số dịch vụ, chúng tôi sẽ yêu cầu bạn cung cấp trung thực và chính xác những thông tin sau: <br /> <ul style="padding-left:30px"> <li> Thông tin nhân thân và liên hệ (đối với tổ chức và cá nhân) như: tên, ngày sinh, giới tính, địa chỉ, điện thoại, địa chỉ thư trực tuyến (email), giấy tờ hợp pháp (như Chứng minh nhân dân, Giấy phép kinh doanh, mã số thuế, ...) </li> <li style={{ marginLeft: "30px" }}> Thông tin tài chính như số tài khoản ngân hàng, số thẻ ghi nợ hoặc thẻ tín dụng, … </li> </ul> Trong một số trường hợp, chúng tôi có thể thu thập thêm thông tin về bạn từ bên thứ ba như ngân hàng, các tổ chức tín dụng và dịch vụ thanh toán, ... <br /> Chúng tôi cũng có thể thu thập thêm các thông tin khác về bạn từ nhiều nguồn và bằng các phương thức khác nhằm đảm bảo chất lượng dịch vụ và phục vụ bạn tốt hơn. <br /> <br /> ',
  },

  {
    title: '  6. CHIA SẺ THÔNG TIN VỚI BÊN THỨ BA ',
    description:
      'Chúng tôi có thể chia sẻ hoặc nhận thông tin cá nhân của bạn với/ từ: <br /> <br /> Các đơn vị ký hợp đồng làm dịch vụ cho chúng tôi trong phạm vi hoạt động của LUMINUSSMOOTHIES.COM, ví dụ: chống gian lận, tiếp thị, công nghệ. Hợp đồng của chúng tôi luôn quy định rằng những nhà thầu đó không được sử dụng thông tin cá nhân của bạn vào mục đích nào khác ngoài phạm vi hợp đồng hoặc cho lợi ích riêng của họ. <br /> Các cơ quan thực thi pháp luật hoặc cơ quan công quyền có thẩm quyền, hoặc các bên thứ ba khác trong các trường hợp: <br /> Có yêu cầu của tòa án hoặc các thủ tục pháp lý tương tự; <br /> Chúng tôi buộc phải làm vậy để tuân thủ các quy định của pháp luật; <br /> <br /> Chúng tôi tin rằng việc tiết lộ thông tin cá nhân của bạn là cần thiết để ngăn chặn thiệt hại về vật chất hoặc tài chính, để báo cáo những hành vi nghi ngờ là phạm pháp hoặc để điều tra những vi phạm chính sách Thỏa thuận người dùng <br /> <br /> Bất kỳ bên thứ ba nào khác nếu được sự đồng ý của bạn. <br /> <br /> ',
  },
  {
    title: '  7. ĐẶT COOKIE ',
    description:
      ' Khi bạn truy cập LUMINUSSMOOTHIES.COM, chúng tôi hoặc bên thứ ba được thuê để giám sát hoặc thống kê hoạt động của Trang web sẽ đặt một số tập tin (file) dữ liệu nhỏ gọi là Cookie lên bộ nhớ máy tính của bạn. <br /> <br /> Một trong số những Cookie này có thể tồn tại lâu để thuận tiện cho bạn trong quá trình sử dụng, ví dụ: lưu địa chỉ thư trực tuyến (Email) của bạn trong trang đăng nhập để bạn không phải nhập lại, ... <br /> <br /> Chúng tôi sẽ mã hóa các tập tin Cookies để đảm bảo tính bảo mật, bạn có thể không cho phép dùng Cookies trên trình duyệt của mình nhưng điều này có thể ảnh hưởng đến quá trình sử dụng dịch vụ của bạn trên trang LUMINUSSMOOTHIES.COM. <br /><br /> ',
  },
  {
    title: '  8. LƯU TRỮ VÀ BẢO VỆ THÔNG TIN ',
    description:
      'Chúng tôi lưu trữ và xử lý thông tin cá nhân của bạn tại máy chủ đặt tại các trung tâm dữ liệu trên lãnh thổ Việt Nam. <br /> <br /> Với sự hiểu biết rằng an ninh hoàn hảo không tồn tại trên môi trường mạng (internet), chúng tôi sử dụng công nghệ tiêu chuẩn để bảo vệ thông tin người dùng của chúng tôi, bao gồm tường lửa, các lớp khóa bảo mật, mã hóa dữ liệu. Chúng tôi cũng có các biện pháp an ninh thích hợp tại chỗ trong cơ sở vật chất của chúng tôi để bảo vệ sự riêng tư về thông tin cá nhân của bạn. Vì vậy, khi chúng tôi sử dụng các biện pháp an ninh hợp lý, chúng tôi không chịu trách nhiệm về bất kỳ sự mất mát hoặc tiết lộ thông tin của bạn. Ngoài ra, chúng tôi không chịu trách nhiệm về công bố thông tin của bạn bởi các đối tác của chúng tôi hoặc của các công ty khác mà họ không thuộc quyền kiểm soát của chúng tôi. <br /> <br /> ',
  },
  {
    title: '   9. THAY ĐỔI THÔNG TIN ',
    description:
      ' Chúng tôi cho phép người dùng thay đổi, điều chỉnh thông tin trong tài khoản người dùng. Bạn có thể thay đổi thông tin cá nhân của mình bất kỳ lúc nào bằng cách sử dụng chức năng tương ứng. <br /> <br /> Chúng tôi có quyền giữ lại các thông tin sau khi bạn đã vô hiệu hoá tài khoản của bạn hoặc thông tin mà bạn đã yêu cầu được lấy đi để thực hiện theo luật pháp, giải quyết tranh chấp và thực thi quyền của chúng tôi. <br /><br /> ',
  },
  {
    title: '  10. SỬA ĐỔI CHÍNH SÁCH BẢO MẬT',
    description:
      ' Chúng tôi có quyền sửa đổi chính sách bảo mật này theo thời gian mà không cần thông báo cho bạn. Nếu tại bất kỳ thời gian chúng tôi quyết định thay đổi đáng kể cách thức mà chúng tôi thu thập hoặc sử dụng thông tin cá nhân, chúng tôi sẽ thông báo cho các thành viên của chúng tôi bằng cách đăng thông báo nổi bật trên trang chủ của chúng tôi. Tuy nhiên, thông tin cá nhân của bạn sẽ luôn luôn được sử dụng theo chính sách bảo mật có hiệu lực tại thời điểm thông tin được thu thập. <br /> <br /> Nếu bạn có bất kỳ ý kiến muốn chia sẻ với chúng tôi về chính sách bảo mật của chúng tôi. <br />',
  },
]

export const NEW_FEED_DATA: INewFeed[] = [
  {
    title: 'Giới Thiệu Về Thương Hiệu ONE Drink & Food Mới',
    description:
      'Nạp năng lượng tươi mới & sảng khoái khi uống gói combo “Mùa Hè Sảng Khoái - Nạp Tung.',
    date: '1/8/2022',
    photo: Photos.WomenLaugh,
    tag: '#suckhoe',
    to: IPath.Introduction,
  },
  {
    title: 'Hợp Tác Kinh Doanh Các Dòng Sản Phẩm Healthy...',
    description:
      'Nạp năng lượng tươi mới & sảng khoái khi uống gói combo “Mùa Hè Sảng Khoái - Nạp Tung.',
    date: '1/8/2022',
    photo: Photos.Fridge,
    tag: '#kinhdoanh',
    to: IPath.Business,
  },
  {
    title: 'Chương Trình Khuyến Mãi Ra Mắt Thương Hiệu Mới',
    description:
      'Nạp năng lượng tươi mới & sảng khoái khi uống gói combo “Mùa Hè Sảng Khoái - Nạp Tung.',
    date: '1/8/2022',
    photo: Photos.Pomegranate,
    tag: '#quangcao',
    to: IPath.Home,
  },
  {
    title: 'Quy Trình Sản Xuất Pha Chế Sản Phẩm',
    description:
      'Nạp năng lượng tươi mới & sảng khoái khi uống gói combo “Mùa Hè Sảng Khoái - Nạp Tung.',
    date: '1/8/2022',
    photo: Photos.Coffee,
    tag: '#suckhoe',
    to: IPath.Home,
  },
  {
    title: 'Chất Dinh Dưỡng Trong Từng Loại Trái Cây',
    description:
      'Nạp năng lượng tươi mới & sảng khoái khi uống gói combo “Mùa Hè Sảng Khoái - Nạp Tung.',
    date: '1/8/2022',
    photo: Photos.TwoCoffee,
    tag: '#sacdep',
    to: IPath.Home,
  },
  {
    title: 'Uống Các Sản Phẩm Healthy & Balance có lợi...',
    description:
      'Nạp năng lượng tươi mới & sảng khoái khi uống gói combo “Mùa Hè Sảng Khoái - Nạp Tung.',
    date: '1/8/2022',
    photo: Photos.Lemon,
    tag: '#sinhlynu',
    to: IPath.Home,
  },
]

export const CRITERIAO__DATA: INewFeed[] = [
  {
    title: 'TƯƠI NGON',
    description:
      'Lựa chọn sản phẩm trái cây tươi mới, pha chế & sử dụng ngay, sạch sẽ, không chất bảo quản',
    photo: Photos.Care1,
    to: IPath.Home,
  },
  {
    title: 'DINH DƯỠNG',
    description:
      'Những sản phẩm từ ONE mang lại nhiều giá trị dinh dưỡng cho người dùng',
    photo: Photos.Care2,
    to: IPath.Home,
  },
  {
    title: 'AN TOÀN',
    description:
      'Nghiên cứu chuyên sâu về sản phẩm, an toàn sức khoẻ, hợp vệ sinh',
    photo: Photos.Care3,
    to: IPath.Home,
  },
  {
    title: 'TÁI CHẾ',
    description:
      'Hướng đến môi trường xanh, ONE ra nhiều ý tưởng để có thể tái sử dụng sản phẩm cũ',
    photo: Photos.Care4,
    to: IPath.Home,
  },
  {
    title: 'VẬN CHUYỂN',
    description:
      'Phục vụ khách hàng từ A-Z, giao nhận sản phẩm khu vực nội thành TP.Hồ Chí Minh',
    photo: Photos.Care5,
    to: IPath.Home,
  },
  {
    title: 'NGHIÊN CỨU',
    description:
      'Nâng cấp sản phẩm, đổi mới liên tục nhằm gia tăng giá trị cho khách hàng',
    photo: Photos.Care6,
    to: IPath.Home,
  },
]

export const BEAUTIFUL_SKIN: ITimeUseJuice[] = [
  {
    title: 'Những tác dụng thần kì của sinh tố giúp cơ thể khoẻ mạnh',
    description: [
      'Thư giãn cùng một ly sinh tố: Sức khỏe gia tăng, tuổi thọ kéo dài – Một ly mỗi ngày, trẻ đẹp làn da!',
      'Sinh tố là đồ uống được pha trộn từ các loại trái cây, rau củ và các loại sữa. Sản phẩm có dạng đặc, là món uống vừa mang giá trị giải khát, vừa cung cấp năng lượng và nhiều khoáng chất có lợi cho cơ thể.',
    ],
  },
  {
    title: 'Sinh tố bao gồm những thành phần nào?',
    description: [
      'Nguyên luyện tạo nên một ly sinh tố hấp dẫn rất đa dạng, có thể đến từ các nguồn như:<br/>- Trái cây tươi: Dâu, táo, dứa, bơ, xoài,…,<br/>- Rau củ: Củ dền, cà rốt, rau bina, dưa chuột,…<br/>- Hạt các loại: Hạt chia, hạt óc chó, hạnh nhân,…<br/>- Thảo mộc: Bạc hà, quế, húng, gừng,…<br/>- Các chất khác: Mật ong, kem, sữa chua, yến mạch, café, sữa,…<br/>Tùy vào cách mix các loại nguyên liệu và liều lượng, sinh tố sẽ mang hương vị khác nhau với các lợi ích dinh dưỡng cũng không giống nhau.',
    ],
  },
  {
    title: 'Công dụng “kim cương” của sinh tố đối với người sử dụng',
    description: [
      ' Sinh tố sẽ giúp bạn luôn cảm thấy thoải mái, tươi mới và tràn đầy năng lượng. Sản phẩm này mang đến rất nhiều lợi ích:',
      '- Sinh tố có thành phần chất xơ cao nên có thể được xem là một món ăn vặt thơm ngon mà không gây tăng cân.',
      '- Sản phẩm giúp người uống dễ tiêu hóa, giảm các triệu chứng khó tiêu.',
      '- Một ly sinh tố mỗi ngày sẽ cung cấp năng lượng cho cơ thể, giúp cân bằng lượng đường.',
      '- Chất carotenoids trong sinh tố sẽ giúp làn da của bạn thêm đẹp và căng tràn sức sống.',
      '- Sinh tố giúp cơ thể tăng cường khả năng hấp thụ canxi, xây dựng khung xương chắc khỏe. Trong sản phẩm luôn chứa rất nhiều khoáng chất như magie, canxi, phốt pho,.. đều là những chất có vai trò quan trọng cho hệ thống khung xương của con người.',
      '- Axit Folic có trong sinh tố sẽ giúp người uống cảm thấy hạnh phúc và vui vẻ hơn, điều trị được chứng trầm cảm nguy hiểm',
      '- Sản phẩm cũng mang đến lợi ích tiêu mỡ và cân bằng tiết tố, giúp bạn sớm sở hữu một thân hình hoàn hảo không mỡ thừa như ước nguyện. Loại nước này có thể dùng thay bữa chính để mang đến hiệu quả giảm cân tốt nhất.',
      '- Sinh tố cũng có tác dụng cải thiện làn da, tăng cường trí nhớ, chống oxy hóa, tăng khả năng miễn dịch, giải độc cơ thể, phòng chống một số bệnh ung thư,…',
      'Nếu bạn đang tìm kiếm một phương thức  dễ dàng và thoải mái để tăng lượng trái cây và rau dùng hằng ngày, thì sinh tố có thể là một sự lựa chọn đáng cân nhắc. Vì chúng thực sự thơm ngon và tốt cho sức khỏe.',
      'Một ly sinh tố hằng ngày là cách siêu tiện lợi để cung cấp năng lượng, đồng thời bổ sung vitamin và khoáng chất cần thiết cho cơ thể. Tuy nhiên, sinh tố cũng có thể gây ra tác dụng tiêu cực cho cơ thế, nếu không được chế biến đúng cách.',
      'Chọn sinh tố từ ONE, bạn không cần phải mất thời gian tự chuẩn bị, mà lại có thể thưởng thức những hương vị tươi ngon, với thành phần dinh dưỡng được đảm bảo từ các chuyên gia hàng đầu trong ngành.',
      '“Đời sống này nói dài không dài, nói ngắn cũng chẳng ngắn, nhưng rất vô thường. Nếu có thể, sao không thử cho bản thân những điều nhẹ nhàng? Hãy dành chút thời gian cho chính mình, dùng chút sinh tố từ ONE cho đời thêm chill…”',
    ],
  },
]
export const YOUNG_VITALITY: ITimeUseJuice[] = [
  {
    title:
      'Hình thành thói quen mới trong việc ăn uống những sản phẩm Healthy & Balance',
    description: [
      '“Sống xanh - ăn uống lành mạnh” đang là xu hướng được giới trẻ theo đuổi. Các bạn trẻ ngày càng quan tâm đến sức khỏe, sử dụng các sản phẩm healthy. Một trong những phương thức tiện lợi, được lựa chọn nhiều nhất chính là dùng sản phẩm nước ép trái cây từ ONE.',
      'Nếu trước đây, thế hệ trẻ chỉ ăn uống theo sở thích và khẩu vị thì theo dòng chảy thời đại, ngày càng nhiều người tìm đến xu hướng healthy - đảm bảo ăn ngon nhưng vẫn tốt cho sức khỏe. Trong bối cảnh cuộc sống ngày càng ồn ào với nhiều áp lực, rất nhiều bạn trẻ Gen Z, Gen Y đang dần định hình một phong cách mới, bắt đầu từ việc thay đổi cách ăn uống hằng ngày theo hướng lành mạnh hơn.',
    ],
  },
  {
    title: 'Thức uống lành mạnh lên ngôi',
    description: [
      'Có thể thấy trong vòng vài năm gần đây, xu hướng ăn uống healthy đang được rất nhiều người quan tâm. Trong số đó, không ít tên tuổi có sức ảnh hưởng với giới trẻ như Châu Bùi, Tóc Tiên… đều chọn khai thác các nội dung liên quan đến sức khỏe trong ăn uống. Các dạng  này đều nhận được rất nhiều sự quan tâm và ủng hộ từ phía khán giả.',
      'Trên mạng xã hội như Facebook, Instagram,… luôn bùng nổ các cuộc thảo luận, các câu chuyện liên quan đến chủ đề “ăn uống healthy”. Từ đây, có thể thấy việc “sống xanh - sống khỏe” đang là mối quan tâm của rất nhiều người. Với các bạn trẻ, sức khỏe đang là một lối sống chứ không đơn thuần là một mối quan tâm trong chốc lát nữa.',
      'Trong lối sống “healthy”, nước ép trái cây là sản phẩm dẫn đầu vì loại nước uống này không chỉ bổ sung vitamin, khoáng chất và năng lượng cho cơ thể, mà còn mang lại nhiều lợi ích về sức khỏe như làm đẹp da, giảm mỡ, thải độc, tốt cho hệ tiêu hóa… Tuy nhiên, việc tự chuẩn bị nước ép trái cây thường xuyên sẽ tốn nhiều thời gian và công sức. Vì thế, nhiều bạn trẻ đang lựa chọn những hãng sản nước ép uy tín để chăm sóc sức khỏe bản thân.',
    ],
  },
  {
    title: 'ONE - Nước ép trái cây định hình phong cách health',
    description: [
      'Nắm bắt được xu thế và tâm lý của các bạn trẻ, nước ép trái cây ONE ra đời và nhanh chóng trở thành sự lựa chọn của nhiều bạn trẻ. Sản phẩm không chỉ tiện lợi khi mua, sử dụng và bảo quản, mà còn mang hương vị tươi ngon, đáp ứng tiêu chí “healthy”, bổ sung dinh dưỡng và nạp năng lượng tức thời.',
      'ONE hướng đến sứ mệnh tạo ra một cuộc sống “healthy và fun”. Sức lan tỏa của nước ép trái cây ONE đã thúc đẩy nhiều bạn trẻ dành nhiều sự quan tâm cho bản thân hơn. Sản phẩm sẽ là “một người bạn đồng hành” đáng tin cậy cho một cuộc sống năng động, vui tươi.',
      'Bên cạnh đó, ONE cũng chú trọng đến yếu tố “sống xanh”. Các sản phẩm từ ONE dễ dàng được tái chế sau khi sử dụng để giảm áp lực lên môi trường. Thương hiệu cũng xây dựng lên một một cộng đồng mà ở đó, các thành viên cùng nhau chia sẻ những kinh nghiệm, biến những sản phẩm đã qua sử dụng trở nên có ích, ứng dụng vào cuộc sống cách sáng tạo và đa dạng. Từ đây, các bạn trẻ vừa có thêm một môi trường lan tỏa năng lượng tích cực, vừa cùng nhau chung tay bảo vệ môi trường.',
      '“Yêu thương bản thân là yêu cả cơ thể vật lý của mình, cho nó sự chăm sóc tốt nhất. Và nước ép trái cây từ ONE luôn sẵn sàng đồng hành cùng sức khỏe của bạn! Hãy đong đầy ly nước tình yêu của chính mình. Trước khi yêu thương một ai khác, hãy bắt đầu với tình yêu với bản thân mình!”  - dòng này hơi tản mạn, có thể cân nhắc bỏ.',
    ],
  },
]
export const TIME_USE_JUICE: ITimeUseJuice[] = [
  {
    title: 'Dùng nước ép trái cây khi nào là tối ưu?',
    description: [
      'Nước ép trái cây là một sản phẩm vừa thơm ngon, vừa bổ dưỡng. Tùy vào thời điểm sử dụng, thức uống này sẽ mang đến những hiệu quả khác nhau. Bài viết dưới đây từ ONE sẽ cung cấp các  thông tin hữu ích, giúp bạn khám phá những “thời điểm vàng” để dùng nước ép trái cây trong ngày.',
    ],
  },
  {
    title: '1.Sử dụng nước ép trước khi dùng bữa sáng',
    description: [
      'Dùng nước trái cây tối thiểu 30 phút trước bữa ăn sáng sẽ mang lại hiệu quả giảm cân, hỗ trợ giữ dáng và có lợi cho hệ tiêu hóa. Đây chính là thời gian dạ dày hấp thụ chất dinh dưỡng cũng như enzyme nhanh nhất, nên một ly nước ép trái cây sẽ mang đến nhiều hiệu quả tích cực.',
    ],
  },
  {
    title: '2.Uống nước ép giữa hai bữa chính',
    description: [
      'Thời điểm giữa hai khoảng ăn chính cũng là lúc cơ thể cần bổ sung chất dinh dưỡng và năng lượng. Một ly nước ép trái cây sẽ là giải pháp đơn giản và hiệu quả, giúp tiếp nước và tăng cường vitamin. Bên cạnh đó, uống nước ép trái cây cũng không gây ra tình trạng “no ngang”, không khiến hai bữa ăn chính trở nên nặng nề.',
    ],
  },
  {
    title: '3.Bổ sung năng lượng từ nước ép trái cây trước và sau buổi tập',
    description: [
      'Với những ưu điểm về dinh dưỡng cũng như hiệu quả giữ gìn vóc dáng và tăng cường sức khỏe, việc dùng nước ép trái cây trước và sau các buổi tập sẽ là một sự kết hợp hoàn hảo. Nước ép trái cây sẽ là nguồn bổ sung nước và năng lượng hiệu quả cho cơ thể. ',
      ' Nước ép trái cây cũng có thể dùng vào buổi tối, nhưng thời điểm uống nên trước 19h00 tối để cơ thể có đủ thời gian hấp thụ, tránh gây ra tình trạng khó tiêu và mất ngủ. Ngoài ra, cũng không nên dùng nước ép trái cây trong tình trạng cơ thể đang đói cồn cào.',
    ],
  },
  {
    title:
      '4.Cuối cùng, CHẤT LƯỢNG nước ép trái cây mới là điều quan trọng nhất!',
    description: [
      'Nước ép trái cây là một loại sản phẩm tốt cho sức khỏe và phù hợp với mọi lứa tuổi. Tuy nhiên, việc chọn nguồn nguyên liệu, tự chế biến và bảo quản nước ép cũng là một công đoạn mất khá nhiều thời gian, đó là chưa kể đến việc còn cần đảm bảo hương vị thơm ngon cho món nước. Ép sai cách và bảo quản không chuẩn cũng sẽ ảnh hưởng đến chất lượng sản phẩm, có thể gây nguy hiểm đến sức khỏe con người.',
      'Thấu hiểu nhu cầu của khách hàng, ONE ra đời và là sự lựa chọn đáng tin cậy, mang đến sự tiện lợi, bổ sung nguồn năng lượng nhanh chóng. Các sản phẩm nước ép trái cây từ chúng tôi được chế biến từ nguồn nguyên liệu sạch, đảm bảo mang đến hương vị tuyệt hảo cùng nguồn năng lượng tươi mới.',
      'Đều đặn uống nước ép trái cây từ ONE mỗi ngày, bạn sẽ thấy sức khỏe có nhiều thay đổi tích cực. Trong mọi khoảnh khắc, chúng tôi luôn sẵn sàng đồng hành cùng bạn. Nước ép trái cây từ ONE cũng mang vị thơm ngon, tạo ra khoảng thời gian “chill” thư giãn cho một ngày dài.',
      '“Sống chậm lại 1 chút, dành thời gian cho bản thân, rồi bạn sẽ nhận ra mình có thể tách ra khỏi những bộn bề hàng ngày: Để nạp năng lượng, để tĩnh tâm, để thư giãn lấy sức mà tiếp tục đương đầu.  Hãy luôn dành thời gian cho chính mình, nhâm nhi chút nước ép trái cây giàu năng lượng và healthy từ ONE.... Để thấy cuộc đời này vẫn luôn thật dễ thương và đẹp đẽ.”',
    ],
  },
]
export const TERMS_OF_USE: ICriteria[] = [
  {
    title: ' ĐIỀU KHOẢN MUA BÁN HÀNG HÓA ',
    description:
      'Chào mừng quý khách đến với LUMINUSSMOOTHIES.COM<br /> <br/>Chúng tôi là LUMINUSSMOOTHIES.COM, địa chỉ 280 Pasteur, P8, Q3 & 53 Nguyễn Đăng Giai, Thảo Điền, Q2 hoạt động kinh doanh thương mại điện tử qua trang web www.LUMINUSSMOOTHIES.COM<br/><br/>Khi quý khách truy cập vào trang web của chúng tôi có nghĩa là quý khách đồng ý với các điều khoản này. Trang web có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Quy định và Điều kiện sử dụng, vào bất cứ lúc nào. Các thay đổi có hiệu lực ngay khi được đăng trên trang web mà không cần thông báo trước. Và khi quý khách tiếp tục sử dụng trang web, sau khi các thay đổi về Quy định và Điều kiện được đăng tải, có nghĩa là quý khách chấp nhận với những thay đổi đó. Quý khách vui lòng kiểm tra thường xuyên để cập nhật những thay đổi của chúng tôi.<br/><br/>Xin vui lòng đọc kỹ trước khi quyết định mua hàng:<br/><br/>',
  },
  {
    title: ' 1. HƯỚNG DẪN SỬ DỤNG WEB',
    description:
      '- Khi vào web của chúng tôi, người dùng tối thiểu phải 18 tuổi hoặc truy cập dưới sự giám sát của cha mẹ hay người giám hộ hợp pháp.<br/><br/>- Chúng tôi cấp giấy phép sử dụng để bạn có thể mua sắm trên web trong khuôn khổ Điều khoản và Điều kiện sử dụng đã đề ra.<br/><br/>- Nghiêm cấm sử dụng bất kỳ phần nào của trang web này với mục đích thương mại hoặc nhân danh bất kỳ đối tác thứ ba nào nếu không được chúng tôi cho phép bằng văn bản. Nếu vi phạm bất cứ điều nào trong đây, chúng tôi sẽ hủy giấy phép của bạn mà không cần báo trước.<br/><br/>- Trang web này chỉ dùng để cung cấp thông tin sản phẩm chứ chúng tôi không phải nhà sản xuất nên những nhận xét hiển thị trên web là ý kiến cá nhân của khách hàng, không phải của chúng tôi.<br/><br/>- Quý khách phải đăng ký tài khoản với thông tin xác thực về bản thân và phải cập nhật nếu có bất kỳ thay đổi nào. Mỗi người truy cập phải có trách nhiệm với mật khẩu, tài khoản và hoạt động của mình trên web. Hơn nữa, quý khách phải thông báo cho chúng tôi biết khi tài khoản bị truy cập trái phép. Chúng tôi không chịu bất kỳ trách nhiệm nào, dù trực tiếp hay gián tiếp, đối với những thiệt hại hoặc mất mát gây ra do quý khách không tuân thủ quy định.<br/><br/>- Trong suốt quá trình đăng ký, quý khách đồng ý nhận email quảng cáo từ website. Sau đó, nếu không muốn tiếp tục nhận mail, quý khách có thể từ chối bằng cách nhấp vào đường link ở dưới cùng trong mọi email quảng cáo.<br/><br/>',
  },
  {
    title: '2. Ý KIẾN KHÁCH HÀNG ',
    description:
      '- Tất cả nội dung trang web và ý kiến phê bình của quý khách đều là tài sản của chúng tôi. Nếu chúng tôi phát hiện bất kỳ thông tin giả mạo nào, chúng tôi sẽ khóa tài khoản của quý khách ngay lập tức hoặc áp dụng các biện pháp khác theo quy định của pháp luật Việt Nam<br/><br/>',
  },
  {
    title: '3  ĐẶT HÀNG VÀ XÁC NHẬN ĐƠN HÀNG ',
    description:
      '- Khi quý khách đặt hàng tại LUMINUSSMOOTHIES.COM, chúng tôi sẽ nhận được yêu cầu đặt hàng và gửi đến quý khách mã số đơn hàng. Tuy nhiên, yêu cầu đặt hàng cần thông qua một bước xác nhận đơn hàng, LUMINUSSMOOTHIES.COM chỉ xác nhận đơn hàng nếu yêu cầu đặt hàng của quý khách thỏa mãn các tiêu chí thực hiện đơn hàng tại LUMINUSSMOOTHIES.COM.<br/><br/>- Để yêu cầu đặt hàng được xác nhận nhanh chóng, quý khách vui lòng cung cấp đúng và đầy đủ các thông tin liên quan đến việc giao nhận, hoặc các điều khoản và điều kiện của chương trình khuyến mãi (nếu có) mà quý khách tham gia.<br/><br/>',
  },
  {
    title: '4. GIÁ TRỊ ĐƠN HÀNG VÀ HÌNH THỨC THANH TOÁN ',
    description:
      '- LUMINUSSMOOTHIES.COM cung cấp các hình thức thanh toán: tiền mặt khi nhận hàng, thẻ Thanh toán Quốc tế hoặc thẻ Thanh toán Nội địa.<br/><br/> - Trừ một số trường hợp có ghi chú riêng, thông thường quý khách có thể lựa chọn một trong 3 hình thức thanh toán trên khi tiến hành đặt hàng. Tuy nhiên, để đảm bảo tính an toàn dành cho quý khách trong quá trình thanh toán, đối những đơn hàng có giá trị cao từ 30 (ba mươi) triệu trở lên, LUMINUSSMOOTHIES.COM chỉ chấp nhận hình thức thanh toán trước bằng thẻ Thanh toán Quốc tế hoặc thẻ Thanh toán Nội địa.<br/><br/> ',
  },
  {
    title: '5. CHƯƠNG TRÌNH KHUYẾN MÃI',
    description:
      '- Với mong muốn mang lại nhiều lợi ích cho khách hàng, LUMINUSSMOOTHIES.COM cùng các Thương nhân thành viên thường xuyên có các chương trình giảm giá đặc biệt. Tuy nhiên, để đảm bảo tính công bằng cho khách hàng là người tiêu dùng cuối cùng của LUMINUSSMOOTHIES.COM, chúng tôi có quyền từ chối các đơn hàng không nhằm mục đích sử dụng cho cá nhân, mua hàng số lượng nhiều hoặc với mục đích mua đi bán lại khi quý khách tham gia các chương trình khuyến mãi.<br/><br/>- Thông thường, số lượng sản phẩm tối đa dành cho mỗi khách hàng khi tham gia chương trình khuyến mãi đặc biệt tại LUMINUSSMOOTHIES.COM là hai (02) sản phẩm. Thể lệ của chương trình khuyến mãi sẽ được cập nhật tại trang khuyến mãi theo từng thời điểm chạy chương trình và có thể được thay đổi mà không cần thông báo trước cho khách hàng.<br/><br/>- LUMINUSSMOOTHIES.COM có quyền từ chối các đơn hàng không thỏa mãn điều khoản và điều kiện tham gia các chương trình khuyến mãi mà không cần thông báo đến khách hàng. Vì vậy, xin quý khách vui lòng tham khảo kĩ Thể lệ của từng chương trình trước khi tham gia.<br/><br/>',
  },
  {
    title: ' 6. MÃ GIẢM GIÁ',
    description:
      '- Mã giảm giá là hình thức chiết khấu mà LUMINUSSMOOTHIES.COM dành cho khách hàng có thể có giá trị giảm một phần hoặc toàn phần giá trị của đơn hàng.<br/><br/>- Mỗi đơn hàng chỉ có thể áp dụng 1 mã giảm giá, quý khách sẽ nhận được thông tin chi tiết về điều khoản và điều kiện sử dụng mã giảm giá kèm theo. Để đảm bảo tính công bằng cho khách hàng là người tiêu dùng cuối cùng của LUMINUSSMOOTHIES.COM, chúng tôi có quyền từ chối các đơn hàng không nhằm mục đích sử dụng cho cá nhân, mua hàng số lượng nhiều hoặc với mục đích mua đi bán lại.<br/><br/>- LUMINUSSMOOTHIES.COM có quyền từ chối các đơn hàng sử dụng mã giảm giá không thỏa mãn điều khoản và điều kiện sử dụng mã giảm gía mà không cần thông báo trước. Mã giảm giá trong trường hợp này sẽ không được cấp lại. Ngoài ra, LUMINUSSMOOTHIES.COM có quyền từ chối việc gia hạn mã đã hết hạn sử dụng, mã không được sử dụng hết giá trị hoặc các trường hợp đơn phương ngừng thược hiện đơn hàng phát sinh từ phía quý khách.<br/><br/>',
  },
  {
    title: ' 7. KHU VỰC GIAO HÀNG',
    description:
      '- LUMINUSSMOOTHIES.COM giao hàng toàn quốc đối với các sản phẩm do chính LUMINUSSMOOTHIES.COM phân phối, trừ các mặt hàng điện lạnh như máy lạnh, máy giặt, tủ lạnh, tủ đông v.v… và một số mặt hàng khác nếu quá trình vận chuyển có thể làm ảnh hưởng đến chất lượng sản phẩm hoặc sức khỏe khách hàng.<br/><br/>- Đối với sản phẩm do các đơn vị Thương nhân thành viên phân phối, khu vực giao hàng có thể sẽ được giới hạn theo thông cập nhật tại trang sản phẩm. Trong một số trường hợp, mà khu vực giao hàng không được cập nhật kịp thời tại thời điểm quý khách đặt hàng, LUMINUSSMOOTHIES.COM sẽ liên hệ đến quý khách để thông báo chi tiết.<br/><br/>',
  },
  {
    title: ' 8. GIÁ CẢ',
    description:
      '- Giá cả sản phẩm được niêm yết tại LUMINUSSMOOTHIES.COM là giá bán cuối cùng đã bao gồm thuế Giá trị gia tăng (VAT). Giá cả của sản phẩm có thể thay đổi tùy thời điểm và chương trình khuyến mãi kèm theo. Phí vận chuyển hoặc Phí thực hiện đơn hàng sẽ được áp dụng thêm nếu có, và sẽ được hiển thị rõ tại trang Thanh toán khi quý khách tiến hành đặt hàng.<br/><br/>- Mặc dù chúng tôi cố gắng tốt nhất để bảo đảm rằng tất cả các thông tin và giá hiển thị là chính xác đối với từng sản phẩm, đôi khi sẽ có một số trường hợp bị lỗi hoặc sai sót. Nếu chúng tôi phát hiện lỗi về giá của bất kỳ sản phẩm nào trong đơn hàng của quý khách, chúng tôi sẽ thông báo cho quý khách trong thời gian sớm nhất có thể và gửi đến quý khách lựa chọn để xác nhận lại đơn hàng với giá chính xác hoặc hủy đơn hàng. Nếu chúng tôi không thể liên lạc với quý khách, đơn hàng sẽ tự động hủy trên hệ thống và lệnh hoàn tiền sẽ được thực hiện (nếu đơn hàng đã được thanh toán trước).<br/><br/>- Khác biệt giá: Các Thương nhân thành viên hoạt động tại sàn giao dịch Thương mại điện tử LUMINUSSMOOTHIES.COM có thể cùng cung cấp 1 sản phẩm giống nhau với chính sách giá khác nhau, Quý khách có quyền lựa chọn và so sánh giá cả giữa các thương nhân thành viên để có được mức giá hợp lý nhất cho yêu cầu đặt hàng của mình. LUMINUSSMOOTHIES.COME không can thiệp về chính sách giá của từng Thương nhân thành viên nếu có phát sinh tranh chấp.<br/><br/>',
  },
  {
    title: ' 9. THÔNG TIN SẢN PHẨM',
    description:
      '- LUMINUSSMOOTHIES.COM cung cấp thông tin chi tiết đối với từng sản phẩm mà chúng tôi đăng tải, tuy nhiên chúng tôi không thể cam kết cung cấp thông tin đầy đủ một cách chính xác, toàn vẹn, cập nhật, và không sai sót đối với từng sản phẩm.<br/><br/>- Trong trường hợp sản phẩm quý khách nhận được không đúng như những gì LUMINUSSMOOTHIES.COM đã mô tả trong phần thông tin sản phẩm, quý khách vui lòng thông tin đến bộ phận Hỗ trợ khách hàng trong thời gian sớm nhất kể từ khi nhận hàng đồng thời đảm bảo sản phẩm trong tình trạng chưa qua sử dụng để được hỗ trợ đổi trả. Thông tin chi tiết về chính sách đổi trả vui lòng tham khảo tại: http://www.LUMINUSSMOOTHIES.COM/chinh-sach-doi-tra-hang/<br/><br/>',
  },
  {
    title: ' 10. CHÍNH SÁCH VỀ HÀNG GIẢ, HÀNG NHÁI, HÀNG KHÔNG ĐÚNG CHẤT LƯỢNG',
    description:
      '- LUMINUSSMOOTHIES.COM hướng đến việc cung cấp hàng hóa và chất lượng dịch vụ tốt nhất cho khách hàng qua các sản phẩm được đăng bán trên trang web của chúng và từ chối bán các sản phẩm sản xuất trái phép, sao chép, hàng giả, hàng nhái, không rõ nguồn gốc xuất xứ...<br/><br/>- Các Thương nhân thành viên của LUMINUSSMOOTHIES.COM đều được yêu cầu thực hiện một cách nghiêm túc đối với việc đảm bảo chất lượng và nguồn gốc, xuất xứ của sản phẩm, cũng như tự chịu trách nhiệm trước pháp luật đối với các hành vi vi phạm. Đối với các trường hợp phát hiện sản phẩm sản xuất trái phép, sao chép, hàng giả, hàng nhái, không rõ nguồn gốc xuất xứ..., LUMINUSSMOOTHIES.COM sẽ ngay lập tức đình chỉ hoặc chấm dứt quyền bán hàng và thực hiện các biện pháp chế tài đối với đơn vị Thương nhân thành viên vi phạm.<br/><br/>- Trong trường hợp quý khách có nghi ngờ sản phẩm sản xuất trái phép, sao chép, hàng giả, hàng nhái, không rõ nguồn gốc xuất xứ..., vui lòng thông báo ngay cho chúng tôi bằng cách liên hệ với Bộ phận Hỗ trợ khách hàng tại www.LUMINUSSMOOTHIES.COM/contact/ để được xác thực thông tin và hỗ trợ.<br/><br/> ',
  },
  {
    title: '11. THƯƠNG HIỆU VÀ BẢN QUYỀN ',
    description:
      '- Mọi quyền sở hữu trí tuệ (đã đăng ký hoặc chưa đăng ký), nội dung thông tin và tất cả các thiết kế, văn bản, đồ họa, phần mềm, hình ảnh, video, âm nhạc, âm thanh, biên dịch phần mềm, mã nguồn và phần mềm cơ bản đều là tài sản của chúng tôi. Toàn bộ nội dung của trang web được bảo vệ bởi luật bản quyền của Việt Nam và các công ước quốc tế. Bản quyền đã được bảo lưu.<br/><br/>',
  },
  {
    title: '12. QUYỀN PHÁP LÝ ',
    description:
      '- Các điều kiện, điều khoản và nội dung của trang web này được điều chỉnh bởi luật pháp Việt Nam và Tòa án có thẩm quyền tại Việt Nam sẽ giải quyết bất kỳ tranh chấp nào phát sinh từ việc sử dụng trái phép trang web này.<br/><br/>',
  },
  {
    title: '13. QUY ĐỊNH VỀ BẢO MẬT ',
    description:
      '- Trang web của chúng tôi coi trọng việc bảo mật thông tin và sử dụng các biện pháp tốt nhất bảo vệ thông tin và việc thanh toán của quý khách. Thông tin của quý khách trong quá trình thanh toán sẽ được mã hóa để đảm bảo an toàn. Sau khi quý khách hoàn thành quá trình đặt hàng, quý khách sẽ thoát khỏi chế độ an toàn.<br/><br/>- Quý khách không được sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào hệ thống hay làm thay đổi cấu trúc dữ liệu. Trang web cũng nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống. Cá nhân hay tổ chức vi phạm sẽ bị tước bỏ mọi quyền lợi cũng như sẽ bị truy tố trước pháp luật nếu cần thiết.<br/><br/>Mọi thông tin giao dịch sẽ được bảo mật nhưng trong trường hợp cơ quan pháp luật yêu cầu, chúng tôi sẽ buộc phải cung cấp những thông tin này cho các cơ quan pháp luật.<br/><br/>',
  },
  {
    title: '14. THANH TOÁN AN TOÀN VÀ TIỆN LỢI ĐỊACHỈWEBSITE ',
    description:
      'Mọi khách hàng tham giao giao dịch tại LUMINUSSMOOTHIES.COM qua thẻ tín dụng quốc tế đều được bảo mật thông tin bằng mã hóa (xem chi tiết tại điều khoản “Quy định về bảo mật” được nêu ở trên). Qua đó, khi thực hiện thanh toán qua mạng quý khách lưu ý các chi tiết sau:<br/><br/>- Chỉ sử dụng trang web có chứng chỉ thanh toán an toàn.<br/><br/>- Tuyệt đối không cho người khác mượn thẻ tín dụng hoặc tài khoản của mình để thực hiện thanh toán tại LUMINUSSMOOTHIES.COM; trong trường hợp phát sinh giao dịch ngoài ý muốn, quý khách vui lòng thông báo cho LUMINUSSMOOTHIES.COM biết để có thể hỗ trợ kịp thời.<br/><br/>- Kiểm tra tài khoản ngân hàng của mình thường xuyên để đảm bảo tất cả giao dịch qua thẻ đều nằm trong tầm kiểm soát.<br/><br/>',
  },
  {
    title: ' 15. GIAO KẾT GIAO DỊCH TẠI LUMINUSSMOOTHIES.COM',
    description:
      '- Khách hàng khi mua sắm tại LUMINUSSMOOTHIES.COM phải thực hiện các thao tác đặt hàng và nhận hàng theo trình tự sau:<br/><br/>Cách 1: Thanh toán trước online qua thẻ tín dụng (Visa, Master card..):<br/><br/>Bước 1: Khách hàng đặt hàng, cung cấp thông tin đầy đủ, xác thực<br/>Bước 2: Khách hàng thanh toán trước;<br/>Bước 3: LUMINUSSMOOTHIES.COM kiểm tra, xác nhận đơn hàng và chuyển hàng;<br/>Bước 4: Khách hàng kiểm tra và nhận hàng;<br/>Cách 2: Thanh toán sau:<br/><br/>Bước 1: Khách hàng đặt hàng; cung cấp thông tin đầy đủ, xác thực<br/>Bước 2: LUMINUSSMOOTHIES.COM xác thực đơn hàng;<br/>Bước 3: LUMINUSSMOOTHIES.COM xác nhận thông tin khách hàng (điện thoại, tin nhắn, email);<br/>Bước 4: LUMINUSSMOOTHIES.COM chuyển hàng;<br/>Bước 5: Khách hàng nhận hàng và thanh toán;<br/>- Trong các trường hợp, khách hàng có thể tra cứu thông tin giao dịch qua email gửi vào hộp thư khách hàng đã đăng ký với LUMINUSSMOOTHIES.COM hoặc tra cứu tình trạng giao dịch tại website http://www.LUMINUSSMOOTHIES.COM/kiem-tra-don-hang/. Ngoài ra, khách hàng có thể tra cứu được lịch sử giao dịch khi đăng nhập vào tài khoản của mình tại website LUMINUSSMOOTHIES.COM<br/><br/>Trường hợp khách hàng muốn chỉnh sửa thông tin, khách hàng thông báo cho LUMINUSSMOOTHIES.COM qua đường dây nóng 0888 718 008 hoặc ghi lời nhắn tại http://www.LUMINUSSMOOTHIES.COM/contact/.<br/><br/>',
  },
  {
    title: '16. THAY ĐỔI, HỦY BỎ GIAO DỊCH TẠI LUMINUSSMOOTHIES.COM ',
    description:
      '- Trong mọi trường hợp, khách hàng đều có quyền chấm dứt giao dịch nếu đã thực hiện các biện pháp sau đây:<br/><br/>1. Thông báo cho LUMINUSSMOOTHIES.COM về việc hủy giao dịch qua đường dây nóng 0888 718 008 hoặc lời ghi nhắn tại http://www.LUMINUSSMOOTHIES.COM/contact/<br/><br/>2. Trả lại hàng hoá đã nhận nhưng chưa sử dụng hoặc hưởng bất kỳ lợi ích nào từ hàng hóa đó (theo quy định của chính sách đổi trả hàng tại http://www.LUMINUSSMOOTHIES.COM/chinh-sach-doi-tra-hang/)<br/><br/>',
  },
  {
    title:
      ' 17. GIẢI QUYẾT HẬU QUẢ DO LỖI NHẬP SAI THÔNG TIN THƯƠNG MẠI TẠI ĐỊACHỈWEBSITE',
    description:
      '- Khách hàng có trách nhiệm cung cấp thông tin đầy đủ và chính xác khi tham gia giao dịch tại LUMINUSSMOOTHIES.COM. Trong trường hợp khách hàng nhập sai thông tin gửi vào trang thông tin điện tử bán hàng LUMINUSSMOOTHIES.COM, LUMINUSSMOOTHIES.COM có quyền từ chối thực hiện giao dịch. Ngoài ra, trong mọi trường hợp, khách hàng đều có quyền đơn phương chấm dứt giao dịch nếu đã thực hiện các biện pháp sau đây:<br/><br/>1. Thông báo cho LUMINUSSMOOTHIES.COM qua đường dây nóng 0888 718 008 hoặc lời nhập nhắn tại địa chỉ LUMINUSSMOOTHIES.COM/contact<br/><br/>2. Trả lại hàng hoá đã nhận nhưng chưa sử dụng hoặc hưởng bất kỳ lợi ích nào từ hàng hóa đó.<br/><br/>',
  },
  {
    title: '18. GIẢI PHÁP TRANH CHẤP ',
    description:
      '- Bất kỳ tranh cãi, khiếu nại hoặc tranh chấp phát sinh từ hoặc liên quan đến giao dịch tại LUMINUSSMOOTHIES.COM hoặc các Quy định và Điều kiện này đều sẽ được giải quyết bằng hình thức thương lượng, hòa giải, trọng tài và/hoặc Tòa án theo Luật bảo vệ Người tiêu dùng Chương 4 về Giải quyết tranh chấp giữa người tiêu dùng và tổ chức, cá nhân kinh doanh hàng hóa, dịch vụ.<br/><br/>',
  },
  {
    title: ' 19. QUY ĐỊNH CHẤP DỨT THỎA THUẬN',
    description:
      '- Trong trường hợp có bất kỳ thiệt hại nào phát sinh do việc vi phạm Quy Định sử dụng trang web, chúng tôi có quyền đình chỉ hoặc khóa tài khoản của quý khách vĩnh viễn. Nếu quý khách không hài lòng với trang web hoặc bất kỳ điều khoản, điều kiện, quy tắc, chính sách, hướng dẫn, hoặc cách thức vận hành của LUMINUSSMOOTHIES.COM thì biện pháp khắc phục duy nhất là ngưng làm việc với chúng tôi.<br/><br/>',
  },
  {
    title: '20. NHỮNG QUY ĐỊNH KHÁC ',
    description:
      '- Tất cả các Điều Khoản và Điều Kiện (và tất cả nghĩa vụ phát sinh ngoài Điều khoản và Điều kiện này hoặc có liên quan) sẽ bị chi phối và được hiểu theo luật pháp Việt Nam. Chúng tôi có quyền sửa đổi các Điều khoản và Điều kiện này vào bất kỳ thời điểm nào và các sửa đổi đó sẽ có hiệu lực ngay tại thời điểm được được đăng tải tại LUMINUSSMOOTHIES.COM.<br/>',
  },
]

export const BUSINESS_DATA: IBusiness[] = [
  {
    title: 'THỂ THAO',
    photo: Photos.ProductCare1,
    description:
      'Các trung tâm thể dục thể thao như: phòng tập gym, yoga, dạy nhảy, bóng đá, cầu lông, bơi lội,...',
  },
  {
    title: 'SẮC ĐẸP',
    photo: Photos.ProductCare2,
    description:
      'Các trung tâm làm đẹp, dưỡng da, phẫu thuật thẫm mỹ, massage trị liệu,...',
  },
  {
    title: 'GIẢI TRÍ',
    photo: Photos.ProductCare3,
    description: 'Các trung tâm giải trí như: quán game, khu vui chơi, ...',
  },
  {
    title: 'NHÀ HÀNG',
    photo: Photos.ProductCare4,
    description:
      'Các nhà hàng, quán nước muốn nhập thêm các dòng sản phẩm hướng đến sức khoẻ để kinh doanh, phục vụ khách hàng',
  },
]
