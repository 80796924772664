import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  barcodePointWrapper: {
    '.content_box': {
      boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
      padding: 20,
      borderRadius: 10,
      '.sliderWrapper': {
        padding: '25px 20px 5px 20px',
        '.mantine-Slider-root': {
          '.mantine-Slider-bar': {
            background: '#000',
          },
          '.mantine-Slider-thumb': {
            padding: 0,
            border: '2px solid #000',
            '&:active': {
              pointerEvents: 'none',
            },
            svg: {
              color: '#000',
              marginTop: 5,
            },
          },
        },
      },
      '.blockBox': {
        margin: 'auto',
        width: '100%',
        height: 177,
        maxHeight: 177,
        textAlign: 'center',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: 10,
      },
    },
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      '.content_box': {
        '.sliderWrapper': {
          padding: '25px 20px 5px 20px',
        },
      },
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      '.content_box': {
        '.sliderWrapper': {
          padding: '25px 10px 5px 10px',
        },
      },
    },
  },
}))
