import { Box, Center, Image, Text } from '@mantine/core'
import { Photos } from 'assets'
import { Voucher } from 'common'
import { useStyles } from './styles'

const Friends = () => {
  const { classes } = useStyles()
  return (
    <Box className={classes.newMemberWrapper}>
      <Center mb={20}>
        <Image src={Photos.AppleHeart} height={103} width={94} />
      </Center>
      <Text
        size={12}
        weight={700}
        dangerouslySetInnerHTML={{
          __html: 'Chào Tín!<br><br>',
        }}
      />
      <Text
        size={12}
        dangerouslySetInnerHTML={{
          __html:
            'Mục tiêu lan toả lối sống: “Mọi người đều Healthy & Balance”, mỗi thành viên ONE giới thiệu một bạn mới đăng ký tài khoản thành công sẽ được nhận một ly nước miễn phí với voucher 50.000đ (được giảm giá trực tiếp vào đơn hàng).',
        }}
      />
      <Box mt={20}>
        <Voucher
          photo={Photos.Xinchao}
          title="Tặng 60.000đ cho bạn mới lần đầu đặt hàng!"
          code="CHAOBANMOI"
          date="15/10/2022"
        />
      </Box>
    </Box>
  )
}

export default Friends
