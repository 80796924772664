import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IIsVisible {
  isVisible: boolean
  contentLoading?: 'DRINK & FOOD' | 'Chờ kết quả thanh toán'
}

const initialState: IIsVisible = {
  isVisible: false,
  contentLoading: 'DRINK & FOOD',
}
export const loadingOverlay = createSlice({
  name: 'VISIBLE_OVERLAY',
  initialState,
  reducers: {
    onVisible: (state: IIsVisible, action: PayloadAction<IIsVisible>) => {
      return action.payload
    },
  },
})
export const { onVisible } = loadingOverlay.actions
export default loadingOverlay
