import { Box, Container } from '@mantine/core'
import { CardPhoto } from 'common'
import { ONE_FOOD_DRINK_DATA } from 'constants/index'
import { useStyles } from './styles'

export default function AboutOneFD() {
  const { classes } = useStyles()
  return (
    <Box className={classes.aboutOneFDWrapper}>
      <Container fluid>
        {ONE_FOOD_DRINK_DATA.map((val, idx) => (
          <CardPhoto photoMaxHeight={600} key={idx} {...val} />
        ))}
      </Container>
    </Box>
  )
}
