import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Center,
  Checkbox,
  Group,
  Image,
  Radio,
  Stack,
  Text,
} from '@mantine/core'
import { DD_MM_YYY } from 'constants/index'
import { FormRecipientProvider, useFormRecipient } from './form-context'
import { Modal } from 'common'
import { Payment, TabLabel } from 'constants/enum'
import { Photos, Svgs } from 'assets'
import { PickLpcation } from 'common/GgMap'
import { TextInputCt } from 'customs/TextInputCt'
import { UseFormReturnType } from '@mantine/form'
import { format } from 'date-fns'
import { formatNumber, getKeyByValue } from 'utils'
import { onMomoPayment, onZaloPayment } from './payment'
import { onOrder } from './actions'
import { onVisible } from 'reducer/LoadingOverLay'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useDisclosure, usePrevious } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './styles'
import { validateFormRecipient } from 'constants/validate'
import DeliveryTime from './DeliveryTime'
import MapAutocomplete from 'components/CartComponent/Delivery/MapAutocomplete'

type IRadioGroup = {
  label: string
  value: Payment
  icon: string
}

const RADIO_GROUP: IRadioGroup[] = [
  {
    label: 'Tiền mặt',
    value: Payment.CASH,
    icon: Photos.CashPhoto,
  },
  { label: 'Momo', value: Payment.MOMO, icon: Photos.MomoPhoto },
  { label: 'ZaloPay', value: Payment.ZALO_PAY, icon: Photos.ZaloPayPhoto },
  { label: 'Thẻ ngân hàng', value: Payment.BANK, icon: Photos.BankPhoto },
]
type IProps = {
  type?: 'typeInfor' | 'orderLookup'
  orderLookup?: IFormRecipient | null
}

export default function Delivery({ type = 'typeInfor', orderLookup }: IProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { carts, momoPayment, ZaloPayment, queryUserResult } = useAppSelector(
    state => state
  )
  const [
    isOpenPickLication,
    { close: closePickLication, open: openPickLication },
  ] = useDisclosure(false)
  const [showEditMethod, setShowEditMethod] = useState(false)
  const previousValue = usePrevious({
    momoPayment,
    ZaloPayment,
  })
  const formRecipient = useFormRecipient({
    initialValues: {
      address: '',
      receivedTime: 'Khung trưa chiều (13:00 - 16:00)',
      receivedDate: format(new Date(), DD_MM_YYY),
      recipientName: '',
      phone: '',
      note: '',
      termsOfService: true,
      paymentMethods: Payment.CASH,
      status: 'paymenting',
      productList: carts,
      createOn: `${new Date()}`,
      idUser: queryUserResult.data?.[0]?.id ?? '',
    },
    validate: validateFormRecipient(),
  })
  const handleSubmit = (values: IFormRecipient) => {
    dispatch(
      onVisible({
        isVisible: true,
        contentLoading: 'Chờ kết quả thanh toán',
      })
    )
    dispatch(onOrder(values))
      .unwrap()
      .then(orderId => {
        if (values.paymentMethods === Payment.MOMO) {
          dispatch(
            onMomoPayment({
              formRecipient: values,
              orderId,
            })
          )
        }
        if (values.paymentMethods === Payment.ZALO_PAY) {
          dispatch(
            onZaloPayment({
              formRecipient: values,
              orderId,
            })
          )
        }
        if (values.paymentMethods === Payment.CASH) {
          dispatch(
            onVisible({
              isVisible: false,
            })
          )
          navigate(orderId)
        }
      })
  }

  const paymentMethods = RADIO_GROUP.find(
    value => value.value === formRecipient.values.paymentMethods
  )
  useEffect(() => {
    if (
      momoPayment.data !== previousValue?.momoPayment.data &&
      momoPayment.status.isSucceeded &&
      momoPayment.data?.payUrl
    ) {
      window.location.href = momoPayment.data.payUrl
    }
    if (
      ZaloPayment.data !== previousValue?.ZaloPayment.data &&
      ZaloPayment.status.isSucceeded &&
      ZaloPayment.data?.orderurl
    ) {
      window.location.href = ZaloPayment.data.orderurl
    }
  }, [momoPayment, ZaloPayment])
  useEffect(() => {
    formRecipient.setValues(orderLookup ?? {})
  }, [orderLookup])
  return (
    <Box className={classes.deliveryWrapper}>
      <FormRecipientProvider form={formRecipient}>
        <form onSubmit={formRecipient.onSubmit(handleSubmit)}>
          <Center inline mb={20}>
            <Svgs.Truck />
            <Text className="delevery-title" size={20} pl={10} weight="bold">
              Giao hàng
            </Text>
          </Center>
          <Stack className="infor_delivery">
            <Box>
              <Group position="apart">
                <Text className="title">Địa chỉ giao hàng</Text>
                <ActionIcon variant="transparent" onClick={openPickLication}>
                  <Svgs.Location />
                </ActionIcon>
              </Group>
              {type === 'typeInfor' ? (
                <MapAutocomplete
                  error={formRecipient.errors.address}
                  valueAddress={formRecipient.values.address}
                  onChange={e =>
                    formRecipient.setFieldValue('address', e ?? '')
                  }
                />
              ) : (
                <Text className="description">
                  {formRecipient.values.address}
                </Text>
              )}
            </Box>
            <Box>
              <Text className="title">Thời gian nhận hàng</Text>
              {type === 'typeInfor' ? (
                <DeliveryTime />
              ) : (
                <Text className="description">
                  Ngày Nhận: {formRecipient.values.receivedDate}{' '}
                  {formRecipient.values.receivedTime}
                </Text>
              )}
            </Box>
            {type === 'orderLookup' && (
              <Box>
                <Text className="title">Người nhận</Text>
                <Text className="description">
                  {formRecipient.values.recipientName}
                </Text>
              </Box>
            )}
            {type === 'orderLookup' && (
              <Box>
                <Text className="title">Số điện thoại</Text>
                <Text className="description">
                  {formRecipient.values.phone}
                </Text>
              </Box>
            )}
            {type === 'orderLookup' && (
              <Box>
                <Text className="title">Ghi chú</Text>
                <Text className="description">
                  {formRecipient.values.note == ''
                    ? 'No'
                    : formRecipient.values.note}
                </Text>
              </Box>
            )}
          </Stack>
          {type === 'orderLookup' && (
            <Box>
              <Stack>
                <PaymentMethods
                  showEditMethod={showEditMethod}
                  formRecipient={formRecipient}
                  label={
                    <Group position="apart">
                      <Center inline>
                        <Svgs.Codepen />
                        <Text
                          className="delevery-title"
                          size={24}
                          pl={10}
                          weight="bold"
                        >
                          Phương thức thanh toán
                        </Text>
                      </Center>
                      <ActionIcon
                        variant="transparent"
                        onClick={() => setShowEditMethod(val => !val)}
                      >
                        <Svgs.PenIcon />
                      </ActionIcon>
                    </Group>
                  }
                />
                {!showEditMethod && (
                  <Box>
                    <Center inline>
                      <Image
                        mr={12}
                        width={25}
                        src={paymentMethods?.icon}
                        alt="asdds"
                      />
                      <Text size={14}>{paymentMethods?.label}</Text>
                    </Center>
                  </Box>
                )}
                {/* <Box>
                  <Center inline>
                    <Svgs.Truck />
                    <Text size={24} pl={10} weight="bold">
                      Giao hàng
                    </Text>
                  </Center>
                </Box> */}
                <Box>
                  <Center inline>
                    <Svgs.Status />
                    <Text size={16} weight="bold" pl={10}>
                      Trạng thái
                    </Text>
                  </Center>
                  <Text size={14}>
                    {getKeyByValue(TabLabel, formRecipient.values.status)}
                  </Text>
                </Box>
                {type !== 'orderLookup' && (
                  <Text>
                    Điều chỉnh thông tin đặt hàng? Liên hệ hotline{' '}
                    <Anchor
                      size="sm"
                      href="/"
                      target="_blank"
                      sx={{
                        color: '#46CFC7',
                      }}
                    >
                      0933 22 00 78
                    </Anchor>
                  </Text>
                )}
              </Stack>
            </Box>
          )}
          {type === 'typeInfor' && (
            <Box>
              <TextInputCt
                iconWidth={178}
                mt={20}
                icon="Họ tên người nhận"
                placeholder="Nguyễn Văn A"
                {...formRecipient.getInputProps('recipientName')}
              />
              <TextInputCt
                iconWidth={178}
                mt={20}
                type="number"
                placeholder="Ví dụ: 09.33.22.00.78"
                icon="Điện thoại liên hệ"
                {...formRecipient.getInputProps('phone')}
              />
              <TextInputCt
                iconWidth={178}
                mt={20}
                type="text"
                placeholder="Điền thông tin ghi chú nếu có"
                icon="Ghi chú khác"
                {...formRecipient.getInputProps('note')}
              />
              <PaymentMethods
                formRecipient={formRecipient}
                showEditMethod={type === 'typeInfor'}
                label={
                  <Center inline mt={40}>
                    <Svgs.Codepen />
                    <Text size={24} pl={10} weight="bold">
                      Phương thức thanh toán
                    </Text>
                  </Center>
                }
              />
              <Checkbox
                pt={40}
                pb={40}
                color="dark"
                {...formRecipient.getInputProps('termsOfService', {
                  type: 'checkbox',
                })}
                error={formRecipient.errors.termsOfService}
                label={
                  <Text size="sm">
                    Tôi đồng ý với các{' '}
                    <Anchor
                      size="sm"
                      href="/"
                      target="_blank"
                      sx={{
                        color: '#FE5F51',
                      }}
                    >
                      Điều Khoản & Điều Kiện
                    </Anchor>{' '}
                    mua hàng của ONE Drink & Food
                  </Text>
                }
              />
            </Box>
          )}
          <Button
            fullWidth
            color="dark"
            radius="xl"
            type="submit"
            sx={{ height: 50, marginTop: 20 }}
          >
            {type === 'orderLookup' ? (
              <Text size={16} weight={700}>
                THANH TOÁN
              </Text>
            ) : (
              <Text size={16} weight={700}>
                TỔNG CỘNG {formatNumber(carts.totalOrderValue - 10000)}đ
                <Text ml={5} component="span" size="sm" weight={400}>
                  | ĐẶT HÀNG
                </Text>
              </Text>
            )}
          </Button>
        </form>
      </FormRecipientProvider>

      <Modal
        size="900px"
        title="Địa chỉ giao hàng"
        onClose={closePickLication}
        opened={isOpenPickLication}
      >
        <PickLpcation
          valueAddress={formRecipient.values.address}
          onChange={e => formRecipient.setFieldValue('address', e ?? '')}
        />
      </Modal>
    </Box>
  )
}

const PaymentMethods = ({
  formRecipient,
  label,
  showEditMethod,
}: {
  label: React.ReactNode
  formRecipient: UseFormReturnType<IFormRecipient>
  showEditMethod?: boolean
}) => {
  return (
    <Radio.Group
      label={label}
      orientation="vertical"
      value={formRecipient.values.paymentMethods}
      defaultValue={formRecipient.values.paymentMethods}
      onChange={e => {
        formRecipient.setFieldValue('paymentMethods', e as Payment)
      }}
      styles={theme => ({
        root: {
          '.mantine-RadioGroup-label': {
            width: '100%',
          },
          '.mantine-Group-root, .mantine-Stack-root': {
            background: theme.white,
            justifyContent: 'space-between',
            paddingTop: '18px',
            '.mantine-Radio-root': {},
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              flexDirection: 'row',
            },
          },
          '&>.mantine-Stack-root': {
            display: showEditMethod ? 'flex' : 'none',
          },
        },
        label: {
          fontWeight: 700,
          fontSize: 14,
        },
      })}
    >
      {RADIO_GROUP.map((item, idx) => (
        <Radio
          key={idx}
          color="dark"
          value={item.value}
          label={
            <Text component={Center}>
              <Image mr={12} width={25} src={item.icon} alt={item.label} />
              {item.label}
            </Text>
          }
        />
      ))}
    </Radio.Group>
  )
}
