import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  specialGiftWrapper: {
    '.content_box': {
      boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
      padding: '40px 30px',
      borderRadius: 10,
      margin: '30px 0',
      '.content_box-GiftWrapper': {
        '.cardGift': {
          overflow: 'hidden',
          boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: 10,
          '.img': {
            '.imgPhoto': {
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '10px 10px 0 0',
            },
          },
          '.description': {
            padding: 20,
          },
          '&:nth-of-type(2) .description': {
            background: 'linear-gradient(180deg, #C32A1C 0%, #91003D 100%)',
            color: 'white',
            button: {
              background: 'white',
              span: {
                background: 'linear-gradient(180deg, #C32A1C 0%, #91003D 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              },
            },
          },
        },
      },
      '.content_box-ProductWrapper': {},
    },
  },
}))
