import { Banner, ContainerShadow } from 'common'
import { Box } from '@mantine/core'
import { useParams } from 'react-router-dom'
import MoreInfor from './MoreInfor'
import PickOption from './PickOption'

export default function InforProduct() {
  const { id } = useParams()
  return (
    <Box mt={70}>
      <ContainerShadow p={50} isHiddenNavLink>
        <PickOption id={id as string} />
      </ContainerShadow>
      <ContainerShadow
        sx={theme => ({
          [`@media (max-width: ${theme.breakpoints.md}px)`]: {
            padding: 0,
          },
        })}
        isHiddenNavLink
        boxShadow="unset"
      >
        <MoreInfor id={id as string} />
      </ContainerShadow>
      <Banner.Free />
    </Box>
  )
}
