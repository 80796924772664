import { ActionIcon, BackgroundImage, Box, Center, Text } from '@mantine/core'
import { Photos, Svgs } from 'assets'
import { useStyles } from './styles'
import Barcode from 'react-barcode'

type IProps = {} & IMembers

export default function CardMember({
  type,
  name,
  powers,
  point,
  rank,
}: IProps) {
  const { classes, cx } = useStyles()

  return (
    <Box className={classes.cardMemberWrapper}>
      <BackgroundImage
        className="cardMember"
        src={
          rank === 'Thành Viên Vàng'
            ? Photos.Atm2
            : rank === 'Thành Viên Ruby'
            ? Photos.Atm3
            : Photos.Atm
        }
        radius={10}
      >
        <Box>
          <Text size={20} weight="bold" color="#fff">
            {name}
          </Text>
          <Center
            inline
            className={cx('point', [
              {
                gold: rank === 'Thành Viên Vàng',
                ruby: rank === 'Thành Viên Ruby',
              },
            ])}
          >
            <Svgs.Logopoint />
            <Text ml={8} size={16} weight="bold" color="#fff">
              {point} ONE
            </Text>
          </Center>
          <Text size={12} color="#fff">
            (Số điểm hiện có)
          </Text>
        </Box>
        <Center className="barcode">
          <Barcode value="M1616382949" height={45} />
        </Center>
        <Text className="earnPoints">Tích điểm</Text>
      </BackgroundImage>
      {type === 'modal' && powers && (
        <Box className="powers_member">
          <Text size={20} mb={10} weight="bold">
            {rank}
          </Text>
          {powers.map((item, idx) => (
            <Center key={idx} inline>
              <ActionIcon size={55}>{item.icon}</ActionIcon>
              <Text size={12} ml={10}>
                {item.description}
              </Text>
            </Center>
          ))}
        </Box>
      )}
    </Box>
  )
}
