import { createStyles } from '@mantine/core'

export const useStyles = createStyles(
  (theme, params: { background?: string }) => ({
    moreInforWrapper: {
      '.signature': {
        '& >div:nth-of-type(1) span': {
          background: params.background,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
      '.cardPhoto_moreInfor-left': {
        '.photo': {
          padding: '30px 0px 30px 30px',
        },
      },
      '.cardPhoto_moreInfor-right': {
        '.photo': {
          padding: '30px 30px 30px 0',
        },
      },
      '.cardPhoto_moreInfor-Signleft': {
        '.photo': {
          padding: '0px 30px 0px 0',
        },
      },
      '.cardPhoto_moreInfor-Signright': {
        '.photo': {
          padding: '0px 0 0px 30px',
        },
      },
      '.UserManual': {
        marginTop: 30,
        marginBottom: 60,
        textAlign: 'center',
      },
      '.btn-Custom': {
        height: 40,
        width: 160,
        '& .mantine-Button-icon': {
          scale: '0.7',
        },
      },
      '.rickText': {
        h1: {
          fontSize: 36,
        },
        p: {
          fontSize: 14,
        },
      },
      '.label': {
        lineHeight: '46px',
      },
      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
        '.cardPhoto_moreInfor-left': {
          '.photo': {
            padding: '0px',
          },
        },
        '.cardPhoto_moreInfor-right': {
          '.photo': {
            padding: '0px',
          },
        },
        '.UserManual': {
          marginTop: 0,
          marginBottom: 30,
        },
        '.label': {
          textAlign: 'left',
          br: {
            display: 'none',
          },
        },
      },
    },
  })
)
