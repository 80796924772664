import { FaRegFrown, FaRegSmileBeam } from 'react-icons/fa'
import { showNotification as showNotificationn } from '@mantine/notifications'
type INotification = {
  title: string
  message: string
  type: 'success' | 'error'
}

const showNotification = ({ title, message, type }: INotification) => {
  const isSuccess = type === 'success'
  const icon = isSuccess ? (
    <FaRegSmileBeam size={20} />
  ) : (
    <FaRegFrown size={20} />
  )
  return showNotificationn({
    title,
    message,
    icon,
    autoClose: 3000,
    styles: {
      root: {
        border: 'none',
        backgroundColor: isSuccess ? '#000' : '#000',
        boxShadow: 'rgb(0 0 0 / 15%) 2.4px 2.4px 3.2px',
      },
      title: { color: '#fff', fontWeight: 700 },
      description: { color: 'white' },
      icon: {
        backgroundColor: 'unset!important',
      },
      closeButton: {
        color: 'white',
        borderRadius: '100%',
        '&:hover': {
          backgroundColor: '#c3d600',
        },
      },
    },
  })
}

export default showNotification
