import { Box, Center, Group, SimpleGrid, Text } from '@mantine/core'
import { IPath } from 'constants/enum'
import { Link } from 'react-router-dom'
import { Svgs } from 'assets'
import { VOUCHER } from 'constants/voucher'
import { Voucher } from 'common'
import { useState } from 'react'
import SearchBar from 'customs/SearchBar'
import useStyles from './styles'

export default function Vouchers() {
  const { classes } = useStyles()
  const [txtVouchers, setVouchers] = useState<string>()

  return (
    <Box className={classes.vouchersWrapper}>
      <Box>
        <SearchBar
          icon={<Svgs.Voucher />}
          rightSection="Tìm kiếm"
          placeholder="Nhập mã khuyến mãi"
          onChange={setVouchers}
          error={
            txtVouchers !== 'chaobanmoi' &&
            txtVouchers &&
            'Rất tiếc ONE không tìm thấy kết quả phù hợp. Vui lòng thử lại với mã đơn hàng khác.'
          }
        />
      </Box>
      {txtVouchers?.toLocaleLowerCase() === 'chaobanmoi' && (
        <Box>
          <Text size={18} mb={20} weight="bold">
            Voucher Của Bạn
          </Text>
          <Voucherr />
        </Box>
      )}
      <Group position="apart" mt={30}>
        <Text size={18} weight="bold">
          Đang Thịnh Hành
        </Text>
        <Link to={IPath.Code_Discount}>
          <Center className="watchMore">
            <Text size={12}>Xem tất cả</Text>
            <Svgs.ArrowLeft />
          </Center>
        </Link>
      </Group>
      <Voucherr />
    </Box>
  )
}

const Voucherr = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.voucherTrending}>
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: 1655, cols: 2 },
          { maxWidth: 'lg', cols: 1 },
        ]}
        spacing={20}
      >
        {VOUCHER.map((val, idx) => (
          <Voucher key={idx} {...val} />
        ))}
      </SimpleGrid>
    </Box>
  )
}
