import {
  CollectionReference,
  collection,
  documentId,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'
import { pathCollection } from 'config/firebase/collection'
import { showNotification } from 'common'

export const doQueryProductId = createAsyncThunk(
  'Query Product Id',
  async (params: string, thunkAPI) => {
    try {
      const product = collection(
        database,
        pathCollection.product
      ) as CollectionReference<IProduct>

      const q = query(product, where(documentId(), '==', params))
      const querySnapshot = await getDocs(q)
      let result: IProduct | null = null
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        result = { ...doc.data(), id: doc.id }
      })
      console.log('Query Product Id', result)
      return result
    } catch (error) {
      const err = error as FirebaseError
      console.log('Query Product Id', error, err)
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)
