import { Box } from '@mantine/core'
import { Svgs } from 'assets'
import { debounce, isEmpty } from 'lodash'
import { doUpdateUser } from 'reducer/User/action'
import { initialListAddress } from './constants'
import { phoneRegex } from 'utils'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useCallback } from 'react'
import { useDidUpdate, usePrevious } from '@mantine/hooks'
import { useForm } from '@mantine/form'
import { useStyles } from './styles'
import Accordion from '../Accordion'
import Address from './Address'

export default function InforAddress() {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { queryUserResult } = useAppSelector(state => state)

  const formMoreAdress = useForm<IMoreAdress>({
    initialValues: {
      listAddress:
        queryUserResult.data?.[0] && queryUserResult.data?.[0].listAddress
          ? queryUserResult.data?.[0].listAddress
          : [initialListAddress, initialListAddress],
    },

    validate: {
      listAddress: {
        recipientName: (value: IFormRecipient['recipientName']) =>
          value?.length < 3 ? true : null,
        address: value => (value.length < 15 ? true : null),
        phone: (value: IFormRecipient['phone']) =>
          phoneRegex(value) ? null : true,
        addressName: value => (value.length < 5 ? true : null),
        wholeHouseNumberFloors: value => (value.length < 5 ? true : null),
      },
    },
    validateInputOnChange: true,
  })
  const preValue = usePrevious(formMoreAdress.errors)

  const debounceTyping = useCallback(
    debounce((user: IUsers) => {
      dispatch(
        doUpdateUser({
          user,
          isShowNotification: false,
        })
      )
    }, 500),
    []
  )

  useDidUpdate(() => {
    if (isEmpty(formMoreAdress.errors) && preValue !== formMoreAdress.errors) {
      debounceTyping({
        ...(queryUserResult.data?.[0] as IUsers),
        listAddress: formMoreAdress.values.listAddress,
      })
    }
  }, [formMoreAdress])

  const onDeleteMoreAddress = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    idx: number
  ) => {
    event.stopPropagation()
    const newListAddress = formMoreAdress.values.listAddress.filter(
      (_item, index) => index !== idx
    )
    // debounceTyping({ listAddress: newListAddress })
    formMoreAdress.setValues({ listAddress: newListAddress })
  }

  const fields = formMoreAdress.values.listAddress.map((_item, idx) => {
    switch (idx) {
      case 0:
        return {
          panel: <Address formMoreAdress={formMoreAdress} idx={idx} />,
          control: 'Thêm địa chỉ nhà',
          icon: <Svgs.Home />,
        }
      case 1:
        return {
          panel: <Address formMoreAdress={formMoreAdress} idx={idx} />,
          control: 'Thêm địa công ty',
          icon: <Svgs.Homev2 />,
        }
      default:
        return {
          panel: <Address formMoreAdress={formMoreAdress} idx={idx} />,
          control: `Thêm địa chỉ mới ${idx + 1}`,
          icon: (
            <Svgs.Closebtn onClick={event => onDeleteMoreAddress(event, idx)} />
          ),
        }
    }
  })
  return (
    <Box className={classes.inforAddressWrapper}>
      <Accordion
        accordionData={fields}
        addNewAddress={() =>
          formMoreAdress.insertListItem('listAddress', initialListAddress)
        }
      />
    </Box>
  )
}
