import {
  BackgroundImage,
  Box,
  Center,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Text,
} from '@mantine/core'
import { Photos, Svgs } from 'assets'
import { useStyles } from './styles'

const WEEK = [
  {
    day: 'Thứ 2',
    point: '+10 <br> ONE',
    icon: (
      <Center w={36} h={28}>
        <Image src={Photos.Day2} />
      </Center>
    ),
  },
  {
    day: 'Thứ 3',
    point: '+10 <br> ONE',
    icon: (
      <Center w={26} h={28}>
        <Image src={Photos.Day3} />
      </Center>
    ),
  },
  {
    day: 'Thứ 4',
    point: '+10 <br> ONE',
    icon: (
      <Center w={24} h={44}>
        <Image src={Photos.Day4} />
      </Center>
    ),
  },
  {
    day: 'Thứ 5',
    point: '+10 <br> ONE',
    icon: (
      <Center w={26} h={36}>
        <Image src={Photos.Day5} />
      </Center>
    ),
  },
  {
    day: 'Thứ 6',
    point: '+10 <br> ONE',
    icon: (
      <Center w={29} h={37}>
        <Image src={Photos.Day6} />
      </Center>
    ),
  },
  {
    day: 'Thứ 7',
    point: '+10 <br> ONE',
    icon: (
      <Center w={36} h={28}>
        <Image src={Photos.Day7} />
      </Center>
    ),
  },
]
const CheckInEveryDay = () => {
  const { classes } = useStyles()
  return (
    <BackgroundImage
      src={Photos.Checkineveryday}
      radius={10}
      className={classes.checkInEveryDayWrapper}
    >
      <Center>
        <Image src={Photos.AppleHeart} height={103} width={94} />
      </Center>
      <Text
        size={12}
        weight={700}
        dangerouslySetInnerHTML={{
          __html: 'Chào Tín!<br><br>',
        }}
      />
      <Text
        size={12}
        dangerouslySetInnerHTML={{
          __html:
            'Bắt đầu một tuần điểm danh mới! Hãy chăm chỉ điểm danh mỗi ngày để tích điểm nhận quà nhé!',
        }}
      />
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: 1168, cols: 2 },
          { maxWidth: 992, cols: 3, spacing: 10, verticalSpacing: 10 },
          { maxWidth: 768, cols: 2 },
          { maxWidth: 576, cols: 3 },
        ]}
        mt={20}
        spacing={20}
        verticalSpacing={20}
      >
        {WEEK.map((val, idx) => (
          <Box className="boxDay" key={idx}>
            <Text className="day">{val.day}</Text>
            <Center h={70}>
              {val.icon}
              <Text
                ml={8}
                size={12}
                weight={700}
                className="point"
                dangerouslySetInnerHTML={{
                  __html: val.point,
                }}
              />
            </Center>
          </Box>
        ))}
      </SimpleGrid>
      <Box className="boxDay sunday">
        <Text className="day" size={14} weight={700}>
          Chủ Nhật
        </Text>
        <Flex justify="center" align="center" gap={20}>
          <Center h={70} className="point">
            <Svgs.Day8 />
            <Text
              ml={8}
              size={12}
              weight={700}
              dangerouslySetInnerHTML={{
                __html: '+10<br> ONE',
              }}
            />
          </Center>
          <Divider color="#3D3D3D" orientation="vertical" />
          <Text className="text">CUỐI TUẦN VẪN CHĂM CHỈ!</Text>
        </Flex>
      </Box>
      <Text
        align="center"
        size={12}
        dangerouslySetInnerHTML={{
          __html: 'Số điểm hiện có: 1400',
        }}
      />
    </BackgroundImage>
  )
}

export default CheckInEveryDay
