import { GetInputProps } from '@mantine/form/lib/types'
import { TextInput, TextInputProps } from '@mantine/core'
import { useStyles } from './styles'

export type TextInputCustomProps<T> = {
  type?: 'Textarea' | 'number'
  autosize?: boolean
  input?: {
    GetInputProps: GetInputProps<T>
    paddingLeft: number
  }
} & TextInputProps
export function TextInputCt<T>({ input, ...props }: TextInputCustomProps<T>) {
  const { classes } = useStyles({ input })
  return (
    <TextInput
      classNames={{
        wrapper: classes.wrapper,
        input: classes.input,
        icon: classes.icon,
      }}
      {...props}
      {...input?.GetInputProps}
    />
  )
}
