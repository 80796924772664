import { Box, Sx } from '@mantine/core'
import { Footer, Header } from 'components'
import { HEADER_DATA, HEADER_DATA_USER } from 'constants/index'
import { Outlet } from 'react-router-dom'
const sx: Sx | (Sx | undefined)[] | undefined = {
  maxWidth: 2000,
  margin: 'auto',
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  '.body_main': {
    flexGrow: 1,
  },
}

export function Layout1() {
  return (
    <Box sx={sx}>
      <Header headerData={HEADER_DATA} isScrollDownSmall />
      <Box className="body_main">
        <Outlet />
      </Box>
      <Footer />
    </Box>
  )
}

export function Layout2() {
  return (
    <Box sx={sx}>
      <Header headerData={HEADER_DATA_USER} />
      <Box className="body_main">
        <Outlet />
      </Box>
    </Box>
  )
}
