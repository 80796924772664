import { Container } from '@mantine/core'
import { Product2, Tabs } from 'common'
import { TABS } from 'constants/enum'
import { doQueryProducts } from 'reducer/Products/actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'

export default function Coffee() {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector(state => state)
  useEffect(() => {
    dispatch(
      doQueryProducts([TABS.COFFEE_BOTTLE, TABS.COFFEE_HOT, TABS.COFFEE_ICE])
    )
  }, [])

  return (
    <Container fluid>
      <Tabs.Tabs
        title="CÀ PHÊ"
        data={[
          // {
          //   tabLabel: TABS.ALL,
          //   component: (
          //     <Product2 data={products.data} status={products.status} />
          //   ),
          // },
          {
            tabLabel: TABS.COFFEE_ICE,
            component: (
              <Product2
                data={products.data?.filter(item => item.group === 'Cà Phê Đá')}
                status={products.status}
              />
            ),
          },
          {
            tabLabel: TABS.COFFEE_HOT,
            component: (
              <Product2
                data={products.data?.filter(
                  item => item.group === 'Cà Phê Nóng'
                )}
                status={products.status}
              />
            ),
          },
          {
            tabLabel: TABS.COFFEE_BOTTLE,
            component: (
              <Product2
                data={products.data?.filter(
                  item => item.group === 'Cà Phê Chai'
                )}
                status={products.status}
              />
            ),
          },
        ]}
      />
    </Container>
  )
}
