import { Box, Button, Center, Group, SimpleGrid, Text } from '@mantine/core'
import { IPath } from 'constants/enum'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { Svgs } from 'assets'
import { useStyles } from './styles'

const CardGift = ({
  title,
  description,
  data,
  type,
}: {
  title: string
  description: string
  data: IVoucher[]
  type?: 'modal'
}) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.cardProductWrapper}>
      <Group mt={30} position="apart">
        <Text size={18} weight="bold">
          {title}
        </Text>
        {type && (
          <Link to={IPath.Gift}>
            <Center className="watchMore">
              <Text size={12}>Xem tất cả</Text>
              <Svgs.ArrowLeft />
            </Center>
          </Link>
        )}
      </Group>
      <Text mb={20} size={10}>
        {description}
      </Text>
      <SimpleGrid
        cols={7}
        spacing={20}
        breakpoints={[
          {
            maxWidth: 333,
            cols: 1,
          },
          {
            maxWidth: 500,
            cols: 2,
          },
          {
            maxWidth: 'xs',
            cols: 3,
          },
          {
            maxWidth: 'sm',
            cols: 3,
          },
          {
            maxWidth: 'md',
            cols: 4,
          },
          {
            maxWidth: 'lg',
            cols: 5,
          },
          {
            maxWidth: 'xl',
            cols: 6,
          },
        ]}
      >
        {data.map((val, idx) => (
          <Box key={idx} className={classes.cardProduct}>
            <Box className="content_box-cardProduct">
              <Box className="img">
                <LazyLoadImage
                  className="imgPhoto"
                  src={val.photo}
                  alt="With default placeholder"
                />
              </Box>
              <Box className="description">
                <Text className="title" mt={10} size={14} weight="bold">
                  {val.title}
                </Text>
                {val.ml && (
                  <Text size={14} weight="bold">
                    {val.ml}
                  </Text>
                )}
                <Text mt={8} size={12}>
                  {val.group}
                </Text>
              </Box>
            </Box>
            <Button mt={10} fullWidth color="dark" radius="xl" h={30}>
              {val.point} ONE
            </Button>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  )
}
export default CardGift
