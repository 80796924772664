import { Box } from '@mantine/core'
import { Feeds } from 'components'

export default function NewFeeds() {
  return (
    <Box>
      <Feeds />
    </Box>
  )
}
