import { IPath } from 'constants/enum'
import { apiMomo } from 'config/axios/apiMomo'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { extracMlAndPrice } from 'utils'
import { momoPayment, zaloPay } from 'config/axios'
import { showNotification } from 'common'
import { v4 } from 'uuid'
import HmacSHA256 from 'crypto-js/hmac-sha256'

const redirectUrl = window.location.origin + IPath.Cart
const mapProductList = ({ productList }: IFormRecipient['productList']) => {
  return productList.map(val => {
    const sizePrice = extracMlAndPrice(val.mlAndPrice, val?.optionPicked?.size)
    return {
      id: val.id,
      name: val.name,
      price: sizePrice.price,
      currency: 'VND',
      quantity: Number(val.amount),
      totalPrice: sizePrice.price * Number(val.amount),
    }
  })
}

const key = {
  accessKey: process.env.REACT_APP_MOMO_ACCESS_KEY,
  secretKey: process.env.REACT_APP_MOMO_SECRET_KEY,
  partnerCode: process.env.REACT_APP_MOMO_PARTNER_CODE,
}
type IParamsOnMomoPayment = {
  formRecipient: IFormRecipient
  orderId: string
}
export const onMomoPayment = createAsyncThunk(
  'onMomoPayment',
  async (params: IParamsOnMomoPayment, thunkAPI) => {
    try {
      const res = await momo(params)
      return thunkAPI.fulfillWithValue(res)
    } catch (error) {
      console.log('error momo payment', error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)
const momo = ({
  formRecipient: { productList, phone, recipientName, address },
  orderId,
}: IParamsOnMomoPayment) => {
  const params: IOrderPaymentMethodParams = {
    partnerCode: key.partnerCode,
    partnerName: 'CÔNG TY TNHH THƯƠNG MẠI DỊCH VỤ ONE DRINK & FOOD',
    // storeId: 'Merchant',
    requestType: 'captureWallet',
    ipnUrl: 'localhost:3000/momo/ipn',
    redirectUrl: redirectUrl + '/momo',
    orderId: orderId,
    amount: productList.totalOrderValue,
    lang: 'en',
    autoCapture: false,
    orderInfo: 'CÔNG TY TNHH THƯƠNG MẠI DỊCH VỤ ONE DRINK & FOOD',
    requestId: v4(),
    extraData: '',
    signature: '',
    items: mapProductList(productList),
    userInfo: {
      name: recipientName,
      phoneNumber: `${phone}`,
      email: '',
    },
    deliveryInfo: {
      deliveryAddress: address,
      deliveryFee: String(productList.totalOrderValue * 0.1),
      quantity: `${productList.totalProductList}`,
    },
  }
  const data = `accessKey=${key.accessKey}&amount=${params.amount}&extraData=${params.extraData}&ipnUrl=${params.ipnUrl}&orderId=${params.orderId}&orderInfo=${params.orderInfo}&partnerCode=${params.partnerCode}&redirectUrl=${params.redirectUrl}&requestId=${params.requestId}&requestType=${params.requestType}`
  const signature = HmacSHA256(data, key.secretKey).toString()
  params.signature = signature
  return momoPayment<IOrderPaymentMethodRes, IOrderPaymentMethodParams>(
    apiMomo.orderPaymentMethod,
    params
  )
}

// APP INFO ZALOPAY
type IParamsOnZaloPayment = {
  formRecipient: IFormRecipient
  orderId: string
}
const config = {
  appid: Number(process.env.REACT_APP_ZALO_APPID),
  key1: process.env.REACT_APP_ZALO_KEY1,
  key2: process.env.REACT_APP_ZALO_KEY2,
}
export const onZaloPayment = createAsyncThunk(
  'onZaloPayment',
  async (params: IParamsOnZaloPayment, thunkAPI) => {
    try {
      const res = await ZaloPay(params)
      return res
    } catch (error) {
      console.log('error zalo payment', error)
      showNotification({
        type: 'error',
        title: 'Error',
        message: 'Error zalo payment',
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)
export const ZaloPay = ({
  formRecipient: { phone, productList },
  orderId,
}: IParamsOnZaloPayment) => {
  const embeddata = {
    merchantinfo:
      '{"promotioninfo":"","merchantinfo":"du lieu rieng cua ung dung"}',
    redirecturl: redirectUrl + '/zalo',
  }

  const order: ICreateOrder = {
    appid: config.appid,
    apptransid: orderId,
    appuser: phone,
    apptime: Date.now(), // miliseconds
    item: JSON.stringify(mapProductList(productList)),
    embeddata: JSON.stringify(embeddata),
    amount: productList.totalOrderValue,
    description: `ONE DRINK & FOOD - Thanh toán đơn hàng #${orderId}`,
    bankcode: 'zalopayapp',
    mac: '',
  }
  // appid|app_trans_id|appuser|amount|apptime|embeddata|item
  const data =
    config.appid +
    '|' +
    order.apptransid +
    '|' +
    order.appuser +
    '|' +
    order.amount +
    '|' +
    order.apptime +
    '|' +
    order.embeddata +
    '|' +
    order.item
  order.mac = HmacSHA256(data, config.key1).toString()
  return zaloPay<ICreateOrderRes, ICreateOrder>(
    {
      url: '/createorder',
      method: 'POST',
    },
    { ...order }
  )
}

export const getStatusByAppTransId = (apptransid: string) => {
  let postData: IGetStatusByAppTransIdParams = {
    appid: config.appid,
    apptransid: apptransid, // Input your apptransid
    mac: '',
  }

  let data = postData.appid + '|' + postData.apptransid + '|' + config.key1
  postData.mac = HmacSHA256(data, config.key1).toString()
  return zaloPay<IGetStatusByAppTransId, IGetStatusByAppTransIdParams>(
    {
      method: 'POST',
      url: '/getstatusbyapptransid',
    },
    postData
  )
}
