import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  memberWrapper: {
    margin: '30px 0',
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      '.tabUser': {
        marginTop: 20,
      },
    },
  },
}))
