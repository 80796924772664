import {
  CollectionReference,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { FirebaseError } from 'firebase/app'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'
import { pathCollection } from 'config/firebase/collection'
import { showNotification } from 'common'

export const doQueryProducts = createAsyncThunk(
  'Query Products',
  async (params: IProduct['group'][], thunkAPI) => {
    try {
      const q = query<IProduct>(
        collection(
          database,
          pathCollection.product
        ) as CollectionReference<IProduct>,
        where('group', 'in', params)
      )
      const querySnapshot = await getDocs(q)
      let result: IProduct[] = []
      querySnapshot.forEach(doc => {
        // doc.data()x is never undefined for query doc snapshots
        result = [...result, { ...doc.data(), id: doc.id }]
      })
      return result
    } catch (error) {
      const err = error as FirebaseError
      console.log('Query Products', error, err)
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)
