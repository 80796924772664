import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  tabsWrapper: {
    '.title': {
      margin: '60px 0',
      textAlign: 'center',
    },
    '.mantine-Tabs-root': {
      '.mantine-Tabs-tabsList': {
        gap: 20,
        flexWrap: 'nowrap',
        overflowX: 'auto',
        [`@media (max-width: ${theme.breakpoints.md}px)`]: {
          justifyContent: 'unset',
          scrollSnapType: 'x mandatory',
          '&::-webkit-scrollbar': {
            height: 2,
          },
        },
        '.mantine-Tabs-tab': {
          scrollSnapAlign: 'center',
          padding: ' 18px',
          height: 76,
          fontSize: 20,
          color: '#000!important',
          borderRadius: 50,
          fontWeight: 700,
          '&[data-active]:hover': {
            backgroundColor: '#F5F5F5',
          },
          '&[data-active]': {
            justifyContent: 'space-between',
            background: '#F5F5F5',
            borderRadius: '50px',
          },
        },
      },
      '&.isEqueals1': {
        '.mantine-Tabs-tabsList': {
          display: 'none',
        },
        '.mantine-Tabs-panel': {
          paddingTop: 0,
        },
      },
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      '.title': {
        margin: '30px 0',
      },
      '.mantine-Tabs-panel': {
        paddingTop: 30,
      },
    },
  },
  tabsUserWrapper: {
    '.mantine-SimpleGrid-root': {
      width: '100%',
      '.mantine-Tabs-tabsList': {
        border: 'unset',
        height: 'max-content',
        overflow: 'hidden',
        boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        '.mantine-Tabs-tab': {
          border: 'unset',
          padding: '11px 20px',
          '&[data-active]': {
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          },
          '.mantine-Tabs-tabLabel': {
            width: '100%',
            fontSize: 14,
          },
        },
        '.withTextWrapper': {
          margin: '40px 0',
          width: '100%',
          '.withText': {
            padding: '20px 40px',
          },
          '.mantine-Tabs-tab': {
            padding: '0px',
            width: '100%',
          },
        },
      },
      '.mantine-Tabs-panel': {
        '> div': {
          // height: '100%',
        },
      },
      [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
        // marginTop: 20,
        '.withTextWrapper': {
          margin: '20px 0!important',
          '.withText': {
            padding: '20px 20px!important',
          },
        },
      },
    },
    '.mantine-ActionIcon-root': {
      svg: {
        width: 20,
        height: 20,
      },
    },
    '.signout': {
      marginTop: 20,
      cursor: 'pointer',
      padding: '11px 20px',
      borderRadius: 10,
      boxShadow: '0px 4px 20px rgb(0 0 0 / 10%)',
      '.mantine-Text-root': {
        flex: 1,
        fontSize: 12,
      },
      '&:hover': {
        background: '#f8f9fa',
      },
    },
  },
}))
