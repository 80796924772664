import { FirebaseError } from 'firebase/app'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { extracMlAndPrice } from 'utils'
import { format } from 'date-fns'
import { pathCollection } from 'config/firebase/collection'
import { showNotification } from 'common'
import { v4 } from 'uuid'

export const onOrder = createAsyncThunk(
  pathCollection.orders,
  async (formRecipient: IFormRecipient, thunkAPI) => {
    const ID = `${format(new Date(), 'yyMMdd')}_${v4()}`
    const newProductList = formRecipient.productList.productList.map(
      product => {
        const { id, name, amount, optionPicked, mlAndPrice } = product
        const sizePrice = extracMlAndPrice(mlAndPrice, optionPicked?.size)
        return {
          id,
          name,
          amount,
          optionPicked,
          mlAndPrice: { [optionPicked?.size as string]: sizePrice },
          totalPrice: sizePrice.price * Number(amount),
        }
      }
    )

    try {
      await setDoc(doc(database, pathCollection.orders, ID), {
        ...formRecipient,
        productList: {
          ...formRecipient.productList,
          productList: newProductList,
        },
      })
      return ID
    } catch (error) {
      console.log('error order', error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const onOrderUpdateStatus = createAsyncThunk(
  pathCollection.users,
  async (
    { id, status }: { id: string; status: IFormRecipient['status'] },
    thunkAPI
  ) => {
    try {
      const userRef = doc(database, pathCollection.orders, id)
      const res = await updateDoc(userRef, {
        status,
      })
      console.log('onOrderUpdateStatus', res)
      return undefined
    } catch (error) {
      const err = error as FirebaseError
      console.log('err', err)
      showNotification({
        type: 'error',
        title: `${err.name}`,
        message: err.code ?? err.message,
      })
      return thunkAPI.rejectWithValue(error)
    }
  }
)
