import { createStyles } from '@mantine/core'

export default createStyles(_theme => ({
  yourCartWrapper: {
    fontSize: 14,
    '.txt__description': {
      fontSize: 14,
    },
    '.cartProduct': {
      '>div.optionPicked': {
        borderRadius: '10px 0 0 10px',
        padding: '10px 20px',
        backgroundColor: '#F5F5F5',
        marginRight: 2,
        width: '100%',
      },
    },
    '.icon': {
      flex: 1,
      width: 70,
      background: '#E5E5E5',
      borderRadius: 'unset',
      '&.penIcon': {
        borderTopRightRadius: '10px',
      },
      '&.closebtn': {
        borderBottomRightRadius: '10px',
      },
    },
    '.group__apart': {
      padding: '10px 0',
      borderBottom: '1px solid #F5F5F5',
      '&.discount': {
        color: '#FE5F51',
        padding: '3px 0',
      },
      '&.hiddenBorder': {
        borderBottom: 'unset',
      },
    },
  },
}))
