import { all } from 'redux-saga/effects'

function* hellosaga() {
  console.log('hello saga')
  yield
}

export default function* rootSaga() {
  yield all([hellosaga()])
}
