import {
  Box,
  Center,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core'
import { Photos } from 'assets'
import { useStyles } from './styles'

const SOCIAL_DATA = [
  {
    title: 'Thời đại xã hội',
    label: 'VĂN MINH HIỆN ĐẠI',
    description1:
      'Khi cuộc sống trở nên hiện đại hoá & văn minh hơn, con người có xu hướng nhắm tới những dòng sản phẩm healthy, quan tâm đến sức khoẻ bản thân nhiều hơn. Vì thế ONE xuất hiện để đáp ứng các nhu cầu ấy.',
    description2:
      'ONE mong muốn tương lai trở thành một thương hiệu không chỉ là ăn uống vặt, những sản phẩm bên ONE còn mang lại những giá trị tinh thần lẫn vật chất cho người dùng. Hướng mọi người đến sự văn minh, khoẻ đẹp. Giới trẻ Việt Nam sẽ được trải nghiệm nhiều điều mới mẻ từ ONE.',
  },
  {
    title: 'Hướng đến cuộc sống',
    label: 'HEALTHY & BALANCE',
    description1:
      'Sứ mệnh của ONE hướng đến một cuộc sống Healthy & Balance. Healthy trong những sản phẩm ăn uống hằng ngày, và Fun trong việc tạo ra môi trường vui nhộn đầy sự tươi mới. ONE muốn dẫn dắt giới trẻ tới gần hơn cuộc sống khoẻ đẹp, quan tâm đến bản thân nhiều hơn.',
    description2:
      'Với mô hình online hiện tại, ONE lựa chọn các nguồn thực phẩm sạch sẽ & tươi ngon để thời gian đầu ra mắt đem tới hương vị sản phẩm tốt nhất. ONE nghiên cứu những giá trị dinh dưỡng của trái cây, giúp người dùng hiểu được lợi ích việc hấp thụ dưỡng chất, vitamin từ các dòng nước ép bên ONE.',
  },
  {
    title: 'Tối ưu dịch vụ',
    label: 'GIÁ TRỊ CỐT LÕI',
    description1:
      '<b>Với Khách Hàng:</b><br/>-Trao đến tay khách hàng những sản phẩm chất lượng nhất <br/>-Cho ra sản phẩm mang lại công dụng “khoẻ đẹp" <br/>-Tạo ra môi trường nhiệt huyết, sáng tạo với giao diện hình ảnh tươi trẻ & các phương thức tái sử dụng sản phẩm đa dạng.',
    description2:
      '<b>Với Chính Doanh Nghiệp:</b><br/>-Nghiên cứu tạo ra những sản phẩm chất lượng, an toàn, vệ sinh. <br/>-Ưu tiên quan tâm về sức khoẻ, trải nghiệm người dùng & những giá trị dinh dưỡng mang lại.<br/> -Hướng đến bảo vệ môi trường & tái sử dụng, tiết kiệm nguyên liệu.',
  },
  {
    title: 'Tái chế sử dụng ',
    label: 'ĐAM MÊ SÁNG TẠO',
    description1:
      'ONE luôn đổi chất lượng sản phẩm, đưa tính nghệ thuật vào thương hiệu nhằm tạo thêm giá trị. ONE thành lập một fanpage hướng dẫn cách thiết kế trang trí những chai lọ bình đã qua sử dụng với nhiều cách khác nhau. Đa dạng trong công dụng như: trang trí nhà cửa, đựng đồ vật, bình cắm hoa, …',
    description2:
      'Hình thành môi trường giao lưu học hỏi sáng tạo, giúp các bạn trẻ biết ứng dụng sản phẩm của mình “có ích hơn", tạo ra s',
  },
]
export default function Social() {
  const { classes } = useStyles()
  return (
    <Container fluid className={classes.socialWrapper}>
      <SimpleGrid
        mb={30}
        mt={30}
        cols={2}
        spacing={40}
        breakpoints={[
          { cols: 1, maxWidth: 'sm' },
          { cols: 2, maxWidth: 'lg' },
        ]}
      >
        {SOCIAL_DATA.map((item, idx) => (
          <Center
            key={idx}
            sx={{
              minHeight: 640,
              background: '#FAFAFA',
              borderRadius: 10,
            }}
          >
            <Stack>
              <Box>
                <Text align="center" size={24}>
                  {item.title}
                </Text>
                <Text mt={10} mb={25} align="center" size={36} weight={700}>
                  {item.label}
                </Text>
              </Box>
              <Box>
                <Text
                  align="center"
                  size={14}
                  sx={{
                    maxWidth: 520,
                    padding: '0 15px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.description1,
                  }}
                />
                <Text
                  align="center"
                  size={14}
                  mt={40}
                  sx={{
                    maxWidth: 510,
                    padding: '0 15px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.description2,
                  }}
                />
              </Box>
            </Stack>
          </Center>
        ))}
      </SimpleGrid>
      <Box className="img-wrapper">
        <Image src={Photos.AppleHeart} alt="Logo" width={259} height={285} />
      </Box>
    </Container>
  )
}
