import { createSlice } from '@reduxjs/toolkit'
import { doSignOut } from './actions'

type IInitialState = IQueryState<{} | null>

const initialState: IInitialState = { data: null, status: {} }

export const signOut = createSlice({
  name: 'signOut',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doSignOut.pending, state => {
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
      state.status.errorMessage = null
    }),
      builder.addCase(doSignOut.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(doSignOut.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})
export default signOut
