import { createStyles } from '@mantine/core'

export const useStyles = createStyles(
  (theme, params: { background?: string }) => ({
    inforProductWrapper: {
      position: 'relative',
      '.coldess': {
        '.mantine-Group-root': {
          justifyContent: 'unset',
          gap: 15,
        },
      },
      '.txtNameProduct': {
        background: params.background,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
      '.hoverIcon': {},

      [`@media (max-width: 1117px)`]: {
        '.content-left': {
          order: 2,
        },
      },
    },
    groupPhotoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      '.mantine-Image-root': {
        position: 'relative',
        cursor: 'pointer',
        '&.image': {
          width: '110px!important',
          '.mantine-Image-image': {
            height: '110px!important',
            width: '110px!important',
          },
        },
        '&.active': {
          borderRadius: 10,
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 12px',
        },
        '&.moreImg': {
          '&:before': {
            content: `"+"attr(data-content)`,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.3)',
            borderRadius: 10,
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: 24,
            fontWeight: 700,
          },
        },
      },
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        '.mantine-Image-root': {
          '&.image': {
            width: '90px!important',
            '.mantine-Image-image': {
              height: '90px!important',
              width: '90px!important',
            },
          },
        },
      },
    },
    modalGroupPhoto: {
      background: 'unset',
      padding: '0px!important',
      boxShadow: 'unset',
      '.group_photo-content': {
        display: 'flex',
        gap: 20,
        maxHeight: '800px',
        '.mantine-Stack-root': {
          overflowY: 'auto',
          paddingLeft: 20,
          direction: 'rtl',
        },
        '.big_photo': {
          width: 'calc(100% - 160px)!important',
          borderRadius: 10,
          '.mantine-Image-figure': {
            height: '100%',
            '.mantine-Image-imageWrapper': {
              height: '100%',
              img: {
                height: '100%!important',
                // maxWidth: '900px',
              },
            },
          },
        },
      },
      '.closeIcon': {
        right: '20px!important',
      },
      [`@media (max-width: 992px)`]: {
        '.group_photo-content': {
          maxHeight: '600px',
        },
      },
      [`@media (max-width: 576px)`]: {
        width: '450px',
        '.group_photo-content': {
          maxHeight: 'unset',
          flexDirection: 'column',
          '.mantine-Stack-root': {
            order: 2,
            paddingLeft: 0,
            paddingBottom: 20,
            flexDirection: 'row',
            direction: 'unset',
          },
          '.big_photo': {
            order: 1,
            width: 'unset!important',
          },
        },
        '.closeIcon': {
          right: '-10px!important',
        },
      },
    },
  })
)
