import {
  ActionIcon,
  Box,
  Center,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  Text,
} from '@mantine/core'
import { DD_MM_YY_HH_MM } from 'constants/index'
import { Svgs } from 'assets'
import { TabLabel } from 'constants/enum'
import { Tabs } from 'common'
import { doQueryStatusOrders } from './actions'
import { formatDateV2, formatNumber, getKeyByValue } from 'utils'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import Delivery from 'components/CartComponent/Delivery'
import YourCart from 'components/CartComponent/YourCart'

const Order = ({
  tabLabel,
  data,
  isLoading,
  onDetail,
}: {
  tabLabel: string
  data?: IFormRecipient[] | null
  isLoading?: boolean
  onDetail: (formRecipient: IFormRecipient) => void
}) => {
  const { classes, cx } = useStyles()
  const [idxActive, setIdxActive] = useState(-1)

  return (
    <Box className={classes.orderWrapper}>
      <LoadingOverlay color="dark" visible={!!isLoading} overlayBlur={2} />
      {data?.length !== 0 && data ? (
        data.map((val, idx) => (
          <Box
            key={idx}
            className={cx('order_Box', {
              activeBg: idxActive === idx,
            })}
            onClick={() => {
              onDetail(val)
              setIdxActive(idx)
            }}
          >
            <Box>
              <Group position="apart">
                <Text className="idOrder" size={14} weight="bold">
                  MÃ ĐƠN: {val.id}
                </Text>
                <Text size={12}>
                  {formatNumber(val.productList.totalOrderValue)}
                </Text>
              </Group>
              <Group position="apart">
                <Text size={12}>
                  {formatDateV2(val.createOn, DD_MM_YY_HH_MM)}
                </Text>
                <Center
                  className={cx('txtStatus', {
                    paymenting:
                      getKeyByValue(TabLabel, TabLabel['Chờ thanh toán']) ==
                      tabLabel,
                  })}
                >
                  <Text>{tabLabel}</Text>
                  <ActionIcon variant="transparent" className="iconArrow">
                    <Svgs.ArrowDown />
                  </ActionIcon>
                </Center>
              </Group>
            </Box>
          </Box>
        ))
      ) : (
        <Center>
          <Text weight="bold">EMPTY</Text>
        </Center>
      )}
    </Box>
  )
}

export default function History() {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { queryStatusOrders, queryUserResult } = useAppSelector(state => state)
  const [activeTab, setActiveTab] = useState<string>()
  const [formRecipient, setFormRecipient] = useState<IFormRecipient>()

  useEffect(() => {
    dispatch(
      doQueryStatusOrders({
        status: activeTab ? TabLabel[activeTab] : TabLabel['Chờ thanh toán'],
        idUser: queryUserResult.data?.[0].id ?? '',
      })
    )
  }, [activeTab])
  const onDetail = (val: IFormRecipient) => {
    setFormRecipient(val)
  }
  return (
    <Container fluid>
      <Box className={classes.historyWrapper}>
        <Grid gutter={0} grow>
          <Grid.Col lg={7.8}>
            <Tabs.User
              type="with icon"
              tabsData={[
                {
                  tabLabel: getKeyByValue(TabLabel, TabLabel['Chờ thanh toán']),
                  component: (
                    <Order
                      onDetail={onDetail}
                      data={queryStatusOrders.data}
                      isLoading={queryStatusOrders.status.isLoading}
                      tabLabel={getKeyByValue(
                        TabLabel,
                        TabLabel['Chờ thanh toán']
                      )}
                    />
                  ),
                },
                {
                  tabLabel: getKeyByValue(TabLabel, TabLabel['Đang thực hiện']),
                  component: (
                    <Order
                      onDetail={onDetail}
                      isLoading={queryStatusOrders.status.isLoading}
                      data={queryStatusOrders.data}
                      tabLabel={getKeyByValue(
                        TabLabel,
                        TabLabel['Đang thực hiện']
                      )}
                    />
                  ),
                },
                {
                  tabLabel: 'Đang giao',
                  component: (
                    <Order
                      onDetail={onDetail}
                      isLoading={queryStatusOrders.status.isLoading}
                      data={queryStatusOrders.data}
                      tabLabel={getKeyByValue(TabLabel, TabLabel['Đang giao'])}
                    />
                  ),
                },
                {
                  tabLabel: 'Đã hoàn tất',
                  component: (
                    <Order
                      onDetail={onDetail}
                      isLoading={queryStatusOrders.status.isLoading}
                      data={queryStatusOrders.data}
                      tabLabel={getKeyByValue(
                        TabLabel,
                        TabLabel['Đã hoàn tất']
                      )}
                    />
                  ),
                },
                {
                  tabLabel: 'Đã huỷ',
                  component: (
                    <Order
                      onDetail={onDetail}
                      isLoading={queryStatusOrders.status.isLoading}
                      data={queryStatusOrders.data}
                      tabLabel={getKeyByValue(TabLabel, TabLabel['Đã huỷ'])}
                    />
                  ),
                },
              ]}
              onTabChange={(tabLabel: string) => setActiveTab(tabLabel)}
            />
          </Grid.Col>
          <Grid.Col lg={4} offsetLg={0.2} offset={0} pos="relative">
            <Box className={classes.yourCardWrapper}>
              {formRecipient ? (
                <Box>
                  <Box mb={20} className="your_Card">
                    <YourCart
                      productList={formRecipient?.productList}
                      isHiddenEditDelete
                      isSendRating={
                        activeTab ===
                        getKeyByValue(TabLabel, TabLabel['Đã hoàn tất'])
                      }
                    />
                  </Box>
                  <Box className="your_Delivery">
                    <Delivery type="orderLookup" orderLookup={formRecipient} />
                  </Box>
                </Box>
              ) : (
                <Center>
                  <Text weight="bold">Pick one see</Text>
                </Center>
              )}
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    </Container>
  )
}
