import {
  ActionIcon,
  Box,
  Button,
  Center,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core'
import {
  Cooldess,
  Ices,
  IcreamCheese,
  SizeGlass,
  Sugars,
} from 'constants/enum'
import { Modal } from 'common'
import { RadioButton } from 'customs'
import { Svgs } from 'assets'
import { TextInputCt } from 'customs/TextInputCt'
import { doQueryProductId } from './actions'
import {
  extracMlAndPrice,
  formatNumber,
} from 'utils'
import { onUpdateToCart } from 'reducer/Carts'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/hooks'
import {
  useCounter,
  useDisclosure,
  useHover,
} from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useForm } from '@mantine/form'
import { useStyles } from './styles'

export const formGlassInitialValues = (
  mlAndPrice?: IProduct['mlAndPrice']
) => {
  let size: SizeGlass = SizeGlass['350ml']
  if (mlAndPrice) {
    size =
      mlAndPrice[Object.keys(mlAndPrice)[0]].ml
  }
  return {
    size,
    sugar: Sugars['100%'],
    cooldess: Cooldess['COOL'],
    moreCombina: '',
    ice: Ices['100%'],
    note: '',
    cheese: IcreamCheese['FILL_CHEESE'],
  }
}
type IProps = {
  id: IProduct['id']
  status?: 'UPDATE_CART'
  amount?: number
  optionPicked?: IFormGlass
}
export default function PickOption({
  id,
  status,
  amount,
  optionPicked,
}: IProps) {
  const dispatch = useAppDispatch()
  const { data } = useAppSelector(
    state => state.productId
  )
  const [count, handlers] = useCounter(amount, {
    min: 1,
  })
  const { hovered: hoveredMinus, ref: refMinus } =
    useHover<HTMLButtonElement>()
  const { hovered: hoveredPlus, ref: refPlus } =
    useHover<HTMLButtonElement>()
  const { classes, cx } = useStyles({
    background: data?.signature?.backGround,
  })
  const formGlass = useForm<IFormGlass>({
    initialValues:
      optionPicked ??
      formGlassInitialValues(data?.mlAndPrice),
  })
  const moreCombinaRadio:
    | IRadioGroup[]
    | undefined =
    data?.moreCombina &&
    data?.moreCombina.map(val => {
      return {
        label: val.option,
        value: val.option,
        detail: val.price,
      }
    })
  const sugars: IRadioGroup[] | undefined =
    data?.sugars &&
    data?.sugars.map(val => {
      return {
        label: val,
        value: val,
      }
    })
  // const notIsPaperCup =
  //   data?.types !== 'cup juice'
  //     ? [
  //         'Phương pháp ép: <b>Cold Pressed</b>',
  //         'Dung tích 250ml / 350ml, bình đựng thuỷ tinh bảo quản ngăn mát tủ lạnh (hạn sử dụng 48 giờ)',
  //         'Mùi vị trái cây nguyên chất, không đường, không chất bảo quản',
  //       ]
  //     : [
  //         'Dung tích ly 500ml / 700ml (hạn sử dụng 8 giờ)',
  //         'Mùi vị trái cây nguyên chất, không chất bảo quản',
  //         'Trái cây tươi mới được pha chế và sử dụng ngay',
  //       ]
  const sizePrice =
    data?.mlAndPrice &&
    extracMlAndPrice(
      data?.mlAndPrice,
      formGlass.values.size
    )
  useEffect(() => {
    dispatch(doQueryProductId(id))
  }, [])

  useEffect(() => {
    if (data?.mlAndPrice) {
      let size =
        data?.mlAndPrice[
          Object.keys(data?.mlAndPrice)[0]
        ].ml
      formGlass.setFieldValue('size', size)
    }
  }, [data])
  return (
    <Box className={classes.inforProductWrapper}>
      <LoadingOverlay
        visible={!data}
        overlayBlur={2}
      />
      <SimpleGrid
        cols={2}
        breakpoints={[
          { maxWidth: 1118, cols: 1 },
        ]}
        spacing={50}
      >
        <Box className="content-left">
          <GroupPhoto photo={data?.photo ?? []} />
          <Box my={25}>
            {data?.rickText?.preserve &&
              data.rickText.preserve.map(
                (val, idx) => (
                  <Flex
                    key={idx}
                    mt={5}
                    gap={10}
                    align="center"
                  >
                    <ActionIcon size={16}>
                      <Svgs.Check />
                    </ActionIcon>
                    <Text
                      size={13}
                      maw="376px"
                      dangerouslySetInnerHTML={{
                        __html: val,
                      }}
                    />
                  </Flex>
                )
              )}
          </Box>
          {data?.rickText && (
            <Text
              size={13}
              weight="700"
              sx={{ lineHeight: '24px' }}
            >
              GIỚI THIỆU
              <Text
                size={13}
                sx={{ lineHeight: '24px' }}
                dangerouslySetInnerHTML={{
                  __html: data?.rickText?.intro,
                }}
                weight="400"
              />
            </Text>
          )}

          {/* <Link to={IPath.Home}>
            <Text
              size={13}
              color="#FE5F51"
              weight="700"
              mt={20}
              sx={{ lineHeight: '24px', fontStyle: 'italic' }}
            >
              QUYỀN LỢI KHÁCH HÀNG &gt;&gt;
            </Text>
          </Link> */}
        </Box>
        <Box>
          <Text
            size={32}
            weight="bold"
            className={cx({
              txtNameProduct:
                data?.group ===
                'Nước Ép Signature',
            })}
          >
            {data?.name}
          </Text>
          <Group position="apart">
            <Box>
              <Text size={16}>
                {data?.hasTag}
              </Text>
              <Text size={16} my={13}>
                {formatNumber(sizePrice?.price)}đ
                <Text
                  component="i"
                  size={13}
                  ml={9}
                >
                  (Đã bao gồm VAT)
                </Text>
              </Text>
              {data?.nutritional && (
                <Box>
                  {' '}
                  <Text size={13} weight={700}>
                    {data?.nutritional.kcal}{' '}
                    <Text
                      component="span"
                      color="#c4c4c4"
                    >
                      kcal{' '}
                    </Text>
                    | {data?.nutritional.water}g{' '}
                    <Text
                      component="span"
                      color="#c4c4c4"
                    >
                      nước
                    </Text>
                  </Text>{' '}
                  <Text size={13} weight={700}>
                    {formatNumber(
                      data?.nutritional.vitamin
                    )}
                    mg{' '}
                    <Text
                      component="span"
                      color="#c4c4c4"
                    >
                      vitamin & khoáng chất
                    </Text>
                  </Text>
                </Box>
              )}
            </Box>
            <Group
              position="apart"
              sx={() => ({
                flexWrap: 'nowrap',
              })}
            >
              <ActionIcon
                size={40}
                variant="transparent"
                onClick={handlers.decrement}
                ref={refMinus}
              >
                {hoveredMinus ? (
                  <Svgs.Minus2 />
                ) : (
                  <Svgs.MinusIcon />
                )}
              </ActionIcon>
              <Text size={24} weight="bold">
                {count}
              </Text>
              <ActionIcon
                size={40}
                variant="transparent"
                onClick={handlers.increment}
                ref={refPlus}
              >
                {hoveredPlus ? (
                  <Svgs.Plus2 />
                ) : (
                  <Svgs.PlusIcon />
                )}
              </ActionIcon>
            </Group>
          </Group>
          <Box
            sx={{
              border: '1px solid #c4c4c4',
              borderRadius: '10px',
              overflow: 'hidden',
            }}
            mt={10}
            mb={10}
          >
            {data?.mainIngredient && (
              <Box>
                {' '}
                <Text
                  sx={theme => ({
                    background:
                      theme.colors.oneDrink[0],
                    padding: '8px 23px',
                    fontWeight: 'bold',
                    fontSize: 14,
                    height: 40,
                  })}
                >
                  THÀNH PHẦN CHÍNH
                </Text>
                <Text
                  sx={{
                    fontSize: 14,
                    padding: '11px 23px',
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {data?.mainIngredient}
                </Text>
              </Box>
            )}
            <Box className="coldess">
              <RadioButton
                label="CHỌN SIZE"
                orientation="vertical"
                GetInputProps={{
                  ...formGlass.getInputProps(
                    'size'
                  ),
                }}
                data={Object.values(
                  data?.mlAndPrice ?? {}
                ).map(val => {
                  return {
                    value: val.ml,
                    label: val.ml,
                    detail: val.price,
                  }
                })}
              />
            </Box>
            <RadioButton
              label="LIỀU LƯỢNG NGỌT"
              orientation="vertical"
              GetInputProps={{
                ...formGlass.getInputProps(
                  'sugar'
                ),
              }}
              data={sugars ?? []}
            />
            {data?.property?.isIces && (
              <RadioButton
                label="LIỀU LƯỢNG ĐÁ"
                orientation="vertical"
                GetInputProps={{
                  ...formGlass.getInputProps(
                    'ice'
                  ),
                }}
                data={[
                  {
                    value: Ices['100%'],
                    label: Ices['100%'],
                  },
                  {
                    value: Ices['50%'],
                    label: Ices['50%'],
                  },
                ]}
              />
            )}
            {moreCombinaRadio && (
              <RadioButton
                GetInputProps={{
                  ...formGlass.getInputProps(
                    'moreCombina'
                  ),
                }}
                label={
                  <Center>
                    KẾT HỢP THÊM{' '}
                    <Text
                      component="span"
                      ml={5}
                      size={12}
                      weight={400}
                    >
                      (Không bắt buộc)
                    </Text>
                  </Center>
                }
                orientation="vertical"
                data={moreCombinaRadio}
                value={
                  formGlass.values.moreCombina
                }
                toggleChecked={isUnchecked =>
                  isUnchecked &&
                  formGlass.setFieldValue(
                    'moreCombina',
                    ''
                  )
                }
              />
            )}
            {data?.property?.isColdess && (
              <Box className="coldess">
                <RadioButton
                  label="ĐỘ LẠNH"
                  orientation="vertical"
                  GetInputProps={{
                    ...formGlass.getInputProps(
                      'cooldess'
                    ),
                  }}
                  data={[
                    {
                      value: Cooldess['COOL'],
                      label: 'Ủ Lạnh',
                    },
                    {
                      value: Cooldess['NOT_COLL'],
                      label: 'Không Lạnh',
                    },
                  ]}
                />
              </Box>
            )}

            {data?.property?.isCheese && (
              <Box className="coldess">
                <RadioButton
                  label="KEM CHEESE"
                  orientation="vertical"
                  GetInputProps={{
                    ...formGlass.getInputProps(
                      'cheese'
                    ),
                  }}
                  data={[
                    {
                      value:
                        IcreamCheese[
                          'FILL_CHEESE'
                        ],
                      label: 'Phủ kem cheese',
                    },
                    {
                      value:
                        IcreamCheese[
                          'NOT_FILL_CHEESE'
                        ],
                      label:
                        'Không phủ kem cheese',
                    },
                  ]}
                />
              </Box>
            )}

            {/* <Checkbox
                styles={{
                  root: {
                    padding: '15px 23px',
                  },
                  input: {
                    borderRadius: '50%',
                  },
                }}
                color="dark"
                {...formGlass.getInputProps('TakeAway', { type: 'checkbox' })}
                label={
                  <Text>
                    Bộ ly + đá + nước đường
                    <Text ml={10} component="b" size="sm">
                      (+0đ)
                    </Text>
                  </Text>
                }
              /> */}
          </Box>
          <TextInputCt<IFormGlass>
            icon={<Svgs.PenIcon />}
            placeholder="Ghi chú thêm"
            input={{
              paddingLeft: 95,
              GetInputProps: {
                ...formGlass.getInputProps(
                  'note'
                ),
              },
            }}
            iconWidth={75}
          />
          <Button
            fullWidth
            color="dark"
            radius="xl"
            mt={10}
            h={50}
            onClick={() =>
              dispatch(
                onUpdateToCart({
                  ...(data as IProduct),
                  optionPicked: formGlass.values,
                  amount: count,
                })
              )
            }
          >
            <Text size={16} weight={700}>
              {formatNumber(
                Number(sizePrice?.price) * count
              )}
              đ
              <Text
                ml={5}
                component="span"
                size="sm"
                weight={400}
              >
                |{' '}
                {status
                  ? 'Thay đổi giỏ hàng '
                  : 'Thêm vào giỏ hàng'}
              </Text>
            </Text>
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  )
}

const GroupPhoto = ({
  photo,
}: {
  photo: IProduct['photo']
}) => {
  const [photoActive, setPhotoActive] =
    useState(0)
  const [opened, { close, open }] =
    useDisclosure(false)
  const { classes, cx } = useStyles({})
  return (
    <Box className={classes.groupPhotoWrapper}>
      <Image
        radius={10}
        src={photo[photoActive]}
        styles={{
          figure: {
            // margin: 'auto',
            // maxWidth: 375,
          },
        }}
      />
      <Group position="left" mt={20} mb={20}>
        {photo &&
          [...photo]
            .splice(0, 3)
            .map((val, idx) => {
              const isLarge2 =
                idx === 2 &&
                [...photo].splice(3).length > 0
              return (
                <Image
                  key={idx}
                  data-content={
                    [...photo].splice(3).length
                  }
                  className={cx('image', [
                    {
                      active: idx === photoActive,
                    },
                    {
                      moreImg: isLarge2,
                    },
                  ])}
                  radius={10}
                  src={val}
                  onClick={() => {
                    if (isLarge2) {
                      open()
                    }
                  }}
                  onMouseEnter={() => {
                    setPhotoActive(idx)
                  }}
                />
              )
            })}
      </Group>
      <Modal
        classNames={{
          modal: classes.modalGroupPhoto,
        }}
        size="950px"
        opened={opened}
        onClose={close}
      >
        <div className="group_photo-content">
          <Stack spacing={20}>
            {photo &&
              photo.map((val, idx) => (
                <Image
                  className="cursor"
                  onMouseEnter={() =>
                    setPhotoActive(idx)
                  }
                  key={idx}
                  src={val}
                  width={110}
                  height={110}
                  withPlaceholder
                  radius={10}
                />
              ))}
          </Stack>
          <Image
            className="big_photo"
            src={photo[photoActive]}
            withPlaceholder
            radius={10}
          />
        </div>
      </Modal>
    </Box>
  )
}
