import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  signInWrapper: {
    height: '100vh',
    '.mantine-Group-root': {
      background: theme.white,
      height: 70,
    },
    '.content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100% - 70px)',
      '>div': {
        position: 'relative',
        overflow: 'hidden',
        // maxHeight: 466,
        width: '100%',
        maxWidth: 414,
        padding: '40px 20px',
        borderRadius: 10,
        backgroundColor: theme.white,
        '.mantine-Input-input': {
          height: 40,
        },
        '.mantine-Button-root': {
          borderRadius: 10,
          width: '100%',
          height: 40,
          fontWeight: 400,
          '.mantine-Button-inner': {
            justifyContent: 'space-between',
            '.mantine-Button-label': {
              fontSize: 14,
            },
          },
          '&.btnsignin': {
            background: '#D9D9D9',
            color: theme.white,
            '&.active': {
              background: '#000',
            },
          },
          '&.apple': {
            background: '#000',
          },
          '&.facebook': {
            background: '#1776D2',
          },
          '&.google': {
            background: theme.white,
            color: 'black',
            border: '1px solid black',
          },
        },
        '.space-center': {
          '.mantine-Button-inner': {
            justifyContent: 'center',
          },
        },
        // '.mantine-Input-icon': {
        //   color: 'unset',
        // },
        // '.mantine-Input-invalid': {
        //   color: 'unset',
        //   borderColor: '#228be6',
        // },
      },
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      padding: '0px 10px',
    },
  },
}))
