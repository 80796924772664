import { Box, Text } from '@mantine/core'
import { ContainerShadow } from 'common'
import { Svgs } from 'assets'
import { onOrderLookup } from './actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useLocation } from 'react-router-dom'
import Delivery from 'components/CartComponent/Delivery'
import SearchBar from 'customs/SearchBar'
import YourCart from 'components/CartComponent/YourCart'

export default function OrderLookup() {
  const dispatch = useAppDispatch()
  const { state: idOder } = useLocation() as IUseLocation<string>
  const { orderLookup } = useAppSelector(state => state)

  const onSearchOrder = (id: string) => {
    dispatch(onOrderLookup({ id: id }))
  }
  console.log('orderLookup', orderLookup)
  return (
    <Box mih="80vh">
      <ContainerShadow p={50} isHiddenNavLink>
        <Text size={24} weight="bold" mb={20}>
          Giỏ hàng của bạn
        </Text>
        <SearchBar
          value={idOder}
          icon={
            <Text weight={700} color="#000">
              #
            </Text>
          }
          rightSection={<Svgs.SearchIcon />}
          placeholder="TRA CỨU THEO MÃ ĐƠN HÀNG"
          rightSectionWidth={64}
          onChange={onSearchOrder}
          error={
            orderLookup.data === undefined &&
            'Rất tiếc ONE không tìm thấy kết quả phù hợp. Vui lòng thử lại với mã đơn hàng khác.'
          }
        />
      </ContainerShadow>
      {orderLookup.data && (
        <Box>
          <ContainerShadow p={50} mt={30} isHiddenNavLink>
            <YourCart
              type="orderLookup"
              productList={orderLookup.data?.productList}
            />
          </ContainerShadow>
          <ContainerShadow boxShadow="unset" isHiddenNavLink pl={50} pr={50}>
            <Delivery type="orderLookup" orderLookup={orderLookup.data} />
          </ContainerShadow>
        </Box>
      )}
    </Box>
  )
}
