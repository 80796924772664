import {
  Box,
  Button,
  Card,
  Group,
  Skeleton as SkeletonM,
  Stack,
  Text,
  clsx,
} from '@mantine/core'
type IProps = {
  isSmall?: boolean
  status: IQueryStatus
}

function Skeleton({ isSmall, status }: IProps) {
  return (
    <Card
      shadow="sm"
      p="lg"
      radius="md"
      className={clsx('card', { cardSmall: isSmall })}
    >
      <Card.Section p={25}>
        <SkeletonM visible={status.isLoading}>
          <Box
            sx={{
              maxWidth: 249,
              maxHeight: 249,
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem dolore
            sint ipsum voluptatem consequatur facere et aut quis quod, iusto
            eius? Non obcaecati tenetur fugiat molestiae laborum natus sunt
            ipsum?
          </Box>
        </SkeletonM>
      </Card.Section>
      {!isSmall && (
        <Stack justify="space-between">
          <SkeletonM visible={status.isLoading}>
            <Text>Dua hau</Text>
          </SkeletonM>
          <Group position="apart">
            <SkeletonM visible={status.isLoading}>
              <Text>50.000đ</Text>
            </SkeletonM>
            <SkeletonM visible={status.isLoading}>
              <Text>50.000đ</Text>
            </SkeletonM>
          </Group>
        </Stack>
      )}
      {isSmall && (
        <Box>
          <Box>
            <SkeletonM visible={status.isLoading}>
              <Text>Dua hau</Text>
            </SkeletonM>
            <SkeletonM visible={status.isLoading}>
              <Text weight={400} size={12}>
                50.000đ
              </Text>
            </SkeletonM>
          </Box>
          <SkeletonM visible={status.isLoading}>
            <Button>Chọn</Button>
          </SkeletonM>
        </Box>
      )}
    </Card>
  )
}

export default Skeleton
