import { Box, Container, SimpleGrid, Text } from '@mantine/core'
import { Modal, Voucher } from 'common'
import { Svgs } from 'assets'
import { VOUCHER } from 'constants/voucher'
import { useState } from 'react'
import { useStyles } from './styles'
import SearchBar from 'customs/SearchBar'

export default function CodeDiscount() {
  const { classes } = useStyles()
  const [txtVouchers, setVouchers] = useState<string>()
  const [openIVoucher, setOpenIVoucher] = useState(false)
  const [voucher, setVoucher] = useState<IVoucher | null>(null)
  return (
    <Container fluid>
      <Text size={24} mt={30} weight="bold">
        Phần Quà Ưu Đãi
      </Text>
      <Box className={classes.codeDiscountWrapper}>
        <Box className="content_box">
          <Box className="content_box-GiftWrapper">
            <Box className="search-bar">
              {/* temporary */}
              <SearchBar
                rightSectionWidth={176}
                icon={<Svgs.Voucher />}
                rightSection="Tìm kiếm"
                placeholder="Nhập mã khuyến mãi"
                onChange={setVouchers}
                error={
                  txtVouchers !== 'chaobanmoi' &&
                  txtVouchers &&
                  'Rất tiếc ONE không tìm thấy kết quả phù hợp. Vui lòng thử lại với mã đơn hàng khác.'
                }
              />
            </Box>
            <Text size={18} weight="bold" ml={20} mt={30} mb={20}>
              Đang thịnh Hành
            </Text>
            <SimpleGrid
              cols={3}
              breakpoints={[
                {
                  maxWidth: 'md',
                  cols: 1,
                },
                {
                  maxWidth: 'xl',
                  cols: 2,
                },
              ]}
            >
              {VOUCHER.map((val, idx) => (
                <Box
                  className="cursor"
                  key={idx}
                  onClick={() => {
                    setOpenIVoucher(true)
                    setVoucher(val)
                  }}
                >
                  <Voucher {...val} />
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </Box>
      </Box>
      <Modal
        classNames={{
          body: classes.modalCodeDiscount,
        }}
        size="900px"
        opened={openIVoucher}
        onClose={() => setOpenIVoucher(false)}
      >
        <Box>
          <Text size={24} weight="bold" mb={20}>
            Đường Phố Sài Gòn Đẹp Quá
          </Text>
          <Box className="code_discount-voucher">
            {voucher && <Voucher from="modal" {...voucher} />}
          </Box>
        </Box>
      </Modal>
    </Container>
  )
}
