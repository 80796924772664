import { Banner, Tabs } from 'common'
import { Box, Container } from '@mantine/core'
import Policy from './Policy'
import TermsOfUse from './TermsOfUse'

const enum TABS {
  POLICY = 'Chính Sách Riêng Tư',
  TERMS_OF_USE = 'Điều Khoản Sử Dụng',
}
const tabsData: ITabs[] = [
  {
    tabLabel: TABS.POLICY,
    component: <Policy />,
  },
  {
    tabLabel: TABS.TERMS_OF_USE,
    component: <TermsOfUse />,
  },
]
const PrivacyPolicy = () => {
  return (
    <Box>
      <Container
        fluid
        mb={100}
        sx={theme => ({
          [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
            margin: '0 40px',
          },
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            padding: '0 16px',
          },
        })}
      >
        <Tabs.Tabs data={tabsData} />
        <Banner.Video />
      </Container>
    </Box>
  )
}

export default PrivacyPolicy
