import { Box, SimpleGrid } from '@mantine/core'
import { memo } from 'react'
import Card from './Card'
import Skeleton from './Skeleton'

type IProps = {
  onClick?: (val: IProduct) => void
  isSmall?: boolean
  status: IQueryStatus
  data?: IProduct[] | null
}

function Product({ onClick, isSmall, status, data }: IProps) {
  return (
    <Box>
      <SimpleGrid
        cols={5}
        spacing={40}
        mb={100}
        breakpoints={[
          { cols: 2, maxWidth: 'sm', spacing: 15 },
          { cols: 3, maxWidth: 'lg' },
          { cols: 4, maxWidth: 1441 },
        ]}
      >
        {data &&
          !status.isLoading &&
          data.map((val, idx) => (
            <Card key={idx} {...val} isSmall={isSmall} onClick={onClick} />
          ))}
        {status.isLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((_, idx) => (
            <Skeleton key={idx} status={{ isLoading: true }} />
          ))}
      </SimpleGrid>
    </Box>
  )
}

export default memo(Product)
