import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  cardMemberWrapper: {
    '.cardMember': {
      padding: 20,
      height: 232,
      position: 'relative',
      '.point': {
        '&.gold': {
          rect: {
            fill: '#F5B955',
          },
          div: {
            color: '#F5B955',
          },
        },
        '&.ruby': {
          rect: {
            fill: '#F34A73',
          },
          div: { color: '#F34A73' },
        },
      },
      '.earnPoints': {
        position: 'absolute',
        content: '""',
        top: 0,
        right: 0,
        color: '#fff',
        width: 120,
        height: 35,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        borderRadius: ' 0px 10px',
        background: 'linear-gradient(270deg, #000000 0%, #434343 100%)',
      },
      '.barcode': {
        height: 128,
        svg: {
          borderRadius: '10px',
        },
      },
    },
    '.powers_member': {
      padding: 20,
    },
  },
}))
