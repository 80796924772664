import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { extracMlAndPrice } from 'utils'
import { formGlassInitialValues } from 'components/InforProduct/PickOption'

export type IInitialStateAddToCart = {
  productList: IProduct[]
  totalProductList: number
  totalOrderValue: number
}

const initialState: IInitialStateAddToCart = {
  productList: [],
  totalProductList: 0,
  totalOrderValue: 0,
}

export const carts = createSlice({
  name: 'ADD_TO_CART',
  initialState,
  reducers: {
    onAddToCart: (
      state: IInitialStateAddToCart,
      action: PayloadAction<IProduct>
    ) => {
      const itemInCart = state.productList.find(
        item => item.id === action.payload.id
      )
      if (itemInCart && itemInCart.amount) {
        itemInCart.amount++
      } else {
        state.productList.push({
          ...action.payload,
          amount: 1,
          optionPicked: formGlassInitialValues(action.payload.mlAndPrice),
        })
      }
      const { totalProductList, totalOrderValue } = onTotal(state.productList)
      state.totalProductList = totalProductList
      state.totalOrderValue = totalOrderValue
    },
    onRemoveItem: (
      state: IInitialStateAddToCart,
      action: PayloadAction<Pick<IProduct, 'id'>>
    ) => {
      const newProduceList = state.productList.filter(
        item => item.id !== action.payload.id
      )
      state.productList = newProduceList
      const { totalProductList, totalOrderValue } = onTotal(state.productList)
      state.totalProductList = totalProductList
      state.totalOrderValue = totalOrderValue
    },
    onUpdateToCart: (
      state: IInitialStateAddToCart,
      action: PayloadAction<IProduct>
    ) => {
      console.log('action.payload', action.payload)
      const idx = state.productList.findIndex(
        item => item.id === action.payload.id
      )
      if (idx === -1) {
        state.productList.push(action.payload)
      } else {
        state.productList[idx] = action.payload
      }
      const { totalProductList, totalOrderValue } = onTotal(state.productList)
      state.totalProductList = totalProductList
      state.totalOrderValue = totalOrderValue
    },
    onClearCarts: () => {
      return initialState
    },
  },
})

const onTotal = (productList: IProduct[]) => {
  return productList.reduce(
    (accumulator, currentValue) => {
      const amount = Number(currentValue.amount)
      const sizePrice = extracMlAndPrice(
        currentValue.mlAndPrice,
        currentValue?.optionPicked?.size
      )
      return {
        totalOrderValue: accumulator.totalOrderValue + amount * sizePrice.price,
        totalProductList: amount + accumulator.totalProductList,
      }
    },
    {
      totalProductList: 0,
      totalOrderValue: 0,
    }
  )
}
export const { onAddToCart, onRemoveItem, onUpdateToCart, onClearCarts } =
  carts.actions
export default carts
