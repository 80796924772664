import { Banner, Swiper } from 'common'
import { Box } from '@mantine/core'
import { Photos } from 'assets'
import { Tea as TeaComponent } from 'components'
// import { createDocs } from 'config/firebase/utils'
import { useEffect } from 'react'

const Tea = () => {
  useEffect(() => {
    // createDocs()
  }, [])

  return (
    <Box>
      <Swiper.Carousel
        height="md"
        data={[Photos.Tea1, Photos.Tea2, Photos.Tea3, Photos.Tea4, Photos.Tea5]}
      />
      <TeaComponent />
      <Banner.Free />
    </Box>
  )
}

export default Tea
