import { createSlice } from '@reduxjs/toolkit'
import { onMomoPayment, onZaloPayment } from './payment'
import { onOrder, onOrderUpdateStatus } from './actions'

type IInitialState = IQueryState<string | null>

const initialState: IInitialState = { data: null, status: {} }

export const doOrder = createSlice({
  name: 'ORDERS',
  initialState,
  reducers: {
    onClearDoOrder: () => {
      return initialState
    },
  },
  extraReducers: builder => {
    builder.addCase(onOrder.pending, state => {
      state.status.isLoading = true
    }),
      builder.addCase(onOrder.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(onOrder.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})

const initialOrderUpdateStatus: IInitialState = { data: null, status: {} }

export const orderUpdateStatus = createSlice({
  name: 'ORDERS UPDATE STATUS',
  initialState: initialOrderUpdateStatus,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(onOrderUpdateStatus.pending, state => {
      state.status.isLoading = true
    }),
      builder.addCase(onOrderUpdateStatus.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(onOrderUpdateStatus.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})

type IInitialStateMomoPayment = IQueryState<IOrderPaymentMethodRes | null>

const initialStateMomoPayment: IInitialStateMomoPayment = {
  data: null,
  status: {},
}

export const doMomoPayment = createSlice({
  name: 'MOMO_PAYMENT',
  initialState: initialStateMomoPayment,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(onMomoPayment.pending, state => {
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
      state.status.errorMessage = null
    }),
      builder.addCase(onMomoPayment.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(onMomoPayment.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload as unknown as IOrderPaymentMethodRes
      })
  },
})

type IInitialStateZaloPayment = IQueryState<ICreateOrderRes | null>

const initialStateZaloPayment: IInitialStateZaloPayment = {
  data: null,
  status: {},
}

export const ZaloPayment = createSlice({
  name: 'ZALO_PAYMENT',
  initialState: initialStateZaloPayment,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(onZaloPayment.pending, state => {
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
      state.status.errorMessage = null
    }),
      builder.addCase(onZaloPayment.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(onZaloPayment.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})

export const { onClearDoOrder } = doOrder.actions
export default { doOrder, doMomoPayment, ZaloPayment, orderUpdateStatus }
