import {
  BackgroundImage,
  Box,
  Grid,
  Group,
  Stack,
  Sx,
  Text,
} from '@mantine/core'
import { Btn } from 'customs'
import { IPath } from 'constants/enum'
import { encodeBase64 } from 'utils'
// import iconv from 'iconv-lite'
type IProps = {
  title: string
  description: string
  btn: {
    label: string
    to: IPath
  }
  txtPhotoRight: {
    title: string
    description: string
    photo: string
  }
  txtPhotoLeft: {
    title: string
    description: string
    photo: string
  }
  flexDirection?: 'row-reverse'
  align?: React.CSSProperties['textAlign']
}

// const decode = (str: string) => Buffer.from(str, 'base64').toString('binary')
// const encode = str => Buffer.from(str, 'binary').toString('base64')
export default function Feed({
  flexDirection,
  title,
  description,
  txtPhotoRight,
  txtPhotoLeft,
  align = 'left',
  btn,
}: IProps) {
  // console.log('iconv', iconv.encode('kiet an com chua', 'win1251'))
  return (
    <Grid
      mt={40}
      mb={60}
      gutter={40}
      sx={{
        flexDirection: flexDirection,
      }}
    >
      <Grid.Col
        xs={12}
        md={7}
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-easing="liner"
        data-aos-once="true"
      >
        <Box
          sx={theme => ({
            padding: '0 40px',
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              padding: 0,
            },
          })}
        >
          <Text
            weight="bold"
            size={54}
            sx={{
              lineHeight: '78px',
            }}
            align={align}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Text pb={30} pt={30} align={align}>
            {description}
          </Text>
          <Group position="apart" grow pb={60} maw={285}>
            <Btn {...btn} />
          </Group>
        </Box>
        <BackgroundImg
          to={`${IPath.Feeds}/${encodeBase64(txtPhotoLeft.title)}`}
          src={txtPhotoLeft.photo}
          title={txtPhotoLeft.title}
          description={txtPhotoLeft.description}
          labelBtn="Đến bài viết"
        />
      </Grid.Col>
      <Grid.Col
        xs={12}
        md={5}
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-easing="liner"
        data-aos-once="true"
      >
        <Stack
          sx={{
            height: '100%',
          }}
          align="center"
          justify="space-between"
        >
          <BackgroundImg
            to={`${IPath.Feeds}/${encodeBase64(txtPhotoRight.title)}`}
            src={txtPhotoRight.photo}
            sx={{
              height: '100%',
              minHeight: '520px',
            }}
            title={txtPhotoRight.title}
            labelBtn="Đến bài viết"
            description={txtPhotoRight.description}
          />
        </Stack>
      </Grid.Col>
    </Grid>
  )
}

const BackgroundImg = ({
  src,
  sx,
  title,
  description,
  labelBtn,
  to,
}: {
  src: string
  title: string
  description: string
  labelBtn: string
  sx?: Sx
  to: IPath | string
}) => (
  <BackgroundImage
    component={Stack}
    justify="flex-end"
    sx={{
      height: 520,
      display: 'flex',
      justifyContent: 'flex-end',
      position: 'relative',
      '&:before': {
        inset: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: '10px',
        content: '""',
        backdropFilter: 'brightness(0.8)',
      },
      ...sx,
    }}
    radius={10}
    src={src}
  >
    <Box
      p={40}
      sx={{
        position: 'relative',
      }}
    >
      <Text weight="bold" size={24} color="white">
        {title}
      </Text>
      <Text size={16} color="white" pb={29} pt={14}>
        {description}
      </Text>
      <Btn label={labelBtn} border="unset" to={to} />
    </Box>
  </BackgroundImage>
)
