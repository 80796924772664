import {
  CollectionReference,
  collection,
  getDocs,
} from 'firebase/firestore'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { database } from 'config/firebase'

const colref = collection(
  database,
  'test10'
) as CollectionReference<IProduct>

export const doVitamin = createAsyncThunk(
  'test10',
  async (_params, thunkAPI) => {
    // thunkAPI.dispatch

    try {
      const res = await getDocs(colref)
      const data = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
      })
      return data
    } catch (error) {
      console.log('error', error)
      return thunkAPI.rejectWithValue(error)
    }
    // getDocs(colref)
    //   .then(snapshot => {
    //     console.log('co')
    //     snapshot.docs.forEach(doc => {
    //       coffee.push({ ...doc.data(), id: doc.id })
    //     })
    //     console.log('vita', coffee)
    //   })
    //   .catch(err => console.log('err', err))
    // return coffee
  }
)
