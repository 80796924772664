import { Container } from '@mantine/core'
import { Product2, Tabs } from 'common'
import { TABS } from 'constants/enum'
import { doQueryProducts } from 'reducer/Products/actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'

export default function Yogurt() {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector(state => state)
  useEffect(() => {
    dispatch(doQueryProducts(['Yogurt Đá Viên', 'Yogurt Đá Xay']))
  }, [])
  return (
    <Container fluid>
      <Tabs.Tabs
        title="YOGURT"
        data={[
          {
            tabLabel: TABS.ALL,
            component: (
              <Product2 data={products.data} status={products.status} />
            ),
          },
          {
            tabLabel: TABS.YOGURT_ICE,
            component: (
              <Product2
                data={products.data?.filter(
                  item => item.group === 'Yogurt Đá Viên'
                )}
                status={products.status}
              />
            ),
          },
          {
            tabLabel: TABS.YOGURT_GRINDIN,
            component: (
              <Product2
                data={products.data?.filter(
                  item => item.group === 'Yogurt Đá Xay'
                )}
                status={products.status}
              />
            ),
          },
        ]}
      />
    </Container>
  )
}
