import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  radioButtonWrapper: {
    '.mantine-Radio-root': {
      position: 'relative',
      '.mantine-Radio-label': {
        cursor: 'pointer',
      },
      '.subLabel': {
        position: 'absolute',
        content: "''",
        top: 0,
        right: 0,
      },
    },
  },
}))
