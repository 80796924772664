import { Box } from '@mantine/core'
import { DD_MM_YYY } from 'constants/index'
import { add, format, getHours } from 'date-fns'
import { useFormRecipientContext } from '../form-context'
import { useMemo } from 'react'
import Select from 'customs/Select'
import useStyles from './styles'

function DeliveryTime() {
  const { classes } = useStyles()
  const formRecipient = useFormRecipientContext()
  const tomorrow = (idx: number) =>
    format(
      add(new Date(), {
        days: idx,
      }),
      DD_MM_YYY
    )
  const currenthoursDate = useMemo(() => {
    const today =
      format(new Date(), DD_MM_YYY) === formRecipient.values.receivedDate
    if (today) {
      formRecipient.setFieldValue('receivedTime', 'Giao trong ngày')
    }
    return {
      hours: getHours(Date.now()),
      today,
    }
  }, [formRecipient.values.receivedDate])

  return (
    <Box className={classes.deliveryTimeWrapper}>
      <Select
        placeholder="Ngày nhận hàng"
        data={Array(3)
          .fill(0)
          .map((_, index) => {
            return {
              value: `${tomorrow(index)}`,
              label: `${tomorrow(index)}`,
            }
          })}
        {...formRecipient.getInputProps('receivedDate')}
      />
      <Select
        placeholder="Khung thời gian"
        data={[
          {
            value: 'Giao trong ngày',
            label: 'Giao trong ngày',
          },
          {
            value: 'Buổi sáng (8:00-12:00)',
            label: 'Buổi sáng (8:00-12:00)',
            disabled: currenthoursDate.hours >= 13 && currenthoursDate.today,
          },
          {
            value: 'Khung trưa chiều (13:00 - 16:00)',
            label: 'Khung trưa chiều (13:00 - 16:00)',
            disabled: currenthoursDate.hours >= 17 && currenthoursDate.today,
          },
          {
            value: 'Khung tối (17:00 - 20:00)',
            label: 'Khung tối (17:00 - 20:00)',
            disabled: currenthoursDate.hours >= 21 && currenthoursDate.today,
          },
        ]}
        {...formRecipient.getInputProps('receivedTime')}
      />
    </Box>
  )
}
export default DeliveryTime
