import './styles.scss'
import { IPath } from 'constants/enum'
import { Link } from 'react-router-dom'

type IProps = {
  resultCode: string
  message: string
}
export default function ErrorUI({ resultCode, message }: IProps) {
  return (
    <div>
      <div className="err_page">
        <div className="err_page_left">
          <img
            src="https://github.com/abhinanduN/codepen/blob/master/err.png?raw=true"
            width="360px"
            height="250px/"
          />
        </div>
        <div className="err_page_right">
          <h1>{resultCode}</h1>
          <h4>{message}</h4>
          <p>
            Don&apos;t worry. Since you&apos;re valuable to us we will bring you
            back to safety
          </p>
          <Link className="err_btn" to={IPath.Home}>
            Back to home
          </Link>
        </div>
      </div>
    </div>
  )
}
