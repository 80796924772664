import {
  Box,
  Button,
  Center,
  Container,
  Grid,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core'
import { Svgs } from 'assets'

export default function Contact() {
  return (
    <Box mt={60}>
      <Stack justify="center" sx={{ background: '#FAFAFA' }} py={60} px={10}>
        <Box>
          <Text align="center" size={24} weight={700} mb={38}>
            Thông Tin Liên Hệ
          </Text>
          <Text align="center" size={14} weight={700} mb={8}>
            Trụ sở đăng ký kinh doanh
          </Text>
          <Text align="center" size={14} mb={8}>
            | 213/13 Hồng Lạc, Phường 10, Quận Tân Bình, Thành phố Hồ Chí Minh |
          </Text>
          <Text align="center" size={14} weight={700} mb={8}>
            Địa chỉ
          </Text>
          <Text align="center" size={14} mb={8}>
            | 1002 Tạ Quang Bửu, Phường 6, Quận 8, Thành phố Hồ Chí Minh (Chung
            Cư The Pegasuite 1) |
          </Text>
          <Text align="center" size={14} weight={700} mb={8}>
            Email
          </Text>
          <Text align="center" size={14} mb={8}>
            | one.drinkandfood@gmail.com |
          </Text>
          <Text align="center" size={14} weight={700} mb={8}>
            Hotline
          </Text>
          <Text align="center" size={14}>
            | 0933 22 00 78 |
          </Text>
          <Center py={19}>
            <Svgs.LeafIcon />
          </Center>
          <Text align="center" size={24} weight={700} mb={28}>
            Feedback
          </Text>
          <Center>
            <Text sx={{ maxWidth: 798 }} align="center">
              ONE đề cao chất lượng dịch vụ mang lại cho khách hàng, thấu hiểu
              nhu cầu, nghiên cứu & nâng cấp sản phẩm liên tục. Vì thế trong quá
              trình hoạt động nếu có vấn đề, ONE mong bạn thứ lỗi và gửi nội
              dung đóng góp qua email:
              <Text component="span" color="#FE5F51" px={5}>
                one.drinkandfood@gmail.com
              </Text>
              và hotline:
              <Text component="span" color="#FE5F51" px={5}>
                0933 22 00 78.
              </Text>
            </Text>
          </Center>
        </Box>
      </Stack>
      <Box
        hidden
        sx={{
          background: 'linear-gradient(135deg, #000000 0%, #434343 100%)',
        }}
      >
        <Container fluid mx={40}>
          <Grid
            align="center"
            sx={theme => ({
              flex: 1,
              margin: 0,
              minHeight: 160,
              [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                padding: '40px 0',
              },
            })}
          >
            <Grid.Col lg={3} md={12}>
              <Text
                sx={theme => ({
                  [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                    textAlign: 'center',
                  },
                })}
                color="#fff"
                size={18}
                weight={700}
                dangerouslySetInnerHTML={{
                  __html: `  ĐĂNG KÝ NHẬN TIN TỪ <br/>ONE DRINK & FOOD`,
                }}
              />
            </Grid.Col>
            <Grid.Col lg={6} md={12}>
              <Center>
                <Box>
                  <TextInput
                    styles={{
                      wrapper: {
                        maxWidth: 495,
                      },
                      input: {
                        padding: '15px 25px',
                        fontSize: 18,
                        minHeight: 50,
                        borderRadius: 10,
                        background: 'transparent',
                        color: '#fff',
                        border: '1px solid #fff',
                        '::placeholder': {
                          color: '#fff',
                        },
                      },
                    }}
                    placeholder="Địa chỉ email của bạn"
                  />
                  <Text size={14} color="#fff" mt={8} align="center">
                    ONE sẽ cập nhập cho bạn những thông tin mới nhất qua địa chỉ
                    email
                  </Text>
                </Box>
              </Center>
            </Grid.Col>
            <Grid.Col lg={3} md={12}>
              <Group
                position="right"
                sx={theme => ({
                  [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                    justifyContent: 'center',
                  },
                })}
              >
                <Button
                  sx={{
                    height: 50,
                    width: 250,
                    background: 'transparent',
                    fontSize: 18,
                    color: '#fff',
                    border: '1px solid #fff',
                    '&:hover': {
                      background: '#fff',
                      color: '#000',
                    },
                  }}
                  radius={10}
                  color="dark"
                >
                  Gửi thông tin
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
