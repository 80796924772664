import { createSlice } from '@reduxjs/toolkit'
import { doTeaHeat } from './action'

type IInitialState = IQueryState<IProduct[] | null>

const initialState: IInitialState = { data: null, status: {} }

export const teaHeat = createSlice({
  name: 'TEA_HEAT',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doTeaHeat.pending, state => {
      state.status.isLoading = true
    }),
      builder.addCase(doTeaHeat.rejected, (state, action) => {
        state.status.errorMessage = action.error
        state.status.isLoading = false
      }),
      builder.addCase(doTeaHeat.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.data = action.payload
      })
  },
})
export default teaHeat
