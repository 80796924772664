import { ConfirmationResult } from 'firebase/auth'
import { createSlice } from '@reduxjs/toolkit'
import { onSignInWithPhoneNumber } from './action'

const initialStateSignInWithPhoneNumber: IQueryState<ConfirmationResult | null> =
  {
    data: null,
    status: {},
  }

export const signInWithPhoneNumber = createSlice({
  name: 'Sign In With Phone Number',
  initialState: initialStateSignInWithPhoneNumber,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(onSignInWithPhoneNumber.pending, state => {
      state.status.errorMessage = null
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
    }),
      builder.addCase(onSignInWithPhoneNumber.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(onSignInWithPhoneNumber.fulfilled, (state, action) => {
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})
