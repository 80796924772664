import { ActionIcon, Box, Center, Grid, Text } from '@mantine/core'
import { Svgs } from 'assets'

export default function Banner() {
  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #000000 0%, #434343 100%)',
      }}
    >
      <Grid
        m={0}
        sx={theme => ({
          minHeight: '160px',
          [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
            padding: '40px 0px',
          },
        })}
      >
        <Grid.Col
          p={0}
          lg={4}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            align="center"
            color="#fff"
            size={18}
            pb={10}
            weight={700}
            sx={theme => ({
              maxWidth: 280,
              [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                maxWidth: 'unset',
              },
            })}
            dangerouslySetInnerHTML={{
              __html: `MỌI THÔNG TIN HỢP TÁC VUI LÒNG LIÊN HỆ QUA`,
            }}
          />
        </Grid.Col>
        <Grid.Col
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: '1px solid #fff',
            borderRight: '1px solid #fff',
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              border: 'none',
            },
          })}
          p={0}
          py={10}
          lg={4}
          sm={6}
          xs={7}
        >
          <Center inline component="a" href="mailto:one.drinkandfood@gmail.com">
            <ActionIcon size={36} variant="transparent">
              <Svgs.Email />
            </ActionIcon>
            <Text ml={20} size={18} color="#fff" mt={8} align="center">
              one.drinkandfood@gmail.com
            </Text>
          </Center>
        </Grid.Col>
        <Grid.Col
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          p={0}
          lg={4}
          sm={6}
          xs={5}
          pt={10}
        >
          <Center component="a" href="tel:+0933220078">
            <ActionIcon size={36} variant="transparent">
              <Svgs.Phone />
            </ActionIcon>
            <Text ml={20} color="#fff" size={18}>
              0933 22 00 78
            </Text>
          </Center>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
