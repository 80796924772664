import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  modalWrapper: {
    '.mantine-Modal-modal': {
      padding: 50,
      position: 'relative',
      '.closeIcon': {
        position: 'absolute',
        top: 10,
        right: 10,
        '&:hover': {
          '& svg rect': { fill: 'white' },
        },
      },
      '.mantine-Modal-body': {
        overflow: 'hidden',
      },
      [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
        padding: '20px',
        '.mantine-Modal-body': {
          marginTop: 20,
          '&:before': {
            display: 'none',
          },
        },
      },
    },
  },
}))
