import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  checkInEveryDayWrapper: {
    boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
    padding: 40,
    borderRadius: 10,
    '.boxDay': {
      borderRadius: 10,
      overflow: 'hidden',
      background: '#fff',
      '.day': {
        height: 30,
        fontSize: 14,
        fontWeight: 700,
        background: '#E5E5E5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&.sunday': {
        margin: '20px 0',
        '>div:nth-of-type(2)': {
          padding: '15px',
          '.point': {
            flex: 1,
          },
          '.text': {
            flex: 1,
            fontSize: 14,
            fontWeight: 700,
            width: 119,
          },
        },
      },
    },
    [`@media (min-width:  ${theme.breakpoints.sm}px) and (max-width: ${theme.breakpoints.md}px)`]:
      {
        '.boxDay': {
          '&.sunday': {
            marginTop: 10,
          },
        },
      },
  },
}))
