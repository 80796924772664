import { Box, BoxProps, Container, NavLink, Text } from '@mantine/core'
import { IPath } from 'constants/enum'
import { Link } from 'react-router-dom'
import { Svgs } from 'assets'
import React from 'react'

type IProps = {
  children: React.ReactNode
  boxShadow?: 'unset'
  isHiddenNavLink?: boolean
} & BoxProps
export default function ContainerShadow({
  children,
  boxShadow,
  isHiddenNavLink,
  ...BoxProps
}: IProps) {
  return (
    <Container fluid>
      {!isHiddenNavLink && (
        <NavLink
          component={Link}
          to={IPath.Home}
          p={0}
          pt={40}
          pb={40}
          label={
            <Text size={16} weight="bold">
              Về trang chủ
            </Text>
          }
          icon={<Svgs.ArrowLeft />}
          sx={{
            width: 'max-content',
            '&:hover': {
              background: 'unset',
            },
          }}
        />
      )}
      <Container
        size={1070}
        sx={theme => ({
          marginTop: 4,
          [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            padding: 0,
          },
        })}
      >
        <Box
          sx={theme => ({
            boxShadow: boxShadow ?? 'rgb(0 0 0 / 10%) 0px 4px 12px',
            borderRadius: '10px',
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
              padding: 20,
            },
          })}
          {...BoxProps}
        >
          {children}
        </Box>
      </Container>
    </Container>
  )
}
