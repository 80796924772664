import { Box, Grid, Stack, Sx, Text, Title } from '@mantine/core'
import { Btn } from 'customs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Photos } from 'assets'
import { Swiper } from 'common'
import { useStyles } from './styles'
type IProps = {
  photoMaxHeight?: number
  sxText?: Sx
  sxPhoto?: Sx
} & IOneFoodDrink

export default function CardPhoto({
  title,
  label,
  qoue,
  description1,
  labelBtn,
  photo,
  photoPosition = 'left',
  textAlign = 'left',
  photoMaxHeight,
  isShowSmallCarousel,
  sxText,
  sxPhoto,
}: IProps) {
  const { classes } = useStyles()

  const isPhotoPosition = photoPosition === 'right'
  return (
    <Box className={classes.cardPhotoWrapper}>
      {title && (
        <Title className="title" order={1}>
          <Text size={24}>{title}</Text>
        </Title>
      )}
      <Grid
        align="center"
        justify="center"
        gutter={60}
        m={0}
        sx={{
          overflowX: 'hidden',
          flexDirection: isPhotoPosition ? 'row-reverse' : 'row',
        }}
      >
        <Grid.Col
          md={6}
          className="photo"
          data-aos={isPhotoPosition ? 'fade-left' : 'fade-right'}
          data-aos-duration="500"
          data-aos-easing="liner"
          data-aos-once="true"
          sx={theme => ({
            paddingLeft: isPhotoPosition ? 60 : 0,
            paddingRight: isPhotoPosition ? 0 : 60,
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
              padding: '30px 0',
            },
            ...sxPhoto,
          })}
        >
          <LazyLoadImage
            className="imgPhoto"
            src={photo}
            alt={label}
            height={photoMaxHeight || 'unset'}
          />
        </Grid.Col>
        <Grid.Col
          md={6}
          data-aos={isPhotoPosition ? 'fade-right' : 'fade-left'}
          data-aos-duration="500"
          data-aos-easing="liner"
          data-aos-once="true"
          sx={theme => ({
            paddingLeft: isPhotoPosition ? 40 : 60,
            paddingRight: isPhotoPosition ? 60 : 40,
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
              padding: '30px 0',
            },
            ...sxText,
          })}
        >
          <Box>
            {isShowSmallCarousel && (
              <Swiper.Carousel
                swiperProps={{
                  slidesPerView: 5,
                  spaceBetween: 20,
                  slidesPerGroup: 5,
                  loop: true,
                  loopFillGroupWithBlank: true,
                  effect: 'slide',
                  pagination: false,
                  autoplay: false,
                }}
                data={[
                  Photos.SlideStrawberry,
                  Photos.SlideWaterMelon,
                  Photos.SlidiPineapple,
                  Photos.SlideGuava,
                  Photos.SlideApple,
                ]}
                height="xs"
              />
            )}
            <Title order={1}>
              <Text
                className="label"
                size={36}
                align={textAlign}
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
              />
            </Title>
            {qoue?.top && (
              <Text
                sx={{
                  lineHeight: '46px',
                }}
                size={24}
                mb={28}
              >
                {qoue.top}
              </Text>
            )}
            <Text
              mt={qoue?.top ?? 30}
              size="sm"
              sx={theme => ({
                lineHeight: '24px',
                fontWeight: 400,
                [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                  textAlign: 'left',
                },
              })}
              dangerouslySetInnerHTML={{
                __html: description1,
              }}
              align={textAlign}
            />
            {qoue?.bottom && (
              <Text
                sx={{
                  lineHeight: '46px',
                }}
                weight={700}
                size={14}
                align={textAlign}
              >
                {qoue.bottom}
              </Text>
            )}
            {labelBtn && (
              <Stack
                className="stack-btn"
                mt={54}
                spacing={20}
                align={isPhotoPosition ? 'end' : 'unset'}
              >
                {labelBtn.map((val, idx) => (
                  <Btn label={val.label} to={val.to} key={idx} />
                ))}
              </Stack>
            )}
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
