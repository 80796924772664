import { createStyles } from '@mantine/core'

const minHeight = 742
const minHeightSmall = 112

export const useStyles = createStyles(theme => ({
  swiperContainer: {
    borderRadius: 10,
    width: '100%',
    '.swiper-slide': {
      minHeight: minHeight,
      img: {
        display: 'block',
        width: '100%',
        minHeight: minHeight,
        objectFit: 'cover',
        borderRadius: 10,
      },
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        minHeight: 189,
        img: {
          minHeight: 189,
        },
      },
    },
    '.swiper-pagination': {
      textAlign: 'left',
      marginLeft: 36,
      bottom: 23,
      '.swiper-pagination-bullet': {
        background: theme.white,
        width: '20px',
        height: '20px',
        '.swiper-pagination-bullet-active': {
          width: '20px',
          height: '20px',
        },
      },
    },
    '.swiper-button-prev': {
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      background: 'rgba(196, 196, 196, 0.5)',
      border: '1px solid #fff',
      left: '20px',
      '&::after': {
        fontWeight: 900,
        color: theme.white,
        fontSize: '1em',
      },
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        display: 'none',
      },
    },
    '.swiper-button-next': {
      borderRadius: '50%',
      width: ' 50px',
      height: ' 50px',
      background: 'rgba(196, 196, 196, 0.5)',
      border: '1px solid white',
      right: '20px',
      '&::after': {
        fontWeight: 900,
        color: theme.white,
        fontSize: '1em',
      },
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        display: 'none',
      },
      '.swiper-pagination': {
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
          display: 'none',
        },
      },
    },
  },
  swiperContainerSmall: {
    borderRadius: '10px',
    height: 'unset',
    '.swiper-slide': {
      minHeight: minHeightSmall,
      minWidth: '82px',
      width: 'unset',
      maxHeight: 130,

      img: {
        minHeight: minHeightSmall,
        width: 'unset!important',
        maxHeight: 130,
        margin: 'auto',
      },
    },
    '.swiper-button-prev': {
      borderRadius: '10px 0 0 10px',
      width: '40px',
      height: '100%',
      background:
        'linear-gradient(90deg, #e5e5e5 0%, rgba(229, 229, 229, 0) 100%)',
      left: 0,
      top: 22,
      zIndex: 1,
      border: 'unset',

      '&::after': {
        fontWeight: 900,
        color: ' white',
        fontSize: '1.4em',
      },
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        display: 'flex',
      },
    },
    '.swiper-button-next': {
      borderRadius: '0px 10px 10px 0px',
      width: 40,
      height: '100%',
      background:
        'linear-gradient(270deg, #e5e5e5 0%, rgba(229, 229, 229, 0) 100%)',
      right: 0,
      top: 22,
      border: 'unset',

      '&::after ': {
        fontWeight: 900,
        color: theme.white,
        fontSize: ' 1.4em',
      },
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        display: 'flex',
      },
    },
  },
  swiperContainerMedium: {
    '.swiper-slide': {
      minHeight: '400px!important',
      // maxHeight: 400,

      img: {
        minHeight: '400px!important',
        maxHeight: 400,
      },
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        minHeight: '189px!important',
        maxHeight: 189,
        img: {
          minHeight: '189px!important',
          maxHeight: 189,
        },
      },
    },
  },
  container: {
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
  },
}))
