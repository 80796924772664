import { Box, Button, Input, Text, clsx } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'

type IProps = {
  phoneForm: UseFormReturnType<IUsers>
}
const Step4 = ({ phoneForm }: IProps) => {
  return (
    <Box>
      <Text size={12} align="center">
        Bạn được giới thiệu?
      </Text>
      <Text my={10} size={24} mt={5} weight="bold" align="center">
        NHẬP MÃ GIỚI THIỆU
      </Text>
      <Text size={12} align="center">
        Nếu không có xin vui lòng bỏ qua bước này
      </Text>
      <Input
        mt={20}
        mb={10}
        styles={{
          input: {
            textAlign: 'center',
          },
        }}
        variant="filled"
        placeholder="Điền mã giới thiệu"
        radius={10}
        invalid={phoneForm.errors.introCode}
        {...phoneForm.getInputProps('introCode')}
        error="false"
      />
      <Button
        className={clsx('space-center btnsignin', [
          {
            active: phoneForm.values.introCode.length >= 6,
          },
        ])}
        color="#D9D9D9"
      >
        Bỏ qua bước này
      </Button>
    </Box>
  )
}
export default Step4
