import { Banner, Swiper } from 'common'
import { Box } from '@mantine/core'
import { Photos } from 'assets'
import { Yogurt as YogurtComponent } from 'components'

export default function Yogurt() {
  return (
    <Box>
      <Swiper.Carousel
        height="md"
        data={[Photos.Yogurt, Photos.Yogurt2, Photos.Yogurt3]}
      />
      <YogurtComponent />
      <Banner.Free />
    </Box>
  )
}
