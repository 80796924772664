import {
  ActionIcon,
  BackgroundImage,
  Box,
  Group,
  LoadingOverlay,
  Stepper,
} from '@mantine/core'
import { IPath } from 'constants/enum'
import { Slash } from 'common'
import { Svgs } from 'assets'
import { doQueryUser } from 'reducer/User/action'
import { onRecaptchaVerifier, onSignInWithPhoneNumber } from './action'
import { splitName } from 'utils'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect, useState } from 'react'
import { useForm } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import { usePrevious } from '@mantine/hooks'
import { validateFormUser } from 'constants/validate'
import Step0 from './Step0'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import background from 'assets/images/background.png'
import useStyles from './styles'

function SignIn() {
  const { classes } = useStyles()
  const { signInWithPhoneNumber, queryUserResult, confirmationResultUser } =
    useAppSelector(state => state)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [countDown] = useState(60)
  const [active, setActive] = useState(0)
  const nextStep = () =>
    setActive(current => (current < 3 ? current + 1 : current))
  // const prevStep = () =>
  //   setActive(current => (current > 0 ? current - 1 : current))
  const phoneForm = useForm<IUsers>({
    initialValues: {
      txtPhone: '',
      firstName: '',
      lastName: '',
      email: '',
      avarar: '',
      member: '',
      dob: '',
      gender: '',
      introCode: '',
      property: {
        providerId: 'phone',
        phoneVerified: false,
      },
    },
    validate: validateFormUser(),
    validateInputOnChange: true,
  })
  const onSignIn = async ({ txtPhone }: IUsers) => {
    onRecaptchaVerifier()
    dispatch(onSignInWithPhoneNumber(txtPhone))
  }

  const previousValue = usePrevious({
    queryUserResult,
    signInWithPhoneNumber,
    confirmationResultUser,
  })
  useEffect(() => {
    if (
      previousValue?.queryUserResult.data !== queryUserResult?.data &&
      queryUserResult.status.isSucceeded &&
      queryUserResult?.data
    ) {
      if (
        queryUserResult.data?.length !== 0 &&
        queryUserResult.status.isSucceeded
      ) {
        navigate(IPath.User)
      } else {
        if (phoneForm.values.property.providerId !== 'phone') {
          setActive(2)
        } else {
          // next step 2 with phone number
          nextStep()
        }
      }
    }
    if (
      previousValue?.signInWithPhoneNumber.data !==
        signInWithPhoneNumber?.data &&
      signInWithPhoneNumber.status.isSucceeded
    ) {
      // next step 1
      nextStep()
    }

    const { data, status } = confirmationResultUser
    if (
      previousValue?.confirmationResultUser.data !==
        confirmationResultUser?.data &&
      status.isSucceeded &&
      data?.user
    ) {
      const newphoneForm = {
        email: data.user.email as string,
        firstName: splitName(data.user.displayName).firstName,
        lastName: splitName(data.user.displayName).lastName,
        txtPhone: data.user.phoneNumber as string,
        avatar: data.user.photoURL,
        property: {
          providerId: data.providerId as string,
          phoneVerified: data.user.phoneNumber ? true : false,
        },
      }
      phoneForm.setValues(newphoneForm)

      dispatch(
        doQueryUser({
          txtPhone: newphoneForm.txtPhone,
          email: newphoneForm.email,
        })
      )
    }
  }, [queryUserResult, signInWithPhoneNumber, confirmationResultUser])

  useEffect(() => {
    const unmountComponent = () => {
      setActive(0)
    }
    return () => {
      unmountComponent()
    }
  }, [])
  const loading =
    signInWithPhoneNumber.status?.isLoading ||
    confirmationResultUser.status.isLoading ||
    queryUserResult.status.isLoading
  return (
    <Box>
      <BackgroundImage src={background} className={classes.signInWrapper}>
        <Group position="center">
          <ActionIcon w={60} h={38} variant="transparent">
            <Svgs.LogoIcon />
          </ActionIcon>
        </Group>
        <Box className="content">
          <Box>
            <LoadingOverlay
              loader={<Slash.SlashScreen />}
              visible={!!loading}
            />
            <Stepper
              styles={{
                steps: {
                  display: 'none',
                },
                content: {
                  padding: 0,
                },
              }}
              active={active}
              onStepClick={setActive}
              breakpoint="sm"
            >
              <Stepper.Step>
                <Step0 phoneForm={phoneForm} onSignIn={onSignIn} />
              </Stepper.Step>
              <Stepper.Step>
                <Step1
                  phoneForm={phoneForm}
                  countDown={countDown}
                  phoneNumber={phoneForm.values.txtPhone}
                />
              </Stepper.Step>
              <Stepper.Step>
                <Step2 phoneForm={phoneForm} nextStep={nextStep} />
              </Stepper.Step>
              <Stepper.Step>
                <Step3 />
              </Stepper.Step>
              <Stepper.Step>
                <Step4 phoneForm={phoneForm} />
              </Stepper.Step>
            </Stepper>
          </Box>
        </Box>
      </BackgroundImage>
    </Box>
  )
}

export default SignIn
