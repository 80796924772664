import './styles.scss'
import 'swiper/css/effect-creative'
import 'swiper/scss'
import 'swiper/scss/effect-coverflow'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper'
import { Box, Container, Image } from '@mantine/core'
import { Photos } from 'assets'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { useStyles } from './styles'

type IProps = {
  data?: string[]
  swiperProps?: SwiperProps
  height?: 'xs' | 'md' | 'lg'
}
export const Carousel = ({
  data = [Photos.Caruasel1, Photos.Caruasel2, Photos.Caruasel3],
  height,
  swiperProps,
}: IProps) => {
  const { classes, cx } = useStyles()
  return (
    <Container fluid className={cx({ [classes.container]: height === 'xs' })}>
      <Box
        mb={30}
        component={Swiper}
        navigation={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        modules={[Autoplay, Navigation, Pagination, EffectCreative]}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        grabCursor={true}
        {...swiperProps}
        className={cx(classes.swiperContainer, {
          [classes.swiperContainerSmall]: height === 'xs',
          [classes.swiperContainerMedium]: height === 'md',
        })}
      >
        {data.map((val, idx) => (
          <SwiperSlide key={idx}>
            <Image radius="md" src={val} alt="Slide image" />
          </SwiperSlide>
        ))}
      </Box>
    </Container>
  )
}

export const Animation = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Swiper
      allowTouchMove={false}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      creativeEffect={{
        prev: {
          shadow: true,
          origin: 'left center',
          translate: ['-5%', 0, -200],
          rotate: [0, 100, 0],
        },
        next: {
          origin: 'right center',
          translate: ['5%', 0, -200],
          rotate: [0, -100, 0],
        },
      }}
      modules={[Autoplay]}
      className="animation"
    >
      {children}
    </Swiper>
  )
}
