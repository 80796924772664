import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  newMemberWrapper: {
    boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
    padding: 40,
    borderRadius: 10,
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      padding: 20,
    },
  },
}))
