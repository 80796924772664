import { Box, Text, clsx } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { onConfirmationResult } from 'reducer/SignIn/actions'
import { onSignInWithPhoneNumber } from '../action'
import { useAppDispatch } from 'app/hooks'
import { useEffect, useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import OtpInput from '../OtpInput'
const Step1 = ({
  phoneForm,
  countDown,
  phoneNumber,
}: {
  phoneForm: UseFormReturnType<IUsers>
  countDown: number
  phoneNumber: string
}) => {
  const dispatch = useAppDispatch()
  const [otp, setOtp] = useState('')
  const [resetCountDown, setResetCountDown] = useState(false)
  useEffect(() => {
    if (otp.trim().length === 6) {
      dispatch(onConfirmationResult(otp))
    }
  }, [otp])

  const dateTime = useMemo(() => {
    return Date.now() + 60 * 1000
  }, [resetCountDown])
  return (
    <Box>
      <Text size={12} align="center">
        Bắt đầu
      </Text>
      <Text my={10} size={24} mt={5} weight="bold" align="center">
        XÁC NHẬN MÃ OTP
      </Text>
      <Text size={12} align="center">
        Mã xác thực gồm 6 số đã được gửi đến số điện thoại{' '}
        <Text>{phoneNumber.replace('0', '+84 ')}</Text>
      </Text>
      <Text my={10} size={12} align="center" weight="bold">
        Nhập mã để tiếp tục
      </Text>
      <Box>
        <OtpInput
          value={otp}
          valueLength={6}
          onChange={(value: string) => setOtp(value)}
        />
      </Box>
      <Text hidden={countDown !== 0} mt={10} size={12} align="center">
        *Mã xác thực không hợp lệ hoặc hết hiệu lực*
      </Text>
      <Text mt={10} size={12} align="center">
        Bạn không nhận được mã?
        <Countdown
          date={dateTime}
          intervalDelay={0}
          precision={3}
          renderer={({ seconds }) => (
            <Text
              className={clsx('cursor', [{ disabled: seconds !== 0 }])}
              onClick={() => {
                onSignInWithPhoneNumber(phoneForm.values.txtPhone)
                setResetCountDown(preVal => !preVal)
              }}
              component="span"
              color="#FE5F51"
            >
              {' '}
              Gửi lại ({seconds}s)
            </Text>
          )}
        />
      </Text>
    </Box>
  )
}
export default Step1
