import { createStyles } from '@mantine/core'

export default createStyles(_theme => ({
  rateWrapper: {
    padding: '40px',
    zIndex: 2,
    overflow: 'auto',
    '.mantine-ActionIcon-root': {
      position: 'absolute',
      top: 10,
      left: 10,
    },
    '.btn': {
      background: '#000',
      borderRadius: 10,
      height: 40,
      width: '100%',
      '.mantine-Button-label': {
        fontSize: 14,
        fontweight: 700,
      },
      '&:hover': {
        background: '#fff',
        color: '#000',
        border: '1px solid #000',
      },
    },
  },
}))
