import { User } from 'reducer'
import { auth } from 'config/firebase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { onClearConfirmationResultUser } from 'reducer/SignIn/reducers'
import { signOut } from 'firebase/auth'
import { store } from 'index'

export const doSignOut = createAsyncThunk(
  'Signout',
  async (_params, thunkAPI) => {
    try {
      store.dispatch(onClearConfirmationResultUser())
      store.dispatch(User.onClearQueryUserResult())
      const res = await signOut(auth)
      console.log('Sign-out successful.', res)
      return {}
    } catch (error) {
      console.log('Signout', error)
      return thunkAPI.rejectWithValue(error)
    }
  }
)
