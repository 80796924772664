import { Banner, Swiper } from 'common'
import { Box } from '@mantine/core'
import { Juice as JuiceComponent } from 'components'
import { Outlet } from 'react-router-dom'
import { Photos } from 'assets'

export default function Juice() {
  return (
    <Box>
      <Swiper.Carousel
        height="md"
        data={[
          Photos.Juice1,
          Photos.Juice2,
          Photos.Juice3,
          Photos.Juice4,
          Photos.Juice5,
        ]}
      />
      <JuiceComponent />
      <Banner.Free />
      <Outlet />
    </Box>
  )
}
