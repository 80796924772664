import { createStyles } from '@mantine/core'

export const useStyles = createStyles(theme => ({
  personalInfoWrapper: {
    '.content_box': {
      boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)',
      padding: 20,
      borderRadius: 10,
      '.code': {
        marginTop: 20,
        position: 'relative',
        height: 40,
        '.txt_intro': {
          display: 'none',
        },
        '.code_intro': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          fontSize: 14,
          fontWeight: 700,
          '.code_intro-title': {
            height: '100%',
            width: 150,
            borderRadius: '10px 0px 0 10px',
            backgroundColor: '#E5E5E5',
          },
          '.copy_btn': {
            cursor: 'pointer',
            flex: 1,
            background: '#F5F5F5',
            borderRadius: '0 10px 10px 0px',
          },
        },
      },
      '.mantine-Input-input': {
        height: 40,
        borderRadius: 10,
        background: '#f5f5f5',
        paddingLeft: 20,
        border: 'unset',
        '&:focus': {
          border: '2px solid #000',
        },
      },
      '&.pt0': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    '.mantine-Button-root': {
      borderRadius: 10,
      height: 40,
      width: '100%',
      fontWeight: 400,
      '.mantine-Button-inner': {
        justifyContent: 'space-between',
        '.mantine-Button-label': {
          fontSize: 14,
        },
      },
      '&.btnsignin': {
        background: '#000',
        color: theme.white,
        transition: 'all 0.3s linear',
        '&.hover:hover': {
          opacity: 0,
        },
      },
    },
    '.space-center': {
      '.mantine-Button-inner': {
        justifyContent: 'center',
      },
    },
  },
}))
