import { Box, Center, Flex, Text, TextInput } from '@mantine/core'
import { KeyboardEvent, useState } from 'react'
import { Svgs } from 'assets'

type IProps = {
  icon?: React.ReactNode
  rightSection?: React.ReactNode
  placeholder?: string
  rightSectionWidth?: number
  onChange: (value: string) => void
  value?: string
  error?: React.ReactNode
}

export default function SearchBar({
  icon,
  rightSection,
  placeholder,
  rightSectionWidth,
  onChange,
  value,
  error,
}: IProps) {
  const [txtOrderLookup, setTxtOrderLookup] = useState<string>(value ?? '')
  const onHandleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onChange(txtOrderLookup)
    }
  }

  return (
    <Box>
      <TextInput
        value={txtOrderLookup}
        onKeyDown={onHandleKeyDown}
        onChange={event => setTxtOrderLookup(event.currentTarget.value)}
        mt={20}
        icon={icon}
        placeholder={placeholder}
        iconWidth={40}
        rightSection={
          <Flex
            onClick={() => onChange(txtOrderLookup)}
            justify="center"
            align="center"
          >
            {rightSection}
          </Flex>
        }
        rightSectionWidth={rightSectionWidth}
        radius={10}
        error={
          error && (
            <Center inline mt={20}>
              <Svgs.ErrorIcon />
              <Text ml={10}>{error}</Text>
            </Center>
          )
        }
        styles={{
          wrapper: {
            borderRadius: '10px',
            overflow: 'hidden',
          },
          input: {
            fontWeight: 'bold',
            height: 40,
            border: 'unset',
            backgroundColor: '#f5f5f5',
          },
          rightSection: {
            backgroundColor: '#E5E5E5',
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  )
}
