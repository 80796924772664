import { Container } from '@mantine/core'
import { Product2, Tabs } from 'common'
import { doQueryProducts } from 'reducer/Products/actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'

export default function Item() {
  const dispatch = useAppDispatch()
  const { products } = useAppSelector(state => state)
  useEffect(() => {
    dispatch(doQueryProducts(['Item']))
  }, [])
  return (
    <Container fluid>
      <Tabs.Tabs
        title="QUÀ TẶNG"
        data={[
          {
            tabLabel: 'QUÀ TẶNG',
            component: (
              <Product2 data={products.data} status={products.status} />
            ),
          },
        ]}
      />
    </Container>
  )
}
