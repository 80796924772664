import { createSlice } from '@reduxjs/toolkit'
import { doRegisterInfor } from './actions'

const initialState: IQueryState<string | null> = {
  data: null,
  status: {},
}

export const registerInfor = createSlice({
  name: 'REGISTER_INFOR',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doRegisterInfor.pending, state => {
      state.status.isLoading = true
      state.status.isFailed = false
      state.status.isSucceeded = false
      state.status.errorMessage = null
    }),
      builder.addCase(doRegisterInfor.rejected, (state, action) => {
        state.status.errorMessage = action.payload
        state.status.isLoading = false
        state.status.isSucceeded = false
        state.status.isFailed = true
      }),
      builder.addCase(doRegisterInfor.fulfilled, (state, action) => {
        state.status.errorMessage = null
        state.status.isLoading = false
        state.status.isSucceeded = true
        state.status.isFailed = false
        state.data = action.payload
      })
  },
})
