import { createStyles, keyframes } from '@mantine/core'

export const scrolling = keyframes({
  '0%': { transform: 'translateY(0)' },
  '100%': { transform: 'translateY(-144vw)' },
})

export const useStyles = createStyles(theme => ({
  detailNewFeedWrapper: {
    '.imgPhoto': {
      borderRadius: 10,
      // maxWidth: 620,
      // maxHeight: 414,
      width: '100%',
    },
    '.title': {
      margin: '80px 0',
      fontSize: '3rem',
      fontWeight: 700,
    },
    '.contentRight': {
      overflow: 'hidden',
      margin: '0 auto',
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      '.title': {
        margin: '30px 0',
        fontSize: '2.5rem',
      },
      '.contentRight': {
        gap: 20,
      },
    },
  },
  marqueeWrapper: {
    '.marquee-content ': {
      '.mantine-Group-root': {
        '.imgPhoto': {
          width: '100%',
          // maxWidth: 288,
          borderRadius: 10,
          marginBottom: 44,
        },
        gap: 44,
        animation: `${scrolling} 20s linear infinite`,
        '.secondHalf': {
          marginBottom: 90,
        },
        '.mantine-SimpleGrid-root div:nth-of-type(even)': {},
      },
      '.marquee-item img': {
        display: 'block',
        width: '100%',
      },
    },
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      '.marquee-content ': {
        '.mantine-Group-root': {
          gap: 20,
          '.imgPhoto': {
            marginBottom: 20,
          },
        },
      },
    },
  },
}))
