import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  pickLocationWrapper: {
    '.mantine-Select-input': {
      backgroundColor: '#F5F5F5!important',
      borderRadius: 10,
    },
  },
}))
