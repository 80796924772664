import { Box, Text } from '@mantine/core'
import { CardMember } from 'common'
import { Svgs } from 'assets'
import { useStyles } from './styles'

const CARD_MEMBER: IMembers[] = [
  {
    rank: 'Thành Viên Bạc',
    point: 1000,
    name: 'Dang',
    powers: [
      {
        icon: <Svgs.MemberGlass />,
        description:
          'Tặng bạn mới 1 sản phẩm nước bất kì khi đăng ký tài khoản',
      },
      {
        icon: <Svgs.MemberBirth />,
        description:
          'Tặng combo 2 chai nước ép 250ml vào ngày sinh nhật của bạn',
      },
    ],
  },
  {
    rank: 'Thành Viên Vàng',
    point: 1000,
    name: 'Dang',
    powers: [
      {
        icon: <Svgs.MemberTag />,
        description: 'Tặng 1 voucher giảm giá 50% (tối đa 100k)',
      },
      {
        icon: <Svgs.MemberCoffee />,
        description: 'Tặng 1 ly sứ trắng in logo ONE',
      },
      {
        icon: <Svgs.MemberPercent />,
        description: 'Giảm giá 10% tất cả đơn hàng',
      },
      {
        icon: <Svgs.MemberBirth />,
        description:
          'Tặng combo 2 chai nước ép 350ml vào ngày sinh nhật của bạn',
      },
    ],
  },
  {
    rank: 'Thành Viên Ruby',
    point: 1000,
    name: 'Dang',
    powers: [
      {
        icon: <Svgs.MemberTag />,
        description: 'Tặng 1 voucher giảm giá 50% (tối đa 150k)',
      },
      {
        icon: <Svgs.MemberLogo />,
        description: 'Tặng 1 áo thun trắng & nón kết',
      },
      {
        icon: <Svgs.MemberPercent />,
        description: 'Giảm giá 15% tất cả đơn hàng',
      },
      {
        icon: <Svgs.MemberBirth />,
        description:
          'Tặng combo 3 chai nước ép 350ml vào ngày sinh nhật của bạn',
      },
    ],
  },
]
const SpecialBenefits = () => {
  const { classes, cx } = useStyles()

  return (
    <Box className={cx(classes.specialBenefitsWrapper)}>
      <Box>
        <Box className="content_left">
          <Text size={24} mb={20} weight="bold">
            Quyền Lợi Đặc Biệt
          </Text>
          <Text
            className="description"
            size={12}
            dangerouslySetInnerHTML={{
              __html: `<p>Khi tham gia đăng ký tài khoản và trở thành thành viên của ONE, bạn nhận được nhiều chính sách ưu đãi &amp; quà tặng từ chúng tớ!<br> </p> <p><br></p> <p>Số điểm bạn đạt được sẽ tương ứng với cập bậc tăng dần. Cấp bậc thẻ thành viên càng cao, chế độ ưu đãi càng nhiều. ONE luôn muốn mang lại quyền lợi cho mọi người cao nhất!</p> <p><br> </p> <p><strong>THÀNH VIÊN BẠC</strong></p> <ul> <li>Dành cho bạn mới!</li> <li>Yêu cầu đã dùng dịch vụ bên ONE tối thiểu một lần<br> </li> </ul> <p><br></p> <p><strong>THÀNH VIÊN VÀNG</strong></p> <ul> <li>Số điểm phải đạt là 3000 ONE (chỉ tính điểm được quy đổi từ chi phí mua hàng, không tính điểm từ điểm danh, chương trình, game khác)<br> </li> </ul> <p><br></p> <p><strong>THÀNH VIÊN RUBY</strong></p> <ul> <li>Số điểm phải đạt là 5000 ONE (chỉ tính điểm được quy đổi từ chi phí mua hàng, không tính điểm từ điểm danh, chương trình, game khác)&nbsp;</li> </ul> <p><br></p>`,
            }}
          />
        </Box>
        <Box className="content_right">
          {CARD_MEMBER.map((item, idx) => (
            <Box key={idx} maw={374} mx="auto">
              <CardMember type="modal" {...item} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
export default SpecialBenefits
