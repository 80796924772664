import { createStyles } from '@mantine/core'

export default createStyles(theme => ({
  vouchersWrapper: {
    '.mantine-TextInput-rightSection': {
      width: '176px',
    },
    '.watchMore': {
      cursor: 'pointer',
      svg: {
        height: 20,
        width: 20,
        transform: 'rotateY(180deg)',
        rect: {
          fill: '#c4c4c4',
        },
      },
    },
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      '.mantine-TextInput-rightSection': {
        width: '110px',
      },
    },
  },
  voucherTrending: {
    // padding: '0 20px',
    position: 'relative',
    '.mantine-SimpleGrid-root': {
      maxHeight: 400,
      overflow: 'auto',
      padding: '20px 20px 100px 10px',
      '&:after': {
        zIndex: 1,
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 'calc(100% - 5px)',
        height: '100px',
        background:
          'linear-gradient(0deg, rgb(255 255 255) 50%, rgb(255 255 255 / 20%) 100%)',
      },
    },
  },
}))
