import { Navigate } from 'react-router'
import { useAppSelector } from 'app/hooks'

type IProps = {
  children: JSX.Element
}

export default function PrivateRoute({ children }: IProps) {
  const { confirmationResultUser } = useAppSelector(state => state)
  const auth = confirmationResultUser.data
  return auth ? children : <Navigate to="/" />
}
