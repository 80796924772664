import { Box, Text } from '@mantine/core'
import { PRIVACY_POLICY_DATA } from 'constants/index'

export default function Policy() {
  return (
    <Box>
      <Text mt={-20} mb={30} size={64} weight="700">
        Chính sách riêng tư
      </Text>
      {PRIVACY_POLICY_DATA.map((val, idx) => (
        <Text key={idx} size={14} weight="700" sx={{ lineHeight: '24px' }}>
          {val.title}
          <Text
            size={14}
            sx={{ lineHeight: '24px' }}
            dangerouslySetInnerHTML={{ __html: val.description }}
            weight="400"
          />
        </Text>
      ))}
    </Box>
  )
}
