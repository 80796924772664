import { ActionIcon, Anchor, Button, Center, Text } from '@mantine/core'
import { ContainerShadow } from 'common'
import { IPath } from 'constants/enum'
import { Link, useParams } from 'react-router-dom'
import { Svgs } from 'assets'
import { onClearCarts } from 'reducer/Carts'
import { onClearDoOrder } from '../Delivery/reducer'
import { onOrderUpdateStatus } from '../Delivery/actions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useEffect } from 'react'

export default function CartSuccess() {
  const dispatch = useAppDispatch()
  let { id } = useParams()
  const { doOrder } = useAppSelector(state => state)
  useEffect(() => {
    dispatch(onClearCarts())
    dispatch(
      onOrderUpdateStatus({
        id: id as string,
        status: 'paid',
      })
    )
    const unmountComponent = () => {
      if (doOrder.status.isSucceeded) {
        dispatch(onClearDoOrder())
      }
    }
    return () => {
      unmountComponent()
    }
  }, [])
  return (
    <ContainerShadow p={50} isHiddenNavLink my={60}>
      <ActionIcon
        size={50}
        mt={8}
        mb={11}
        sx={{
          margin: 'auto',
          svg: {
            height: 50,
            width: 50,
          },
        }}
      >
        <Svgs.CheckCircle />
      </ActionIcon>
      <Text size={24} weight="bold" align="center" mb={28}>
        Đặt hàng thành công
      </Text>
      <Center>
        <Text
          mb={28}
          size={14}
          sx={{
            maxWidth: '380px',
          }}
        >
          ONE tiếp nhận thành công thông tin đặt hàng từ bạn Hãy đợi ONE chuẩn
          bị sản phẩm & giao đến ngay nhé!
        </Text>
      </Center>
      <Center>
        <Button
          component={Link}
          to={IPath.Order_Lookup}
          state={id}
          mb={10}
          color="dark"
          radius="xl"
          sx={{
            margin: 'auto',
            height: 50,
            width: 375,
          }}
        >
          <Text size={16} weight={700} align="center">
            Tra cứu đơn hàng
          </Text>
        </Button>
      </Center>
      <Text size={14} align="center" mb={25}>
        Mã đơn hàng:{' '}
        <Text size={16} component="span" weight={700}>
          #{id}
        </Text>
      </Text>
      <Text size="sm" align="center" mb={8}>
        Điều chỉnh thông tin đặt hàng? Liên hệ hotline{' '}
        <Anchor
          size="sm"
          href="/"
          target="_blank"
          sx={{
            color: '#46CFC7',
          }}
        >
          0933 22 00 78
        </Anchor>
      </Text>
    </ContainerShadow>
  )
}
