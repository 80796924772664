import {
  ActionIcon,
  Avatar,
  Box,
  List,
  Select,
  SelectItem,
  Tooltip,
} from '@mantine/core'
import { IPath } from 'constants/enum'
import { Link } from 'react-router-dom'
import { Modal } from 'common'
import { SIGNATURE } from 'constants/juice'
import { Svgs } from 'assets'
import { forwardRef, useState } from 'react'
import { useAppSelector } from 'app/hooks'
import {
  useClickOutside,
  useDisclosure,
} from '@mantine/hooks'
import Card from 'common/Product2/Card'
import SignIn from './SignIn'
import useStyles from './styles'

type IProps = {
  width?: string
}

const newSelectItem: SelectItem[] = [
  ...SIGNATURE,
].map((val, _idx) => ({
  ...val,
  label: val.name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ''),
  value: val.id as string,
  group: val.group,
}))
export default function Search({
  width = '100%',
}: IProps) {
  const { carts, confirmationResultUser } =
    useAppSelector(state => state)
  const [txtSearchText, setTxtSearchText] =
    useState<string | null>(null)
  const [isShowSearch, setIsShowSearch] =
    useState<boolean>(false)
  const [opened, { close, open }] =
    useDisclosure(false)
  const { classes } = useStyles()

  const useClickOutsideRef =
    useClickOutside<HTMLInputElement>(() =>
      setIsShowSearch(false)
    )

  return (
    <Box
      className={classes.searchWrapper}
      ref={useClickOutsideRef}
    >
      <List
        styles={{
          root: {
            display: 'flex',
            height: '40px',
          },
          item: {
            fontSize: 16,
            padding: '10px',
            position: 'relative',
            cursor: 'pointer',
          },
        }}
        listStyleType="none"
      >
        <List.Item
          onClick={() => {
            setIsShowSearch(true)
          }}
        >
          <Svgs.SearchV2 />
        </List.Item>
        <List.Item>
          <Link to={IPath.Order_Lookup}>
            <Svgs.Paper />
          </Link>
        </List.Item>
        <List.Item>
          <Tooltip
            position="top"
            label={carts.totalProductList}
            opened={true}
            radius="xl"
            styles={{
              tooltip: {
                width: '25px',
                height: '25px',
                fontSize: 14,
                justifyContent: 'center',
                alignItems: 'center',
                display:
                  carts.totalProductList === 0
                    ? 'none'
                    : 'flex',
                fontWeight: 600,
                top: '-12px!important',
                left: '17px!important',
              },
            }}
          >
            <Link to={IPath.Cart}>
              <Svgs.Cartv2 />
            </Link>
          </Tooltip>
        </List.Item>
        {!confirmationResultUser.data && (
          <List.Item onClick={open}>
            <Svgs.User />
          </List.Item>
        )}
        {confirmationResultUser.data && (
          <List.Item>
            <Avatar
              component={Link}
              to={IPath.User}
              radius={100}
              size={24}
              src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=250&q=80"
            />
          </List.Item>
        )}
      </List>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          opacity: isShowSearch ? 1 : 0,
          visibility: isShowSearch
            ? 'visible'
            : 'hidden',
          width: isShowSearch ? width : '50%',
          transition: 'all 0.4s ease-in-out',
        }}
      >
        <Select
          aria-label="Tìm kiếm món nhanh"
          value={txtSearchText}
          onChange={val => setTxtSearchText(val)}
          searchable
          icon={
            <ActionIcon
              size={24}
              variant="transparent"
            >
              <Svgs.SearchWhiteIcon />
            </ActionIcon>
          }
          initiallyOpened
          maxDropdownHeight={600}
          itemComponent={Items}
          placeholder="Tìm kiếm món nhanh"
          nothingFound="No options"
          styles={{
            input: {
              display: 'block',
              color: 'white',
              background: 'black',
              borderRadius: '20px',
            },
            item: {
              '&[data-selected]': {
                backgroundColor: 'unset',
                '&, &:hover': {
                  backgroundColor: 'unset',
                },
              },
              '&[data-hovered]': {
                backgroundColor: 'unset',
              },
            },
            rightSection: {
              display: 'none',
            },
            dropdown: {
              borderRadius: '10px',
              border: 'unset',
              boxShadow:
                'rgb(0 0 0 / 10%) 0px 4px 12px',
            },
          }}
          data={newSelectItem}
        />
      </Box>
      <Modal
        onClose={close}
        opened={opened}
        fullScreen
        padding={0}
      >
        <SignIn />
      </Modal>
    </Box>
  )
}
type IPropsSelectItems =
  {} & React.ComponentPropsWithoutRef<'div'> &
    IProduct

const Items = forwardRef<
  HTMLDivElement,
  IPropsSelectItems
>(
  (
    {
      name,
      photo,
      id,
      mainIngredient,
      rickText,
      hasTag,
      moreCombina,
      aboutProduct,
      mlAndPrice,
      signature,
      group,
      sugars,
    }: IPropsSelectItems,
    ref
  ) => {
    return (
      <Box ref={ref} pb={10} pt={10}>
        <Card
          isSmall
          id={id}
          name={name}
          photo={photo}
          mainIngredient={mainIngredient}
          rickText={rickText}
          hasTag={hasTag}
          moreCombina={moreCombina}
          aboutProduct={aboutProduct}
          mlAndPrice={mlAndPrice}
          signature={signature}
          group={group}
          sugars={sugars}
        />
      </Box>
    )
  }
)
