import { createStyles } from '@mantine/core'

export const useStyles = createStyles(_theme => ({
  deliveryWrapper: {
    marginTop: '40px',
    '.infor_delivery': {
      '&>div': {
        minHeight: '84px',
        borderBottom: '1px solid #F5F5F5',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '5px',
        '.title': {
          fontSize: '16px',
          fontWeight: 'bold',
        },
        '.description': {
          fontSize: '14px',
        },
        '&:last-child': {
          borderBottom: 'unset',
        },
      },
    },
  },
}))
