import { Box, Center, Title } from '@mantine/core'
import { ContainerShadow } from 'common'
import { useAppSelector } from 'app/hooks'
import Delivery from './Delivery'
import YourCart from './YourCart'

export default function CartComponent() {
  const { carts } = useAppSelector(state => state)
  const isCartsEmpty = carts.productList.length <= 0
  return (
    <Box>
      {!isCartsEmpty && (
        <Box>
          <ContainerShadow p={50} isHiddenNavLink>
            <YourCart productList={carts} />
          </ContainerShadow>
          <ContainerShadow boxShadow="unset" isHiddenNavLink pl={50} pr={50}>
            <Delivery type="typeInfor" />
          </ContainerShadow>
        </Box>
      )}
      {isCartsEmpty && (
        <Center h="60vh">
          <Title>Bạn chưa có sản phẩm nào trong giỏ hàng</Title>
        </Center>
      )}
    </Box>
  )
}
