import {
  Box,
  Button,
  Center,
  Divider,
  Input,
  Stack,
  Text,
  clsx,
} from '@mantine/core'
import { Svgs } from 'assets'
import { UseFormReturnType } from '@mantine/form'
import { signGoogle, signInFacebook } from 'reducer/SignIn/actions'
import { useAppDispatch } from 'app/hooks'
import _ from 'lodash'
const Step0 = ({
  phoneForm,
  onSignIn,
}: {
  phoneForm: UseFormReturnType<IUsers>
  isLoading?: boolean
  onSignIn: (values: IUsers) => void
}) => {
  const dispatch = useAppDispatch()
  const isvalueFormEmtpy = phoneForm.values.txtPhone === ''
  const isErrors = _.isEmpty(phoneForm.errors)
  const isDisableCreate = !isvalueFormEmtpy && isErrors
  return (
    <Box>
      <Text size={12} align="center">
        Đăng ký làm thành viên tại
      </Text>
      <Text size={24} mt={5} mb={40} weight="bold" align="center">
        ONE DRINK & FOOD
      </Text>
      <Stack spacing={15}>
        <Input
          type="number"
          iconWidth={92}
          icon={
            <Center>
              <Svgs.Vnflag />{' '}
              <Text
                component="span"
                ml={5}
                pr={10}
                sx={{
                  borderRight: '1px solid #C4C4C4',
                }}
              >
                +84
              </Text>
            </Center>
          }
          variant="filled"
          placeholder="Nhập số điện thoại"
          radius={10}
          invalid={phoneForm.errors.txtPhone}
          {...phoneForm.getInputProps('txtPhone')}
          error="false"
        />
        <Button
          id="sign-in-button"
          type="submit"
          color="#D9D9D9"
          disabled={!isDisableCreate}
          className={clsx('space-center btnsignin', [
            {
              active: isDisableCreate,
            },
          ])}
          mt={15}
          onClick={() => onSignIn(phoneForm.values)}
        >
          Đăng nhập
        </Button>
        {/* <Box id="sign-in-button2"></Box> */}
        <Divider
          color="#C4C4C4"
          label={<Text weight="bold">HOẶC</Text>}
          labelPosition="center"
        />
        <Button
          // onClick={() => onConfirmationResult(otp)}
          className="apple"
          leftIcon={<Svgs.Apple />}
        >
          Đăng nhập bằng Apple
        </Button>
        <Button
          onClick={() => dispatch(signInFacebook())}
          className="facebook"
          leftIcon={<Svgs.FbSignin />}
        >
          Đăng nhập bằng Facebook
        </Button>
        <Button
          onClick={() => dispatch(signGoogle())}
          className="google"
          variant="outline"
          leftIcon={<Svgs.GgSignin />}
        >
          Đăng nhập bằng Google
        </Button>
      </Stack>
    </Box>
  )
}

export default Step0
